import React from "react";
import "../../assets/styles/statisticCard.css";

function StatisticCard({title,value}) {
  return(
    <div className="statistic-card">
      <small className="title">{title}</small>
      <div className="value">{value}</div>
    </div>
  )
}

export default StatisticCard;
