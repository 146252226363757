import React from 'react';
import * as Sentry from '@sentry/browser';
import InternPage500 from "./InternPage500";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      Sentry.showReportDialog({ eventId: eventId })
    });
  }

  render() {
    if (this.state.hasError) {
      return <InternPage500 />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;