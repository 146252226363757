import React  from 'react';
import {useTranslation} from "react-i18next";
import {Button, Dropdown, Menu} from "antd";
import { GB, FR } from 'country-flag-icons/react/3x2';
import {_english, _french, _language} from "../../UsefulConstants";
import TranslationOutlined from "@ant-design/icons/es/icons/TranslationOutlined";

function LanguageSelector({location="in"}) {

  const {t, i18n} = useTranslation();

  function changeLanguage(language){
    i18n.changeLanguage(language);
  }

  const menu =
    <Menu
      items={[
        {
          key: '1',
          label: (
            <div className="cursorView mx-2" onClick={(e)=>changeLanguage('en',e)}>
              <GB className="flag-icon-presentation mr-2"/>
              <span>{t(_english)}</span>
            </div>
          )
        },
        {
          key: '2',
          label: (
            <div className="cursorView mx-2" onClick={(e)=>changeLanguage('fr',e)}>
              <FR className="flag-icon-presentation mr-2"/>
              <span>{t(_french)}</span>
            </div>
          )
        }
      ]}
    />
    ;

  return(
    <>
      {
        location === "in" ? (
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <Button type="text">
              <div>
                <TranslationOutlined className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_language)}</span>
              </div>
            </Button>
          </Dropdown>
        ) : (
          <div>
            <small className="text-underline cursorView" onClick={(e)=>changeLanguage('en',e)}>
              {t(_english)}
            </small>
            <span className="mx-1">|</span>
            <small className="text-underline cursorView" onClick={(e)=>changeLanguage('fr',e)}>
              {t(_french)}
            </small>
          </div>
        )
      }
    </>
  )

}

export default LanguageSelector;