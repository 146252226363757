import React, {useState, useEffect} from 'react';
import {Row, Col, Skeleton} from "antd";
import {Link, useHistory, withRouter, useParams} from "react-router-dom";
import axios from "axios";
import RemoteCall, {getDate, textWithFirstCapitalLetter} from "../../../../UsefulFunctions";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import KycElement from "./KycElement";
import {
  _activity_area, _annual_incomes_range, _kamix_profile, _message, _salary_and_incomes, _socio_professional_category,
  _status, _updated_at, _verified_at
} from "../../../../UsefulConstants";
import "../../../../assets/styles/myAccount.css";

function UserIncomesInfo() {

  const { t } = useTranslation();
  const { from } = useParams();
  const history = useHistory();

  const source = axios.CancelToken.source();

  const [loadingUserIncomesInfo,setLoadingIncomesInfo] = useState(false);
  const [userIncomesInfo,setUserIncomesInfo] = useState(null);
  const [fieldsValues,setFieldsValues] = useState(null);

  function updateUserIncomesInfo(response){
    setUserIncomesInfo(response.user_incomes_info);
  }

  async function getUserIncomesInfo(){

    setLoadingIncomesInfo(l => !l);

    let response = await RemoteCall(
      true,
      "kyc/mandatory/v3/incomes-info",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingIncomesInfo(l => !l);

    if(response != null){
      setUserIncomesInfo(response.user_incomes_info);
      setFieldsValues(response.fields_values);
    }

  }

  useEffect(
    () => {
      getUserIncomesInfo();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={from != null ? "/home" : "/profile/account"}>
              <ArrowLeftOutlined className="icon-sm mb-3" />
            </Link>


            <div className="mb-3 ml-5">
              <small>{t(_kamix_profile)}</small>
              <h5 className="fw-bold">{t(_salary_and_incomes)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loadingUserIncomesInfo ? (
                    <>
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"20px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                    </>
                  ) : (
                    <>

                      {
                        userIncomesInfo != null &&
                        <>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={21}>
                              <div className="text-normal small mb-2">{t(_status)}</div>
                              <div className={"status-"+userIncomesInfo.status.toLowerCase()}>
                                {textWithFirstCapitalLetter(t(userIncomesInfo.status))}
                              </div>
                            </Col>
                            <Col xs={3} />
                          </Row>

                          {
                            (userIncomesInfo.status_message != null && userIncomesInfo.status_message !== "") &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_message)}</div>
                                <div className="text-dark">
                                  {userIncomesInfo.message}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            userIncomesInfo.updated_at != null &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_updated_at)}</div>
                                <div className="text-dark">
                                  {getDate(userIncomesInfo.updated_at).fullDate}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            (userIncomesInfo.verified_at != null && (userIncomesInfo.status === "ACCEPTED" || userIncomesInfo.status === "VALIDATED")) &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_verified_at)}</div>
                                <div className="text-dark">
                                  {getDate(userIncomesInfo.verified_at).fullDate}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          <div className="mb-4"/>


                          <KycElement
                            title={t(_annual_incomes_range)}
                            text={userIncomesInfo.annual_income_range}
                            type={"select"}
                            modification={true}
                            field={'annual_income_range'}
                            status={userIncomesInfo.status}
                            getResponse={updateUserIncomesInfo}
                            fieldsValues={fieldsValues != null && fieldsValues.filter(el => el.field === "annual_income_range")}
                            url={"incomes-info"}
                            editable={userIncomesInfo.editable}
                          />

                          <KycElement
                            title={t(_socio_professional_category)}
                            text={userIncomesInfo.socio_professional_category}
                            type={"select"}
                            modification={true}
                            field={'socio_professional_category'}
                            status={userIncomesInfo.status}
                            getResponse={updateUserIncomesInfo}
                            fieldsValues={fieldsValues != null && fieldsValues.filter(el => el.field === "socio_professional_category")}
                            url={"incomes-info"}
                            editable={userIncomesInfo.editable}
                          />

                          <KycElement
                            title={t(_activity_area)}
                            text={userIncomesInfo.business_sector}
                            type={"select"}
                            modification={true}
                            field={'business_sector'}
                            status={userIncomesInfo.status}
                            getResponse={updateUserIncomesInfo}
                            fieldsValues={fieldsValues != null && fieldsValues.filter(el => el.field === "business_sector")}
                            url={"incomes-info"}
                            editable={userIncomesInfo.editable}
                          />

                        </>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(UserIncomesInfo);