import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton, Input, Button, Modal} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import RemoteCall, {
  getAmountToDisplay,
  getAmountToDisplay2,
  getName, getReceiverV3Mobile,
  getReceiverV3Name,
  WarningModal
} from "../../UsefulFunctions";
import {sessionStorage} from "../../Security";
import {ArrowLeftOutlined, ExclamationCircleFilled} from "@ant-design/icons";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import {
  _amount, _amount_nearest_50_xaf_warning, _back, _balance, _days, _in, _invalid_amount, _maximum_amount_per_transfer,
  _maximum_amount_per_transfer_warning, _message_optional, _next, _no, _ok, _purchase_nkap, _purchase_nkap_infos_1, _reason,
  _remaining_transfer_limit, _remaining_transfer_limit_warning, _required, _to, _yes, yellow_kamix
} from "../../UsefulConstants";
import {isStringEmpty} from "../../stringutils";

const { TextArea } = Input;

function DataEntries() {

  const { t } = useTranslation();
  const source = axios.CancelToken.source();
  const history = useHistory();

  let transactionInformation = sessionStorage.get('transactionInformation');

  let oldAmount = (transactionInformation != null && transactionInformation.tx != null) ? transactionInformation.tx.amount : 0;
  let oldReason = (transactionInformation != null && transactionInformation.tx != null) ? transactionInformation.tx.reason : "";
  let oldMessage = (transactionInformation != null && transactionInformation.tx != null) ? transactionInformation.tx.message : "";
  let tx_type = transactionInformation != null && transactionInformation.tx_type != null ? transactionInformation.tx_type : "transfer";

  const [toggleAmount,setToggleAmount] = useState(false);
  const [amountRecipientWillReceive,setAmountRecipientWillReceive] = useState(null);

  const [loading,setLoading] = useState(false);
  const [loadingUserBalance,setLoadingUserBalance] = useState(false);
  const [loadingUserLimits,setLoadingUserLimits] = useState(false);
  const [userBalance,setUserBalance] = useState(null);
  const [userLimits,setUserLimits] = useState(null);

  const [amount,setAmount] = useState(oldAmount);
  const [amount2,setAmount2] = useState(0);
  const [isAmountValid,setIsAmountValid] = useState(true);
  const [isAmount2Valid,setIsAmount2Valid] = useState(true);
  const [reason,setReason] = useState(oldReason);
  const [isReasonValid,setIsReasonValid] = useState(true);
  const [message,setMessage] = useState(oldMessage);


  function onToggleAmount() {
    setToggleAmount(t => !t);
    setAmountRecipientWillReceive(element => !toggleAmount ? null : element);
    setAmount2(element => !toggleAmount ? 0 : element);
  }

  function onChangeAmount(e){
    setAmount2(e.target.value);
    setIsAmount2Valid(true);
  }

  async function getAmountRecipientWillReceive(e){

    e.preventDefault();

    if(amount2 === 0){
      setIsAmount2Valid(amount2 !== 0);
    }else{

      let amountRecipientWillReceive = 0;

      if(userBalance != null && userBalance.currency != null){
        amountRecipientWillReceive = getAmountToDisplay2(amount2 * userBalance.currency.price);
      }

      await setAmountRecipientWillReceive(amountRecipientWillReceive);
      await setToggleAmount(amountRecipientWillReceive.decision);

      if(!amountRecipientWillReceive.decision){
        setAmount(amountRecipientWillReceive.eur);
        setToggleAmount(false);
      }

    }

  }

  function getAmountToDisplayHere() {
    setAmount(amountRecipientWillReceive.eur);
    setToggleAmount(false);
  }

  function onChangeReason(e){
    setReason(e.target.value);
    setIsReasonValid(true);
  }

  function onChangeMessage(e){
    setMessage(e.target.value);
  }

  async function getUserBalance(){

    setLoadingUserBalance(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/wallet/balances",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserBalance(l => !l);

    if(response != null){
      let balances = response.balances;
      for(let element of balances){
        if(element.currency != null && element.currency.unique_name === "stable"){
          setUserBalance(element);
          break;
        }
      }
    }

  }

  async function getUserLimits(){

    setLoadingUserLimits(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/do/limits",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserLimits(l => !l);

    if(response != null){
      setUserLimits(response.transaction_limits);
    }

  }

  async function onSubmit(e){

    e.preventDefault();

    let isReasonValid = tx_type !== "transfer" || (tx_type === "transfer" && !isStringEmpty(reason));

    if(amount === 0 || !isReasonValid){
      setIsAmountValid(amount !== 0);
      setIsReasonValid(isReasonValid);
    }else if(tx_type === "transfer" && userLimits != null && userLimits.one_transaction != null &&  amount > userLimits.one_transaction.max){
      WarningModal(t(_invalid_amount),t(_maximum_amount_per_transfer_warning));
    }else if(tx_type === "transfer" && userLimits != null && userLimits.sliding != null &&  amount > userLimits.sliding.max){
      WarningModal(t(_invalid_amount),t(_remaining_transfer_limit_warning));
    }else{

      setLoading(l => !l);

      let bodyParam = {
        amount:amount,
        currency:"stable",
        support_currency:"EUR",
        reason:reason,
      };

      if(tx_type === "transfer"){
        bodyParam.receiver_id = transactionInformation.receiver.receiver.kid;
        bodyParam.message = message;
      }

      let response = await RemoteCall(
        true,
        tx_type === "transfer" ? "transactions/do/transfer" : "transactions/do/purchase",
        "post",
        false,
        null,
        bodyParam,
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        let data={};
        data.tx_type = transactionInformation.tx_type;
        data.receiver = transactionInformation.receiver;
        data.userBalance = userBalance;
        data.transaction = response.transaction;
        data.confirm_via_otp = response.confirm_via_otp;
        await sessionStorage.delete('transactionInformation');
        sessionStorage.set('transactionInformation',data);
        history.push('/transfer/data-review')
      }

    }

  }

  useEffect(
    () => {
      if(transactionInformation == null || transactionInformation === ""){
        history.push('/home');
      }else{
        getUserBalance();
        if(transactionInformation.tx_type === "transfer") getUserLimits();
      }
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const currency = userBalance != null && userBalance.currency != null ? userBalance.currency.short_symbols : "";

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={tx_type === "transfer" ? "/transfer/select-receiver" : "/home"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              {
                tx_type === "transfer" ? (
                  <>
                    <small>{t(_to)}</small>
                    <h5 className="fw-bold mb-0">{getReceiverV3Name(transactionInformation.receiver)}</h5>
                    <small className="fw-500">{getReceiverV3Mobile(transactionInformation.receiver)}</small>
                  </>
                ) : (
                  <>
                    <h5 className="fw-bold mb-0">{t(_purchase_nkap)}</h5>
                    <small className="fw-500">{t(_purchase_nkap_infos_1)}</small>
                  </>
                )
              }

            </div>

            <Row className="justify-content-center">
              <Col xs={24} md={22} className=" p-2">

                <form onSubmit={(e)=>onSubmit(e)}>

                  <Row className="my-box bg-white px-3 py-3 mb-3">
                    <Col flex="auto">
                      {
                        loadingUserBalance ? (
                          <>
                            <Skeleton.Button active shape block style={{borderRadius:"2px", width:"30%", height:"29px", marginBottom: "7px"}} />
                            <Skeleton.Button active shape block style={{borderRadius:"2px", width:"50%", height:"20px"}} />
                          </>
                        ) : (
                          <>
                            <h4>{currency}</h4>
                            <div className="d-flex">
                              <span className="mr-1">{t(_balance)} : </span>
                              <span className="mr-1">{userBalance != null && getAmountToDisplay(userBalance.balance, "EUR")}</span>
                              <span>{currency}</span>
                            </div>
                          </>
                        )
                      }
                    </Col>
                    <Col xs={8}>
                      <Input
                        className="my-input-white text-right cursorView"
                        type="text"
                        value={amount}
                        onClick={onToggleAmount}
                        readOnly={true}
                        size="large"
                        disabled={loadingUserBalance}
                      />
                      {
                        !isAmountValid &&
                        <div className="position-absolute ant-form-item-explain-error" style={{right:"0"}}>
                          {(amount === 0)  && t(_required)}
                        </div>
                      }
                    </Col>
                  </Row>

                  {
                    tx_type === "transfer" &&
                    <Row className="my-box bg-white px-3 py-3 small mb-3">
                      <Col xs={12}>
                        <div>{t(_maximum_amount_per_transfer)}</div>
                        <div>{t(_remaining_transfer_limit)}</div>
                      </Col>
                      <Col flex="auto" className="text-right">
                        {
                          loadingUserLimits ? (
                            <Skeleton.Button active shape block style={{borderRadius:"2px", width:"50%", height:"30px"}} />
                          ) : (
                            <>
                              {
                                userLimits != null &&
                                <div>
                                  {
                                    userLimits.one_transaction != null &&
                                    <div>
                                      {/*{getAmountToDisplay(userLimits.one_transaction.min,"EUR") +" "+ currency} {" "}
                                    {t(_to).toLowerCase()} {" "}*/}
                                      {getAmountToDisplay(userLimits.one_transaction.max,"EUR") +" "+ currency}
                                    </div>
                                  }
                                  {
                                    userLimits.sliding != null &&
                                    <div>
                                      {getAmountToDisplay(userLimits.sliding.max,"EUR") +" "+ currency +" "}
                                      {t(_in).toLowerCase() +" "}
                                      {userLimits.sliding.days +" "+ t(_days)}
                                    </div>
                                  }
                                </div>
                              }
                            </>
                          )
                        }
                      </Col>
                    </Row>
                  }

                  <div className="mb-4">
                    <Input
                      type="string"
                      className="my-input-white"
                      value={reason}
                      onChange={onChangeReason}
                      placeholder={t(_reason)}
                    />
                    {
                      !isReasonValid &&
                      <div className="position-absolute ant-form-item-explain-error">
                        {(reason === "")  && t(_required)}
                      </div>
                    }
                  </div>

                  {
                    tx_type === "transfer" &&
                    <div className="mb-4">
                      <TextArea
                        className="my-input-white"
                        value={message}
                        onChange={onChangeMessage}
                        placeholder={t(_message_optional)}
                      />
                    </div>
                  }

                  <div className="d-flex justify-content-center">
                    <Button className="my-button-2 bg-yellow-kamix text-dark half-width" loading={loading} htmlType="submit">
                      {t(_next)}
                    </Button>
                  </div>

                </form>

              </Col>
            </Row>

            <Modal
              visible={toggleAmount}
              onCancel={onToggleAmount}
              title={null}
              footer={null}
              closable={false}
            >

              <Row className="justify-content-center">
                <Col xs={22} md={20}>

                  {
                    (amountRecipientWillReceive == null || !amountRecipientWillReceive.decision) ? (
                      <form onSubmit={(e)=>getAmountRecipientWillReceive(e)}>

                        <div className="text-normal text-center mb-3">{t(_amount)}</div>
                        <Row className="justify-content-center">
                          <Col xs={24} md={20}>
                            <Input
                              className="my-input-white text-right"
                              type="number"
                              value={amount2}
                              onChange={onChangeAmount}
                              size="large"
                            />
                            {
                              !isAmount2Valid &&
                              <div className="position-absolute ant-form-item-explain-error" style={{right:"0"}}>
                                {(amount2 === 0)  && t(_required)}
                              </div>
                            }
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-center mt-3">
                          <Button className="my-button-2 text-dark fw-normal m-1" onClick={onToggleAmount}>
                            {t(_back)}
                          </Button>
                          <Button className="my-button-2 bg-yellow-kamix text-dark m-1" htmlType="submit">
                            {t(_ok)}
                          </Button>
                        </div>

                      </form>
                    ) : (
                      <>
                        <div className="d-flex justify-content-center">
                          <ExclamationCircleFilled style={{fontSize:"40px", color:yellow_kamix}} />
                        </div>
                        <div className="text-normal text-center mt-3 mb-4">{t(_amount_nearest_50_xaf_warning)}</div>
                        <div className="d-flex justify-content-center">
                          <Button className="my-button-2 text-dark fw-normal m-1" onClick={onToggleAmount}>{t(_no)}</Button>
                          <Button className="my-button-2 bg-yellow-kamix text-dark m-1" onClick={getAmountToDisplayHere}>{t(_yes)}</Button>
                        </div>
                      </>
                    )
                  }

                </Col>
              </Row>

            </Modal>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(DataEntries);