import React from 'react';
import { Redirect, Route, Switch} from 'react-router-dom';
import LoginStep1 from "./login/LoginStep1";
import LoginStep2 from "./login/LoginStep2";
import LoginStep3 from "./login/LoginStep3";

function Login() {
  return(
    <>
      <Switch>
        <Route exact path="/sign-in/step-1" name="Step 1" render={props => <LoginStep1 {...props}/>} />
        <Route exact path="/sign-in/step-2" name="Step 2" render={props => <LoginStep2 {...props}/>} />
        <Route exact path="/sign-in/step-3" name="Step 3" render={props => <LoginStep3 {...props}/>} />
        <Redirect from="/sign-in" to="/sign-in/step-1" />
      </Switch>
    </>
  );
}

export default Login;
