import React, {useState, useEffect} from 'react';
import {Row, Col, Skeleton, Button} from "antd";
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined, CopyOutlined,LinkOutlined} from "@ant-design/icons";
import RemoteCall from "../../../UsefulFunctions";
import MyCopyButton from "../../others/MyCopyButton";
import StatisticCard from "../../others/StatisticCard";
import ReceiverPresentation from "../payment/receivers/ReceiverPresentation";
import {
  _invite_friends, _my_sponsor_link, _no_invited_user_yet,
  _points_earned_text, _usage_text, _view_more
} from "../../../UsefulConstants";

function InviteFriends() {

  const { t } = useTranslation();
  const { location } = useParams();
  const history = useHistory();
  const source = axios.CancelToken.source();

  const size = 10;
  const [loading,setLoading] = useState(false);
  const [loadingGodChildren,setLoadingGodChildren] = useState(false);
  const [loadingMoreGodChildren,setLoadingMoreGodChildren] = useState(false);
  const [link,setLink] = useState('');
  const [godChildren,setGodChildren] = useState([]);
  const [points,setPoints] = useState(0);
  const [count,setCount] = useState(0);
  const [page,setPage] = useState(1);

  async function getUserLink(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "users/sponsor-link",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      setLink(response.sponsor_link);
    }

  }

  async function getGodChildren(){

    setLoadingGodChildren(l => !l);

    let response = await RemoteCall(
      true,
      "users/invited",
      "get",
      {
        page:page,
        size:size
      },
      null,
      null,
      source.token,
      history
    );

    setLoadingGodChildren(l => !l);

    if(response != null){
      setGodChildren(response.invited != null ? response.invited : []);
      setCount(response.count);
      setPoints(response.points);
    }

  }

  async function getMoreGodChildren(){

    await setPage(page => page+1);
    setLoadingMoreGodChildren(l => !l);

    let response = await RemoteCall(
      true,
      "users/invited",
      "get",
      {
        page:page,
        size:size
      },
      null,
      null,
      source.token,
      history
    );

    setLoadingMoreGodChildren(l => !l);

    if(response != null){
      setGodChildren(response.invited != null ? godChildren.concat(response.invited) : godChildren);
      setCount(response.count);
      setPoints(response.points);
    }

  }

  useEffect(
    () => {
      getUserLink();
      getGodChildren();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/"+location}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <h5 className="fw-bold">{t(_invite_friends)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={20} className="my-3 p-2">
                {
                  loading ? (
                    <>
                      <Row gutter={[16]} className="justify-content-center align-items-center d-flex d-md-none">
                        <Col xs={24}>
                          <Skeleton.Button active block style={{height:"42px", width:"100%"}} />
                        </Col>
                      </Row>
                      <Row gutter={[16]} className="justify-content-center align-items-center d-none d-md-flex">
                        <Col xs={3}>
                          <Skeleton.Button active block style={{height:"42px"}} />
                        </Col>
                        <Col xs={18}>
                          <Skeleton.Button active block style={{height:"42px"}} />
                        </Col>
                        <Col xs={3}>
                          <Skeleton.Button active block style={{height:"42px"}} />
                        </Col>
                      </Row>

                    </>
                  ) : (
                    <>

                      <Row gutter={[16]} className="justify-content-center align-items-center">
                        <Col xs={3} className="d-flex align-items-center justify-content-center">
                          <LinkOutlined style={{fontSize:"30px"}} />
                        </Col>
                        <Col xs={18}>
                          <div className="text-normal small mb-2">{t(_my_sponsor_link)}</div>
                          <div className="fw-bold text-black">{link}</div>
                        </Col>
                        <Col xs={3} className="d-flex justify-content-center align-items-center">
                          <MyCopyButton text={link}>
                            <Button className="my-button-2 border-radius-kamix-2 bg-dark text-white fw-normal" style={{width:"25px", height:"25px"}}>
                              <CopyOutlined style={{fontSize:"14px"}} />
                            </Button>
                          </MyCopyButton>
                        </Col>
                      </Row>

                    </>
                  )
                }
              </Col>
            </Row>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">
                {
                  loadingGodChildren ? (
                    <>
                      <Row>
                        <Col xs={0} md={3} />
                        <Col xs={24} md={18}>

                          <Row gutter={[16,8]} className="mb-4">
                            <Col xs={12}>
                              <Skeleton.Button active block style={{height:"70px", margin:"5px"}} />
                            </Col>
                            <Col xs={12}>
                              <Skeleton.Button active block style={{height:"70px", margin:"5px"}} />
                            </Col>
                          </Row>

                          <div>
                            <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                            <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                          </div>

                        </Col>
                        <Col xs={0} md={3} />
                      </Row>

                    </>
                  ) : (
                    <>

                      <Row gutter={[16]} className="justify-content-center align-items-center mb-4">
                        <Col xs={0} md={3} />
                        <Col xs={24} md={18}>

                          <Row gutter={[16,8]} className="mb-4">
                            <Col xs={12}>
                              <StatisticCard
                                title={t(_usage_text)}
                                value={count}
                              />
                            </Col>
                            <Col xs={12}>
                              <StatisticCard
                                title={t(_points_earned_text)}
                                value={points}
                              />
                            </Col>
                          </Row>

                          <>
                            {
                              godChildren.length === 0 ? (
                                <div className="d-flex justify-content-center">
                                  <h6 className="fw-bold">{t(_no_invited_user_yet)}</h6>
                                </div>
                              ) : (
                                <div>

                                  {
                                    godChildren.map(item => (
                                      <div key={item._id} className="mb-2">
                                        <ReceiverPresentation receiver={item} />
                                      </div>
                                    ))
                                  }

                                  {
                                    godChildren < count &&
                                    <div className="d-flex justify-content-center mt-4">
                                      <Button
                                        className="my-button-2 bg-yellow-kamix text-dark"
                                        loading={loadingMoreGodChildren}
                                        onClick={getMoreGodChildren}
                                      >
                                        {t(_view_more)}
                                      </Button>
                                    </div>
                                  }

                                </div>
                              )
                            }
                          </>

                        </Col>
                        <Col xs={0} md={3} />
                      </Row>

                    </>
                  )
                }
              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(InviteFriends);