import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton, Steps, Dropdown, Menu, Button, Modal} from "antd";
import {Link, withRouter, useHistory, useParams} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined, EllipsisOutlined, EditOutlined, LinkOutlined} from "@ant-design/icons";
import { ScaleLoader } from 'react-spinners';
import { saveAs } from 'file-saver';
import BarLoader from "react-bar-loader";
import {sessionStorage} from "../../Security";
import Select from "react-select";
import RemoteCall, {
  getName, getAmountColorByTypeAndStatus, getTextColorByStatus, isSender, getAmountToDisplay, getDate, getSplitMonth,
  isReceiver, getTextToDisplayByTypeAndStatus, getStepper, getCountryFromCountryCode, getTextToDisplay2, notification
} from "../../UsefulFunctions";
import {
  green_kamix,
  _amount,
  _code,
  _date,
  _fees,
  _from,
  _in,
  _message,
  _details,
  _payment_collected_by_kamix,
  _finalize_tx,
  _payment_collection_pending_details_1,
  _payment_mode,
  _reference,
  _see_tx_on_blockchain,
  _status,
  _to,
  _country_selected,
  _transaction_invoice,
  _download,
  _perform_same_transaction,
  _transfer_cryptos,
  _check_status,
  _payment_link,
  _select_country_where_bank_account_is_held,
  _continue,
  _cancel,
  _modification_applied,
  _reason
} from "../../UsefulConstants";
import "../../assets/styles/history.css";

const { Step } = Steps;

function TxInfos() {

  const { t } = useTranslation();
  const { code, from } = useParams();
  const history = useHistory();
  const source = axios.CancelToken.source();

  const [toggle,setToggle] = useState(false);
  const [loadingTxInfos,setLoadingTxInfos] = useState(false);
  const [loadingTxInfos2,setLoadingTxInfos2] = useState(false);
  const [loadingBlockchainUrl,setLoadingBlockchainUrl] = useState(false);
  const [loadingInvoice,setLoadingInvoice] = useState(false);
  const [loadingCountriesAllowed,setLoadingCountriesAllowed] = useState(false);
  const [loadingMarket,setLoadingMarket] = useState(false);

  const [transaction,setTransaction] = useState(null);
  const [currency,setCurrency] = useState(null);
  const [blockchainUrl,setBlockchainUrl] = useState('');
  const [countrySelected,setCountrySelected] = useState(null);
  const [countriesAllowed,setCountriesAllowed] = useState([]);


  function onToggle(){
    setToggle(true);
    setCountrySelected(null);
    getCountriesAllowed();
  }

  async function getCountriesAllowed(){

    setLoadingCountriesAllowed(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/do/launch/market/"+code,
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingCountriesAllowed(l => !l);

    if(response != null){

      //-----------------------------------------------------------
      let countries = [];
      let automaticBankTransfer = response.allowed_modes != null && response.allowed_modes.filter(el => el.name === "AUTOMATIC_BANK_TRANSFER")[0];
      //----------------------------
      if(automaticBankTransfer != null && automaticBankTransfer.data != null && automaticBankTransfer.data.countries != null){
        for(let el of automaticBankTransfer.data.countries){
          el.value = el.country;
          el.label = getTextToDisplay2(el.display_names,"value");
          countries.push(el);
        }
      }
      //----------------------------
      setCountriesAllowed(countries);
      //----------------------------
      if(transaction != null && transaction.tink !=  null && transaction.tink.market != null){
        for(let c of countries){
          if(c.country === transaction.tink.market){
            setCountrySelected(c);
            break;
          }
        }
      }
      //-----------------------------------------------------------

    }

  }

  async function changeMarket(){

    setLoadingMarket(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/do/launch/market/"+code,
      "patch",
      false,
      null,
      {country:countrySelected.value},
      source.token,
      history
    );

    setLoadingMarket(l => !l);

    if(response != null){
      notification("success",t(_modification_applied));
      setTransaction(response.transaction);
      setToggle(false);
    }

  }

  async function getTxInfos(){

    setLoadingTxInfos(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/details/"+code,
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingTxInfos(l => !l);

    if(response != null){
      setTransaction(response.transaction);
      setCurrency(response.currency);
    }

  }

  async function getTxInfos2(){

    setLoadingTxInfos2(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/details/"+code,
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingTxInfos2(l => !l);

    if(response != null){
      setTransaction(response.transaction);
    }

  }

  async function getTxBlockchainUrl(){

    setLoadingBlockchainUrl(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/details/blockchain/"+code,
      "get",
      false,
      {redirect:false},
      null,
      source.token,
      history
    );

    setLoadingBlockchainUrl(l => !l);

    if(response != null){
      setBlockchainUrl(response.url);
    }

  }

  async function downloadInvoice(){

    setLoadingInvoice(l => !l);

    let route = "transactions/invoices/"+code;
    if(type != null && type === "subscription") route = "transactions/invoices/user/subscription";

    let response = await RemoteCall(
      true,
      route,
      "get",
      false,
      {redirect:false},
      null,
      source.token,
      history,
      true
    );

    setLoadingInvoice(l => !l);

    if(response != null){
      let name = "tx-"+code+"-invoice.pdf";
      if(type != null && type === "subscription") name = "subscription-invoice.pdf";
      saveAs(response,name);
    }

  }

  function selectReceiver(){
    if(transaction != null){
      let data = {};
      data.receiver = transaction.transfer_receiver;
      sessionStorage.set('transactionInformation',data);
      history.push('/transfer/data-entries');
    }
  }

  function performSameTx(){
    if(transaction != null && transaction.transfer_receiver != null){
      let data = {};
      data.receiver = transaction.transfer_receiver;
      data.tx = transaction;
      sessionStorage.set('transactionInformation',data);
      history.push('/transfer/data-entries');
    }
  }


  useEffect(
    () => {
      getTxInfos();
      getTxBlockchainUrl();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  let eurToXaf = process.env.REACT_APP_EUR_TO_XAF;
  let type = transaction != null && transaction.type != null ? transaction.type.toString().toLowerCase() : null;
  let status = transaction != null && transaction.status != null ? transaction.status.toString().toLowerCase() : null;
  let mode = transaction != null && transaction.mode != null ? transaction.mode.toString().toLowerCase() : null;
  let reason = transaction != null && transaction.reason != null ? transaction.reason : null;
  let sender = transaction != null && transaction.sender;
  let transfer_receiver = transaction != null && transaction.transfer_receiver;
  let senderName = (sender != null && getName(sender) !== "") ? getName(sender) : getName(sender);
  let textColor = getTextColorByStatus(status);
  let amountColor = getAmountColorByTypeAndStatus(type,status,transaction);
  let cryptoSymbols = currency != null && currency.short_symbols;
  let textToDisplay = getTextToDisplayByTypeAndStatus(type,status,transaction,cryptoSymbols);
  let stepper = getStepper(type,status,transaction,cryptoSymbols);

  const items = [];

  if(
    status != null &&
    (status === "pending" || status === "rollback" || status === "sent" || status === "paid")
  ){
    items.push(
      {
        key: '1',
        label: (
          <div className="cursorView text-normal mx-2" onClick={getTxInfos2}>
            {t(_check_status)}
          </div>
        )
      }
    )
  }

  if(
    transfer_receiver != null && type != null && type === "transfer" && isSender(transaction) &&
    status != null && (status === "pending" || status === "sent" || status === "done")
  ){
    items.push(
      {
        key: '2',
        label: (
          <div className="cursorView mx-2" onClick={selectReceiver}>
            {t(_transfer_cryptos)}
          </div>
        )
      }
    )
  }

  if(
    status != null && type != null &&
    (
      (status === "done" && type === "buy") ||
      (status === "done" && type === "transfer" && isSender(transaction)) ||
      ((status === "done" || status === "paid" ) && type === "refund")
    )
  ){
    items.push(
      {
        key: '3',
        label: (
          <div className="cursorView mx-2" onClick={performSameTx}>
            {t(_perform_same_transaction)}
          </div>
        )
      }
    )
  }

  const menu =
    <Menu
      items={items}
    />
  ;

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={from === "h" ? '/history' : '/history/all'}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 mx-5">
              <Row className="mb-1">
                <Col flex="auto">
                  <h5 className="fw-bold">{t(_details)}</h5>
                </Col>
                {
                  (
                    (type != null && type === "transfer") ||
                    (status != null && (status === "pending" || status === "rollback" || status === "sent" || status === "paid"))
                  )
                  &&
                  <Dropdown overlay={menu} placement="bottomRight" arrow>
                    <div className="dropdownButton2">
                      <EllipsisOutlined style={{fontSize:"15px", transform:"rotate(90deg)"}} />
                    </div>
                  </Dropdown>
                }
              </Row>
              {
                loadingTxInfos2 &&
                <BarLoader color={green_kamix} height="3" className="bar-loader" />
              }
            </div>

            <Row className="justify-content-center mb-3">
              {
                loadingTxInfos ? (
                  <>
                    <Row className="my-box bg-white full-width special-spacing">
                      <Col xs={24}>
                        <Skeleton.Button
                          active shape block
                          style={{height:"90px", padding:"16px", borderRadius: "8px"}}
                        />
                      </Col>
                    </Row>
                    <Row className="my-box bg-white full-width special-spacing">
                      <Col xs={24}>
                        <Skeleton.Button
                          active shape block
                          style={{height:"180px", padding:"16px", borderRadius: "8px"}}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>

                    {
                      transaction != null &&
                      <>

                        {/*Entête*/}
                        {
                          type != null &&
                          <Row className="my-box bg-white full-width special-spacing">
                            <Col xs={24}>

                              <div className="text-gray-kamix">
                                {
                                  type != null && (type === "transfer" ?
                                    (t('transaction_details_message_type_'+type+'_'+isSender(transaction))) :
                                    (t('transaction_details_message_type_'+type)))
                                }
                              </div>
                              <div className="amount">
                                {getAmountToDisplay(transaction.amount,"EUR")} {" "}
                                {
                                  (type != null && type !== "subscription" && type !== "fund") ?
                                    currency != null && currency.short_symbols :
                                    <>&euro;</>
                                }
                              </div>
                              {
                                (type != null && type !== "subscription" && type !== "fund") &&
                                <div className="fw-normal text-gray-kamix">
                                  {
                                    currency != null &&
                                    <>
                                      {getAmountToDisplay(currency.price * transaction.amount,"EUR")} &euro;
                                      {" "} / {" "}
                                      {getAmountToDisplay(currency.price * transaction.amount * eurToXaf,"XAF")} {" "}
                                      <span className="small">XAF</span>
                                    </>
                                  }
                                </div>
                              }

                            </Col>
                          </Row>
                        }

                        <Row className="my-box bg-white full-width special-spacing">
                          <Col xs={24}>

                            {/* Transaction Code */}
                            <Row className="mb-3 mb-md-1">
                              <Col xs={24} md={8}>
                                <h6 className="text-gray-kamix">{t(_code)}</h6>
                              </Col>
                              <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                <h6 className="fw-500">{transaction.code}</h6>
                              </Col>
                            </Row>


                            {/* Transaction Status */}
                            {
                              type != null && status != null && ((type === "subscription") || (type === "transfer" && status === "done" && isReceiver(transaction)))  &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_status)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className={textColor + " fw-500"}>{t(status).toUpperCase()}</h6>
                                </Col>
                              </Row>
                            }

                            {
                              type != null && type === "fund" ? (
                                <Row className="mb-3 mb-md-1">
                                  <Col xs={24}>
                                    <h6 className="text-gray-kamix">{t(_status)}</h6>
                                  </Col>
                                  <Col xs={24}>
                                    {
                                      status != null && status === "done" &&
                                      <div>
                                        <h6 className={textColor + " fw-500"}>{t(status).toUpperCase()}</h6>
                                        <div className="text-dark fw-500 text-italic">
                                          {t(_payment_collected_by_kamix)}
                                        </div>
                                      </div>
                                    }
                                    {
                                      status != null && status === "pending" &&
                                      <div className="text-dark fw-500 italic textSmall mb-1">
                                        <div>{t(_payment_collection_pending_details_1)}</div> {" "}
                                        {
                                          mode === "automatic_bank_transfer" && transaction.tink != null &&
                                          <div className="tink-card">
                                            {
                                              transaction.tink.market != null && typeof transaction.tink.market === "string" &&
                                              <div className="d-flex align-items-end">
                                                <div className="mr-2">
                                                  <div className="text-gray-kamix fw-500 italic small">{t(_country_selected)}</div>
                                                  <div className="tink-country">{getCountryFromCountryCode(transaction.tink.market).label}</div>
                                                </div>
                                                <div>
                                                  <div/>
                                                  <div className="tink-flag" onClick={onToggle}>
                                                    <EditOutlined />
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                            {
                                              transaction.tink.url != null && typeof transaction.tink.url === "string" &&
                                              <div className="mt-2">
                                                <div className="text-gray-kamix fw-500 italic small">{t(_payment_link)}</div>
                                                <a href={transaction.tink.url} target="_blank" rel="noopener noreferrer" className="my-link">
                                                  <Button className="my-button-2 bg-green-kamix text-white mt-1 fw-normal">
                                                    <span className="tink-url mr-1">{transaction.tink.url}</span>
                                                    <LinkOutlined />
                                                  </Button>
                                                </a>
                                              </div>
                                            }
                                          </div>
                                        }
                                        {
                                          mode === "credit_card" && transaction.credit_card != null && transaction.credit_card.url != null &&
                                          <a href={transaction.credit_card.url} target="_blank" rel="noopener noreferrer">
                                            <Button className="my-button-2 bg-green-kamix text-white mt-1 fw-normal">
                                              {t(_finalize_tx)}
                                            </Button>
                                          </a>
                                        }
                                        {
                                          mode === "bank_transfer" &&
                                          <Link to={`/history/tx/h/${transaction.code}/finalize-tx`}>
                                            <Button className="my-button-2 bg-green-kamix text-white mt-1 fw-normal">
                                              {t(_finalize_tx)}
                                            </Button>
                                          </Link>
                                        }
                                      </div>
                                    }
                                    {
                                      status != null && !["pending", "done"].includes(status) &&
                                      <div>
                                        <h6 className={textColor + " fw-500"}>{t(status).toUpperCase()}</h6>
                                      </div>
                                    }
                                  </Col>
                                </Row>
                              ) : (
                                <Row className="mb-3 mb-md-1">
                                  <Col xs={24}>
                                    <h6 className="text-gray-kamix mb-3">{t(_status)}</h6>
                                  </Col>
                                  <Col xs={24}>
                                    <h6 className={textColor + " fw-500 mb-3"}>{t(status).toUpperCase()}</h6>
                                  </Col>
                                  <Col xs={24}>
                                    {
                                    stepper != null && stepper.table != null && type != null && type !== "fund" &&
                                        <Steps current={stepper.activeStep} direction="vertical" size="small"
                                               className="stepper">
                                          {stepper.table.map((element, index) => (
                                              <Step
                                                  key={index}
                                                  icon={element.icon}
                                                  description={
                                                    <div
                                                        className={index < stepper.activeStep ? "text-green-kamix" : (index === stepper.activeStep ? textColor : "text-gray-kamix")}>
                                                      {element.label}
                                                    </div>
                                                  }
                                              />
                                          ))}
                                        </Steps>
                                    }
                                    <h6 className={textColor + " fw-500 italic small"}>{textToDisplay}</h6>
                                  </Col>
                                </Row>
                              )
                            }

                          </Col>
                        </Row>

                        <Row className="my-box bg-white full-width special-spacing">
                          <Col xs={24}>

                            {/*Transaction sender*/}
                            {
                              (!isSender(transaction) && type != null && type === "transfer") &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_from)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-500 text-ellipsis">{senderName}</h6>
                                </Col>
                              </Row>
                            }


                            {/*Transaction receiver*/}
                            {
                              (!isReceiver(transaction) && type != null && type === "transfer") &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_to)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-500 text-ellipsis">{transaction.transfer_receiver_name}</h6>
                                </Col>
                              </Row>
                            }


                            {/*Transaction crypto currency name*/}
                            {
                              (transaction.currency != null && type != null && type !== "subscription" && type !== "fund") &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_in)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-500 text-ellipsis">{currency != null && currency.name}</h6>
                                </Col>
                              </Row>
                            }


                            {/* Transaction Amount */}
                            <Row className="mb-3 mb-md-1">
                              <Col xs={24} md={8}>
                                <h6 className="text-gray-kamix">{t(_amount)}</h6>
                              </Col>
                              <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                {
                                  (type != null && (type === "transfer" || type === "buy" || type === "refund")) &&
                                  <h6 className={amountColor +" fw-500"}>
                                    {getAmountToDisplay(transaction.amount,"EUR")} {" "}
                                    {currency != null && currency.short_symbols} {" "}
                                    <span className="fw-500 text-dark small">
                                      ({getAmountToDisplay(currency != null && currency.price * transaction.amount,"EUR")} &euro;
                                      {" "} / {" "}
                                      {getAmountToDisplay(currency != null && currency.price * transaction.amount * eurToXaf,"XAF")} <span className="small fw-bold">XAF</span>)
                                    </span>
                                  </h6>
                                }
                                {
                                  (type != null && (type === "subscription" || type === "fund")) &&
                                  <h6 className={amountColor +" fw-500"}>
                                    {getAmountToDisplay(transaction.amount, "EUR")} &euro; / {getAmountToDisplay(transaction.amount * eurToXaf, "XAF")} XAF
                                  </h6>
                                }
                              </Col>
                            </Row>


                            {/* Transaction Fees */}
                            {
                              type != null && type !== "fund" &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_fees)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  {
                                    type != null && (type === "transfer" || type === "buy" || type === "refund") &&
                                    <h6 className="text-dark fw-500">
                                      {getAmountToDisplay(transaction.fees,"EUR")} {" "}
                                      {currency != null && currency.short_symbols} {" "}
                                      <span className="fw-500 small">
                                      ({getAmountToDisplay(currency != null && currency.price * transaction.fees,"EUR")} &euro;
                                        {" "} / {" "}
                                        {getAmountToDisplay(currency != null && currency.price * transaction.fees * eurToXaf,"XAF")} <span className="small fw-bold">XAF</span>)
                                    </span>
                                    </h6>
                                  }
                                  {
                                    type != null && (type === "subscription" || type === "fund") &&
                                    <h6 className="text-dark fw-500">
                                      {getAmountToDisplay(transaction.fees, "EUR")} &euro;
                                    </h6>
                                  }
                                </Col>
                              </Row>
                            }

                            {/* Payment mode */}
                            {
                              reason != null &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_reason)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-normal text-ellipsis text-italic">{reason}</h6>
                                </Col>
                              </Row>
                            }

                            {/* Payment mode */}
                            {
                              mode != null &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_payment_mode)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className={textColor + " fw-500"}>{t(mode.toString().toUpperCase())}</h6>
                                </Col>
                              </Row>
                            }


                            {/* Reference */}
                            {
                              (
                                mode != null && mode.toString().includes("paypal") &&
                                transaction.paypal != null && transaction.paypal.reference != null && transaction.paypal.reference !== ""
                              ) &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_reference)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-500">{transaction.paypal.reference}</h6>
                                </Col>
                              </Row>
                            }

                            {
                              (
                                mode != null && mode.toString().includes("credit_card") &&
                                transaction.sherlock != null && transaction.sherlock.reference != null && transaction.sherlock.reference !== ""
                              ) &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_reference)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-500">{transaction.sherlock.reference}</h6>
                                </Col>
                              </Row>
                            }

                            {
                              (
                                mode != null && mode.toString().includes("bank_transfer") &&
                                transaction.bank_transfer != null && transaction.bank_transfer.reference != null && transaction.bank_transfer.reference !== ""
                              ) &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_reference)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-500">{transaction.bank_transfer.reference}</h6>
                                </Col>
                              </Row>
                            }

                            {
                              (
                                mode != null && mode.toString().includes("other") &&
                                transaction.other_payment_mode != null && transaction.other_payment_mode.reference != null && transaction.other_payment_mode.reference !== ""
                              ) &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_reference)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-500">{transaction.other_payment_mode.reference}</h6>
                                </Col>
                              </Row>
                            }

                          </Col>
                        </Row>

                        <Row className="my-box bg-white full-width special-spacing">
                          <Col xs={24}>

                            {/* Transaction Date */}
                            <Row className="mb-3 mb-md-1">
                              <Col xs={24} md={8}>
                                <h6 className="text-gray-kamix">{t(_date)}</h6>
                              </Col>
                              <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                <h6 className="fw-500 text-dark">
                                  {
                                    getDate(transaction.max_date).day +" "+
                                    getSplitMonth(t("months_"+getDate(transaction.max_date).month)+"") +" "+
                                    getDate(transaction.max_date).year +", "+
                                    getDate(transaction.max_date).hour
                                  }
                                </h6>
                              </Col>
                            </Row>


                            {/* Transaction Message */}
                            {
                              transaction.message != null && transaction.message !== "" &&
                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_message)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  <h6 className="fw-500 text-dark">{transaction.message}</h6>
                                </Col>
                              </Row>
                            }


                            {/*Voir la Tx sur la blockchain*/}
                            {
                              (
                                (status != null && status === "done") &&
                                (type != null && type !== "subscription" && type !== "fund")
                              ) &&
                              <Row className="mb-1 mb-md-1">
                                <Col xs={24} md={8}>
                                  {
                                    loadingBlockchainUrl ? (
                                      <Skeleton.Button
                                        active shape block
                                        style={{height:"25px", borderRadius:"8px", width:"100%"}}
                                      />
                                    ) : (
                                      <a href={blockchainUrl} target="_blank" rel="noopener noreferrer">
                                        <h6 className="url">{t(_see_tx_on_blockchain)}</h6>
                                      </a>
                                    )
                                  }
                                </Col>
                              </Row>
                            }

                          </Col>
                        </Row>

                        {/*Reçu de la transaction*/}
                        {
                          (
                            (status != null && type != null) &&
                            (status === "done") &&
                            (type === "subscription" || type === "buy" || (type === "transfer" && isSender(transaction)))
                          ) &&
                          <Row className="my-box bg-white full-width special-spacing">
                            <Col xs={24}>

                              {/* Transaction Invoice */}

                              <Row className="mb-3 mb-md-1">
                                <Col xs={24} md={8}>
                                  <h6 className="text-gray-kamix">{t(_transaction_invoice)}</h6>
                                </Col>
                                <Col xs={24} md={16} className="d-md-flex justify-content-end">
                                  {
                                    loadingInvoice ? (
                                      <ScaleLoader
                                        sizeUnit={"px"}
                                        height={15}
                                        width={4}
                                        radius={2}
                                        margin={2}
                                        color={green_kamix}
                                        loading={loadingInvoice}
                                      />
                                    ) : (
                                      <h6 className="url cursorView" onClick={downloadInvoice}>
                                        {t(_download)}
                                      </h6>
                                    )
                                  }
                                </Col>
                              </Row>

                            </Col>
                          </Row>
                        }

                      </>
                    }

                  </>
                )
              }
            </Row>

          </Col>
        </Row>

        <Modal
          visible={toggle}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <div className="text-dark text-center mb-3">{t(_select_country_where_bank_account_is_held)}</div>
              <Row className="justify-content-center">
                <Col xs={24} md={20}>
                  <Select
                    value={countrySelected}
                    onChange={(e)=>setCountrySelected(e)}
                    options={countriesAllowed}
                    isSearchable
                    required
                    isDisabled={loadingCountriesAllowed}
                  />
                </Col>
              </Row>

              <div className="d-flex justify-content-center mt-3">
                <Button
                  className="border-radius-kamix text-dark mr-2"
                  disabled={loadingCountriesAllowed}
                  onClick={()=>setToggle(false)}
                >
                  {t(_cancel)}
                </Button>
                <Button
                  className="border-radius-kamix bg-yellow-kamix text-dark"
                  disabled={countrySelected == null} loading={loadingMarket}
                  onClick={changeMarket}
                >
                  {t(_continue)}
                </Button>
              </div>

            </Col>
          </Row>

        </Modal>

      </Col>
    </Row>
  )

}

export default withRouter(TxInfos);