import React from 'react';
import {Row, Col} from "antd";
import {Link, useParams, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {_follow_us_on_social_media, _icon, _our_different_pages} from "../../../UsefulConstants";
import {ArrowLeftOutlined} from "@ant-design/icons";
import FacebookIcon from "../../../assets/icons/follow_us/facebook.png";
import TwitterIcon from "../../../assets/icons/follow_us/twitter.png";
import LinkedInIcon from "../../../assets/icons/follow_us/linkedIn.png";
import InstagramIcon from "../../../assets/icons/follow_us/instagram.png";
import "../../../assets/styles/myAccount.css";

function FollowUs() {

  const { t } = useTranslation();
  const { from } = useParams();

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={from != null ? "/home" : "/profile"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <h5 className="fw-bold">{t(_follow_us_on_social_media)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                <h6 className="text-normal fw-bold mb-0">{t(_our_different_pages)}</h6>
                <div>
                  <a href="https://twitter.com/KamixApp" target="_blank" rel="noopener noreferrer" className="kamix-sm-icon">
                    <img alt={t(_icon)} className="icon-rs" src={TwitterIcon}/>
                  </a>
                  <a href="https://www.facebook.com/KamixApp/" target="_blank" rel="noopener noreferrer" className="kamix-sm-icon">
                    <img alt={t(_icon)} className="icon-rs" src={FacebookIcon}/>
                  </a>
                  <a href="https://www.instagram.com/kamixapp/" target="_blank" rel="noopener noreferrer" className="kamix-sm-icon">
                    <img alt={t(_icon)} className="icon-rs" src={InstagramIcon}/>
                  </a>
                  <a href="https://www.linkedin.com/company/kamixapp/" target="_blank" rel="noopener noreferrer" className="kamix-sm-icon">
                    <img alt={t(_icon)} className="icon-rs" src={LinkedInIcon}/>
                  </a>
                </div>

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(FollowUs);