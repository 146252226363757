import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {Row, Col, Input, Button, Skeleton} from "antd";
import {useTranslation} from "react-i18next";
import KamixLogo from "../assets/images/logo_kamix.png";
import RemoteCall, {notification} from "../UsefulFunctions";
import axios from "axios";
import {
  _invalid_password, _confirm_new_password, _new_password, _required, _password_constraint_text,
  strongPassword, _invalid_password_2, _set_new_password, _confirm, _password_reset_success,
  _resend_new_link, _app_name
} from "../UsefulConstants";
import "../assets/styles/pages.css";

function ResetPassword() {

  const { t } = useTranslation();
  const history = useHistory();
  const { link, code } = useParams();

  const source = axios.CancelToken.source();

  const [isDone,setIsDone] = useState(false);
  const [loading,setLoading] = useState(false);
  const [loadingCode,setLoadingCode] = useState(false);
  const [password,setPassword] = useState('');
  const [password2,setPassword2] = useState('');
  const [isPassword1Valid,setIsPassword1Valid] = useState(true);
  const [isPassword2Valid,setIsPassword2Valid] = useState(true);

  function onChangePassword(e) {
    setPassword(e.target.value);
    setIsPassword1Valid(true);
  }

  function onChangePassword2(e) {
    setPassword2(e.target.value);
    setIsPassword2Valid(true);
  }

  async function onSubmit(e){

    e.preventDefault();

    if(password === "" || password2 === "" || password !== password2){
      setIsPassword1Valid(password !== "" && strongPassword.test(password));
      setIsPassword2Valid(password2 !== "" && password === password2);
    }else if(!strongPassword.test(password)){
      setIsPassword1Valid(password !== "" && strongPassword.test(password) );
      setIsPassword2Valid(password2 !== "" && password === password2);
    }else{

      let formData = {};
      formData.new_password = password;
      formData.confirm_password = password2;

      setLoading(l => !l);

      let response = await RemoteCall(
        false,
        "users/reset-password/"+link,
        "patch",
        false,
        null,
        formData,
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        notification("success",t(_password_reset_success));
        history.push("/sign-in")
      }

    }

  }

  async function onsubmitCode() {

    setLoadingCode(l => !l);

    let bodyParam = null;
    if(code != null) bodyParam = {code:code};

    let response = await RemoteCall(
      false,
      "users/verify-reset-password/"+link,
      "post",
      false,
      null,
      bodyParam,
      source.token,
      history
    );

    setLoadingCode(l => !l);

    if(response != null){
      setIsDone(true);
    }

  }

  useEffect(
    () => {
      onsubmitCode();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="margin-bottom-25">
              <Link to={"/sign-in"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
            </div>

            <h1 className="text-header mb-3">{t(_set_new_password)}</h1>

            {
              loadingCode ? (
                <>
                  <Skeleton active paragraph={{rows: 4}} />
                </>
              ) : (
                <>

                  {
                    isDone ? (
                      <>
                        <div className="text-normal text-justify mb-4">{t(_password_constraint_text)}</div>
                        <form onSubmit={(e)=>onSubmit(e)}>
                          <div className="mb-4">
                            <Input.Password
                              type="password"
                              value={password}
                              onChange={onChangePassword}
                              status={isPassword1Valid ? "success" : "error"}
                              className="my-input"
                              size="large"
                              placeholder={t(_new_password)}
                            />
                            {
                              !isPassword1Valid &&
                              <div className="position-absolute ant-form-item-explain-error">
                                {password === "" && t(_required)}
                                {password !== "" && !strongPassword.test(password) && t(_invalid_password_2)}
                              </div>
                            }
                          </div>
                          <div className="mb-4">
                            <Input.Password
                              type="password"
                              value={password2}
                              onChange={onChangePassword2}
                              status={isPassword2Valid ? "success" : "error"}
                              className="my-input"
                              size="large"
                              placeholder={t(_confirm_new_password)}
                            />
                            {
                              !isPassword2Valid &&
                              <div className="position-absolute ant-form-item-explain-error">
                                {password2 === "" && t(_required)}
                                {password !== password2 && password2 !== "" && t(_invalid_password)}
                              </div>
                            }
                          </div>
                          <Button className="my-button bg-yellow-kamix text-dark mt-2" loading={loading} htmlType="submit">
                            {t(_confirm)}
                          </Button>
                        </form>
                      </>
                    ) : (
                      <>
                        <div className="text-normal text-justify mb-4">
                          {t(_resend_new_link)}
                        </div>
                      </>
                    )

                  }

                </>
              )
            }

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default ResetPassword;
