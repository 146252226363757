import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import {Row, Col, Skeleton, Button, Modal} from "antd";
import axios from "axios";
import Select from 'react-select';
import {useTranslation} from "react-i18next";
import RemoteCall, {getDate, getSplitMonth, isToday, notification} from "../../../UsefulFunctions";
import {ArrowLeftOutlined, WarningOutlined} from "@ant-design/icons";
import {
  _my_devices, _device_id, _device_type, _is_main_device, _yes, _no, _can_perform_transaction_text, _save,
  _on_authorization, _select, _modification_applied, _delete_device, _set_as_main_device, _actions,
  _cancel, _delete, _continue, _delete_device_question, _set_device_as_main_question, _last_seen_on
} from "../../../UsefulConstants";

function DeviceManager() {

  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  const source = axios.CancelToken.source();

  const settingsOptions = [
    { value: 'ON', label: t(_yes) },
    { value: 'ON_AUTHORIZATION', label: t(_on_authorization) },
    { value: 'OFF', label: t(_no) }
  ];

  const [toggleSetAsMain,setToggleSetAsMain] = useState(false);
  const [toggleDeletion,setToggleDeletion] = useState(false);
  const [loading,setLoading] = useState(false);
  const [loadingTxSettings,setLoadingTxSettings] = useState(false);
  const [loadingSetAsMain,setLoadingSetAsMain] = useState(false);
  const [loadingDeletion,setLoadingDeletion] = useState(false);
  const [device,setDevice] = useState(null);
  const [isMain,setIsMain] = useState(false);
  const [settingSelected,setSettingSelected] = useState(null);

  function onChangeSettingSelected(e){
    setSettingSelected(e)
  }

  function getSelectedSettings(device){
    switch (device != null && device.can_do_transactions.toString().toLowerCase()) {
      case "yes":
        return  { value: 'ON', label: t(_yes) };
      case "on":
        return { value: 'ON', label: t(_yes) };
      case "no":
        return { value: 'OFF', label: t(_no) };
      case "off":
        return { value: 'OFF', label: t(_no) };
      default:
        return { value: 'ON_AUTHORIZATION', label: t(_on_authorization) };
    }
  }

  async function getDeviceInfos(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "users/devices/"+id,
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      setDevice(response.device);
      setIsMain(response.is_main);
      setSettingSelected(getSelectedSettings(response.device));
    }

  }

  async function changeTxSettings(){

    setLoadingTxSettings(l => !l);

    let response = await RemoteCall(
      true,
      "users/devices/transactions-settings/"+id,
      "patch",
      false,
      null,
      {setting:settingSelected != null ? settingSelected.value : ""},
      source.token,
      history
    );

    setLoadingTxSettings(l => !l);

    if(response != null){
      notification("success",t(_modification_applied));
      setDevice(response.device);
      setSettingSelected(getSelectedSettings(response.device));
    }

  }

  async function setDeviceAsMain(){

    setLoadingSetAsMain(l => !l);

    let response = await RemoteCall(
      true,
      "users/devices/main/"+id,
      "patch",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingSetAsMain(l => !l);

    if(response != null){
      notification("success",t(_modification_applied));
      setDevice(response.device);
    }

  }

  async function deleteDevice(){

    setLoadingDeletion(l => !l);

    let response = await RemoteCall(
      true,
      "users/devices/"+id,
      "delete",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingDeletion(l => !l);

    if(response != null){
      notification("success",t(_modification_applied));
      history.push('/profile/devices');
    }

  }


  useEffect(
    () => {
      getDeviceInfos();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile/devices"}>
              <ArrowLeftOutlined className="icon-sm mb-3" />
            </Link>

            <div className="d-flex flex-column mb-3 ml-5">
              <small>{t(_my_devices)}</small>
              {
                device != null ? (
                  <h5 className="fw-bold">{device.name}</h5>
                ) : (
                  <Skeleton.Button active shape style={{width:"50%", height:"30px"}} />
                )
              }
            </div>

            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loading ? (
                    <>
                      <Skeleton active paragraph={{rows: 6}}/>
                    </>
                  ) : (
                    <>

                      {
                        device != null &&
                        <div>

                          {/* Device ID */}
                          <div className="mb-3">
                            <small>{t(_device_id)}</small>
                            <h6 className="font-weight-bold">{device.did}</h6>
                          </div>

                          {/* Device type */}
                          <div className="mb-3">
                            <small>{t(_device_type)}</small>
                            <h6 className={"devicePresentation-type-"+device.type.toString().toLowerCase()}>{device.type}</h6>
                          </div>

                          {/* Main device */}
                          <div className="mb-3">
                            <small>{t(_is_main_device)}</small>
                            <h6 className={device.main ? "fw-bold text-green-kamix" : "fw-bold text-red-kamix"}>
                              {t(device.main ? _yes : _no)}
                            </h6>
                          </div>

                          {/* Last seen */}
                          <div className="mb-3">
                            <small>{t(_last_seen_on)}</small>
                            <h6 className="fw-bold text-dark">
                              {
                                isToday(device.last_seen) ? (
                                  <div>{getDate(device.last_seen).hour}</div>
                                ) : (
                                  <div>
                                    {
                                      getDate(device.last_seen).day +" "+
                                      getSplitMonth(t("months_"+getDate(device.last_seen).month)+"") +" "+
                                      getDate(device.last_seen).year +", "+
                                      getDate(device.last_seen).hour
                                    }
                                  </div>
                                )
                              }
                            </h6>
                          </div>

                          {/* Can perform transaction */}
                          <div>
                            <small className="mb-4">{t(_can_perform_transaction_text)}</small>
                            {
                              isMain ? (
                                <Row className="justify-content-start">
                                  <Col xs={16} md={14} lg={10}>
                                    <Select
                                      value={settingSelected}
                                      onChange={onChangeSettingSelected}
                                      options={settingsOptions}
                                      placeholder={t(_select)}
                                    />
                                  </Col>
                                  <Button
                                    className="my-button-2 bg-yellow-kamix text-dark fw-normal ml-2"
                                    loading={loadingTxSettings}
                                    onClick={changeTxSettings}
                                  >
                                    {t(_save)}
                                  </Button>
                                </Row>
                              ) : (
                                <h6 className="devicePresentation-type-custom">
                                  <span className={device.can_do_transactions.toString().toLowerCase()}>
                                    {t(device.can_do_transactions.toString().toLowerCase())}
                                  </span>
                                </h6>
                              )
                            }
                          </div>



                          {/* Actions */}
                          {
                            isMain && !device.main &&
                            <div className="mt-4">
                              <div className="fw-bold mb-2">{t(_actions)}</div>
                              <div className="d-flex flex-wrap">
                                <Button
                                  className="border-radius-kamix bg-yellow-kamix text-dark fw-normal mr-2"
                                  onClick={()=>setToggleSetAsMain(t=>!t)}
                                >
                                  {t(_set_as_main_device)}
                                </Button>
                                <Button className="border-radius-kamix bg-red-kamix text-white" onClick={()=>setToggleDeletion(t=>!t)}>
                                  {t(_delete_device)}
                                </Button>
                              </div>
                            </div>
                          }

                        </div>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

        <Modal
          visible={toggleDeletion}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <Row className="justify-content-center">
                <Col xs={3}>
                  <WarningOutlined style={{fontSize:"30px", color:"#BF2626"}} />
                </Col>
                <Col xs={21}>
                  <div className="text-normal mb-3">{t(_delete_device_question)}</div>
                  <div className="d-flex">
                    <Button className="border-radius-kamix text-dark mr-2" disabled={loadingDeletion} onClick={()=>setToggleDeletion(t=>!t)}>
                      {t(_cancel)}
                    </Button>
                    <Button className="border-radius-kamix bg-red-kamix text-white" loading={loadingDeletion} onClick={deleteDevice}>
                      {t(_delete)}
                    </Button>
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>

        </Modal>

        <Modal
          visible={toggleSetAsMain}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <Row className="justify-content-center">
                <Col xs={3}>
                  <WarningOutlined style={{fontSize:"30px", color:"#ffc91e"}} />
                </Col>
                <Col xs={21}>
                  <div className="text-normal mb-3">{t(_set_device_as_main_question)}</div>
                  <div className="d-flex">
                    <Button className="border-radius-kamix text-dark mr-2" disabled={loadingSetAsMain} onClick={()=>setToggleSetAsMain(t=>!t)}>
                      {t(_cancel)}
                    </Button>
                    <Button className="border-radius-kamix bg-yellow-kamix text-dark" loading={loadingSetAsMain} onClick={setDeviceAsMain}>
                      {t(_continue)}
                    </Button>
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>

        </Modal>

      </Col>
    </Row>
  )

}

export default withRouter(DeviceManager);