import React, {useRef, useState, useEffect} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {Row, Col, Input, Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
import axios from 'axios';
import RemoteCall, {notification, getCountries} from "../../UsefulFunctions";
import ReactCodeInput from "react-code-input";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import EmailValidator from "email-validator";
import {getCountryCallingCode, isValidPhoneNumber} from "react-phone-number-input";
import {sessionStorage} from "../../Security";
import Select from 'react-select';
import {InfoCircleFilled, CheckCircleFilled} from "@ant-design/icons";
import {
  _required, _invalid_phone_number, defaultCountry, _mobile_number, codeInputProps, _code_invalid, _resend_code_text,
  _cancel, _invalid_email, _email, _reset_by_email, _reset_by_mobile_number, _enter_mobile_number_associated_to_your_profile,
  _enter_email_associated_to_your_profile, _continue, _mail_sent_to, _click_on_link_received_and_set_new_password, _close,
  _mail_not_receive_resend, _resend, _send_code_by_sms_to, _email_missing, _warning_email_not_provided_yet,
  _warning_email_provided_but_not_verified, _select_phone_number_to_use, _warning_phone_provided_but_not_verified
} from "../../UsefulConstants";
import "../../assets/styles/pages.css";
import "../../assets/styles/constants.css";

function ResetPage({where = "out"}) {

  const { t } = useTranslation();
  const history = useHistory();
  const codeRef = useRef(undefined);

  let userCredentials = sessionStorage.get('userCredentials');
  let userEmail = (userCredentials != null && userCredentials.user != null && userCredentials.user.email != null) ? userCredentials.user.email : null;
  let userMobiles = (userCredentials != null && userCredentials.user != null && userCredentials.user.mobiles != null) ? userCredentials.user.mobiles : [];

  const source = axios.CancelToken.source();

  const [method,setMethod] = useState("mobile");
  const [step,setStep] = useState("main");
  const [toggle,setToggle] = useState(false);

  const [loading,setLoading] = useState(false);
  const [phoneNumber,setPhoneNumber] = useState('');
  const [email,setEmail] = useState((where === "in" && userEmail != null) ? userEmail.email : "");
  const [country, setCountry] = useState(defaultCountry);
  const [isPhoneInputValid,setIsPhoneInputValid] = useState(true);
  const [isEmailValid,setIsEmailValid] = useState(true);

  const [user,setUser] = useState(null);
  const [resetPasswordId,setResetPasswordId] = useState('');

  const [loadingResendCode,setLoadingResetCode] = useState(false);
  const [code,setCode] = useState('');
  const [isCodeValid,setIsCodeValid] = useState(true);

  const [phoneNumberSelected,setPhoneNumberSelected] = useState(null);


  function onMethodChange(method){
    setMethod(method);
    setToggle(t => !t);

    setPhoneNumber('');
    setIsPhoneInputValid(true);
    setEmail((where === "in" && userEmail != null) ? userEmail.email : "");
    setIsEmailValid(true);
    setCountry(defaultCountry);
    setUser(null);
    setResetPasswordId('');
    setCode("");
    setPhoneNumberSelected(null);
  }

  function onChangePhoneNumber(e){
    setPhoneNumber(e.target.value);
    setIsPhoneInputValid(true);
  }

  function onChangeEmail(e){
    setEmail(e.target.value);
    setIsEmailValid(true);
  }

  function onChangeCountry(e) {
    setCountry(e)
  }

  function onChangeCode(code){
    setCode(code);
    setIsCodeValid(true);
  }

  function clearCodeInput() {
    if(codeRef != null && codeRef.current != null){
      codeRef.current.state.input[0] = "";
      codeRef.current.state.input[1] = "";
      codeRef.current.state.input[2] = "";
      codeRef.current.state.input[3] = "";
      codeRef.current.state.input[4] = "";
      codeRef.current.state.input[5] = "";
    }
  }

  function cancel(){
    setCode('');
    setPhoneNumber('');
    setCountry(defaultCountry);
    setUser(null);
    setResetPasswordId('');
    setStep('main');
    clearCodeInput();
  }

  function onResetEmailFinish(){
    if(where === "in"){
      setToggle(t=>!t);
    }else{
      history.push('/sign-in');
    }
  }

  async function resendCode() {

    setLoadingResetCode(l=>!l);

    let response = await RemoteCall(
      false,
      "users/refresh-reset-password/"+resetPasswordId,
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingResetCode(l=>!l);

    if(response != null){
      if(method === "mobile"){
        setCode('');
        clearCodeInput();
      }
      setResetPasswordId(response.reset_password_id);
      notification("success",t(_resend));
    }

  }

  async function onUserRequestChangePassword(e) {

    e.preventDefault();
    let mobile = where === "in" ?
      phoneNumberSelected != null && phoneNumberSelected.mobile :
      '+'+getCountryCallingCode(country.code)+phoneNumber;

    if(
      ( method === "email" && (email === "" || !EmailValidator.validate(email)) ) ||
      ( method === "mobile" && where === "out" && (phoneNumber === "" || !isValidPhoneNumber(mobile)) )
    ){
      method === "mobile" && setIsPhoneInputValid(phoneNumber !== "" && isValidPhoneNumber(mobile));
      method === "email" && setIsEmailValid(email !== "" && EmailValidator.validate(email));
    }else{

      let formData = {};
      formData['login'] = method === "mobile" ? mobile : email;
      formData['reset_password_mode'] = method;

      setLoading(l => !l);

      let response = await RemoteCall(
        false,
        "users/request-reset-password",
        "post",
        false,
        null,
        formData,
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        setStep(method === "mobile" ? "mobile_success" : "email_success");
        setUser(response.user);
        setResetPasswordId(response.reset_password_id);
      }

    }

  }

  async function onsubmitCode(e) {

    e.preventDefault();

    if(code === "" || code.length !== 6){
      setIsCodeValid(code !== "" && code.length === 6);
    }else{

      if(where === "in"){
        const win = window.open('/reset/password/'+resetPasswordId+'/'+code, "_blank");
        win.focus();
      }else{
        history.push('/reset/password/'+resetPasswordId+'/'+code);
      }

    }

  }

  useEffect(
    () => {
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  let mobile = formatPhoneNumberIntl('+'+getCountryCallingCode(country.code)+phoneNumber);

  return(
    <>

      <div>
        <Button className="text-dark fw-bold border-radius-kamix d-block mb-2" onClick={(e) => onMethodChange("email",e)}>
          {t(_reset_by_email)}
        </Button>
        <Button className="text-dark fw-bold border-radius-kamix d-block" onClick={(e) => onMethodChange("mobile",e)}>
          {t(_reset_by_mobile_number)}
        </Button>
      </div>


      <Modal
        visible={toggle}
        title={null}
        footer={null}
        closable={false}
      >

        <Row className="justify-content-center">
          <Col xs={22} md={20}>

            {
              step === "main" &&
              <>

                {
                  (where === "out" || (where === "in" && (userEmail != null || userMobiles.length > 0))) &&
                  <div className="text-normal mb-3">
                    {
                      t(
                        method === "mobile" ?
                          (where === "out" ? _enter_mobile_number_associated_to_your_profile : _select_phone_number_to_use) :
                          _enter_email_associated_to_your_profile
                      )
                    }
                  </div>
                }

                <form onSubmit={(e)=>onUserRequestChangePassword(e)}>

                  {
                    method === "mobile" ? (
                      <>
                        {
                          where === "out" ? (
                            <div className="d-flex mb-4">
                              <Select
                                className="my-select"
                                classNamePrefix="my-select"
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                value={country}
                                onChange={onChangeCountry}
                                options={getCountries()}
                                isSearchable
                                autosize={false}
                                getOptionLabel ={(option)=>option.name}
                                formatOptionLabel={(option, { context }) => {
                                  return context === 'menu' ? option.label : option.label2;
                                }}
                              />
                              <div className="d-flex flex-column ml-2">
                                <Input
                                  className="my-input"
                                  type="number"
                                  value={phoneNumber}
                                  onChange={onChangePhoneNumber}
                                  status={isPhoneInputValid ? "success" : "error"}
                                  size="large"
                                  placeholder={t(_mobile_number)}
                                />
                                {
                                  !isPhoneInputValid &&
                                  <small className="position-absolute ant-form-item-explain-error" style={{top:"80px"}}>
                                    {phoneNumber === "" && t(_required)}
                                    {phoneNumber !== "" && !isValidPhoneNumber(phoneNumber) && t(_invalid_phone_number)}
                                  </small>
                                }
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex flex-column mb-4">
                              {
                                userMobiles.map((item)=>(
                                  <div className="d-flex" key={item._id}>
                                    <Button
                                      className={(item.verified ? "bg-green-kamix" : "bg-red-kamix") + " my-button-2 text-white mb-2"}
                                      onClick={()=>setPhoneNumberSelected(item)}
                                      style={{fontSize:"0.9em"}}
                                      disabled={loading}
                                    >
                                      {formatPhoneNumberIntl(""+item.mobile)}
                                    </Button>
                                    {
                                      phoneNumberSelected != null && phoneNumberSelected._id === item._id &&
                                      <CheckCircleFilled style={{fontSize:"18px", marginLeft:"10px", marginTop:"5px", color:"#ffc91e"}} />
                                    }
                                  </div>
                                ))
                              }
                            </div>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {
                          (where === "out" || ( where === "in" && userEmail != null)) ? (
                            <div className="d-flex flex-column mb-4">
                              <Input
                                className="my-input text-dark"
                                type="text"
                                value={email}
                                onChange={onChangeEmail}
                                status={isEmailValid ? "success" : "error"}
                                size="large"
                                placeholder={t(_email)}
                                disabled={userEmail != null}
                              />
                              {
                                !isEmailValid &&
                                <small className="position-absolute ant-form-item-explain-error" style={{top:"80px"}}>
                                  {email === "" && t(_required)}
                                  {email !== "" && !EmailValidator.validate(email) && t(_invalid_email)}
                                </small>
                              }
                            </div>
                          ) : (
                            <Row className="justify-content-center">
                              <Col xs={3}>
                                <InfoCircleFilled style={{fontSize:"30px", color:"#ffc91e"}} />
                              </Col>
                              <Col xs={21}>
                                <h6 className="fw-bold">{t(_email_missing)}</h6>
                                <div className="text-normal my-3">{t(_warning_email_not_provided_yet)}</div>
                              </Col>
                            </Row>
                          )
                        }
                      </>
                    )
                  }

                  {
                    where === "in" && method === "email" && userEmail != null && !userEmail.verified &&
                    <div className="text-red-kamix mb-4">
                      {t(_warning_email_provided_but_not_verified)}
                    </div>
                  }

                  {
                    where === "in" && phoneNumberSelected != null && !phoneNumberSelected.verified &&
                    <div className="text-red-kamix mb-4">
                      {t(_warning_phone_provided_but_not_verified)}
                    </div>
                  }

                  <div className="d-flex">
                    <Button
                      className="border-radius-kamix text-dark mr-2" disabled={loading} onClick={()=>setToggle(t=>!t)}>
                      {t(_cancel)}
                    </Button>
                    {
                      (
                        where === "out" ||
                        (method === "email" && where === "in" && userEmail != null) ||
                        (method === "mobile" && where === "in" && phoneNumberSelected != null)
                      ) &&
                      <Button
                        className="my-button-2 bg-yellow-kamix text-black"
                        loading={loading}
                        disabled={(
                          (method === "email" && userEmail != null && !userEmail.verified) ||
                          (method === "mobile" && phoneNumberSelected != null && !phoneNumberSelected.verified)
                        )}
                        htmlType="submit"
                      >
                        {t(_continue)}
                      </Button>
                    }
                  </div>

                </form>

              </>
            }

            {
              step === "email_success" &&
              <>

                <div className="text-normal mb-2">
                  <span>{t(_mail_sent_to)}</span> {" "}
                  <span className="fw-bold text-italic text-green-kamix">{user != null && user.email != null && user.email.email}</span>
                </div>

                <div className="text-normal mb-2">
                  {t(_click_on_link_received_and_set_new_password)}
                </div>

                <div className="mb-4">
                  <Button type="text" className="mt-3" loading={loadingResendCode} onClick={resendCode}>
                    {t(_mail_not_receive_resend)}
                  </Button>
                </div>

                <Button className="my-button-2 bg-dark text-white" onClick={onResetEmailFinish}>
                  {t(_close)}
                </Button>

              </>
            }

            {
              step === "mobile_success" &&
              <>

                <div className="text-normal mb-3">
                  <span className="text-justify">{t(_send_code_by_sms_to)}</span> {" "}
                  <span className="fw-bold one-line">{mobile}</span>
                </div>

                <form onSubmit={(e)=>onsubmitCode(e)}>
                  <div>
                    <ReactCodeInput
                      ref={codeRef}
                      isValid={isCodeValid}
                      type='number'
                      fields={6}
                      value={code}
                      onChange={onChangeCode}
                      autoFocus={false}
                      {...codeInputProps}
                    />
                    {
                      !isCodeValid &&
                      <div className="position-absolute ant-form-item-explain-error mx-3">
                        {code === "" && t(_required)}
                        {code !== "" && code.length !== 6 && t(_code_invalid)}
                      </div>
                    }
                  </div>
                  <div className="mt-4 mb-3">
                    <Button type="text" loading={loadingResendCode} onClick={resendCode}>
                      <span className="text-underline">{t(_resend_code_text)}</span>
                    </Button>
                  </div>
                  <div className="d-flex align-items-start">
                    <Button className="my-button-2 bg-dark text-white fw-normal mr-2" onClick={cancel}>
                      {t(_cancel)}
                    </Button>
                    <Button className="my-button-2 bg-yellow-kamix text-dark" htmlType="submit">
                      {t(_continue)}
                    </Button>
                  </div>
                </form>

              </>
            }

          </Col>
        </Row>

      </Modal>

    </>
  )

}

export default withRouter(ResetPage);
