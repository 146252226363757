import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton, Collapse} from "antd";
import {Link, withRouter, useHistory} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import RemoteCall from "../../UsefulFunctions";
import MyCopyButton from "../others/MyCopyButton";
import {
  _kamix_banks, _bank, _holder, _kamix_iban, _kamix_bic, _purchase_nkap_infos_1, _purchase_nkap_infos_2
} from "../../UsefulConstants";

const { Panel } = Collapse;

function Purchase() {

  const { t } = useTranslation();
  const history = useHistory();
  const source = axios.CancelToken.source();

  const [loadingRibKamix,setLoadingRibKamix] = useState(false);
  const [kamixRibs,setKamixRibs] = useState(null);

  async function getKamixRibs(){

    setLoadingRibKamix(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/kamix-rib",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingRibKamix(l => !l);

    if(response != null){
      setKamixRibs(response.kamix_ribs);
    }

  }

  useEffect(
    () => {
      getKamixRibs();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );



  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={'/home'}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <h5 className="fw-bold">{t(_kamix_banks)}</h5>
            </div>

            <Row className="justify-content-center mb-3">
              {
                loadingRibKamix ? (
                  <>
                    <Row className="my-box bg-white full-width special-spacing">
                      <Col xs={24}>
                        <Skeleton.Button
                          active shape block
                          style={{height:"120px", padding:"16px", marginBottom:"5px", borderRadius: "8px"}}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>

                  <Row className="my-box bg-white full-width p-4 px-md-5 py-md-4">
                    <Col xs={24}>

                      <div className="mb-1">{t(_purchase_nkap_infos_1)}.</div>
                      <div className="mb-3">{t(_purchase_nkap_infos_2)}.</div>

                      {
                        kamixRibs != null && kamixRibs.map((element,index)=>(
                          <React.Fragment key={index}>
                            <Collapse>
                              <Panel
                                header={
                                  <div>
                                    <div className="small">{element.bank_name}</div>
                                    <div className="small fw-bold">{element.iban}</div>
                                  </div>
                                }
                              >

                                <Row className="align-items-end mb-3">
                                  <div className="d-flex justify-content-center align-items-end mr-2"  style={{marginBottom:"3px"}}>
                                    <MyCopyButton text={element.bank_name} fontSize={13} />
                                  </div>
                                  <Col flex="auto">
                                    <div className="text-normal small mb-2">{t(_bank)}</div>
                                    <div className="fw-bold small text-black text-ellipsis">{element.bank_name}</div>
                                  </Col>
                                </Row>

                                <Row className="align-items-end mb-3">
                                  <div className="d-flex justify-content-center align-items-end mr-2"  style={{marginBottom:"3px"}}>
                                    <MyCopyButton text={element.account_holder} fontSize={13} />
                                  </div>
                                  <Col flex="auto">
                                    <div className="text-normal small mb-2">{t(_holder)}</div>
                                    <div className="fw-bold small text-black text-ellipsis">{element.account_holder}</div>
                                  </Col>
                                </Row>

                                <Row className="align-items-end mb-3">
                                  <div className="d-flex justify-content-center align-items-end mr-2"  style={{marginBottom:"3px"}}>
                                    <MyCopyButton text={element.iban} fontSize={13} />
                                  </div>
                                  <Col flex="auto">
                                    <div className="text-normal small mb-2">{t(_kamix_iban)}</div>
                                    <div className="fw-bold small text-black text-ellipsis">{element.iban}</div>
                                  </Col>
                                </Row>

                                <Row className="align-items-end mb-3">
                                  <div className="d-flex justify-content-center align-items-end mr-2"  style={{marginBottom:"3px"}}>
                                    <MyCopyButton text={element.bic} fontSize={13} />
                                  </div>
                                  <Col flex="auto">
                                    <div className="text-normal small mb-2">{t(_kamix_bic)}</div>
                                    <div className="fw-bold small text-black text-ellipsis">{element.bic}</div>
                                  </Col>
                                </Row>

                              </Panel>
                            </Collapse>
                          </React.Fragment>
                        ))
                      }

                    </Col>
                  </Row>

                  </>
                )
              }
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(Purchase);