import React, {useState} from 'react';
import {Row, Col, Input, Checkbox, Button} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import RemoteCall, {notification} from "../../../../UsefulFunctions";
import axios from "axios";
import {
  _continue, _from, _full_statement, _payment_settings, _to, _transaction_statement,
  _transaction_statement_success_message, _transaction_statement_text,
} from "../../../../UsefulConstants";

function TxStatement() {

  const { t } = useTranslation();
  const history = useHistory();
  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [startDate,setStartDate]= useState("");
  const [endDate,setEndDate]= useState("");
  const [checked, setChecked] = useState(false);

  function startDateChange(e){
    setStartDate(e.target.value);
  }

  function endDateChange(e){
    setEndDate(e.target.value);
  }

  function onChangeFullStatement(e) {
    setChecked(e.target.checked);
  }

  async function getTransactionStatement(){

    setLoading(l => !l);

    let queryParam = {
      dates: checked ? "" : ( "" + new Date(startDate).getTime() + "-" + new Date(endDate).getTime() )
    };

    let response = await RemoteCall(
      true,
      "transactions/details/history/download",
      "get",
      false,
      queryParam,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      notification("success",t(_transaction_statement_success_message), false)
    }

  }

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile/payment"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_payment_settings)}</small>
              <h5 className="fw-bold">{t(_transaction_statement)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                <div className="text-normal mb-3">{t(_transaction_statement_text)}</div>

                <div className="mb-4">
                  <Row className="mb-3">
                    <Col className="mr-2">
                      <div className="small mb-1">{t(_from)}</div>
                      <Input type="date" onChange={startDateChange} value={startDate} disabled={checked} />
                    </Col>
                    <Col>
                      <div className="small mb-1">{t(_to)}</div>
                      <Input type="date" onChange={endDateChange} value={endDate} disabled={checked} />
                    </Col>
                  </Row>
                  <Row>
                    <Checkbox checked={checked} onChange={onChangeFullStatement}>
                      <div>{t(_full_statement)}</div>
                    </Checkbox>
                  </Row>
                </div>

                <Button
                  className="my-button-2 bg-yellow-kamix text-dark"
                  loading={loading}
                  disabled={!checked && (startDate === "" || endDate === "")}
                  onClick={getTransactionStatement}
                >
                  {t(_continue)}
                </Button>

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(TxStatement);