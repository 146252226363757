import React from 'react';
import { Link } from "react-router-dom";
import {Row, Col,} from "antd";
import {useTranslation} from "react-i18next";
import {_icon} from "../../UsefulConstants";
import "../../assets/styles/profileElement.css";

function ProfileElement({where,to,title,image}) {

  const { t } = useTranslation();

  let content =
    <>
      <Row>
        <img alt={t(_icon)} className="icon" src={image}/>
      </Row>
      <Row>
        <span className="title">{title}</span>
      </Row>
    </>
  ;

  return(
    <Row className="profile-element">
      <Col xs={24}>
        {
          where === "in" ? (
            <Link to={to}>
              {content}
            </Link>
          ) : (
            <a href={to} target="_blank" rel="noopener noreferrer">
              {content}
            </a>
          )
        }
      </Col>
    </Row>
  )

}

export default ProfileElement;