import React from "react";
import {Link} from "react-router-dom";
import {Row, Col, Button} from "antd";
import {useTranslation} from "react-i18next";
import {_go_to_home_page, _oops, _something_went_wrong} from "../../UsefulConstants";
import "../../assets/styles/pages.css";

function InternPage500() {

  const { t } = useTranslation();

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Row className="justify-content-center bg-white my-box mt-5">
              <Col xs={22} md={20} className="my-3 p-2">

                <h1 className="text-header">{t(_oops)}</h1>
                <div className="text-normal text-justify mb-4">{t(_something_went_wrong)}</div>

                <Link to={'/'}>
                  <Button className="my-button bg-dark text-white fw-normal mt-2">
                    {t(_go_to_home_page)}
                  </Button>
                </Link>

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default InternPage500;
