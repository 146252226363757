import React from 'react';
import {sessionStorage} from "../../Security";
import UserBadge from "../others/UserBadge";
import {useTranslation} from "react-i18next";
import MiniLogoKamix from "../../assets/images/mini_logo_2.png";
import {QrcodeOutlined} from "@ant-design/icons";
import MyTooltip from "../others/MyTooltip";
import {
  getDate, getName, getSplitMonth, isToday, isYesterday, getAmountToDisplay, getTextColorByStatus,
  getAmountColorByTypeAndStatus, isSender, isReceiver, getTextToDisplayByTypeAndStatus
} from "../../UsefulFunctions";
import {
  _crypto_withdrawal, _from, _payment_to_kamix, _purchase_of_crypto, _subscription_title, _to, _yesterday
} from "../../UsefulConstants";
import "../../assets/styles/history.css";

function TxPresentation({transaction}) {

  const { t } = useTranslation();
  let currencies = sessionStorage.get('currencies');
  let currency = currencies != null && currencies.filter(c => c.unique_name === transaction.currency)[0];

  let type = transaction.type.toLowerCase();
  let status = transaction.status.toLowerCase();
  let sender = transaction.sender;

  let plusOrMinusOrNothing = "";
  if(type === "subscription") plusOrMinusOrNothing = "-";
  if(type === "fund" && status === "done") plusOrMinusOrNothing = "+";
  if(type === "buy" && status === "done") plusOrMinusOrNothing = "+";
  if(type === "transfer" && status === "sent") plusOrMinusOrNothing = "-";
  if(type === "transfer" && status === "done" && isReceiver(transaction)) plusOrMinusOrNothing = "+";
  if(type === "transfer" && status === "done" && isSender(transaction)) plusOrMinusOrNothing = "-";
  if(type === "refund" && status === "paid") plusOrMinusOrNothing = "-";
  if(type === "refund" && status === "done") plusOrMinusOrNothing = "-";

  let eurToXaf = process.env.REACT_APP_EUR_TO_XAF;
  let textColor = getTextColorByStatus(status);
  let amountColor = getAmountColorByTypeAndStatus(type,status,transaction);
  let textToDisplay = getTextToDisplayByTypeAndStatus(type,status,transaction,currency != null ? currency.short_symbols : "");

  const iconStyle = {fontSize:"15px"};

  return(
    <div className="txPresentation">

      <div className="icon-block mr-3">

        {/* Gestion photo */}

        {
          (type === "subscription" || type === "fund" || type === "buy" || type === "refund") &&
          <img
            alt="pic"
            className="tx-badge border-logo"
            src={MiniLogoKamix}
          />
        }
        {
          (type === "transfer" && isSender(transaction)) &&
          <UserBadge user={transaction.transfer_receiver_name} />
        }
        {
          (type === "transfer" && isReceiver(transaction)) &&
          <UserBadge user={sender} />
        }

      </div>

      <div className="displayForSmallScreen2">

        <div className="text-darker fw-bold text-ellipsis">
          {
            (type === "transfer" && transaction.by_qr_code) &&
            <span className="text-green-kamix mr-2">
              <QrcodeOutlined style={iconStyle} />
            </span>
          }

          {
            type === "subscription" &&
            <span>{t(_subscription_title)}</span>
          }

          {
            type === "fund" &&
            <span>{t(_payment_to_kamix)}</span>
          }

          {
            type === "buy" &&
            <span>{t(_purchase_of_crypto)}</span>
          }

          {
            type === "transfer" && isSender(transaction) &&
            <span>
              <span>{t(_to)}</span> {" "}
              <span>{transaction.transfer_receiver_name}</span> {" "}
            </span>
          }

          {
            type === "transfer" && isReceiver(transaction) &&
            <span>
              <span>{t(_from)}</span> {" "}
              {sender!= null && <span>{getName(sender)}</span>} {" "}
              <span className="d-none d-sm-block text-ellipsis">({sender != null && sender.eth_address})</span>
            </span>
          }

          {
            type === "refund" &&
            <span>{t(_crypto_withdrawal)}</span>
          }
        </div>

        <div className="d-flex align-items-center small mb-2 mb-sm-0">
          <span className={textColor}>{t(status.toUpperCase())}</span>
          <span className="ml-2">{transaction != null && <MyTooltip text={textToDisplay} icon={"?"} color={"gray"} />}</span>
        </div>

        <div className="displayForSmallScreen small">
          <div>
            {isToday(transaction.max_date) && <>{getDate(transaction.max_date).hour}</>}
            {isYesterday(transaction.max_date) && <>{t(_yesterday)}, {" "}{getDate(transaction.max_date).hour}</>}
            {
              ! isToday(transaction.max_date) && ! isYesterday(transaction.max_date) &&
              <>
                {
                  getDate(transaction.max_date).day +" "+
                  getSplitMonth(t("months_"+getDate(transaction.max_date).month)+"") +" "+
                  getDate(transaction.max_date).year +", "+
                  getDate(transaction.max_date).hour
                }
              </>
            }
          </div>
          <div className={amountColor}>
            {
              (type === "subscription" || type === "fund") &&
              <div className="d-flex align-items-center">
                <span className={(plusOrMinusOrNothing !== "" ? "mr-1" : "") + " fw-bold"}>{plusOrMinusOrNothing}</span>
                <span className="mr-1">{getAmountToDisplay(transaction.amount, "EUR")} &euro;</span>
                <span className="fw-bold">({getAmountToDisplay(transaction.amount * eurToXaf, "XAF")} XAF)</span>
              </div>
            }
            {
              (type === "buy" || type === "transfer" || type === "refund") &&
              <div className="d-flex align-items-center">
                <div className="d-flex mr-1">
                  <span className={(plusOrMinusOrNothing !== "" ? "mr-1" : "") + " fw-bold"}>{plusOrMinusOrNothing}</span>
                  <span className="mr-1">{getAmountToDisplay(transaction.amount,"EUR")}</span>
                  <span>{currency != null && currency.short_symbols}</span>
                </div>
                <div className="d-flex fw-bold">
                  <span>
                    ({getAmountToDisplay(currency != null && currency.price * transaction.amount * eurToXaf,"XAF")} XAF)
                  </span>
                </div>
              </div>
            }
          </div>
        </div>

      </div>

      <div className="removeForSmallScreen small">
        <div>
          {isToday(transaction.max_date) && <>{getDate(transaction.max_date).hour}</>}
          {isYesterday(transaction.max_date) && <>{t(_yesterday)}, {" "}{getDate(transaction.max_date).hour}</>}
          {
            ! isToday(transaction.max_date) && ! isYesterday(transaction.max_date) &&
            <>
              {
                getDate(transaction.max_date).day +" "+
                getSplitMonth(t("months_"+getDate(transaction.max_date).month)+"") +" "+
                getDate(transaction.max_date).year +", "+
                getDate(transaction.max_date).hour
              }
            </>
          }
        </div>
        <div className={amountColor}>
          {
            (type === "subscription" || type === "fund") &&
            <div className="d-flex align-items-center">
              <span className={(plusOrMinusOrNothing !== "" ? "mr-1" : "") + " fw-bold"}>{plusOrMinusOrNothing}</span>
              <span className="mr-1">{getAmountToDisplay(transaction.amount, "EUR")} &euro;</span>
              <span className="fw-bold">({getAmountToDisplay(transaction.amount * eurToXaf, "XAF")} XAF)</span>
            </div>
          }
          {
            (type === "buy" || type === "transfer" || type === "refund") &&
            <div className="d-flex align-items-center">
              <div className="d-flex mr-1">
                <span className={(plusOrMinusOrNothing !== "" ? "mr-1" : "") + " fw-bold"}>{plusOrMinusOrNothing}</span>
                <span className="mr-1">{getAmountToDisplay(transaction.amount,"EUR")}</span>
                <span>{currency != null && currency.short_symbols}</span>
              </div>
              <div className="d-flex fw-bold">
                <span>
                  ({getAmountToDisplay(currency != null && currency.price * transaction.amount * eurToXaf,"XAF")} XAF)
                </span>
              </div>
            </div>
          }
        </div>
      </div>

    </div>
  );

}

export default TxPresentation;