import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row} from "antd";
import {Link, useHistory, withRouter, useParams} from "react-router-dom";
import axios from "axios";
import RemoteCall, {getCountries, getLanguage, notification} from "../../../../UsefulFunctions";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {isStringEmpty, replaceAll} from "../../../../stringutils.js";
import Select from "react-select";
import {sessionStorage} from "../../../../Security";
import {getCountryCallingCode, isValidPhoneNumber} from "react-phone-number-input";
import EmailValidator from "email-validator";
import parsePhoneNumberFromString from "libphonenumber-js/min";
import {CardFrame, Frames} from "frames-react";
import {
  defaultCountry, _billing_address_line_1, _billing_address_line_2, _card_form_not_filled, _card_validation_failed,
  _email_address, _i_have_finished, _living_city, _my_credit_cards, _name_on_card, _new_credit_card, _next, _optional,
  _payment_settings, _phone_number, _select_a_country, _state, _verify_card, _zip_code,
} from "../../../../UsefulConstants";
import "../../../../assets/styles/creditCardPresentation.css";

function CreditCardManager() {

  const { t } = useTranslation();
  const { from } = useParams();
  const history = useHistory();
  const source = axios.CancelToken.source();

  let userCredentials = sessionStorage.get('userCredentials');

  const [loading,setLoading] = useState(false);
  const [cardHolder, setCardHolder] = useState({
    name: '',
    phone: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: null,
    zipCode: ''
  });
  const [isCardHolderValid,setIsCardHolderValid] = useState({
    name: true,
    phone: true,
    email: true,
    addressLine1: true,
    addressLine2: true,
    city: true,
    state: true,
    country: true,
    zipCode: true
  });
  const [phoneCountry, setPhoneCountry] = useState(defaultCountry);

  const [deviceSessionId, setDeviceSessionId] = useState('');
  const [deviceSessionIdExpiryDate, setDeviceSessionIdExpiryDate] = useState(0);
  const [cardToken, setCardToken] = useState(null);
  const [verificationUrl, setVerificationUrl] = useState(null);
  const [cardTokenExpiryDate, setCardTokenExpiryDate] = useState(0);


  function onChangeInfos(e, inputName) {
    setCardToken(null);
    setVerificationUrl(null);
    setCardTokenExpiryDate(0);

    let name = inputName ? inputName : e.target.name;
    let value = inputName ? e : e.target.value;

    setCardHolder({
      ...cardHolder,
      [name]: value
    });
    setIsCardHolderValid({
      ...isCardHolderValid,
      [name]: true
    })
  }

  function onChangeInfosMulti(changes) {
    setCardHolder({...cardHolder, ...changes})
  }

  function formValidation () {
    let isFormValid = true, message = "";
    if (!Frames.isCardValid()) {
      isFormValid = false;
      message = t(_card_validation_failed);
    }
    else {
      let vUpdates = {};
      for (const name of Object.keys(cardHolder)) {
        let value = cardHolder[name];
        let isValid = false;
        if (name === "phone") {
          let mobile = '+'+getCountryCallingCode(phoneCountry.code)+value;
          isValid = !isStringEmpty(value) && isValidPhoneNumber(mobile);
        }
        else if(name === "email") {
          isValid = !isStringEmpty(value) && EmailValidator.validate(value);
        }
        else if(name === "country") {
          isValid = true;
          if (value === "US" || value === "CA") {
            vUpdates['state'] = !isStringEmpty(cardHolder.state);
          }
          else {
            vUpdates['state'] = true;
          }
        }
        else if(name === "state") {

        }
        else if(name === "addressLine2") {
          isValid = true;
        }
        else {
          isValid = !isStringEmpty(value);
        }
        vUpdates[name] = isValid;
      }
      setIsCardHolderValid({
        ...isCardHolderValid,
        ...vUpdates
      });
      isFormValid = Object.values(vUpdates).filter(x => x === false).length === 0;
    }
    return {isFormValid, message}
  }

  function initializeCardHolderInfo() {
    let user = userCredentials?.['user'];
    if (user) {
      let updates = {};
      if (isStringEmpty(cardHolder.phone)) {
        let phone = user['mobiles']?.[0]?.['mobile'];
        if (phone) {
          let pn = parsePhoneNumberFromString(phone);
          if (pn) {
            let country = getCountries().find(x => x.code === pn.country) || defaultCountry;
            setPhoneCountry(country);
            updates['phone'] = replaceAll(phone, `+${country.cc}`, '');
          }
        }
      }
      if (isStringEmpty(cardHolder.email)) {
        let email = user['email']?.['email'];
        if (email) {
          updates['email'] = email;
        }
      }
      if (Object.keys(updates).length > 0) {
        setTimeout(() => onChangeInfosMulti(updates), 400)
      }
    }
  }

  async function saveCreditCardToken(_token){
    setLoading(true);

    let token = "";
    if (_token) {
      token = _token;
      setCardToken(_token);
      setCardTokenExpiryDate(Date.now() + 12 * 60 * 1000);
    }
    else {
      token = cardToken;
    }

    let devSessionId = null;
    if (deviceSessionId != null && Date.now() < deviceSessionIdExpiryDate) {
      devSessionId = deviceSessionId;
    }
    else {
      try {
        const risk = window.Risk.init(process.env.REACT_APP_RISK_PUBLIC_KEY);
        devSessionId = await risk.publishRiskData(); //expires in 20 minutes
        setDeviceSessionId(devSessionId);
        setDeviceSessionIdExpiryDate(Date.now() + 17 * 60 * 1000);
      } catch (e) {}
    }

    if (from != null && from === "t") {

      let transactionInformation = sessionStorage.get('transactionInformation');
      let data = {};
      data.tx_type = transactionInformation.tx_type;
      data.receiver = transactionInformation.receiver;
      data.userBalance = transactionInformation.userBalance;
      data.transaction = transactionInformation.transaction;
      data.allowed_modes = transactionInformation.allowed_modes;
      data.isNewCard = true;
      data.creditCard = {
        cc_source_id: token,
        device_session_id: devSessionId
      };
      data.method = "CREDIT_CARD";
      await sessionStorage.delete('transactionInformation');
      sessionStorage.set('transactionInformation', data);
      history.push('/transfer/execution');

    }else{

      let response = await RemoteCall(
        true,
        "transactions/credit-cards",
        "post",
        false,
        null,
        {
          cc_source_id: token,
          device_session_id: devSessionId
        },
        source.token,
        history
      );

      setLoading(false);

      if(response != null){
        let url = response.url;
        if (url) {
          setVerificationUrl(url);
          openCardVerificationUrl(url);
        }
      }

    }

  }

  function openCardVerificationUrl(_url){
    let url = _url || verificationUrl;
    if (url) window.open(url.toString(), '_blank');
  }

  function handleSubmitButton() {

    if(verificationUrl != null && Date.now() < cardTokenExpiryDate){
      openCardVerificationUrl();
    }
    else if(cardToken != null && Date.now() < cardTokenExpiryDate){
      saveCreditCardToken();
    }
    else{
      setCardToken(null);
      setVerificationUrl(null);
      setCardTokenExpiryDate(0);

      let fv = formValidation();
      if(!fv.isFormValid) {
        let message = fv.message || t(_card_form_not_filled);
        notification("warning", message);
        return;
      }

      setLoading(true);
      Frames.submitCard();
    }
  }

  function handleTokenizationFailed (e) {
    setLoading(false);
    notification("warning", e.message);
    console.error(e);
  }

  useEffect(
    () => {

      // Création de l'élément script
      let src = process.env.REACT_APP_RISK_SOURCE;
      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      // Fonction appelée lorsque le script est chargé
      const onScriptLoad = async () => {
        console.log(`Le script ${src} a été chargé avec succès.`);
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', function (e) {
        console.error(e);
      });

      // Ajout du script au document
      document.body.appendChild(script);

      initializeCardHolderInfo();

      // Nettoyage : Suppression du script et de l'écouteur d'événements lors du démontage du composant
      return () => {
        script.removeEventListener('load', onScriptLoad);
        document.body.removeChild(script);
        source.cancel();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={(from != null && from === "t") ? '/transfer/method-selection' : '/profile/payment/credit-cards'}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="d-flex flex-column mb-3 ml-5">
              <small>{t(_payment_settings)} / {t(_my_credit_cards)}</small>
              <h5 className="fw-bold">{t(_new_credit_card)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={18} className="my-3 p-2">

                <form>

                  <div className="mb-2">
                    <Frames
                      config={{
                        publicKey: 'pk_sbox_47yezyiuuiryzzbhmm2yhxpk54w',
                        cardholder: {
                          name: cardHolder.name,
                          phone: cardHolder.phone,
                          billingAddress: {
                            country: cardHolder.country?.code,
                            state: cardHolder.state,
                            city: cardHolder.city,
                            zip: cardHolder.zipCode,
                            addressLine1: cardHolder.addressLine1,
                            addressLine2: cardHolder.addressLine2
                          }
                        },
                        schemeChoice: false,
                        localization: getLanguage() === 'fr' ? 'FR-FR' : 'EN-GB'
                      }}
                      cardTokenized={(e) => {
                        let token = e.token;
                        saveCreditCardToken(token);
                      }}
                      cardTokenizationFailed={(e) => handleTokenizationFailed(e)}
                    >
                      <CardFrame className="frame-input"/>
                    </Frames>
                  </div>

                  <div className="mb-2">
                    <Input
                      name="name"
                      type="text"
                      value={cardHolder.name}
                      onChange={onChangeInfos}
                      className="my-input"
                      status={isCardHolderValid.name ? "success" : "error"}
                      size="large"
                      placeholder={t(_name_on_card)}
                      required
                    />
                  </div>

                  <div className="d-flex mb-2">
                    <Select
                      className="my-select mr-3"
                      classNamePrefix="my-select"
                      components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                      value={phoneCountry}
                      onChange={(e) => setPhoneCountry(e)}
                      options={getCountries()}
                      isSearchable
                      autosize={false}
                      getOptionLabel={(option) => option.name}
                      formatOptionLabel={(option, {context}) => {
                        return context === 'menu' ? option.label : option.label2;
                      }}
                    />
                    <div className="full-width">
                      <Input
                        name="phone"
                        type="number"
                        value={cardHolder.phone}
                        onChange={onChangeInfos}
                        className="my-input"
                        status={isCardHolderValid.phone ? "success" : "error"}
                        size="large"
                        placeholder={t(_phone_number)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <Input
                      name="email"
                      type="text"
                      value={cardHolder.email}
                      onChange={onChangeInfos}
                      className="my-input"
                      status={isCardHolderValid.email ? "success" : "error"}
                      size="large"
                      placeholder={t(_email_address)}
                      required
                    />
                  </div>

                  <Row gutter={8} className="mb-2">
                    <Col xs={12}>
                      <Select
                        className={isCardHolderValid.country ? "my-select-2" : "my-select-2-warning"}
                        classNamePrefix={isCardHolderValid.country ? "my-select-2" : "my-select-2-warning"}
                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                        value={cardHolder.country}
                        onChange={(e) => onChangeInfos(e, "country")}
                        options={getCountries()}
                        isSearchable
                        autosize={false}
                        getOptionLabel={(option) => option.name}
                        formatOptionLabel={(option) => {
                          return option.label
                        }}
                        placeholder={t(_select_a_country)}
                        required
                      />
                    </Col>
                    <Col xs={12}>
                      <Input
                        name="state"
                        type="text"
                        value={cardHolder.state}
                        onChange={onChangeInfos}
                        className="my-input"
                        status={isCardHolderValid.state ? "success" : "error"}
                        size="large"
                        placeholder={t(_state)}
                        required={["US", "CA"].includes(cardHolder.country?.code)}
                      />
                    </Col>
                  </Row>

                  <Row gutter={8} className="mb-2">
                    <Col xs={12}>
                      <Input
                        name="city"
                        type="text"
                        value={cardHolder.city}
                        onChange={onChangeInfos}
                        className="my-input"
                        status={isCardHolderValid.city ? "success" : "error"}
                        size="large"
                        placeholder={t(_living_city)}
                        required
                      />
                    </Col>
                    <Col xs={12}>
                      <Input
                        name="zipCode"
                        type="text"
                        value={cardHolder.zipCode}
                        onChange={onChangeInfos}
                        className="my-input"
                        status={isCardHolderValid.zipCode ? "success" : "error"}
                        size="large"
                        placeholder={t(_zip_code)}
                        required
                      />
                    </Col>
                  </Row>

                  <div className="d-flex mb-2">
                    <Input
                      name="addressLine1"
                      type="text"
                      value={cardHolder.addressLine1}
                      onChange={onChangeInfos}
                      className="my-input"
                      status={isCardHolderValid.addressLine1 ? "success" : "error"}
                      size="large"
                      placeholder={t(_billing_address_line_1)}
                      required
                    />
                  </div>

                  <div className="d-flex mb-2">
                    <Input
                      name="addressLine2"
                      type="text"
                      value={cardHolder.addressLine2}
                      onChange={onChangeInfos}
                      className="my-input"
                      status={isCardHolderValid.addressLine2 ? "success" : "error"}
                      size="large"
                      placeholder={t(_billing_address_line_2) + " (" + t(_optional) + ")"}
                      required={false}
                    />
                  </div>

                </form>

                <div className="d-flex align-items-center justify-content-center mt-4">
                  {
                    verificationUrl != null &&
                    <Link to={'/profile/payment/credit-cards'}>
                      <Button className="my-button bg-green-kamix text-white me-2" loading={loading} htmlType="reset">
                        {t(_i_have_finished)}
                      </Button>
                    </Link>
                  }
                  <Button className="my-button bg-yellow-kamix text-dark" loading={loading} htmlType="submit" onClick={handleSubmitButton}>
                    {verificationUrl != null ? t(_verify_card) : t(_next)}
                  </Button>
                </div>

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(CreditCardManager);