import React, {useEffect, useState} from 'react';
import {Row, Col, Button, Modal} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {sessionStorage} from "../../Security";
import {ArrowLeftOutlined, ExclamationCircleFilled} from "@ant-design/icons";
import BarLoader from 'react-bar-loader'
import RemoteCall from "../../UsefulFunctions";
import {
  yellow_kamix,
  _making_transfer,
  _transaction_text_accepted,
  _transaction_text_failed,
  _transaction_text_pending,
  _click_on_link_below,
  _transfer_in_progress,
  _try_again,
  _understood,
  _not_redirected_to_transfer_completion_page,
  _purchase_in_progress,
  _making_purchase,
  _purchase_text_failed,
  _purchase_text_accepted,
  _purchase_text_pending,
  _abandon_transaction_confirm_question, _no, _yes
} from "../../UsefulConstants";

function TransferExecution() {

  const { t } = useTranslation();
  const source = axios.CancelToken.source();
  const history = useHistory();

  const [loading,setLoading] = useState(false);
  const [transaction,setTransaction] = useState(null);
  // const [kamixRibs,setKamixRibs] = useState([]);
  const [url,setUrl] = useState(null);

  const [toggleAbandonTx, setToggleAbandonTx] = useState(false);

  //-----------------------------------------------------------
  let transactionInformation = sessionStorage.get('transactionInformation');
  let transaction_code =
    transactionInformation != null && transactionInformation.transaction != null &&
    transactionInformation.transaction.code
  ;
  let tx_type = transactionInformation != null && transactionInformation.tx_type != null ? transactionInformation.tx_type : "transfer";
  //-----------------------------------------------------------

  async function launchTransfer(){

    setLoading(l => !l);

    //-----------------------------------------------------------
    let bodyParam = {
      mode:transactionInformation != null && transactionInformation.method
    };
    //----------------------------
    if(transactionInformation != null && transactionInformation.method === "AUTOMATIC_BANK_TRANSFER" && transactionInformation.country != null){
      bodyParam.country = transactionInformation.country
    }
    //----------------------------
    if(transactionInformation != null && transactionInformation.method === "CREDIT_CARD"){
      bodyParam.device_session_id = transactionInformation.creditCard?.device_session_id;
      if(transactionInformation.isNewCard){
        bodyParam.cc_source_id = transactionInformation.creditCard?.cc_source_id;
        bodyParam.source = "token";
      }else{
        bodyParam.cc_source_id = transactionInformation.creditCard?.credit_card_id;
        bodyParam.source = "card";
      }
    }
    //-----------------------------------------------------------

    let response = await RemoteCall(
      true,
      "transactions/do/launch/" + transaction_code,
      "patch",
      false,
      null,
      bodyParam,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      setTransaction(response.transaction);
      //setKamixRibs(response.kamix_ribs);
      setUrl(response.url);
      if(response.url != null && typeof response.url === "string"){
        const win = window.open(response.url, "_blank");
        win?.focus();
      }
    }

  }

  function onToggleAbandonTx (toggle) {
    setToggleAbandonTx(toggle);
  }

  function abandonTx () {
    history.push('/home');
  }


  useEffect(
    () => {

      //----------------------------------------------------
      if(transactionInformation == null || transactionInformation === "") history.push('/home');
      //----------------------------------------------------

      // Création de l'élément script
      let src = process.env.REACT_APP_RISK_SOURCE;
      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      // Fonction appelée lorsque le script est chargé
      const onScriptLoad = async () => {
        console.log(`Le script ${src} a été chargé avec succès.`);
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', function (e) {
        console.error(e);
      });

      // Ajout du script au document
      document.body.appendChild(script);

      //----------------------------------------------------
      launchTransfer();
      //----------------------------------------------------

      // Nettoyage : Suppression du script et de l'écouteur d'événements lors du démontage du composant
      return () => {
        script.removeEventListener('load', onScriptLoad);
        document.body.removeChild(script);
        source.cancel();
      };

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <div onClick={() => setToggleAbandonTx(true)}>
              <ArrowLeftOutlined className="icon-sm" />
            </div>

            <div className="mb-3 mx-5">
              <h5 className="fw-bold mb-2">
                {
                  loading ? (
                    t(tx_type === "transfer" ? _making_transfer : _making_purchase)
                  ) : (
                    t(
                      (tx_type === "transfer" ? 'transfer_' : 'purchase_') +
                      (transaction != null && transaction.status != null ? transaction.status.toLowerCase() : "")
                    )
                  )
                }
              </h5>
              {
                loading &&
                <BarLoader color={yellow_kamix} height="4" className="bar-loader" />
              }
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loading &&
                  <div>{(tx_type === "transfer") ? t(_transfer_in_progress) : t(_purchase_in_progress)}</div>
                }

                {
                  transaction != null &&
                  <>

                    {
                      url &&
                      <div className="mb-5">
                        <div className="text-normal fw-bold mb-2">{t(_not_redirected_to_transfer_completion_page)}</div>
                        <div className="text-normal mb-1">{t(_click_on_link_below)}</div>
                        <a href={url} target="_blank" rel="noopener noreferrer" className="my-link">
                          {url}
                        </a>
                      </div>
                    }

                    {
                      transaction.status === "PENDING" &&
                      <div>{t(tx_type === "transfer" ? _transaction_text_pending : _purchase_text_pending)}</div>
                    }

                    {
                      transaction.status === "ACCEPTED" &&
                      <div>{t(tx_type === "transfer" ? _transaction_text_accepted : _purchase_text_accepted)}</div>
                    }

                    {
                      transaction.status === "FAILED" &&
                      <div>{t(tx_type === "transfer" ? _transaction_text_failed : _purchase_text_failed)}</div>
                    }

                    <div className="d-flex mt-3">
                      {
                        transaction.status === "FAILED" ? (
                          <Button className="border-radius-kamix bg-yellow-kamix text-dark" onClick={launchTransfer}>
                            {t(_try_again)}
                          </Button>
                        ) : (
                          <Link to={'/history'}>
                            <Button className="border-radius-kamix bg-yellow-kamix text-dark">
                              {t(_understood)}
                            </Button>
                          </Link>
                        )
                      }
                    </div>
                  </>
                }

              </Col>
            </Row>

          </Col>
        </Row>

        <Modal
            visible={toggleAbandonTx}
            title={null}
            footer={null}
            closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <>
                <div className="d-flex justify-content-center">
                  <ExclamationCircleFilled style={{fontSize:"40px", color:yellow_kamix}} />
                </div>
                <div className="text-normal text-center mt-3 mb-4">{t(_abandon_transaction_confirm_question)}</div>
                <div className="d-flex justify-content-center">
                  <Button className="my-button-2 text-dark fw-normal m-1" onClick={() => onToggleAbandonTx(false)}>{t(_no)}</Button>
                  <Button className="my-button-2 bg-yellow-kamix text-dark m-1" onClick={abandonTx}>{t(_yes)}</Button>
                </div>
              </>

            </Col>
          </Row>

        </Modal>

      </Col>
    </Row>
  )

}

export default withRouter(TransferExecution);