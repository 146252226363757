import React from 'react';
import {useTranslation} from "react-i18next";
import {Image} from "antd";
import {getDate, getSplitMonth, isToday} from "../../UsefulFunctions";
import {CheckOutlined} from "@ant-design/icons";
import {_browser_video_warning, _photo} from "../../UsefulConstants";
import "../../assets/styles/ticketManager.css";

function MessagePresentation({message}) {

  const { t } = useTranslation();

  return(
    <div>

      {
        message.type === "TEXT" &&
        <div className={message.sender === "USER" ? "messageTextPresentationUser" : "messageTextPresentationAdmin"}>
          <div>
            <div className="text-dark small dangerouslySetInnerHTML" dangerouslySetInnerHTML={{ __html: message.text }} />
          </div>
          <div className="footerMessage">
            <span className="mr-1">
              {
                isToday(message.created_at) ? (
                  <div>{getDate(message.created_at).hour}</div>
                ) : (
                  <div>
                    {
                      getDate(message.created_at).day +" "+
                      getSplitMonth(t("months_"+getDate(message.created_at).month)+"") +" "+
                      getDate(message.created_at).year +", "+
                      getDate(message.created_at).hour
                    }
                  </div>
                )
              }
            </span>
            <span>
              {
                message.read_by_admin &&
                <span className="text-primary"><CheckOutlined /></span>
              }
              </span>
          </div>
        </div>
      }

      {
        (message.type === "PHOTO" || message.type === "VIDEO") &&
        <div className={message.sender === "USER" ? "messageMultimediaPresentationUser" : "messageMultimediaPresentationAdmin"}>
          {
            message.type === "PHOTO" &&
            <Image alt={t(_photo)} className="multimediaPreview" src={message.file_url}/>
          }
          {
            message.type === "VIDEO" &&
            <video className="multimediaPreview" controls="controls">
              <source src={message.file_url} />
              {t(_browser_video_warning)}
            </video>
          }
          <div className="footerMessage">
            <span className="mr-1">
              {
                isToday(message.created_at) ? (
                  <div>{getDate(message.created_at).hour}</div>
                ) : (
                  <div>
                    {
                      getDate(message.created_at).day +" "+
                      getSplitMonth(t("months_"+getDate(message.created_at).month)+"") +" "+
                      getDate(message.created_at).year +", "+
                      getDate(message.created_at).hour
                    }
                  </div>
                )
              }
            </span>
            <span>
              {
                message.read_by_admin &&
                <span className="text-primary"><CheckOutlined /></span>
              }
            </span>
          </div>
        </div>
      }

    </div>
  )

}

export default MessagePresentation;