import React from 'react';
import {Row, Col} from "antd";
import {Link, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import SubMenuElement from "../../others/SubMenuElement";
import {
  _payment_settings, _my_receivers, _my_receivers_text, _history, _transfers, _transaction_statement,
  _transaction_statement_text, _my_credit_cards_text, _my_credit_cards
} from "../../../UsefulConstants";

function PaymentSettings() {

  const { t } = useTranslation();

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <h5 className="fw-bold">{t(_payment_settings)}</h5>
            </div>

            <div className="small mb-1 ml-5">{t(_history)}</div>
            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={20} className="my-3">

                <SubMenuElement
                  to={"/profile/payment/tx-statement"}
                  title={t(_transaction_statement)}
                  description={t(_transaction_statement_text)}
                />

              </Col>
            </Row>

            <div className="small mb-1 ml-5">{t(_transfers)}</div>
            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={20} className="my-3">

                <SubMenuElement
                  to={"/profile/payment/receivers"}
                  title={t(_my_receivers)}
                  description={t(_my_receivers_text)}
                />

                <SubMenuElement
                  to={"/profile/payment/credit-cards"}
                  title={t(_my_credit_cards)}
                  description={t(_my_credit_cards_text)}
                />

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(PaymentSettings);