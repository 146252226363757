import React, {useState} from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {_copied} from "../../UsefulConstants";
import {CopyOutlined} from "@ant-design/icons";
import "../../assets/styles/myCopyButton.css";

function MyCopyButton({text,children,fontSize}) {

  const { t } = useTranslation();
  const [isTextCopy,setIsTextCopy] = useState(false);

  async function handleCopy() {
    setIsTextCopy(true);
    await setTimeout(() => {
      setIsTextCopy(false);
    }, 1000);
  }

  return(
    <div className="d-flex flex-column justify-content-center align-items-center">
      { isTextCopy && <div className="copiedText">{t(_copied)}</div> }
      {
        children != null ? (
          <CopyToClipboard text={text} onCopy={() => handleCopy()}>
            {children}
          </CopyToClipboard>
        ) : (
          <CopyToClipboard text={text}>
            <CopyOutlined style={{fontSize:fontSize != null ? (fontSize+"px") : "15px"}} />
          </CopyToClipboard>
        )
      }
    </div>
  )

}

export default MyCopyButton;