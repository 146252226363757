import React from 'react';
import { Link } from "react-router-dom";
import {Row, Col} from "antd";
import {RightOutlined} from "@ant-design/icons";
import "../../assets/styles/subMenuElement.css";

function SubMenuElement({to,title,description}) {

  return(
    <div className="submenu-element">
      <Link to={to}>
        <Row className="align-items-center">
          <Col xs={22}>
            <div className="title"> {title}</div>
            <div className="description">{description}</div>
          </Col>
          <Col xs={2} className="d-flex justify-content-end">
            <RightOutlined style={{fontSize:"20px"}} />
          </Col>
        </Row>
      </Link>
    </div>
  )

}

export default SubMenuElement;