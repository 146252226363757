import React from 'react';
import {Row, Col} from "antd";
import {Link, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ProfileElement from "./others/ProfileElement";
import FollowUsOnRs from "../assets/icons/profile/feedback.svg";
import Devices from "../assets/icons/profile/devices.svg";
import CreditCard from "../assets/icons/profile/credit_card.svg";
import Security from "../assets/icons/profile/shield.svg";
import TermsAndConditions from "../assets/icons/profile/terms.svg";
import PrivacyPolicy from "../assets/icons/profile/privacy.svg";
import User from "../assets/icons/profile/user.svg";
import {ArrowRightOutlined} from "@ant-design/icons";
import {
  _follow_us_on_social_media, _my_devices, _kamix_profile, _privacy_policy, _security_settings,
  _terms_and_conditions, _payment_settings, _invite_someone
} from "../UsefulConstants";
import "../assets/styles/profileElement.css";

function Profile() {

  const { t } = useTranslation();
  const CGU = process.env.REACT_APP_CGUV;
  const RGPD = process.env.REACT_APP_RGPD;

  return(
    <Row className="justify-content-center my-3">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Row gutter={[16,16]} className="justify-content-center">
              <Col xs={24}>
                <ProfileElement where={"in"} to={'/profile/account'} title={t(_kamix_profile)} image={User} />
              </Col>
              <Col xs={12}>
                <ProfileElement where={"in"} to={'/profile/devices'} title={t(_my_devices)} image={Devices}/>
              </Col>
              <Col xs={12}>
                <ProfileElement where={"in"} to={'/profile/payment'} title={t(_payment_settings)} image={CreditCard}/>
              </Col>
              <Col xs={12}>
                <ProfileElement where={"in"} to={'/profile/security'} title={t(_security_settings)} image={Security}/>
              </Col>
              <Col xs={12}>
                <ProfileElement where={"out"} to={RGPD} title={t(_privacy_policy)} image={PrivacyPolicy}/>
              </Col>

              <Col xs={24}>
                <Link to={'/profile/invitation'}>
                  <div className="invite-friends-box">
                    <div className="title">{t(_invite_someone)}</div>
                    <div className="line-decoration"/>
                    <ArrowRightOutlined className="icon" />
                  </div>
                </Link>
              </Col>

              <Col xs={12}>
                <ProfileElement where={"out"} to={CGU} title={t(_terms_and_conditions)} image={TermsAndConditions}/>
              </Col>
              <Col xs={12}>
                <ProfileElement where={"in"} to={'/profile/follow-us'} title={t(_follow_us_on_social_media)} image={FollowUsOnRs}/>
              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(Profile);