import React from 'react';
import {Row, Col} from "antd";
import {useTranslation} from "react-i18next";
import {MobileOutlined, LaptopOutlined} from "@ant-design/icons";
import {sessionStorage} from "../../../Security";
import {
  _can_perform_transaction_text, _connected_device, _last_seen_on, _main_device, _last_seen_today
} from "../../../UsefulConstants";
import {getDate, getSplitMonth, isToday} from "../../../UsefulFunctions";
import "../../../assets/styles/devicePresentation.css";

function DevicePresentation({device}) {

  const { t } = useTranslation();
  let userCredentials = sessionStorage.get('userCredentials');

  return(
    <Row gutter={[8]} className="devicePresentation">

      <div className="devicePresentation-icon">
        {
          device.type === "mobile" ? (
            <MobileOutlined style={{fontSize:"30px"}} />
          ) : (
            <LaptopOutlined style={{fontSize:"30px"}} />
          )
        }
      </div>

      <Col flex="auto" className="infos">
        <div className="fw-bold text-darker mb-1">{device.name}</div>
        <div className="d-flex flex-column align-items-start">
          <div className="d-flex flex-wrap align-items-end" style={{fontSize:"0.9em"}}>
            <span className="text-dark mr-2">{t(_can_perform_transaction_text)}</span>
            <span className="devicePresentation-type-custom">
              <span className={device.can_do_transactions.toString().toLowerCase()}>
                {t("tx_setting_"+device.can_do_transactions.toString().toLowerCase())}
              </span>
            </span>
          </div>
          <div className="d-flex small mt-1">
            <div className="mr-1">
              {t(isToday(device.last_seen) ? _last_seen_today : _last_seen_on)}
            </div>
            <div className="fw-bold text-dark">
              {
                isToday(device.last_seen) ? (
                  <div>{getDate(device.last_seen).hour}</div>
                ) : (
                  <div>
                    {
                      getDate(device.last_seen).day +" "+
                      getSplitMonth(t("months_"+getDate(device.last_seen).month)+"") +" "+
                      getDate(device.last_seen).year +", "+
                      getDate(device.last_seen).hour
                    }
                  </div>
                )
              }
            </div>
          </div>
          <div className="d-flex flex-wrap mt-2">
            {
              device.main &&
              <div className="main-device">
                {t(_main_device)}
              </div>
            }
            {
              userCredentials != null && userCredentials.device === device.did &&
              <div className="badge">
                {t(_connected_device)}
              </div>
            }
          </div>
        </div>
      </Col>

      <div className="device-type">
        <div className={device.type.toString().toLowerCase()}>{device.type}</div>
      </div>

    </Row>
  )

}

export default DevicePresentation;