import React from 'react';
import {Row, Col} from "antd";
import {CreditCardFilled} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {creditCardStatus, displayCreditCardNumber, getCreditCardLogo} from "../../../../UsefulFunctions";
import "../../../../assets/styles/creditCardPresentation.css";

function CreditCardPresentation({creditCard}) {

  const { t } = useTranslation();
  let cardImage= creditCard != null ? getCreditCardLogo(creditCard.card_scheme?.toUpperCase()) : null;

  return(
    <Row className="creditCardPresentation">
      <div className="d-flex align-items-center mr-3">
        {
          cardImage != null ? (
            <img
              alt={"credit card icon"}
              className="img-fluid"
              src={cardImage}
              style={{ width: "30px", height:"30px", objectFit:"cover" }}
            />
          ) : (
            <CreditCardFilled style={{fontSize:"30px"}} />
          )
        }
      </div>
      <Col className="mr-auto">
        <div className="fw-bold mb-1">
          {creditCard.credit_card_id ? displayCreditCardNumber(creditCard.bin, creditCard.last_four, creditCard.card_scheme) : creditCard.temporary_id}
        </div>
        <div className="my-badge mb-2 mb-md-0">
          {t(creditCard.card_type)}
        </div>
        <div className="d-sm-block d-md-none">
          <div className={"fw-medium one-line small status-" + (creditCardStatus(creditCard) != null ? creditCardStatus(creditCard).toLowerCase() : "")}>
            {(creditCardStatus(creditCard) != null) ? t(creditCardStatus(creditCard)).toUpperCase() : "--"}
          </div>
        </div>
      </Col>
      <div className="d-none d-md-flex align-items-end">
        <div className={"fw-medium one-line small status-" + (creditCardStatus(creditCard) != null ? creditCardStatus(creditCard).toLowerCase() : "")}>
          {(creditCardStatus(creditCard) != null) ? t(creditCardStatus(creditCard)).toUpperCase() : "--"}
        </div>
      </div>
    </Row>
  )

}

export default CreditCardPresentation;