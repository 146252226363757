import React from "react";
import {Link, withRouter, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Row, Col, Layout, Button, Dropdown, Menu} from 'antd';
import {_app_name, _history, _home, _logout, _profile, _support} from "../UsefulConstants";
import KamixLogo from "../assets/images/logo_kamix.png";
import {UserOutlined, CloseSquareOutlined, MenuOutlined} from "@ant-design/icons";
import LanguageSelector from "../components/others/LanguageSelector";
import {ReactComponent as HomeIcon} from "../assets/icons/header/home.svg";
import {ReactComponent as HistoryIcon} from "../assets/icons/header/history.svg";
import {ReactComponent as SupportIcon} from "../assets/icons/header/support.svg";
import {ReactComponent as UserIcon} from "../assets/icons/profile/user.svg";
import {ReactComponent as LogoutIcon} from "../assets/icons/profile/log_out.svg";
import "../assets/styles/containers.css"

const { Header } = Layout;

function DefaultHeader(props) {

  const { t } = useTranslation();
  let location = useLocation();

  const responsiveMenu =
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Link to={'/home'}>
              <div className={location.pathname.includes('home') ? "my-navlink-active-2" : "my-navlink-2"}>
                <HomeIcon className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_home)}</span>
              </div>
            </Link>
          )
        },
        {
          key: '2',
          label: (
            <Link to={'/history'}>
              <div className={location.pathname.includes('history') ? "my-navlink-active-2" : "my-navlink-2"}>
                <HistoryIcon className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_history)}</span>
              </div>
            </Link>
          )
        },
        {
          key: '3',
          label: (
            <Link to={'/support'}>
              <div className={location.pathname.includes('support') ? "my-navlink-active-2" : "my-navlink-2"}>
                <SupportIcon className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_support)}</span>
              </div>
            </Link>
          )
        },
        {
          key: '4',
          label: (
            <Link to={'/profile'}>
              <div className={location.pathname.includes('profile') ? "my-navlink-active-2" : "my-navlink-2"}>
                <UserOutlined className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_profile)}</span>
              </div>
            </Link>
          )
        },
        {
          key: '5',
          label: (
            <div className="my-navlink-2" onClick={e => props.onLogout(e)}>
              <CloseSquareOutlined className="my-navbar-icon" />
              <span className="my-navbar-title">{t(_logout)}</span>
            </div>
          )
        }
      ]}
    />
  ;

  return(
    <Header className="header">
      <Row className="justify-content-center">
        <Col xs={24} md={22} xl={18} className="d-flex align-items-center">

          <Link to={'/home'}>
            <div className="logoBox">
              <img src={KamixLogo} className="kamixLogo" alt={t(_app_name)} />
            </div>
          </Link>

          <div className="my-navbar d-none d-lg-flex">
            <Link to={'/home'}>
              <div className={location.pathname.includes('home') ? "my-navlink-active" : "my-navlink"}>
                <HomeIcon className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_home)}</span>
              </div>
            </Link>
            <Link to={'/history'}>
              <div className={location.pathname.includes('history') ? "my-navlink-active" : "my-navlink"}>
                <HistoryIcon className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_history)}</span>
              </div>
            </Link>
            <Link to={'/support'}>
              <div className={location.pathname.includes('support') ? "my-navlink-active" : "my-navlink"}>
                <SupportIcon className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_support)}</span>
              </div>
            </Link>
            <Link to={'/profile'}>
              <div className={location.pathname.includes('profile') ? "my-navlink-active" : "my-navlink"}>
                <UserIcon className="my-navbar-icon" />
                <span className="my-navbar-title">{t(_profile)}</span>
              </div>
            </Link>
          </div>

          <div className="my-navbar-buttons d-none d-lg-flex">
            <LanguageSelector />
            <Button type="text" onClick={e => props.onLogout(e)}>
              <div>
                <LogoutIcon style={{width:"20px", marginRight:"5px"}} />
                <span className="my-navbar-title">{t(_logout)}</span>
              </div>
            </Button>
          </div>

          <div className="my-navbar-buttons  d-flex d-lg-none">
            <Dropdown overlay={responsiveMenu} placement="bottomRight" arrow  trigger={['click']}>
              <MenuOutlined className="responsive-menu-button" />
            </Dropdown>
          </div>

        </Col>
      </Row>
    </Header>
  )

}

export default withRouter(DefaultHeader);
