import React from 'react';
import {Row, Col} from "antd";
import {Link, withRouter} from "react-router-dom";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import ResetElement from "../../others/ResetElement";
import {_forget_password_explanation, _password_forgotten, _security_settings} from "../../../UsefulConstants";

function PasswordReset() {

  const { t } = useTranslation();

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile/security"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_security_settings)}</small>
              <h5 className="fw-bold">{t(_password_forgotten)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                <div className="text-normal text-justify mb-4">
                  {t(_forget_password_explanation)}
                </div>

                <ResetElement where={"in"} />

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(PasswordReset);