import React, {useState, useEffect} from 'react';
import {Row, Col, Input, Button} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import RemoteCall, {notification} from "../../../UsefulFunctions";
import {
  _change_profile_password, _confirm_password, _continue, _invalid_password, _new_password, _cancel,
  _old_password, _password_constraint_text_2, _password_reset_success, _required, _security_settings, strongPassword
} from "../../../UsefulConstants";

function PasswordUpdate() {

  const { t } = useTranslation();
  const history = useHistory();

  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [oldPassword,setOldPassword] = useState('');
  const [newPassword,setNewPassword] = useState('');
  const [confirmPassword,setConfirmPassword] = useState('');
  const [isOldPasswordValid,setIsOldPasswordValid] = useState(true);
  const [isNewPasswordValid,setIsNewPasswordValid] = useState(true);
  const [isConfirmPasswordValid,setIsConfirmPassword] = useState(true);

  function onChangeOldPassword(e) {
    setOldPassword(e.target.value);
    setIsOldPasswordValid(true);
  }

  function onChangeNewPassword(e) {
    setNewPassword(e.target.value);
    setIsNewPasswordValid(true);
  }

  function onChangeConfirmPassword(e) {
    setConfirmPassword(e.target.value);
    setIsConfirmPassword(true);
  }

  async function onSubmit(e){
    e.preventDefault();
    if(
      oldPassword === "" || newPassword === "" || confirmPassword === "" ||
      !strongPassword.test(newPassword) || newPassword !== confirmPassword
    ){
      setIsOldPasswordValid(oldPassword !== "");
      setIsNewPasswordValid(newPassword !== "" && strongPassword.test(newPassword));
      setIsConfirmPassword(confirmPassword !== "" && confirmPassword === newPassword);
    }else{

      let formData = {};
      formData.old_password = oldPassword;
      formData.new_password = newPassword;
      formData.confirm_password = confirmPassword;

      setLoading(l => !l);

      let response = await RemoteCall(
        true,
        "users/change-password",
        "patch",
        false,
        null,
        formData,
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        notification("success",t(_password_reset_success));
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }

    }
  }

  useEffect(
    () => {
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile/security"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_security_settings)}</small>
              <h5 className="fw-bold">{t(_change_profile_password)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                <Row className="justify-content-center">
                  <Col xs={22} md={18}>

                    <form onSubmit={(e)=>onSubmit(e)}>
                      <div className="mb-4">
                        <div className="text-normal small mb-1">{t(_old_password)}</div>
                        <Input.Password
                          type="password"
                          value={oldPassword}
                          onChange={onChangeOldPassword}
                          status={isOldPasswordValid ? "success" : "error"}
                          className="my-input"
                          size="large"
                        />
                        {
                          !isOldPasswordValid &&
                          <div className="position-absolute ant-form-item-explain-error">
                            {oldPassword === "" && t(_required)}
                          </div>
                        }
                      </div>
                      <div className="mb-4">
                        <div className="text-normal small mb-1">{t(_new_password)}</div>
                        <Input.Password
                          type="password"
                          value={newPassword}
                          onChange={onChangeNewPassword}
                          status={isNewPasswordValid ? "success" : "error"}
                          className="my-input"
                          size="large"
                        />
                        {
                          !isNewPasswordValid &&
                          <div className="position-absolute ant-form-item-explain-error">
                            {newPassword === "" && t(_required)}
                            {newPassword !== "" && !strongPassword.test(newPassword) && t(_password_constraint_text_2)}
                          </div>
                        }
                      </div>
                      <div>
                        <div className="text-normal small mb-1">{t(_confirm_password)}</div>
                        <Input.Password
                          type="password"
                          value={confirmPassword}
                          onChange={onChangeConfirmPassword}
                          status={isConfirmPasswordValid ? "success" : "error"}
                          className="my-input"
                          size="large"
                        />
                        {
                          !isConfirmPasswordValid &&
                          <div className="position-absolute ant-form-item-explain-error">
                            {confirmPassword === "" && t(_required)}
                            {confirmPassword !== newPassword && confirmPassword !== "" && t(_invalid_password)}
                          </div>
                        }
                      </div>
                      <div className="d-flex" style={{marginTop:"35px"}}>
                        <Link to={`/profile/security`}>
                          <Button className="text-dark border-radius-kamix mr-2" disabled={loading}>{t(_cancel)}</Button>
                        </Link>
                        <Button className="my-button-2 bg-yellow-kamix text-dark" loading={loading} htmlType="submit">
                          {t(_continue)}
                        </Button>
                      </div>
                    </form>

                  </Col>
                </Row>

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(PasswordUpdate);