import React, {useState, useEffect} from 'react';
import {useParams, useHistory, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RemoteCall, {getDate, getTextToDisplay2, notification, textWithFirstCapitalLetter} from "../../../../UsefulFunctions";
import axios from "axios";
import {Button, Col, Input, Modal, Row, Skeleton} from "antd";
import {ArrowLeftOutlined, CloseCircleOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import KycElement from "./KycElement";
import Select from "react-select";
import FormData from 'form-data';
import {
  _message, _kamix_profile, _status, _updated_at, _verified_at, _description, _add_a_document, _documents, _document_name,
  _add_additional_kyc, _select_document_type, _choose_file, _cancel, _add, _file_size_warning, _modification_applied
} from "../../../../UsefulConstants";
import "../../../../assets/styles/myAccount.css";

function AdditionalKyc(){

  const { t } = useTranslation();
  const { kycName,from } = useParams();
  const history = useHistory();
  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [loadingAddItem,setLoadingAddItem] = useState(false);
  const [toggleAdditionalKyc,setToggleAdditionalKyc] = useState(false);

  const [userAdditionalKyc,setUserAdditionalKyc] = useState(null);
  const [documentName,setDocumentName] = useState("");
  const [file,setFile] = useState(null);


  function onToggleAddAdditionalKyc() {
    setFile(null);
    setDocumentName("");
    setToggleAdditionalKyc(t => !t);
  }

  function getResponse(response){
    let uak = response.user_additional_kyc;
    uak.value = uak.kyc_name;
    uak.label = getTextToDisplay2(uak.display_names,"value");
    setUserAdditionalKyc(uak);
  }

  function onChangeFile(e){
    if((e.target.files[0] != null && e.target.files[0].size/1048576)>10){
      notification("warning",t(_file_size_warning));
    }else{
      setFile(e.target.files[0]);
    }
  }

  function deleteFile(){
    setFile(null);
  }

  async function addDocument(e){

    e.preventDefault();

    //--------------------------------------------
    let bodyParam = new FormData();
    bodyParam.append("purpose","add");
    bodyParam.append("file",file);
    //---------------------
    if(documentName !== ""){
      bodyParam.append("name",documentName);
    }
    //--------------------------------------------

    setLoadingAddItem(t=>!t);

    let response = await RemoteCall(
      true,
      "kyc/additional/v3/"+userAdditionalKyc.value,
      "post",
      false,
      null,
      bodyParam,
      source.token,
      history
    );

    setLoadingAddItem(t=>!t);

    if(response != null){
      getResponse(response);
      setToggleAdditionalKyc(false);
      notification("success",t(_modification_applied));
    }

  }

  async function getUserKyc(){

    setLoading(l => !l);

    let response = await RemoteCall(
      "true",
      "kyc/additional/v3/"+kycName,
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      getResponse(response);
    }

  }

  function cancel() {
    source.cancel();
  }

  useEffect(
    () => {
      getUserKyc();
      return () => cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kycName]
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={from != null ? "/home" : "/profile/account"}>
              <ArrowLeftOutlined className="icon-sm mb-3" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_kamix_profile)}</small>
              {
                loading ?
                  <Skeleton.Button active shape block style={{width:"40%", height:"30px"}} /> :
                  <h5 className="fw-bold">
                    {userAdditionalKyc != null ? getTextToDisplay2(userAdditionalKyc.display_names,"value") : kycName}
                  </h5>
              }
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loading ? (
                    <>
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <div className="mb-4"/>
                      <Skeleton.Button active shape block style={{width:"100%", height:"200px", borderRadius:"8px", marginBottom:"10px"}} />
                    </>
                  ) : (
                    <>

                      {
                        userAdditionalKyc != null &&
                        <>

                          {
                            (
                              userAdditionalKyc.user_description != null &&
                              getTextToDisplay2(userAdditionalKyc.user_description,"value") !== ""
                            ) &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_description)}</div>
                                <div className="text-dark">
                                  {getTextToDisplay2(userAdditionalKyc.user_description,"value")}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={21}>
                              <div className="text-normal small mb-2">{t(_status)}</div>
                              <div className={"status-"+userAdditionalKyc.status.toLowerCase()}>
                                {textWithFirstCapitalLetter(t(userAdditionalKyc.status))}
                              </div>
                            </Col>
                            <Col xs={3} />
                          </Row>

                          {
                            (userAdditionalKyc.status_message != null && userAdditionalKyc.status_message !== "") &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_message)}</div>
                                <div className="text-dark">
                                  {userAdditionalKyc.message}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            userAdditionalKyc.updated_at != null &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_updated_at)}</div>
                                <div className="text-dark">
                                  {getDate(userAdditionalKyc.updated_at).fullDate}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            (
                              userAdditionalKyc.verified_at != null &&
                              (userAdditionalKyc.status === "ACCEPTED" || userAdditionalKyc.status === "VALIDATED")
                            ) &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_verified_at)}</div>
                                <div className="text-dark">
                                  {getDate(userAdditionalKyc.verified_at).fullDate}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            userAdditionalKyc.documents != null && userAdditionalKyc.documents.length > 0 &&
                            <>
                              <hr className="my-4 w-50" />
                              <div className="fw-bold small mb-3">{t(_documents)}</div>
                            </>
                          }

                          <div>

                            {
                              userAdditionalKyc.documents != null && userAdditionalKyc.documents.map((document,index)=>(
                                <div key={index} className="mb-3">

                                  <KycElement
                                    title={document.name}
                                    text={document}
                                    type={"file"}
                                    modification={true}
                                    editable={userAdditionalKyc.editable}
                                    status={userAdditionalKyc.status}
                                    getResponse={getResponse}
                                    index={document.index}
                                    additional={true}
                                    additionalKyc={userAdditionalKyc}
                                    kyc_name={userAdditionalKyc.kyc_name}
                                  />

                                </div>
                              ))
                            }

                            {/* userAdditionalKyc.status !== "ACCEPTED" && userAdditionalKyc.status !== "VALIDATED" && */}
                            {
                              <>
                                <hr className="my-4 w-50" />
                                <div className="text-normal small fw-bold mb-2">{t(_add_a_document)}</div>
                                <div>
                                  <Button className="my-button-2 bg-green-kamix text-white" onClick={onToggleAddAdditionalKyc}>
                                    <PlusOutlined />
                                  </Button>
                                </div>
                              </>
                            }

                          </div>

                        </>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

        <Modal
          visible={toggleAdditionalKyc}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <h6 className="text-center fw-bold mb-4">{t(_add_additional_kyc)}</h6>

              <form onSubmit={(e)=>addDocument(e)}>

                <div className="mb-4">

                  <div className="text-normal mb-1">{t(_select_document_type)}</div>
                  <Row className="mb-3">
                    <Col xs={24}>
                      <Select
                        value={userAdditionalKyc}
                        isDisabled
                      />
                    </Col>
                  </Row>

                  <div className="text-normal mb-1">{t(_document_name)}</div>
                  <Row className="mb-3">
                    <Col xs={24}>
                      <Input
                        type="text"
                        value={documentName}
                        onChange={(e) => setDocumentName(e.target.value)}
                        className="my-input"
                        size="large"
                      />
                    </Col>
                  </Row>

                  <div>
                    <div className="text-normal mb-1">{t(_choose_file)}</div>
                    {
                      file == null ? (
                        <div>
                          <input
                            type="file" name="file1" id="file1" className="inputfile" onChange={onChangeFile}
                            accept="image/png, image/jpeg, application/pdf"
                          />
                          <label htmlFor="file1" className="text-center">
                            <UploadOutlined style={{fontSize:"15px"}} className="chooseAFile" />
                          </label>
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-start">
                              <span className="myFile mr-2" style={{fontSize:"0.9em"}}>{file.name}</span>
                              <span className="text-danger cursorView" onClick={deleteFile}>
                                  <CloseCircleOutlined style={{fontSize:"18px"}} />
                                </span>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>

                </div>

                <div className="d-flex justify-content-center">
                  <Button className="border-radius-kamix text-dark mr-2" onClick={()=>setToggleAdditionalKyc(t=>!t)}>
                    {t(_cancel)}
                  </Button>
                  <Button className="my-button-2 bg-green-kamix text-white" loading={loadingAddItem} htmlType="submit">
                    {t(_add)}
                  </Button>
                </div>

              </form>

            </Col>
          </Row>

        </Modal>

      </Col>
    </Row>
  )

}

export default AdditionalKyc;
