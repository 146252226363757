import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton, Button, Modal} from "antd";
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import axios from "axios";
import RemoteCall, {
  cardExpiryDate, creditCardStatus, displayCreditCardNumber, getCountryFromCountryCode, getDate, notification
} from "../../../../UsefulFunctions";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined, WarningOutlined, DeleteOutlined} from "@ant-design/icons";
import {
  _cancel, _delete, _payment_settings, _my_credit_cards, _credit_card_deleted, _card_expiry_date, _credit_card_deletion_warning,
  _type, _card_issuer_country, _card_issuer, _card_holder_name, _created_at, _card_number, _status
} from "../../../../UsefulConstants";
import "../../../../assets/styles/creditCardPresentation.css";

function CreditCardDetails() {

  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const source = axios.CancelToken.source();

  const [toggle,setToggle] = useState(false);
  const [loadingCreditCard,setLoadingCreditCard] = useState(false);
  const [loadingCreditCardDeletion,setLoadingCreditCardDeletion] = useState(false);
  const [creditCard,setCreditCard] = useState(null);


  async function getCreditCardInfos(){

    setLoadingCreditCard(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/credit-cards/"+id,
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingCreditCard(l => !l);

    if(response != null){
      setCreditCard(response.credit_card);
    }

  }

  async function deleteCreditCard(){

    setLoadingCreditCardDeletion(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/credit-cards/"+id,
      "delete",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingCreditCardDeletion(l => !l);

    if(response != null){
      notification("success",t(_credit_card_deleted));
      history.push('/profile/payment/credit-cards');
    }

  }


  useEffect(
    () => {
      if(id != null) getCreditCardInfos();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={'/profile/payment/credit-cards'}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="d-flex flex-column mb-3 ml-5">
              <small>{t(_payment_settings)} / {t(_my_credit_cards)}</small>
              {
                loadingCreditCard ? (
                  <Skeleton.Button active shape style={{width:"300px", height:"30px"}} />
                ) : (
                  <>
                    {
                      creditCard != null &&
                      <h5 className="fw-bold">{displayCreditCardNumber(creditCard.bin, creditCard.last_four, creditCard.card_scheme)}</h5>
                    }
                  </>
                )
              }
            </div>

            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={18} className="my-3 p-2">

                {
                  loadingCreditCard ? (
                    <>
                      <Skeleton active paragraph={{rows: 5}}/>
                    </>
                  ) : (
                    <>

                      {
                        creditCard != null &&
                        <Row>

                          <Col flex={"auto"}>

                            <div className="mb-3">
                              <div className="small mb-1">{t(_card_number)}</div>
                              <div className="general-badge">
                                {displayCreditCardNumber(creditCard.bin, creditCard.last_four, creditCard.card_scheme)}
                              </div>
                            </div>

                            <div className="mb-3">
                              <div className="small mb-1">{t(_card_expiry_date)}</div>
                              <div className="general-badge">
                                {cardExpiryDate(creditCard.expiry_month, creditCard.expiry_year, creditCard.card_scheme)}
                              </div>
                            </div>

                            <div className="mb-3">
                              <div className="small mb-1">{t(_status)}</div>
                              <div className={"fw-medium small status-" + (creditCardStatus(creditCard) != null ? creditCardStatus(creditCard).toLowerCase() : "") + ""}>
                                {(creditCardStatus(creditCard) != null) ? t(creditCardStatus(creditCard)).toUpperCase() : "--"}
                              </div>
                            </div>

                            <div className="mb-3">
                              <div className="small mb-1">{t(_type)}</div>
                              <div className="general-badge">
                                {creditCard.card_scheme.toUpperCase()}
                              </div>
                            </div>

                            {
                              creditCard.holder_name &&
                              <div className="mb-3">
                                <div className="small mb-1">{t(_card_holder_name)}</div>
                                <div className="fw-bold">{creditCard.holder_name}</div>
                              </div>
                            }

                            {
                              creditCard.issuer &&
                              <div className="mb-3">
                                <div className="small mb-1">{t(_card_issuer)}</div>
                                <div className="fw-bold">{creditCard.issuer}</div>
                              </div>
                            }

                            {
                              creditCard.issuer_country != null &&
                              <div className="mb-3">
                                <div className="small mb-1">{t(_card_issuer_country)}</div>
                                <div className="fw-bold">{getCountryFromCountryCode(creditCard.issuer_country).label3}</div>
                              </div>
                            }

                            <div>
                              <div className="small mb-1">{t(_created_at)}</div>
                              <div className="fw-bold">{creditCard.created_at != null ? getDate(creditCard.created_at).fullDate : "--"}</div>
                            </div>

                          </Col>

                          <div className="position-absolute" style={{right:0}}>
                            <Button
                              className="border-radius-kamix border-danger text-danger d-flex align-items-center"
                              onClick={()=>setToggle(t=>!t)}
                            >
                              <DeleteOutlined style={{fontSize:"15px"}} />
                            </Button>
                          </div>

                        </Row>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

        <Modal
          visible={toggle}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <Row className="justify-content-center">
                <Col xs={3}>
                  <WarningOutlined style={{fontSize:"30px", color:"#BF2626"}} />
                </Col>
                <Col xs={21}>
                  <div className="text-normal mb-3">{t(_credit_card_deletion_warning)}</div>
                  <div className="d-flex">
                    <Button
                      className="border-radius-kamix text-dark mr-2"
                      disabled={loadingCreditCardDeletion}
                      onClick={()=>setToggle(t=>!t)}
                    >
                      {t(_cancel)}
                    </Button>
                    <Button
                      className="border-radius-kamix bg-red-kamix text-white"
                      loading={loadingCreditCardDeletion}
                      onClick={deleteCreditCard}
                    >
                      {t(_delete)}
                    </Button>
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>

        </Modal>

      </Col>
    </Row>
  )

}

export default withRouter(CreditCardDetails);