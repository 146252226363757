import React from 'react';
import {Row, Col} from "antd";
import UserBadge from "../../../others/UserBadge";
import {getReceiverV3Name, getTextToDisplay2} from "../../../../UsefulFunctions";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import "../../../../assets/styles/receiverPresentation.css";
import ReceiversV3Badge from "./ReceiversV3Badge";

function ReceiverV3Presentation({receiver, relationships}) {

    return(
        <Row className="receiverPresentation">
            <ReceiversV3Badge receiver={receiver} />
            <Col flex="auto" className="infos">
                <div className="d-flex flex-column">
                    <div className="fw-bolder text-ellipsis">
                        {getReceiverV3Name(receiver)} <span className="text-dark text-italic">({getTextToDisplay2((relationships || []).find(x => x.name === receiver.relationship)?.display_names || [], "value")})</span>
                    </div>
                    <div className="text-mobile">{formatPhoneNumberIntl(''+receiver.receiver?.receiver_id)}</div>
                </div>
            </Col>
        </Row>
    )

}

export default ReceiverV3Presentation;