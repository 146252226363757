import React, {useEffect, useRef, useState} from 'react';
import {Row, Col, Input, Button, Modal} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import RemoteCall, {notification} from "../../UsefulFunctions";
import {sessionStorage} from "../../Security";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import ReactCodeInput from "react-code-input";
import {
  _confirm, _enter_password, _fees, _next, _no_fees, _enter_verification_code_text, _password, _reason, _receiver,
  _recipient_receive, _required, _resend_code_text, _to, _transfer_details, _verification_code_sent_message,
  _you_send, codeInputProps, _purchase_details, _you_recharge, _you_will_be_charged
} from "../../UsefulConstants";

function DataReview() {

  const { t } = useTranslation();
  const source = axios.CancelToken.source();
  const history = useHistory();
  const codeRef = useRef(undefined);

  let transactionInformation = sessionStorage.get('transactionInformation');
  const currency =
    (transactionInformation != null && transactionInformation.userBalance != null &&
    transactionInformation.userBalance.currency != null) ? transactionInformation.userBalance.currency.short_symbols : ""
  ;
  let transaction_code =
    transactionInformation != null && transactionInformation.transaction != null &&
    transactionInformation.transaction.code
  ;

  let tx_type = transactionInformation != null && transactionInformation.tx_type != null ? transactionInformation.tx_type : "transfer";

  const [toggle,setToggle] = useState(false);
  const [loading,setLoading] = useState(false);
  const [loadingResendCode,setLoadingResetCode] = useState(false);
  const [code,setCode] = useState('');
  const [isCodeValid,setIsCodeValid] = useState(true);
  const [password,setPassword] = useState('');
  const [isPasswordValid,setIsPasswordValid] = useState(true);


  function onChangePassword(e) {
    setPassword(e.target.value);
    setIsPasswordValid(true);
  }

  function onChangeCode(code){
    setCode(code);
    setIsCodeValid(true);
  }

  function clearCodeInput() {
    if(codeRef != null && codeRef.current != null){
      codeRef.current.state.input[0] = "";
      codeRef.current.state.input[1] = "";
      codeRef.current.state.input[2] = "";
      codeRef.current.state.input[3] = "";
      codeRef.current.state.input[4] = "";
      codeRef.current.state.input[5] = "";
    }
  }

  async function resendCode() {

    setLoadingResetCode(l=>!l);

    let response = await RemoteCall(
      true,
      "transactions/do/confirm/"+transaction_code,
      "get",
      false,
      {transaction_code:transaction_code},
      null,
      source.token,
      history
    );

    setLoadingResetCode(l=>!l);
    clearCodeInput();

    if(response != null){
      setCode('');
      notification("success",t(_verification_code_sent_message));
    }

  }

  async function onSubmit(e) {

    e.preventDefault();
    let confirm_via_otp = transactionInformation != null && transactionInformation.confirm_via_otp;

    if((confirm_via_otp && (code === "" || code.length !== 6) ) || (!confirm_via_otp && password === "")){
      setIsPasswordValid(password !== "");
      setIsCodeValid(code !== "" && code.length === 6);
    }else{

      setLoading(l => !l);

      let response = await RemoteCall(
        true,
        "transactions/do/confirm/"+transaction_code,
        "patch",
        false,
        null,
        {code: confirm_via_otp ? code : password},
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        let data={};
        data.tx_type = transactionInformation.tx_type;
        data.receiver = transactionInformation.receiver;
        data.userBalance = transactionInformation.userBalance;
        data.transaction = response.transaction;
        data.allowed_modes = response.allowed_modes;
        await sessionStorage.delete('transactionInformation');
        sessionStorage.set('transactionInformation',data);
        history.push('/transfer/method-selection');
      }

    }

  }


  useEffect(
    () => {
      if(transactionInformation == null || transactionInformation === "") history.push('/home');
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );



  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/transfer/data-entries"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <h5 className="fw-bold mb-0">
                {tx_type === "transfer" ? t(_transfer_details) : t(_purchase_details)}
              </h5>
            </div>

            <Row className="justify-content-center">
              <Col xs={24} md={22} className=" p-2">

                {
                  tx_type === "transfer" &&
                  <Row className="my-box bg-white px-3 py-3 small mb-3">
                    <Col xs={8}>
                      <div className="mb-2">{t(_to)}</div>
                      <div>{t(_receiver)}</div>
                    </Col>
                    <Col flex="auto" className="text-right">
                      <div className="fw-700 mb-2">
                        {
                          transactionInformation != null && transactionInformation.transaction != null &&
                          transactionInformation.transaction.transfer_receiver_name
                        }
                      </div>
                      <div className="fw-700">
                        {
                          formatPhoneNumberIntl(
                            "" + transactionInformation != null && transactionInformation.transaction != null &&
                            transactionInformation.transaction.transfer_receiver_mobile
                          )
                        }
                      </div>
                    </Col>
                  </Row>
                }

                <Row className="my-box bg-white px-3 py-3 small mb-3">
                  <Col xs={24}>
                    <div className="mb-2">{t(_reason)}</div>
                    <div>
                      {
                        transactionInformation != null && transactionInformation.transaction != null &&
                        transactionInformation.transaction.reason
                      }
                    </div>
                  </Col>
                </Row>

                <Row className="my-box bg-white px-3 py-3 small mb-5">
                  <Col xs={8}>
                    <div className="mb-2">
                      {tx_type === "transfer" ? t(_you_send) : t(_you_recharge)}
                    </div>
                    <div className="mb-2">{t(_fees)}</div>
                    <div className="mb-2">{t(_you_will_be_charged)}</div>
                    {
                      tx_type === "transfer" &&
                      <div>{t(_recipient_receive)}</div>
                    }
                  </Col>
                  <Col flex="auto" className="text-right">
                    <div className="fw-700 mb-2">
                      {
                          transactionInformation != null && transactionInformation.transaction != null &&
                          transactionInformation.transaction.amount + " " + currency
                      }
                    </div>
                    <div className="small fw-700">
                      {t(_no_fees)}
                    </div>
                    <div className="fw-700 mt-2">
                      {
                          transactionInformation != null && transactionInformation.transaction != null &&
                          <div>
                            {transactionInformation.transaction.amount + " " + currency}
                            <span className="fw-bold" style={{fontSize: "0.9em"}}> ({transactionInformation.transaction.amount + " EUR"})</span>
                          </div>
                      }
                    </div>
                    {
                        tx_type === "transfer" && transactionInformation.transaction != null && transactionInformation.transaction.amount_xaf != null &&
                        <div className="fw-700 mt-2">
                          {
                              transactionInformation.transaction.amount_xaf + " XAF"
                          }
                        </div>
                    }
                  </Col>
                </Row>

                <div className="d-flex justify-content-center">
                  <Button className="my-button-2 bg-yellow-kamix text-dark half-width"
                          onClick={() => setToggle(t => !t)}>
                    {t(_next)}
                  </Button>
                </div>

              </Col>
            </Row>

          </Col>
        </Row>

        <Modal
          visible={toggle}
          title={null}
          footer={null}
          closable={false}
        >

          {
            transactionInformation != null &&
            <Row className="justify-content-center">
              <Col xs={24} md={20}>

                <div className="text-center mb-3">
                  {
                    transactionInformation.confirm_via_otp != null && transactionInformation.confirm_via_otp ? (
                      <>{t(_enter_verification_code_text)}</>
                    ) : (
                      <>{t(_enter_password)}</>
                    )
                  }
                </div>

                <form onSubmit={(e)=>onSubmit(e)}>

                  {
                    transactionInformation.confirm_via_otp ? (
                      <>
                        <div className="text-center">
                          <ReactCodeInput
                            ref={codeRef}
                            isValid={isCodeValid}
                            type='number'
                            fields={6}
                            value={code}
                            onChange={onChangeCode}
                            autoFocus={false}
                            {...codeInputProps}
                          />
                        </div>
                        <div className="d-flex justify-content-center">
                          <Button type="text" className="mt-3" loading={loadingResendCode} onClick={resendCode}>
                            <span className="text-underline">{t(_resend_code_text)}</span>
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Input.Password
                          type="password"
                          value={password}
                          onChange={onChangePassword}
                          status={isPasswordValid ? "success" : "error"}
                          className="my-input"
                          size="large"
                          placeholder={t(_password)}
                        />
                        {
                          !isPasswordValid &&
                          <div className="position-absolute ant-form-item-explain-error">
                            {t(_required)}
                          </div>
                        }
                      </>
                    )
                  }

                  <div className="d-flex justify-content-center mt-4">
                    <Button className="my-button-2 bg-yellow-kamix text-dark half-width" loading={loading} htmlType="submit">
                      {t(_confirm)}
                    </Button>
                  </div>

                </form>



              </Col>
            </Row>
          }

        </Modal>

      </Col>
    </Row>
  )

}

export default withRouter(DataReview);