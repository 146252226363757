import React, {useState, useEffect} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {Row, Col, Button} from "antd";
import {useTranslation} from "react-i18next";
import axios from 'axios';
import {notification, remoteCallFunction2} from "../UsefulFunctions";
import KamixLogo from "../assets/images/logo_kamix.png";
import {
  _app_name, _something_went_wrong, _newsletter, _unsubscribe_successful,
  _unsubscribed_question, _unsubscribe
} from "../UsefulConstants";
import "../assets/styles/pages.css";

function Verification() {

  const { t } = useTranslation();
  const history = useHistory();
  let { kid } = useParams();

  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [flag,setFlag] = useState(false);

  async function unsubscribedMailList() {

    setLoading(l=>!l);

    let response = await remoteCallFunction2(
      "messaging/mail_list",
      "post",
      null,
      {kid:kid},
      source.token,
      history
    );

    setLoading(l=>!l);

    if(response != null){
      let error = response.error;
      if(error != null && !error){
        setFlag(true);
      }else{
        notification("warning",t(_something_went_wrong));
      }
    }

  }

  useEffect(
    () => {
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
    );

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="margin-bottom-25">
              <Link to={"/sign-in"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
            </div>

            <div className="mb-4">
              <h1 className="text-header mb-2">{t(_newsletter)}</h1>
              <div className="text-normal">{t(flag ? _unsubscribe_successful : _unsubscribed_question)}</div>
            </div>

            {
              !flag &&
              <Button className="my-button bg-yellow-kamix text-dark" loading={loading} onClick={unsubscribedMailList}>
                {t(_unsubscribe)}
              </Button>
            }

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default Verification;
