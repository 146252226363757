import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import React, {useEffect, useState} from "react";
import RemoteCall from "../../../../UsefulFunctions";
import {Button, Col, Row, Skeleton} from "antd";
import {ArrowLeftOutlined, PlusCircleFilled} from "@ant-design/icons";
import {
    _my_receivers,
    _no_receivers_registered,
    _payment_settings,
    _register_receiver
} from "../../../../UsefulConstants";
import Pagination from "../../../others/Pagination";
import ReceiverV3Presentation from "./ReceiverV3Presentation";


function ReceiversV3List () {

    const { t } = useTranslation();
    const { from } = useParams();
    const source = axios.CancelToken.source();
    const history = useHistory();

    const size = 4;
    const [loading,setLoading] = useState(false);
    const [receivers,setReceivers] = useState([]);
    const [relationships,setRelationships] = useState([]);
    const [receiversShowed,setReceiversShowed] = useState([]);

    async function getReceivers(){

        setLoading(l => !l);

        let response = await RemoteCall(
            true,
            "transactions/receiversv3/transfer",
            "get",
            false,
            null,
            null,
            source.token,
            history
        );
        let _relationships = await fetchRelationships();

        setLoading(l => !l);

        if(response != null){
            let receivers = response.transfer_receivers;
            setRelationships(_relationships);
            setReceivers(receivers);
            setReceiversShowed(receivers.slice(0,size));
        }

    }

    async function fetchRelationships () {
        let response = await RemoteCall(
            true,
            "transactions/receiversv3/transfer/relationships",
            "get",
            false,
            null,
            null,
            source.token,
            history
        )
        if (response != null && response.relationships) {
            return response.relationships;
        }
        return [];

    }

    useEffect(
        () => {
            getReceivers();
            return () =>  source.cancel();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return(
        <Row className="justify-content-center mb-2">
            <Col xs={24} md={20} xl={16}>

                <Row className="justify-content-center">
                    <Col xs={24} lg={22} xl={20}>

                        <Link to={from != null ? (from === "t" ? "/transfer/select-receiver" : "/home") : "/profile/payment"}>
                            <ArrowLeftOutlined className="icon-sm" />
                        </Link>

                        <div className="mb-3 ml-5">
                            <small>{t(_payment_settings)}</small>
                            <h5 className="fw-bold">{t(_my_receivers)}</h5>
                        </div>

                        <Row className="justify-content-center bg-white my-box">
                            <Col xs={22} md={20} className="my-3 p-2">

                                {
                                    loading ? (
                                        <Row className="justify-content-center">
                                            <Col xs={24} md={22}>
                                                <div className="d-flex justify-content-end mb-4">
                                                    <Skeleton.Button active shape style={{width:"50px"}} />
                                                </div>
                                                <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                                                <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                                                <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                                                <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                                                <div className="d-flex justify-content-end mt-4">
                                                    <Skeleton.Button active shape style={{width:"50px"}} />
                                                </div>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <>

                                            {
                                                receivers.length === 0 ? (
                                                    <Row className="justify-content-center my-4">
                                                        <Col xs={24} md={20}>
                                                            <h6 className="text-normal text-center mb-3">
                                                                {t(_no_receivers_registered)}
                                                            </h6>
                                                            <div className="text-center">
                                                                <Link to={'/profile/payment/receivers/create/r'}>
                                                                    <Button className="my-button bg-yellow-kamix text-dark fw-normal">{t(_register_receiver)}</Button>
                                                                </Link>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <div>

                                                        <div className="mb-4">
                                                            <Pagination table={receivers} size={size} toShow={setReceiversShowed} />
                                                        </div>

                                                        <Row className="justify-content-center">
                                                            <Col xs={24} md={22}>
                                                                {
                                                                    receiversShowed.map(item => (
                                                                        <div key={item.receiver.kid}>
                                                                            <Link to={`/profile/payment/receivers/${item.receiver.kid}/manage`}>
                                                                                <ReceiverV3Presentation receiver={item} relationships={relationships} />
                                                                            </Link>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </Col>
                                                        </Row>

                                                        <div className="d-flex justify-content-end mt-4">
                                                            <Link to={'/profile/payment/receivers/create/r'}>
                                                                <div className="createReceiverButton">
                                                                    <PlusCircleFilled style={{fontSize:"20px"}} />
                                                                </div>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                )
                                            }

                                        </>
                                    )
                                }

                            </Col>
                        </Row>

                    </Col>
                </Row>


            </Col>
        </Row>
    )

}

export default withRouter(ReceiversV3List);