import React, {useState, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {Row, Col, Input, Button} from "antd";
import {useTranslation} from "react-i18next";
import axios from 'axios';
import RemoteCall, {getCountries, getBrowserFingerPrint} from "../../UsefulFunctions";
import {localStorage, sessionStorage} from "../../Security";
import EmailValidator from "email-validator";
import {getCountryCallingCode, isValidPhoneNumber} from "react-phone-number-input";
import Select from 'react-select';
import KamixLogo from "../../assets/images/logo_kamix.png";
import LanguageSelector from "../../components/others/LanguageSelector";
import {
  _required, _invalid_phone_number, defaultCountry, _mobile_number, _log_in, _enter_your_mobile,
  _forget_password_question, _log_in_with_email, _invalid_email, _log_in_with_mobile_number,
  _email, _enter_your_email, _dont_have_profile_yet_question, _register, _app_name
} from "../../UsefulConstants";
import "../../assets/styles/pages.css";
import "../../assets/styles/constants.css";

function Login() {

  const { t } = useTranslation();
  const history = useHistory();

  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [loadingRegistrationLink,setLoadingRegistrationLink] = useState(false);

  const [method,setMethod] = useState("mobile");
  const [phoneNumber,setPhoneNumber] = useState('');
  const [email,setEmail] = useState('');
  const [country, setCountry] = useState(defaultCountry);
  const [isPhoneInputValid,setIsPhoneInputValid] = useState(true);
  const [isEmailValid,setIsEmailValid] = useState(true);
  const [registrationLink,setRegistrationLink] = useState("");


  function onChangePhoneNumber(e){
    setPhoneNumber(e.target.value);
    setIsPhoneInputValid(true);
  }

  function onChangeEmail(e){
    setEmail(e.target.value);
    setIsEmailValid(true);
  }

  function onChangeCountry(e) {
    setCountry(e)
  }

  async function onSubmit(e) {

    e.preventDefault();
    let mobile = '+'+getCountryCallingCode(country.code)+phoneNumber;

    if(
      ( method === "email" && (email === "" || !EmailValidator.validate(email)) ) ||
      ( method === "mobile" && (phoneNumber === "" || !isValidPhoneNumber(mobile)) )
    ){
      setIsPhoneInputValid(phoneNumber !== "" && isValidPhoneNumber(mobile));
      setIsEmailValid(email !== "" && EmailValidator.validate(email));
    }else{

      let formData = {};
      formData['login'] = method === "mobile" ? mobile : email;

      setLoading(l => !l);

      let response = await RemoteCall(
        false,
        "users/login",
        "post",
        false,
        null,
        formData,
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){

        let data={};
        data.user = response.user;
        data.request_password  = response.request_password ;
        data.login_id_verification_id  = response.login_id_verification_id ;
        sessionStorage.set('loginInformation',data);
        history.push("/sign-in/step-2");

        // Defining default country to use if exist on Select countries in App
        localStorage.set('defaultCountryCode', country.code);
        // end

      }

    }

  }

  async function getRegistrationLink() {

    if(registrationLink === ""){

      setLoadingRegistrationLink(l=>!l);

      let response = await RemoteCall(
        true,
        "users/onboarding",
        "post",
        false,
        {device_unique_name:getBrowserFingerPrint()},
        null,
        source.token,
        history
      );

      setLoadingRegistrationLink(l=>!l);

      if(response != null){
        setRegistrationLink(response.onboarding_url);
        const win = window.open(response.onboarding_url, "_blank");
        win.focus();
      }

    }else{
      const win = window.open(registrationLink, "_blank");
      win.focus();
    }

  }

  useEffect(
    () => {
      sessionStorage.clear();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
    );

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="d-flex justify-content-between align-items-center margin-bottom-25">
              <Link to={"#"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
              <LanguageSelector location={"out"} />
            </div>

            <h1 className="text-header">{t(_log_in)}</h1>
            <div className="text-normal text-justify mb-2">
              <div className="d-flex align-items-center flex-wrap mb-4">
                <div className="text-dark mr-2">{t(_dont_have_profile_yet_question)}</div>
                <Button type="text" loading={loadingRegistrationLink} onClick={getRegistrationLink} className="d-flex align-items-center">
                  <span className="text-underline my-link">{t(_register)}</span>
                </Button>
              </div>
              <h6>
                {t( (method === "mobile" ? _enter_your_mobile : _enter_your_email))}
              </h6>
            </div>

            <form onSubmit={(e)=>onSubmit(e)}>
              {
                method === "mobile" ? (
                  <div className="d-flex mb-4">
                    <Select
                      className="my-select mr-3"
                      classNamePrefix="my-select"
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                      value={country}
                      onChange={onChangeCountry}
                      options={getCountries()}
                      isSearchable
                      autosize={false}
                      getOptionLabel ={(option)=>option.name}
                      formatOptionLabel={(option, { context }) => {
                        return context === 'menu' ? option.label : option.label2;
                      }}
                    />
                    <div className="full-width">
                      <Input
                        className="my-input"
                        type="number"
                        value={phoneNumber}
                        onChange={onChangePhoneNumber}
                        status={isPhoneInputValid ? "success" : "error"}
                        size="large"
                        placeholder={t(_mobile_number)}
                      />
                      {
                        !isPhoneInputValid && method === "mobile" &&
                        <div className="position-absolute ant-form-item-explain-error">
                          {phoneNumber === "" && t(_required)}
                          {phoneNumber !== "" && !isValidPhoneNumber(phoneNumber) && t(_invalid_phone_number)}
                        </div>
                      }
                    </div>
                  </div>
                ) : (
                  <div className="mb-4">
                    <Input
                      className="my-input"
                      type="text"
                      value={email}
                      onChange={onChangeEmail}
                      status={isEmailValid ? "success" : "error"}
                      size="large"
                      placeholder={t(_email)}
                    />
                    {
                      !isEmailValid && method === "email" &&
                      <div className="position-absolute ant-form-item-explain-error">
                        {email === "" && t(_required)}
                        {email !== "" && !EmailValidator.validate(email) && t(_invalid_email)}
                      </div>
                    }
                  </div>
                )
              }

              <div className="d-flex flex-column mb-4">
                <small className="d-inline-block text-underline cursorView mb-3" onClick={() => setMethod(m => m === "mobile" ? "email" : "mobile")}>
                  {t(method === "mobile" ? _log_in_with_email : _log_in_with_mobile_number)}
                </small>
                <div>
                  <Link to={'/forget-password'}>
                    <small className="cursorView mb-3 text-underline">
                      {t(_forget_password_question)}
                    </small>
                  </Link>
                </div>
              </div>

              <Button className="my-button full-width bg-yellow-kamix text-dark mt-2" loading={loading} htmlType="submit">
                {t(_log_in)}
              </Button>
            </form>

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default Login;
