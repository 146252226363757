import React, {useState, useEffect} from 'react';
import {Row, Col, Skeleton} from "antd";
import {Link, useHistory, withRouter, useParams} from "react-router-dom";
import axios from "axios";
import RemoteCall, {getDate, textWithFirstCapitalLetter} from "../../../../UsefulFunctions";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import KycElement from "./KycElement";
import {
  _accommodation_type, _birth_date, _birth_place, _first_name, _kamix_profile, _last_name, _link_to_usa, _living_address,
  _living_city, _living_country, _message, _my_identity, _nationality, _status, _updated_at, _verified_at, _zip_code
} from "../../../../UsefulConstants";
import "../../../../assets/styles/myAccount.css";

function UserIdentity() {

  const { t } = useTranslation();
  const { from } = useParams();
  const history = useHistory();

  const source = axios.CancelToken.source();

  const [loadingUserIdentity,setLoadingUserIdentity] = useState(false);
  const [userIdentity,setUserIdentity] = useState(null);
  const [fieldsValues,setFieldsValues] = useState(null);

  function updateUserIdentity(response){
    setUserIdentity(response.user_identity);
  }

  async function getUserIdentity(){

    setLoadingUserIdentity(l => !l);

    let response = await RemoteCall(
      true,
      "kyc/mandatory/v3/identity",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserIdentity(l => !l);

    if(response != null){
      setUserIdentity(response.user_identity);
      setFieldsValues(response.fields_values)
    }

  }

  useEffect(
    () => {
      getUserIdentity();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={from != null ? "/home" : "/profile/account"}>
              <ArrowLeftOutlined className="icon-sm mb-3" />
            </Link>


            <div className="mb-3 ml-5">
              <small>{t(_kamix_profile)}</small>
              <h5 className="fw-bold">{t(_my_identity)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loadingUserIdentity ? (
                    <>
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"20px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"50px", borderRadius:"8px", marginBottom:"10px"}} />
                    </>
                  ) : (
                    <>

                      {
                        userIdentity != null &&
                        <>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={21}>
                              <div className="text-normal small mb-2">{t(_status)}</div>
                              <div className={"status-"+userIdentity.status.toLowerCase()}>
                                {textWithFirstCapitalLetter(t(userIdentity.status))}
                              </div>
                            </Col>
                            <Col xs={3} />
                          </Row>

                          {
                            (userIdentity.status_message != null && userIdentity.status_message !== "") &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_message)}</div>
                                <div className="text-dark">
                                  {userIdentity.message}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            userIdentity.updated_at != null &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_updated_at)}</div>
                                <div className="text-dark">
                                  {getDate(userIdentity.updated_at).fullDate}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            (userIdentity.verified_at != null && (userIdentity.status === "ACCEPTED" || userIdentity.status === "VALIDATED")) &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_verified_at)}</div>
                                <div className="text-dark">
                                  {getDate(userIdentity.verified_at).fullDate}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          <div className="mb-4"/>


                          <KycElement
                            title={t(_first_name)}
                            text={userIdentity.firstname}
                            type={"text"}
                            modification={true}
                            field={'firstname'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_last_name)}
                            text={userIdentity.lastname}
                            type={"text"}
                            modification={true}
                            field={'lastname'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_living_country)}
                            text={userIdentity.living_country}
                            type={"country"}
                            modification={true}
                            field={'living_country'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_living_city)}
                            text={userIdentity.living_city}
                            type={"text"}
                            modification={true}
                            field={'living_city'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_living_address)}
                            text={userIdentity.living_address}
                            type={"text"}
                            modification={true}
                            field={'living_address'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_zip_code)}
                            text={userIdentity.zip_code}
                            type={"text"}
                            modification={true}
                            field={'zip_code'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_birth_date)}
                            text={userIdentity.birthdate}
                            type={"date"}
                            modification={true}
                            field={'birthdate'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_birth_place)}
                            text={userIdentity.birthplace}
                            type={"text"}
                            modification={true}
                            field={'birthplace'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_nationality)}
                            text={userIdentity.nationality}
                            type={"country"}
                            modification={true}
                            field={'nationality'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_link_to_usa)}
                            text={userIdentity.linked_to_usa}
                            type={"boolean"}
                            modification={true}
                            field={'linked_to_usa'}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                          <KycElement
                            title={t(_accommodation_type)}
                            text={userIdentity.accommodation_type}
                            type={"select"}
                            modification={true}
                            field={'accommodation_type'}
                            fieldsValues={fieldsValues}
                            status={userIdentity.status}
                            getResponse={updateUserIdentity}
                            url={"identity"}
                            editable={userIdentity.editable}
                          />

                        </>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(UserIdentity);