import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton, Input} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import RemoteCall from "../../UsefulFunctions";
import {sessionStorage} from "../../Security";
import {ArrowLeftOutlined, PlusCircleFilled, SearchOutlined} from "@ant-design/icons";
import {
  _my_receivers, _new_receiver, _quick_actions, _saved_receivers, _no_receivers_registered_yet,
  _search, _send_money_to_new_beneficiary, _start_a_transfer
} from "../../UsefulConstants";
import "../../assets/styles/receiverPresentation.css";
import ReceiverV3Presentation from "../profile/payment/receiversv3/ReceiverV3Presentation";

function SelectReceiver() {

  const { t } = useTranslation();
  const source = axios.CancelToken.source();
  const history = useHistory();

  const [loading,setLoading] = useState(false);
  const [receivers,setReceivers] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [originalReceivers,setOriginalReceivers] = useState([]);
  const [searchValue,setSearchValue] = useState([]);

  function onChangeSearchValue(e){
    let value = e.target.value;
    setSearchValue(e.target.value);

    if(value === ""){
      setReceivers(originalReceivers);
    }else{
      let newReceivers = originalReceivers.filter(
        receiver =>
          (receiver.receiver?.firstname != null && receiver.receiver.firstname.toLowerCase().includes(value)) ||
          (receiver.receiver?.lastname != null && receiver.receiver.lastname.toLowerCase().includes(value))
      );
      setReceivers(newReceivers);
    }
  }

  async function getReceivers(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/receiversv3/transfer",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );
    let _relationships =await fetchRelationships();

    setLoading(l => !l);

    if(response != null){
      let receivers = response.transfer_receivers;
      //receivers.sort((a, b) => a.firstname.localeCompare(b.firstname));
      setRelationships(_relationships);
      setReceivers(receivers);
      setOriginalReceivers(receivers);
    }

  }

  async function fetchRelationships () {
    let response = await RemoteCall(
        true,
        "transactions/receiversv3/transfer/relationships",
        "get",
        false,
        null,
        null,
        source.token,
        history
    )
    if (response != null && response.relationships) {
      return response.relationships;
    }
    return [];

  }

  function selectReceiver(receiver){
    let data={};
    data.tx_type = "transfer";
    data.receiver = receiver;
    sessionStorage.set('transactionInformation',data);
    history.push('/transfer/data-entries');
  }


  useEffect(
    () => {
      getReceivers();
      sessionStorage.delete('transactionInformation');
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/home"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_start_a_transfer)}</small>
              <h5 className="fw-bold">{t(_my_receivers)}</h5>
            </div>

            <Row className="justify-content-center">
              <Col xs={24} md={22} className=" p-2">

                <div className="mb-4">
                  <Input
                    className="my-input-white"
                    type="text"
                    value={searchValue}
                    onChange={onChangeSearchValue}
                    size="large"
                    placeholder={t(_search)}
                    prefix={<SearchOutlined style={{margin:"0 8px"}} />}
                    allowClear
                  />
                </div>

                <small>{t(_quick_actions)}</small>
                <Row className="justify-content-center my-box bg-white mt-1 mb-4">
                  <Col xs={24} md={22}>

                    <Link to={'/profile/payment/receivers/create/t'}>
                      <Row className="receiverPresentation bg-white">
                        <div className="createReceiverButton">
                          <PlusCircleFilled style={{fontSize:"20px"}} />
                        </div>
                        <Col flex="auto" className="infos">
                          <div className="d-flex flex-column">
                            <div className="fw-bolder text-ellipsis">{t(_new_receiver)}</div>
                            <div className="text-mobile">{t(_send_money_to_new_beneficiary)}</div>
                          </div>
                        </Col>
                      </Row>
                    </Link>

                  </Col>
                </Row>

                <small>{t(_saved_receivers)}</small>
                <Row className="justify-content-center my-box bg-white mt-1 mb-4">
                  <Col xs={24} md={22} className="my-2 p-2">

                    {
                      loading ? (
                        <div>
                          <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                          <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                          <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                          <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                        </div>
                      ) : (
                        <>

                          {
                            receivers.length === 0 ? (
                              <h6 className="text-normal text-center small my-3">
                                {t(_no_receivers_registered_yet)}
                              </h6>
                            ) : (
                              <>
                                {
                                  receivers.map(item => (
                                    <div key={item.receiver_id} className="child" onClick={(e)=>selectReceiver(item,e)}>
                                      <ReceiverV3Presentation receiver={item} relationships={relationships} />
                                    </div>
                                  ))
                                }
                              </>
                            )
                          }

                        </>
                      )
                    }

                  </Col>
                </Row>

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(SelectReceiver);