import React, {useEffect, useState} from 'react';
import {Row, Col, Button, Skeleton} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined,} from "@ant-design/icons";
import RemoteCall, {getLanguage, getTextToDisplay2, textWithFirstCapitalLetter} from "../../UsefulFunctions";
import {
  _documentation, _documentation_review_text, _home, _missing_information_text, _understood, _verify,
  _wallet_missing_information_email_title, _wallet_missing_information_infos_title, _wallet_missing_information_mobile_title,
  _wallet_missing_information_quota_title, _wallet_missing_information_incomes_title, _wallet_missing_information_privacy_title,
  _add, _accept, _additional_document, _profile_status, _reason
} from "../../UsefulConstants";
import "../../assets/styles/home.css";

function ControlDocs() {

  const { t } = useTranslation();
  const history = useHistory();
  const source = axios.CancelToken.source();
  const lang = getLanguage();

  const [loadingUserAccountStatus,setLoadingUserAccountStatus] = useState(false);
  const [userAccountStatus,setUserAccountStatus] = useState(null);

  async function getUserAccountStatus(){

    setLoadingUserAccountStatus(l => !l);

    let response = await RemoteCall(
      true,
      "kyc/profile/v3",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserAccountStatus(l => !l);

    if(response != null){
      setUserAccountStatus(response.user_account_status);
    }

  }

  useEffect(
    () => {
      getUserAccountStatus();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/home"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_home)}</small>
              <h5 className="fw-bold">{t(_documentation)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box-2">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loadingUserAccountStatus ? (
                    <div>
                      <div className="missing-information mt-0">
                        <Skeleton.Button active shape block style={{borderRadius:"8px", width:"50%"}} />
                      </div>
                      <div className="documents-missing-box-layout mb-0">
                        <Skeleton.Button
                          active shape block
                          style={{height:"200px", padding:"16px", borderRadius: "8px"}}
                        />
                      </div>
                    </div>
                  ) : (
                    <>

                      {
                        userAccountStatus != null &&
                        <>

                          {
                            (userAccountStatus.profile_status === "ACCEPTED" && userAccountStatus.account_status === "BLOCKED") ? (
                              <div>

                                <div className="text-normal small mb-2">{t(_profile_status)}</div>
                                <div className={"fw-bold mb-3 status-"+userAccountStatus.account_status.toLowerCase()}>
                                  {textWithFirstCapitalLetter(t(userAccountStatus.account_status))}
                                </div>

                                <div className="text-normal small mb-2">{t(_reason)}</div>
                                <div className="fw-bold text-italic">
                                  {userAccountStatus.account_status_message}
                                </div>

                              </div>
                            ) : (
                              <>

                                {
                                  userAccountStatus.profile_status === "FILLED" ? (
                                    <div>
                                      <h5 className="missing-information mt-0">{t(_documentation_review_text)}</h5>
                                      <div className="d-flex mt-3">
                                        <Link to={"/home"}>
                                          <Button className="my-button bg-yellow-kamix text-dark fw-normal">{t(_understood)}</Button>
                                        </Link>
                                      </div>
                                    </div>
                                  ) : (
                                    <>

                                      <h5 className="missing-information mt-0 mb-3">{t(_missing_information_text)}</h5>
                                      <div className="documents-missing-box-layout mb-0">

                                        {
                                          userAccountStatus.profile_status_alerts != null && userAccountStatus.profile_status_alerts.map((alert,index)=>(
                                            <React.Fragment key={alert._id != null ? alert._id : "alert-"+index}>

                                              {
                                                alert.kyc_name === "cguv_accept" &&
                                                <div className={"my-box-2 documents-missing-box "+lang}>
                                                  <div className={"sub-box-"+lang}>
                                                    <div className="title">{t(_wallet_missing_information_privacy_title)}</div>
                                                    <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                                  </div>
                                                  <Link to={"/home/accept-cguv"}>
                                                    <Button block className="my-button-2 fw-normal">
                                                      {t(_accept)}
                                                    </Button>
                                                  </Link>
                                                </div>
                                              }

                                              {
                                                alert.kyc_name === "email" &&
                                                <div className={"my-box-2 documents-missing-box "+lang}>
                                                  <div className={"sub-box-"+lang}>
                                                    <div className="title">{t(_wallet_missing_information_email_title)}</div>
                                                    <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                                  </div>
                                                  <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                                    <Link to={'/profile/account/email/manage/h'}>
                                                      <Button block className="my-button-2 fw-normal">
                                                        {t(_verify)}
                                                      </Button>
                                                    </Link>
                                                  </div>
                                                </div>
                                              }

                                              {
                                                alert.kyc_name === "mobiles" &&
                                                <div className={"my-box-2 documents-missing-box "+lang}>
                                                  <div className={"sub-box-"+lang}>
                                                    <div className="title">{t(_wallet_missing_information_mobile_title)}</div>
                                                    <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                                  </div>
                                                  <Link to={'/profile/account/h'}>
                                                    <Button block className="my-button-2 fw-normal">
                                                      {t(_verify)}
                                                    </Button>
                                                  </Link>
                                                </div>
                                              }

                                              {
                                                alert.kyc_name === "quota" &&
                                                <div className={"my-box-2 documents-missing-box "+lang}>
                                                  <div className={"sub-box-"+lang}>
                                                    <div className="title">{t(_wallet_missing_information_quota_title)}</div>
                                                    <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                                  </div>
                                                  <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                                    <Link to={'/profile/account/kyc/quota/h'}>
                                                      <Button block className="my-button-2 fw-normal">
                                                        {t(_verify)}
                                                      </Button>
                                                    </Link>
                                                  </div>
                                                </div>
                                              }

                                              {
                                                alert.kyc_name === "identity" &&
                                                <div className={"my-box-2 documents-missing-box "+lang}>
                                                  <div className={"sub-box-"+lang}>
                                                    <div className="title">{t(_wallet_missing_information_infos_title)}</div>
                                                    <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                                  </div>
                                                  <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                                    <Link to={'/profile/account/kyc/identity/h'}>
                                                      <Button block className="my-button-2 fw-normal">
                                                        {t(_verify)}
                                                      </Button>
                                                    </Link>
                                                  </div>
                                                </div>
                                              }

                                              {
                                                alert.kyc_name === "incomes_info" &&
                                                <div className={"my-box-2 documents-missing-box "+lang}>
                                                  <div className={"sub-box-"+lang}>
                                                    <div className="title">{t(_wallet_missing_information_incomes_title)}</div>
                                                    <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                                  </div>
                                                  <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                                    <Link to={'/profile/account/kyc/incomes/h'}>
                                                      <Button block className="my-button-2 fw-normal">
                                                        {t(_verify)}
                                                      </Button>
                                                    </Link>
                                                  </div>
                                                </div>
                                              }

                                              {
                                                (
                                                  alert.kyc_name !== "cguv_accept" && alert.kyc_name !== "email" && alert.kyc_name !== "mobiles" &&
                                                  alert.kyc_name !== "quota" && alert.kyc_name !== "identity" && alert.kyc_name !== "proof_of_identity" &&
                                                  alert.kyc_name !== "incomes_info"
                                                ) &&
                                                <div className={"my-box-2 documents-missing-box "+lang}>
                                                  <div className={"sub-box-"+lang}>
                                                    <div className="title">{t(_additional_document)}</div>
                                                    <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                                  </div>
                                                  <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                                    {
                                                      alert.kyc_name != null ? (
                                                        <Link to={`/profile/account/add-kyc/${alert.kyc_name}/h`}>
                                                          <Button block className="my-button-2 fw-normal">
                                                            {t(_add)}
                                                          </Button>
                                                        </Link>
                                                      ) : (
                                                        <a href={userAccountStatus.onboarding_url} target="_blank" rel="noopener noreferrer">
                                                          <Button block className="my-button-2 fw-normal">
                                                            {t(_add)}
                                                          </Button>
                                                        </a>
                                                      )
                                                    }
                                                  </div>
                                                </div>
                                              }

                                            </React.Fragment>
                                          ))
                                        }

                                      </div>

                                    </>
                                  )
                                }

                              </>
                            )
                          }

                        </>
                      }
                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(ControlDocs);