import React from 'react';
import {getBadgeInfos} from "../../UsefulFunctions";

function UserBadge({user}) {
  let badgeInfos = getBadgeInfos(user);
  return(
    <div className="abbreviation" style={{backgroundColor:badgeInfos[1].hex}}>
      {badgeInfos[0]}
    </div>
  )
}

export default UserBadge;