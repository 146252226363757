import React from 'react';
import {getReceiverV3Name, hashCode} from "../../../../UsefulFunctions";


export function getReceiverV3BadgeInfo(receiver){

    let _colors = [
        {color:"red", hex: "#F44336"},
        {color:"pink", hex: "#E91E63"},
        {color:"deepPurple", hex: "#673AB7"},
        {color:"indigo", hex: "#3F51B5"},
        {color:"blue", hex: "#2196F3"},
        {color:"teal", hex: "#009688"},
        {color:"green", hex: "#4CAF50"},
        {color:"amber", hex: "#FFC107"},
        {color:"orange", hex: "#FF9800"},
        {color:"brown", hex: "#795548"},
    ];

    let source = typeof(receiver) === "object" ?  getReceiverV3Name(receiver) : receiver;
    let parts = source.split(" ");
    let initiales = "";

    if (parts.length === 1) {
        initiales = parts[0].substring(0, 2);
    } else {
        initiales = parts[0].substring(0, 1) + parts[parts.length-1].substring(0, 1);
    }

    let index = hashCode(initiales) % _colors.length;

    return [initiales,_colors[index]];
}

function ReceiversV3Badge({receiver}) {
    let badgeInfos = getReceiverV3BadgeInfo(receiver);
    return(
        <div className="abbreviation" style={{backgroundColor:badgeInfos[1].hex}}>
            {badgeInfos[0]}
        </div>
    )
}

export default ReceiversV3Badge;