import React from 'react';
import {Row, Col} from "antd";
import {useTranslation} from "react-i18next";
import {getDate, getSplitMonth, isToday} from "../../UsefulFunctions";
import {VideoCameraFilled, CameraFilled} from "@ant-design/icons";
import {_admin, _photo, _solved, _video, _you} from "../../UsefulConstants";
import "../../assets/styles/ticketPresentation.css";

function TicketPresentation({ticket}) {

  const { t } = useTranslation();

  let lastMessage = ticket.last_message;
  let message = lastMessage != null && getMessage(lastMessage.text);

  function getMessage(message){
    let result = "";
    let  temp = document.createElement("div");
    temp.innerHTML = message;
    return result + temp.textContent || temp.innerText || "";
  }

  return(
    <Row className="ticketPresentation">
      <Col xs={17} className="d-flex flex-column text-left">
        <div className="fw-bold text-green-kamix text-ellipsis">{ticket.title}</div>
        <div className="fw-bold small">{ticket.category}</div>
        <div className="lastMessagePresentation text-ellipsis">
          {
            lastMessage != null &&
            <span className="fw-bold mr-2">{lastMessage.sender === "USER" ?  t(_you) : t(_admin)} {" :"}</span>
          }
          {
            lastMessage != null && lastMessage.type === "TEXT" &&
            <div className="text-ellipsis">{message}</div>
          }
          {
            lastMessage != null && lastMessage.type === "PHOTO" &&
            <div className="d-flex align-items-center">
              <CameraFilled style={{fontSize:"15px", marginRight:"5px"}} />
              {t(_photo)}
            </div>
          }
          {
            lastMessage != null && lastMessage.type === "VIDEO" &&
            <div className="d-flex align-items-center">
              <VideoCameraFilled style={{fontSize:"15px", marginRight:"5px"}} />
              {t(_video)}
            </div>
          }
          {
            lastMessage == null &&
            <div className="no-message">-</div>
          }
        </div>
        <div className="ticketPresentation-date-2 d-block d-md-none">
          {
            lastMessage != null ? (
              <>
                {
                  isToday(lastMessage.created_at) ? (
                    <div>{getDate(lastMessage.created_at).hour}</div>
                  ) : (
                    <div>
                      {
                        getDate(lastMessage.created_at).day +" "+
                        getSplitMonth(t("months_"+getDate(lastMessage.created_at).month)+"") +" "+
                        getDate(lastMessage.created_at).year +", "+
                        getDate(lastMessage.created_at).hour
                      }
                    </div>
                  )
                }
              </>
            ) : (
              <>
                {
                  isToday(ticket.created_at) ? (
                    <div>{getDate(ticket.created_at).hour}</div>
                  ) : (
                    <div>
                      {
                        getDate(ticket.created_at).day +" "+
                        getSplitMonth(t("months_"+getDate(ticket.created_at).month)+"") +" "+
                        getDate(ticket.created_at).year +", "+
                        getDate(ticket.created_at).hour
                      }
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      </Col>

      {/* <Col xs={1}/> */}

      <Col xs={7} className="d-flex flex-column justify-content-start align-items-end">
        <div className="d-flex align-items-center" style={{marginBottom:"20px"}}>
          <div className="invisible mb-1">#</div>
          {
            ticket.unread_messages > 0 &&
            <span className="unreadMessages">
              {ticket.unread_messages}
            </span>
          }
          {
            ticket.solved &&
            <span className="solvedMessage ml-2">{t(_solved)}</span>
          }
        </div>
        <div className="ticketPresentation-date d-none d-md-block">
          {
            lastMessage != null ? (
              <>
                {
                  isToday(lastMessage.created_at) ? (
                    <div>{getDate(lastMessage.created_at).hour}</div>
                  ) : (
                    <div>
                      {
                        getDate(lastMessage.created_at).day +" "+
                        getSplitMonth(t("months_"+getDate(lastMessage.created_at).month)+"") +" "+
                        getDate(lastMessage.created_at).year +", "+
                        getDate(lastMessage.created_at).hour
                      }
                    </div>
                  )
                }
              </>
            ) : (
              <>
                {
                  isToday(ticket.created_at) ? (
                    <div>{getDate(ticket.created_at).hour}</div>
                  ) : (
                    <div>
                      {
                        getDate(ticket.created_at).day +" "+
                        getSplitMonth(t("months_"+getDate(ticket.created_at).month)+"") +" "+
                        getDate(ticket.created_at).year +", "+
                        getDate(ticket.created_at).hour
                      }
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      </Col>

    </Row>
  );

}

export default TicketPresentation;