import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {_my_devices} from "../../../UsefulConstants";
import {ArrowLeftOutlined,} from "@ant-design/icons";
import RemoteCall from "../../../UsefulFunctions";
import Pagination from "../../others/Pagination";
import DevicePresentation from "./DevicePresentation";

function Devices() {

  const { t } = useTranslation();
  const source = axios.CancelToken.source();
  const history = useHistory();

  const size = 3;
  const [loading,setLoading] = useState(false);
  const [devices,setDevices] = useState([]);
  const [devicesShowed,setDevicesShowed] = useState([]);

  async function getDevices(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "users/devices",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      let devices = response.devices;
      setDevices(devices);
      setDevicesShowed(devices.slice(0,size));
    }

  }


  useEffect(
    () => {
      getDevices();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <h5 className="fw-bold">{t(_my_devices)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loading ? (
                    <div className="d-flex flex-column justify-content-center">
                      <div className="d-flex justify-content-end mb-4">
                        <Skeleton.Button active shape style={{width:"80px"}} />
                      </div>
                      <Skeleton.Button active shape block style={{width:"100%", height:"110px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"110px", borderRadius:"8px", marginBottom:"10px"}} />
                      <Skeleton.Button active shape block style={{width:"100%", height:"110px", borderRadius:"8px", marginBottom:"10px"}} />
                    </div>
                  ) : (
                    <>

                      <div className="mb-4">
                        <Pagination table={devices} size={size} toShow={setDevicesShowed} />
                      </div>

                      {
                        devicesShowed.map(item => (
                          <div key={item._id} className="mb-2">
                            <Link to={`/profile/devices/${item.did}`}>
                              <DevicePresentation device={item} />
                            </Link>
                          </div>
                        ))
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(Devices);