import React from "react";
import {Link} from "react-router-dom";
import {Row, Col, Button} from "antd";
import {useTranslation} from "react-i18next";
import {_app_name, _go_to_home_page, _oops_you_are_lost, _page_not_found_info} from "../UsefulConstants";
import KamixLogo from "../assets/images/logo_kamix.png";
import "../assets/styles/pages.css";

function Page404() {

  const { t } = useTranslation();

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="margin-bottom-25">
              <Link to={"#"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
            </div>

            <h1 className="text-header">{t(_oops_you_are_lost)}</h1>
            <div className="text-normal text-justify mb-4">{t(_page_not_found_info)}</div>

            <Link to={'/'}>
              <Button className="my-button bg-dark text-white fw-normal mt-2">
                {t(_go_to_home_page)}
              </Button>
            </Link>

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default Page404;
