import React, { useState } from 'react';
import {useHistory, withRouter} from "react-router-dom";
import {Row, Col, Button, Modal, Input, DatePicker} from "antd";
import {useTranslation} from "react-i18next";
import RemoteCall, {
  notification, getCountryFromCountryCode, getAmountToDisplay, getTextToDisplay2, getAllCountries, getLanguage
} from "../../../../UsefulFunctions";
import axios from "axios";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css';
import Select from 'react-select';
import moment from "moment";
import FormData from 'form-data';
import DefaultImage from "../../../../assets/images/image_icon.png";
import {
  CloseSquareOutlined, EditOutlined, FileImageOutlined, DownloadOutlined, LoadingOutlined, CloseCircleOutlined,
  FilePdfOutlined, WarningOutlined
} from "@ant-design/icons";
import {
  countryNameInLang, _birth_date, _cancel, _choose_file, _click_to_download, _click_to_modify, _file_selected,
  _file_size_warning, _modification_applied, _nationality, _no, _not_uploaded_yet, _save, _warning, _warning_file_upload_1,
  _warning_file_upload_2, _warning_file_upload_3, _warning_file_upload_4, _yes, _zoom_in_or_out_text
} from "../../../../UsefulConstants";
import "../../../../assets/styles/myAccount.css";

function KycElement(props) {

  const { t } = useTranslation();
  const history = useHistory();
  const source = axios.CancelToken.source();

  const [toggle,setToggle] = useState(false);
  const [loadingEdition,setLoadingEdition] = useState(false);
  const [loadingDeletion,setLoadingDeletion] = useState(false);

  const [country,setCountry] = useState(null);
  const [date,setDate] = useState(null);
  const [value,setValue] = useState('');
  const [selectedOption,setSelectedOption] = useState(null);
  const [fieldsValues,setFieldsValues] = useState([]);
  const [boolOption,setBoolOption] = useState(null);
  const [file,setFile] = useState(null);

  const [error,setError] = useState(false);


  function onError(){
    setError(true);
  }

  function onToggle(){
    //----------------------------------------------
    let text = props.text;
    let type = props.type;
    let field = props.field;
    //----------------------------------------------
    let value = "";
    let date = null;
    let country = null;
    let table = [];
    let selectedOption = null;
    //----------------------------------------------
    if(type === "text" && text != null && text !== "") value = text;
    //----------------------------------------------
    if(type === "number" && text != null && text !== "0" && text !== 0) value = text;
    //----------------------------------------------
    if(type === "boolean"){
      if(text != null){
        setBoolOption({value: text, label: t(text === true ? _yes : _no)});
      }
    }
    //----------------------------------------------
    if(type === "date" && text != null) date = text;
    //----------------------------------------------
    if(type === "country" && text != null) country = getCountryFromCountryCode(text);
    //----------------------------------------------
    if(type === "select"){
      if(props.fieldsValues != null){
        for(let element of Array.from(props.fieldsValues)){
          element.label = getTextToDisplay2(element.display_names);
          if(element.field === field) table.push(element);
          if(text != null){ if(element.value === text) selectedOption = element; }
        }
      }
    }
    //----------------------------------------------
    setValue(value);
    setDate(date != null ? moment(date, 'DD/MM/YYYY') : date);
    setCountry(country);
    setFile(null);
    setSelectedOption(selectedOption);
    setFieldsValues(type === "select" ? table : props.fieldsValues);
    setToggle(t => !t);
  }

  function onChangeValue(e){
    setValue(e.target.value);
  }

  function onChangeDate(date){
    setDate(date);
  }

  function onChangeCountry(country){
    setCountry(country);
  }

  function onChangeBoolOption(e){
    setBoolOption(e);
  }

  function onChangeSelectedOption(selectedOption){
    setSelectedOption(selectedOption);
  }

  function onChangeFile(e){
    if((e.target.files[0] != null && e.target.files[0].size/1048576)>10){
      notification("warning",t(_file_size_warning));
    }else{
      setFile(e.target.files[0]);
    }
  }

  function deleteFile(){
    setFile(null);
  }

  async function editElement(e){

    e.preventDefault();
    setLoadingEdition(l => !l);

    let newValue = "";
    switch (type) {
      case "text":
        newValue = value;
        break;
      case "number":
        newValue = value;
        break;
      case "boolean":
        newValue = boolOption.value;
        break;
      case "date":
        newValue = date.format("DD/MM/YYYY");
        break;
      case "country":
        newValue = country.code;
        break;
      case "select":
        newValue = selectedOption.value;
        break;
      default:
        break;
    }

    let response = await RemoteCall(
      true,
      "kyc/mandatory/v3/"+props.url,
      "patch",
      false,
      null,
      { updates:[{"label": field, "value": newValue?.toString()}] },
      source.token,
      history
    );

    setLoadingEdition(l => !l);

    if(response != null){
      props.getResponse(response);
      notification("success",t(_modification_applied));
      setToggle(t => !t);
    }

  }

  async function editAdditionalElement(e){

    e.preventDefault();
    setLoadingEdition(l => !l);

    //--------------------------------------------
    let bodyParam = new FormData();
    bodyParam.append("purpose","edit");
    bodyParam.append("file",file);
    bodyParam.append("index",props.index);
    //--------------------------------------------

    let response = await RemoteCall(
      "true",
      "kyc/additional/v3/"+props.kyc_name,
      "post",
      false,
      null,
      bodyParam,
      source.token,
      history
    );

    setLoadingEdition(l => !l);

    if(response != null){
      props.getResponse(response);
      notification("success",t(_modification_applied));
      setToggle(t => !t);
    }

  }

  async function deleteKycFile(){

    setLoadingDeletion(l => !l);

    //--------------------------------------------
    let bodyParam = new FormData();
    bodyParam.append("purpose","remove");
    bodyParam.append("index",props.index);
    //--------------------------------------------

    let response = await RemoteCall(
      "true",
      "kyc/additional/v3/"+props.kyc_name,
      "post",
      false,
      null,
      bodyParam,
      source.token,
      history
    );

    setLoadingDeletion(l => !l);

    if(response != null){
      props.getResponse(response);
      notification("success",t(_modification_applied));
    }

  }



//----------------------------------------------------------------------------
  let title = props.title;
  let text = props.text;
  let type = props.type;
  let editable = props.editable;
  //let status = props.status;
  let field = props.field;
  //----------------------------------------------------------------------------
  let displayText = "--";
  if(text != null){
    switch (type) {
      case "text":
        displayText = text;
        break;
      case "number":
        displayText = getAmountToDisplay(text,"EUR");
        break;
      case "date":
        displayText = text;
        break;
      case "boolean":
        displayText = text ? t(_yes) : t(_no);
        break;
      case "country":
        displayText = countryNameInLang.getName(text,getLanguage());
        break;
      case "select":
        if(props.fieldsValues != null){
          for(let element of Array.from(props.fieldsValues)){
            if(element.value === text){
              displayText = getTextToDisplay2(element.display_names);
              break;
            }
          }
        }
        break;
      default:
        displayText = text;
        break;
    }
  }
  //----------------------------------------------------------------------------

  return(
    <>

      <Row gutter={[8]} className="mb-2">
        <div className="mr-2">
          <Button
            className="my-button-2 border-radius-kamix-2 text-dark fw-normal"
            style={{width:"25px", height:"25px"}}
            onClick={onToggle}
            disabled={!props.modification || (editable != null && !editable)}
          >
            <EditOutlined style={{fontSize:"14px"}} />
          </Button>
        </div>

        <Col flex="auto">
          <div className="text-normal small mb-2">
            {title}
            {
              (text == null || text === "" || (type === "file" && text.url == null)) &&
              <span className="ml-2 text-red-kamix">*</span>
            }
          </div>
          <h6 className="fw-bold text-normal">
            {
              type !== "file" ? (
                <>
                  {
                    (displayText !== "--" && displayText !== "" && displayText !== "0" )? (
                      <>
                        {displayText}
                      </>
                    ) : (
                      <div>
                        <span className="cursorView" onClick={(editable != null && editable) ? onToggle : null}>
                          {t(_click_to_modify)}
                        </span>
                      </div>
                    )
                  }
                </>
              ) : (
                <>
                  {
                    (text != null && text.url != null) ? (
                      <>
                        <div className="d-flex align-items-start cursorView my-2">
                          {
                            text.format === "pdf" ? (
                              <FilePdfOutlined style={{fontSize:"50px"}} />
                            ) : (
                              <>
                                {
                                  text.url != null ? (
                                    <span title={t(_zoom_in_or_out_text)}>
                                      <Zoom>
                                        <img
                                          alt="pic"
                                          className="img-fluid shadow-sm"
                                          src={error ? DefaultImage : text.url}
                                          style={error ? { maxWidth:"100%", maxHeight:"50px" } : { maxWidth:"100%", maxHeight:"200px" }}
                                          onError={onError}
                                        />
                                      </Zoom>
                                    </span>
                                  ) : (
                                    <span>
                                      <FileImageOutlined style={{fontSize:"50px"}}  />
                                    </span>
                                  )
                                }
                              </>
                            )
                          }
                          <div className="d-flex align-items-center">
                            <a href={text.url} target="_blank" rel="noopener noreferrer">
                              <span className="ml-2 mr-1" title={t(_click_to_download)}>
                                <DownloadOutlined style={{fontSize:"18px"}} />
                              </span>
                            </a>
                            {
                              (editable != null && editable) &&
                              <>
                                {
                                  loadingDeletion ?
                                    <LoadingOutlined style={{fontSize:"15px"}} /> :
                                    <span className="text-danger" onClick={deleteKycFile}>
                                      <CloseCircleOutlined style={{fontSize:"18px"}} />
                                    </span>
                                }
                              </>
                            }
                          </div>
                        </div>
                      </>
                    ) :(
                      <div>
                        <span className="fw-medium cursorView" onClick={(editable != null && editable) ? onToggle : null}>
                          {t(_not_uploaded_yet)}
                        </span>
                      </div>
                    )
                  }
                </>
              )
            }
          </h6>
        </Col>
      </Row>

      <Modal
        visible={toggle}
        onCancel={onToggle}
        title={<h6 className="mb-0">{title}</h6>}
        footer={null}
        closeIcon={<div className="d-flex justify-content-center mt-3"><CloseSquareOutlined style={{fontSize:"20px", color:"black"}} /></div>}
      >

        <Row className="justify-content-center">
          <Col xs={22} md={20}>



            <form onSubmit={(e) => props.additional != null ? editAdditionalElement(e) : editElement(e)}>

              {
                (type === "text" || type === "number") &&
                <>
                  <Input
                    type={type}
                    value={value}
                    onChange={onChangeValue}
                    className="my-input"
                    size="large"
                    status={(value != null && value !== "") ? "success" : "error"}
                    required
                  />
                </>
              }

              {
                type === "date" &&
                <>
                  <DatePicker
                    type="text"
                    value={date}
                    onChange={(e)=>onChangeDate(e)}
                    className="my-input"
                    status={date != null  ? "success" : "error"}
                    size="large"
                    placeholder={t(_birth_date)}
                  />
                </>
              }

              {
                type === "boolean" &&
                <>
                  <Select
                    value={boolOption} onChange={(e) => onChangeBoolOption(e)}
                    options={[{value: false, label: t(_no)}, {value: true, label: t(_yes),}]}
                  />
                </>
              }

              {
                type === "country" &&
                <>
                  <Select
                    className={country != null ? "my-select-2" : "my-select-2-warning"}
                    classNamePrefix={country != null ? "my-select-2" : "my-select-2-warning"}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    value={country}
                    onChange={(e)=>onChangeCountry(e)}
                    options={getAllCountries()}
                    isSearchable
                    autosize={false}
                    getOptionLabel ={(option)=>option.name}
                    formatOptionLabel={(option) => {return option.label}}
                    placeholder={t(_nationality)}
                    required
                  />
                </>
              }

              {
                type === "select" &&
                <>
                  <Select
                    className={selectedOption != null ? "my-select-2" : "my-select-2-warning"}
                    classNamePrefix={selectedOption != null ? "my-select-2" : "my-select-2-warning"}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    value={selectedOption}
                    onChange={(e)=>onChangeSelectedOption(e)}
                    options={fieldsValues}
                    isSearchable
                    autosize={false}
                    required
                  />
                </>
              }

              {
                type === "file"  &&
                <>
                  {
                    file == null ? (
                      <>
                        <div className="d-flex align-items-center fw-medium text-warning-kamix mb-2">
                          <span className="mr-2">{t(_warning)}</span>
                          <WarningOutlined />
                        </div>
                        <div className="fw-medium mb-2">{t(_warning_file_upload_1)}</div>
                        <div className="italic mb-2">
                          <ul>
                            <li>{t(_warning_file_upload_2)}</li>
                            <li>{t(_warning_file_upload_3)}</li>
                            <li>{t(_warning_file_upload_4)}</li>
                          </ul>
                        </div>
                        <div className="d-flex justify-content-center mb-4">
                          <input
                            type="file" name="file1" id="file1" className="inputfile" onChange={onChangeFile}
                            accept="image/png, image/jpeg, application/pdf"
                          />
                          <label htmlFor="file1" className="text-center">
                            <span className="chooseAFile">{t(_choose_file)}</span>
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex justify-content-center align-items-start">
                          {
                            file.type.includes('pdf') ? (
                              <div className="d-flex flex-column">
                                <div className="text-black small mb-1">{t(_file_selected)}</div>
                                <div className="d-flex align-items-start">
                                  <span className="myFile mr-2">{file.name}</span>
                                  <span className="text-danger cursorView" onClick={deleteFile}>
                                  <CloseCircleOutlined style={{fontSize:"18px"}} />
                                </span>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex flex-column">
                                <div className="text-black small mb-1">{t(_file_selected)}</div>
                                <div className="d-flex align-items-start">
                                  <img
                                    alt="pic"
                                    className="img-fluid shadow-sm mr-2"
                                    src={URL.createObjectURL(file)}
                                    style={{ maxWidth: "100%", maxHeight:"200px" }}
                                  />
                                  <span className="text-danger cursorView" onClick={deleteFile}>
                                  <CloseCircleOutlined style={{fontSize:"18px"}} />
                                </span>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </>
                    )
                  }
                </>
              }

              <div className="d-flex justify-content-center mt-3">
                <Button
                  className="border-radius-kamix text-dark mr-2"
                  disabled={loadingEdition || loadingDeletion} onClick={()=>setToggle(t=>!t)}
                >
                  {t(_cancel)}
                </Button>
                <Button
                  className="my-button-2 bg-yellow-kamix text-dark"
                  loading={loadingEdition || loadingDeletion} disabled={(type === "boolean" && boolOption === "") || (type === "date" && date == null)}
                  htmlType="submit"
                >
                  {t(_save)}
                </Button>
              </div>

            </form>


          </Col>
        </Row>

      </Modal>

    </>
  );

}

export default withRouter(KycElement);