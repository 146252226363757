import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined,} from "@ant-design/icons";
import RemoteCall, {notification} from "../../UsefulFunctions";
import {_accept, _home, _modification_applied, _terms_and_privacy_policy} from "../../UsefulConstants";

function AcceptCGUV() {

  const { t } = useTranslation();
  const source = axios.CancelToken.source();
  const history = useHistory();

  const [loadingCGUV,setLoadingCGUV] = useState(false);

  async function acceptCGUV(){

    setLoadingCGUV(l => !l);

    let response = await RemoteCall(
      true,
      "kyc/mandatory/v3/cgu",
      "post",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingCGUV(l => !l);

    if(response != null){
      notification("success",t(_modification_applied));
      history.push('/home')
    }

  }


  useEffect(
    () => {
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/home"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_home)}</small>
              <h5 className="fw-bold">{t(_terms_and_privacy_policy)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} className="my-3 p-2">

                <div
                  dangerouslySetInnerHTML={{
                    __html: '<iframe src="https://www.kamix.fr/conditions-generales-dutilisation-et-de-vente" width="100%" height="450"></iframe>'
                  }}
                />

                <div className="d-flex justify-content-center mt-4">
                  <Button className="my-button bg-yellow-kamix text-dark fw-normal" loading={loadingCGUV} onClick={acceptCGUV}>
                    {t(_accept)}
                  </Button>
                </div>

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(AcceptCGUV);