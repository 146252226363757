import React, {useState, useEffect} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {Row, Col, Skeleton} from "antd";
import {useTranslation} from "react-i18next";
import axios from 'axios';
import RemoteCall from "../UsefulFunctions";
import KamixLogo from "../assets/images/logo_kamix.png";
import {
  _email_verification, _email_verification_success_text_1, _email_verification_success_text_2,
  _close_page_and_good_continuation, _error_message_default, _app_name
} from "../UsefulConstants";
import "../assets/styles/pages.css";

function Verification() {

  const { t } = useTranslation();
  const history = useHistory();
  let { kid, link } = useParams();

  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [user,setUser] = useState(null);
  const [flag,setFlag] = useState(false);

  async function verifyEmail() {

    setLoading(l=>!l);

    let response = await RemoteCall(
      true,
      "users/login-id-verification/"+kid,
      "post",
      false,
      {login_id_verification_id:link},
      null,
      source.token,
      history
    );

    setLoading(l=>!l);

    if(response != null){
      setFlag(true);
      setUser(response.user)
    }

  }

  useEffect(
    () => {
      verifyEmail();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
    );

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="margin-bottom-25">
              <Link to={"#"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
            </div>

            <h1 className="text-header mb-4">{t(_email_verification)}</h1>

            <Row className="justify-content-start">
              <Col xs={24} md={20}>

                {
                  loading ? (
                    <Skeleton active paragraph={{rows: 2}} />
                  ) : (
                    <>
                      {
                        flag ? (
                          <>
                            {
                              user != null && user.email != null &&
                              <h6 className="text-normal text-dark mb-3">
                                {t(_email_verification_success_text_1)} {' '}
                                <span className="fw-bold text-italic">{user.email.email}</span> {' '}
                                {t(_email_verification_success_text_2)}
                              </h6>
                            }
                            <h6 className="text-normal">{t(_close_page_and_good_continuation)}</h6>
                          </>
                        ) : (
                          <div className="text-dark">{t(_error_message_default)}</div>
                        )
                      }
                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default Verification;
