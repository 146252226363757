import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Skeleton, Input, Collapse} from "antd";
import {Link, withRouter, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import TxPresentation from "../history/TxPresentation";
import {ArrowLeftOutlined, ReloadOutlined} from "@ant-design/icons";
import RemoteCall, {getDate, groupBy, isToday, isYesterday, notification} from "../../UsefulFunctions";
import {sessionStorage} from "../../Security";
import Select from "react-select";
import {
  _from, _history, _my_transactions, _no_transaction_made_yet, _search, _search_by, _search_by_crypto_currency,
  _search_by_statuses, _search_by_types, _search_by_warning_text, _search_by_warning_text_date, _select, _to,
  _today, _view_more, _yesterday
} from "../../UsefulConstants";
import "../../assets/styles/history.css";

const { Panel } = Collapse;

function TxHistory() {

  const { t } = useTranslation();
  const history = useHistory();
  const source = axios.CancelToken.source();

  let currencies = sessionStorage.get('currencies');
  currencies = [currencies[0]];
  for(let currency of currencies){
    currency.label = currency.name;
    currency.value = currency.unique_name;
  }

  const [loading,setLoading] = useState(false);
  const [loadingViewMore,setLoadingViewMore] = useState(false);
  const [loadingTransactions,setLoadingTransactions] = useState(false);
  const [tx,setTx] = useState(null);
  const [transactions,setTransactions] = useState(null);
  const [keys,setKeys] = useState(null);
  const [count,setCount] = useState(0);
  const [page,setPage] = useState(1);

  const [types,setTypes]= useState([]);
  const [statuses,setStatuses]= useState([]);
  const [startDate,setStartDate]= useState("");
  const [endDate,setEndDate]= useState("");
  const [currencySelected,setCurrencySelected] = useState(currencies[0]);


  function onChangeTypes(e){
    setTypes(e);
  }

  function onChangeStatuses(e){
    setStatuses(e);
  }

  function onChangeCryptoCurrency(e){
    setCurrencySelected(e);
  }

  function startDateChange(e){
    setStartDate(e.target.value);
  }

  function endDateChange(e){
    setEndDate(e.target.value);
  }

  function reload(){
    setTypes([]);
    setStatuses([]);
    setStartDate("");
    setEndDate("");
    setPage(1);
    setKeys(null);

    getTransactions();
  }


  async function getTransactions(){

    setLoadingTransactions(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/details/history/list",
      "get",
      false,
      {
        limit:false,
        page:1
      },
      null,
      source.token,
      history
    );

    setLoadingTransactions(l => !l);

    if(response != null){
      let transactions = response.transactions;

      for(let tx of transactions) tx.minDate = getDate(tx.max_date).minDate;
      if(transactions.length > 0) transactions = groupBy(transactions,"minDate");

      setTx(response.transactions);
      setCount(response.count);
      setTransactions(transactions);
      setKeys(Object.keys(transactions));
    }

  }

  async function viewMore(){

    setLoadingViewMore(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/details/history/list",
      "get",
      false,
      {
        limit:false,
        page:page+1
      },
      null,
      source.token,
      history
    );

    setPage(p => p+1);
    setLoadingViewMore(l => !l);

    if(response != null){
      let transactions = tx.concat(response.transactions);

      for(let tx of transactions) tx.minDate = getDate(tx.max_date).minDate;
      if(transactions.length > 0) transactions = groupBy(transactions,"minDate");

      setTx(tx.concat(response.transactions));
      setCount(response.count);
      setTransactions(transactions);
      setKeys(Object.keys(transactions));
    }

  }

  async function search(){

    if(
      (types == null || types.length === 0)  && (statuses == null || statuses.length === 0) &&
      currencySelected == null && startDate === "" && endDate === ""
    ){
      notification("warning",t(_search_by_warning_text));
    }else if((startDate !== "" && endDate === "") || (startDate === "" && endDate !== "")){
      notification("warning",t(_search_by_warning_text_date));
    }else{

      let typesToSend = "";
      let statusesToSend = "";

      //--------------------------------------------------------------------------
      if(types.length === 0){
        typesToSend = "";
      }else if(types.length === 1){
        typesToSend = types[0].value;
      }else{
        typesToSend = types[0].value;
        for (let i = 1; i < types.length - 1; i++) typesToSend = typesToSend + "-" + types[i].value;
        typesToSend = typesToSend + "-" + types[types.length-1].value;
      }
      //--------------------------------------------------------------------------
      if(statuses.length === 0){
        statusesToSend = "";
      }else if(statuses.length === 1){
        statusesToSend = statuses[0].value;
      }else{
        statusesToSend = statuses[0].value;
        for (let i = 1; i < statuses.length - 1; i++) statusesToSend = statusesToSend + "-" + statuses[i].value;
        statusesToSend = statusesToSend + "-" + statuses[statuses.length-1].value;
      }
      //--------------------------------------------------------------------------

      let queryParams = {};
      queryParams.page = 1;
      queryParams.limit = false;
      queryParams.types = typesToSend;
      queryParams.statuses = statusesToSend;
      queryParams.currency = currencySelected != null ? currencySelected.value : null;
      queryParams.date = (startDate === "" || endDate === "") ? "" : (new Date(startDate).getTime() +"-"+ new Date(endDate).getTime());

      //--------------------------------------------------------------------------

      setLoading(l => !l);

      let response = await RemoteCall(
        true,
        "transactions/details/history/list",
        "get",
        false,
        queryParams,
        null,
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        let transactions = response.transactions;

        for(let tx of transactions) tx.minDate = getDate(tx.max_date).minDate;
        if(transactions.length > 0) transactions = groupBy(transactions,"minDate");

        setTx(response.transactions);
        setCount(response.count);
        setTransactions(transactions);
        setKeys(Object.keys(transactions));
      }

    }

  }


  useEffect(
    () => {
      getTransactions();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const typeOptions = [
    { value: 'subscriptions', label: t('SUBSCRIPTION') },
    { value: 'transfers_out', label: t('TRANSFERRED') },
    { value: 'purchases', label: t('BOUGHT') },
  ];

  const statusesOptions = [
    { value: 'PENDING', label: t('PENDING') },
    { value: 'PAID', label: t('PAID') },
    { value: 'SENT', label: t('SENT') },
    { value: 'DONE', label: t('DONE') },
    { value: 'FAILED', label: t('FAILED') },
  ];

  return(
    <Row className="justify-content-center">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={'/history'}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_history)}</small>
              <h5 className="fw-bold">{t(_my_transactions)}</h5>
            </div>

            <Row className="justify-content-center mt-4 mb-5">
              <Col xs={24} md={20}>

                <Collapse className="bg-white my-box">
                  <Panel header={<h6 className="mb-0">{t(_search_by)}</h6>}>

                    <div className="mb-3">
                      <div className="small text-gray-kamix mb-1">{t(_search_by_types)}</div>
                      <Select
                        placeholder={t(_select)}
                        value={types}
                        onChange={onChangeTypes}
                        options={typeOptions}
                        isMulti
                      />
                    </div>
                    <div className="mb-3">
                      <div className="small text-gray-kamix mb-1">{t(_search_by_statuses)}</div>
                      <Select
                        placeholder={t(_select)}
                        value={statuses}
                        onChange={onChangeStatuses}
                        options={statusesOptions}
                        isMulti
                      />
                    </div>
                    <div className="mb-3">
                      <div className="small text-gray-kamix mb-1">{t(_search_by_crypto_currency)}</div>
                      <Select
                        placeholder={t(_select)}
                        value={currencySelected}
                        onChange={onChangeCryptoCurrency}
                        options={currencies}
                      />
                    </div>
                    <div className="mb-4">
                      <Row>
                        <Col className="mr-2">
                          <div className="small text-gray-kamix mb-1">{t(_from)}</div>
                          <Input type="date" onChange={startDateChange} value={startDate} />
                        </Col>
                        <Col>
                          <div className="small text-gray-kamix mb-1">{t(_to)}</div>
                          <Input type="date" onChange={endDateChange} value={endDate} />
                        </Col>
                      </Row>
                    </div>

                    <div className="d-flex justify-content-end">
                      <Button className="my-button-2 bg-dark text-white mr-2" onClick={reload} disabled={loading} >
                        <ReloadOutlined />
                      </Button>
                      <Button className="my-button-2 bg-yellow-kamix text-dark" onClick={search} loading={loading}>{t(_search)}</Button>
                    </div>

                  </Panel>
                </Collapse>

              </Col>
            </Row>

            <Row className="mb-3">
              {
                (loadingTransactions || loading) ? (
                  <>
                    <div className="full-width mb-3">
                      <Skeleton.Button
                        active shape block
                        style={{height:"25px", borderRadius:"8px", width:"25%", marginBottom:"10px"}}
                      />
                      <div className="my-box bg-white p-4">
                        <Skeleton.Button
                          active shape block
                          style={{height:"90px", padding:"16px", borderRadius: "8px"}}
                        />
                      </div>
                    </div>
                    <div className="full-width">
                      <Skeleton.Button
                        active shape block
                        style={{height:"25px", borderRadius:"8px", width:"25%", marginBottom:"10px"}}
                      />
                      <div className="my-box bg-white p-4">
                        <Skeleton.Button
                          active shape block
                          style={{height:"90px", padding:"16px", borderRadius: "8px"}}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="full-width">

                    {
                      keys != null && keys.length !== 0 ? (
                        <>

                          {
                            keys.map((element,index)=>(
                              <div key={index} className="child mb-3">
                                {
                                  transactions[element] != null &&
                                  <>
                                    {
                                      (isToday(transactions[element][0].max_date) || isYesterday(transactions[element][0].max_date)) ? (
                                        <small>
                                          {isToday(transactions[element][0].max_date) ? t(_today) : t(_yesterday)}
                                        </small>
                                      ) : (
                                        <small>
                                          {
                                            getDate(transactions[element][0].max_date).day +" "+
                                            t("months_"+getDate(transactions[element][0].max_date).month) +" "+
                                            getDate(transactions[element][0].max_date).year
                                          }
                                        </small>
                                      )
                                    }
                                    <div className="my-box bg-white mt-2 p-2">
                                      {
                                        transactions[element].map(item => (
                                          <div key={item.code} className="child">
                                            <Link to={`/history/tx/a/${item.code}`}>
                                              <TxPresentation transaction={item} />
                                            </Link>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  </>
                                }
                              </div>
                            ))
                          }

                          {
                            count > tx.length &&
                            <div className="d-flex justify-content-center my-3">
                              <Button className="my-button-2 bg-yellow-kamix text-dark" onClick={viewMore} loading={loadingViewMore}>
                                {t(_view_more)}
                              </Button>
                            </div>
                          }

                        </>
                      ) : (
                        <div className="my-box bg-white p-2 text-center">
                          <h6 className="my-5">{t(_no_transaction_made_yet)}.</h6>
                        </div>
                      )
                    }

                  </div>
                )
              }
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(TxHistory);