import React, {useState, useEffect, useRef} from "react";
import {Link, useHistory} from "react-router-dom";
import {Row, Col, Input, Button} from "antd";
import {useTranslation} from "react-i18next";
import axios from 'axios';
import RemoteCall, {getName, notification} from "../../UsefulFunctions";
import LanguageSelector from "../../components/others/LanguageSelector";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import {sessionStorage} from "../../Security";
import ReactCodeInput from "react-code-input";
import KamixLogo from "../../assets/images/logo_kamix.png";
import {
  codeInputProps, _required, _log_in, _password, _welcome_message_with_user_name, _welcome_message_without_user_name,
  _send_code_by_sms_to, _error_message_device_already_exists, _app_name, _enter_password_to_confirm_identity, _next,
  _verification_code_sent_message, _code_invalid, _resend_code_text, _cancel
} from "../../UsefulConstants";
import "../../assets/styles/pages.css";
import "../../assets/styles/constants.css";

function LoginStep2() {

  let loginInformation = sessionStorage.get('loginInformation');
  let user = loginInformation != null && loginInformation.user;
  let mobile = user != null && user.mobiles != null && user.mobiles[0] != null ? user.mobiles[0].mobile : "";

  const { t } = useTranslation();
  const codeRef = useRef(undefined);
  const history = useHistory();
  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [loadingResendCode,setLoadingResetCode] = useState(false);
  const [code,setCode] = useState('');
  const [isCodeValid,setIsCodeValid] = useState(true);
  const [password,setPassword] = useState('');
  const [isPasswordValid,setIsPasswordValid] = useState(true);
  const [loginIdVerificationId,setLoginIdVerificationId] =
    useState(loginInformation != null ? loginInformation.login_id_verification_id : "");


  function onChangeCode(code){
    setCode(code);
    setIsCodeValid(true);
  }

  function onChangePassword(e) {
    setPassword(e.target.value);
    setIsPasswordValid(true);
  }

  function clearCodeInput() {
    if(codeRef != null && codeRef.current != null){
      codeRef.current.state.input[0] = "";
      codeRef.current.state.input[1] = "";
      codeRef.current.state.input[2] = "";
      codeRef.current.state.input[3] = "";
      codeRef.current.state.input[4] = "";
      codeRef.current.state.input[5] = "";
    }
  }

  async function resendCode() {

    setLoadingResetCode(l=>!l);

    let response = await RemoteCall(
      false,
      "users/login-id-verification/"+user.kid,
      "get",
      false,
      {login_id_verification_id:loginIdVerificationId},
      null,
      source.token,
      history
    );

    setLoadingResetCode(l=>!l);
    clearCodeInput();

    if(response != null){
      setCode('');
      setLoginIdVerificationId(response.login_id_verification_id);
      notification("success",t(_verification_code_sent_message));
    }

  }

  async function onSubmit(e) {

    e.preventDefault();

    setLoading(l => !l);

    let response = await RemoteCall(
      false,
      "users/login/password/enter/"+user.kid,
      "post",
      false,
      null,
      {password:password},
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){

      let confirm_connection = response.confirm_connection_id;
      if(confirm_connection == null || confirm_connection === ""){
        let isDeviceAlreadyExists = response.device_already_registered;
        if(isDeviceAlreadyExists != null && isDeviceAlreadyExists){
          notification(
            "warning",
            t(_error_message_device_already_exists).replace("XXX",formatPhoneNumberIntl(response.account)),
            false
          );
        }else if(response.redirect_url != null && response.redirect_url !== ""){
          //redirection to  + response.redirect_url
        }else{
          let data = {};
          let welcome = getName(response.user) !== "" ?
            t(_welcome_message_with_user_name).replace("XXXX",getName(response.user)) :
            t(_welcome_message_without_user_name);
          let counter = 60 * 2 * 60000;
          data.user = response.user;
          data.token = response.tkn;
          data.device = response.device;
          data.refreshLimit = new Date().getTime() + counter;
          sessionStorage.set('userCredentials', data);
          history.push('/');
          notification('success',welcome);
        }
      }else{
        let data={};
        data.user = loginInformation.user;
        data.confirm_connection_id = response.confirm_connection_id;
        data.step2 = "ok";
        await sessionStorage.delete('loginInformation');
        sessionStorage.set('loginInformation',data);
        history.push("/sign-in/step-3");
      }

    }

  }

  async function onSubmit2(e) {

    e.preventDefault();

    if(code === "" || code.length !== 6){
      setIsCodeValid(code !== "" && code.length === 6);
    }else{

      setLoading(l => !l);

      let response = await RemoteCall(
        false,
        "users/login-id-verification/"+user.kid,
        "post",
        false,
        {login_id_verification_id:loginIdVerificationId},
        {code:code},
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        let data={};
        data.user = loginInformation.user;
        data.step2 = "ok";
        await sessionStorage.delete('loginInformation');
        sessionStorage.set('loginInformation',data);
        history.push("/sign-in/step-3");
      }

    }

  }

  useEffect(
    () => {
      if(loginInformation == null || loginInformation === "") history.push('/sign-in');
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="d-flex justify-content-between align-items-center margin-bottom-25">
              <Link to={"#"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
              <LanguageSelector location={"out"} />
            </div>

            {
              loginInformation.login_id_verification_id != null ? (
                <>

                  <h1 className="text-header">{t(_log_in)}</h1>
                  <div className="text-normal mb-4">
                    <span className="text-justify">{t(_send_code_by_sms_to)}</span> {" "}
                    <span className="fw-bold one-line">{formatPhoneNumberIntl(mobile)}</span>
                  </div>

                  <form onSubmit={(e)=>onSubmit2(e)}>

                    <div>
                      <ReactCodeInput
                        ref={codeRef}
                        isValid={isCodeValid}
                        type='number'
                        fields={6}
                        value={code}
                        onChange={onChangeCode}
                        autoFocus={false}
                        {...codeInputProps}
                      />
                      {
                        !isCodeValid &&
                        <div className="position-absolute ant-form-item-explain-error mx-3">
                          {code === "" && t(_required)}
                          {code !== "" && code.length !== 6 && t(_code_invalid)}
                        </div>
                      }
                    </div>

                    <div className="mb-4">
                      <Button type="text" className="mt-3" loading={loadingResendCode} onClick={resendCode}>
                        <span className="text-underline">{t(_resend_code_text)}</span>
                      </Button>
                    </div>

                    <div className="d-flex">
                      <Link to={"/sign-in"}>
                        <Button className="special-button bg-light text-dark me-2" disabled={loading}>
                          {t(_cancel)}
                        </Button>
                      </Link>
                      <Button className="special-button bg-yellow-kamix text-dark" loading={loading} htmlType="submit">
                        {t(_next)}
                      </Button>
                    </div>

                  </form>

                </>
              )  : (
                <>

                  <h1 className="text-header">{t(_log_in)}</h1>
                  <div className="text-normal text-justify mt-3 mb-2">
                    {t(_enter_password_to_confirm_identity)}
                  </div>

                  <form onSubmit={(e)=>onSubmit(e)}>
                    <div className="mb-5">
                      <Input.Password
                        type="password"
                        value={password}
                        onChange={onChangePassword}
                        status={isPasswordValid ? "success" : "error"}
                        className="my-input"
                        size="large"
                        placeholder={t(_password)}
                      />
                      {
                        !isPasswordValid &&
                        <div className="position-absolute ant-form-item-explain-error">
                          {t(_required)}
                        </div>
                      }
                    </div>

                    <div className="d-flex">
                      <Link to={"/sign-in"}>
                        <Button className="special-button bg-light text-dark me-2" disabled={loading}>
                          {t(_cancel)}
                        </Button>
                      </Link>
                      <Button className="special-button bg-yellow-kamix text-dark" loading={loading} htmlType="submit">
                        {t(_next)}
                      </Button>
                    </div>

                  </form>

                </>
              )
            }

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default LoginStep2;

