import React, {useState, useEffect, useRef} from 'react';
import {Row, Col, Button, Modal, Skeleton, Input} from "antd";
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import axios from "axios";
import RemoteCall, {
  getCountryFromCountryCode, getTextToDisplay2, notification, setUserCredentials, textWithFirstCapitalLetter, getCountries
} from "../../../UsefulFunctions";
import {sessionStorage} from "../../../Security";
import {formatPhoneNumberIntl, getCountryCallingCode, isValidPhoneNumber} from 'react-phone-number-input';
import {useTranslation} from "react-i18next";
import KamixIcon from "../../../assets/images/mini_logo.png";
import MyCopyButton from "../../others/MyCopyButton";
import ReactCodeInput from "react-code-input";
import Select from "react-select";
import {
  CopyOutlined, WalletOutlined, EditOutlined, MailOutlined, MobileOutlined, ArrowLeftOutlined, AppstoreOutlined,
  StarOutlined, CheckCircleOutlined, DeleteOutlined, CloseSquareOutlined, WarningOutlined, StarFilled, InfoCircleFilled,
} from "@ant-design/icons";
import {
  codeInputProps,
  defaultCountry,
  _profile_deleted,
  _add_mobile_number,
  _cancel,
  _click_to_verify,
  _code_invalid,
  _continue,
  _delete,
  _delete_mobile_number,
  _delete_mobile_number_question,
  _delete_my_kamix_profile,
  _delete_my_kamix_profile_question,
  _edit,
  _edit_mobile_number,
  _icon,
  _invalid_phone_number,
  _mobile_number,
  _mobile_verification,
  _kamix_profile,
  _danger_zone,
  _my_blockchain_addresses,
  _my_kamix_id_2,
  _my_phone_numbers,
  _new_mobile_number,
  _number_deleted,
  _number_modified,
  _quota,
  _number_set_as_main,
  _number_verified,
  _old_mobile_number,
  _password,
  _required,
  _resend,
  _resend_code_text,
  _my_identity,
  _send_code_by_sms_to,
  _set_as_main_mobile,
  _set_as_main_mobile_question,
  _verify_mobile_number,
  _define_email,
  _email,
  _profile_information,
  _profile_status,
  _average_monthly_transfer_volume,
  _general_information,
  _verification_status,
  _click_to_manage,
  _salary_and_incomes,
  _salary_and_incomes_text,
  _indications,
  _manage,
  _additional_documents,
  _add_additional_kyc, _add_dotfile_kyc, _account_blocked, _yes, _no
} from "../../../UsefulConstants";
import "../../../assets/styles/myAccount.css";

function MyAccount() {

  const { t } = useTranslation();
  const { from } = useParams();
  const history = useHistory();
  const codeRef = useRef(undefined);

  let userCredentials = sessionStorage.get('userCredentials');
  const source = axios.CancelToken.source();

  const [loadingUserInfos,setLoadingUserInfos] = useState(false);
  const [loadingUserBlockchainAdresses,setLoadingUserBlockchainAdresses] = useState(false);
  const [user,setUser] = useState(null);
  const [userBlockchainAddresses,setUserBlockchainAddresses] = useState(null);
  const [toggle,setToggle] = useState(false);
  const [toggleDelete,setToggleDelete] = useState(false);
  const [mobileManager,setMobileManager] = useState({item:"", index:"", switch:"main"});

  const [loadingDelete,setLoadingDelete] = useState(false);
  const [password,setPassword] = useState("");

  const [loadingVerification,setLoadingVerification] = useState(false);
  const [loadingMain,setLoadingMain] = useState(false);
  const [loadingEdition,setLoadingEdition] = useState(false);
  const [loadingDeletion,setLoadingDeletion] = useState(false);
  const [id,setId] = useState('');

  const [loadingCode,setLoadingCode] = useState(false);
  const [loadingResendCode,setLoadingResetCode] = useState(false);
  const [code,setCode] = useState('');
  const [isCodeValid,setIsCodeValid] = useState(true);

  const [oldCountry, setOldCountry] = useState(defaultCountry);
  const [newCountry, setNewCountry] = useState(defaultCountry);
  const [oldPhoneNumber,setOldPhoneNumber] = useState('');
  const [newPhoneNumber,setNewPhoneNumber] = useState('');
  const [isNewPhoneNumberValid,setIsNewPhoneNumberValid] = useState(true);

  const [loadingUserAccountStatus,setLoadingUserAccountStatus] = useState(false);
  const [userAccountStatus,setUserAccountStatus] = useState(null);

  const [userIdentity,setUserIdentity] = useState(null);
  const [userQuota,setUserQuota] = useState(null);
  const [userIncomesInfo,setUserIncomesInfo] = useState(null);


  function onToggle(){
    setToggle(t => !t);
    onSwitch('main');
  }

  function onChangeCode(code){
    setCode(code);
    setIsCodeValid(true);
  }

  function clearCodeInput() {
    if(codeRef != null && codeRef.current != null){
      codeRef.current.state.input[0] = "";
      codeRef.current.state.input[1] = "";
      codeRef.current.state.input[2] = "";
      codeRef.current.state.input[3] = "";
      codeRef.current.state.input[4] = "";
      codeRef.current.state.input[5] = "";
    }
  }

  function onChangeNewPhoneNumber(e){
    setNewPhoneNumber(e.target.value);
    setIsNewPhoneNumberValid(true);
  }

  function onChangeNewCountry(e) {
    setNewCountry(e)
  }

  function makeAChoice(item,index){
    setToggle(t => !t);
    setMobileManager({
      item: item,
      index: index,
      switch:"main"
    });
  }

  function onSwitch(decision){

    setMobileManager({
      ...mobileManager,
      switch: decision
    });

    if(decision === "verify") launchNumberVerification();
    if(decision === "edit") {
      let mobile = mobileManager.item.mobile;
      let countryCallingCode = getCountryCallingCode(mobileManager.item.country);
      mobile = mobile.toString().replace("+"+countryCallingCode,"");
      setOldPhoneNumber(mobile);
      setOldCountry(getCountryFromCountryCode(mobileManager.item.country));
    }

    setId('');
    setCode('');
    setNewCountry(defaultCountry);
    setNewPhoneNumber('');

  }

  async function deleteKamixAccount(e){

    e.preventDefault();

    setLoadingDelete(l => !l);

    let response = await RemoteCall(
      true,
      "users/close-account",
      "post",
      false,
      null,
      {password:password},
      source.token,
      history
    );

    setLoadingDelete(l => !l);

    if(response != null){
      sessionStorage.delete('userCredentials');
      notification("success",t(_profile_deleted));
      history.push('/start');
    }

  }

  async function launchNumberVerification(){

    setLoadingVerification(l => !l);

    let response = await RemoteCall(
      true,
      "users/confirm-mobile",
      "get",
      false,
      {
        mobile:mobileManager.item.mobile,
        force:false
      },
      null,
      source.token,
      history
    );

    setLoadingVerification(l => !l);

    if(response != null){
      setUser(user);
      setId(response.login_id_verification_id)
    }
  }

  async function resendCode() {

    setLoadingResetCode(l=>!l);

    let response = await RemoteCall(
      true,
      "users/login-id-verification/"+userCredentials.user.kid,
      "get",
      false,
      {login_id_verification_id:id},
      null,
      source.token,
      history
    );

    setLoadingResetCode(l=>!l);

    if(response != null){
      setCode('');
      clearCodeInput();
      setId(response.login_id_verification_id);
      notification("success",t(_resend));
    }

  }

  async function onsubmitCode(e) {

    e.preventDefault();

    if(code === "" || code.length !== 6){
      setIsCodeValid(code !== "" && code.length === 6);
    }else{

      setLoadingCode(l => !l);

      let response = await RemoteCall(
        true,
        "users/login-id-verification/"+userCredentials.user.kid,
        "post",
        false,
        {login_id_verification_id:id},
        {code:code},
        source.token,
        history
      );

      setLoadingCode(l => !l);

      if(response != null){
        setUser(response.user);
        await setUserCredentials(userCredentials, response.user);
        notification("success",t(_number_verified));
        onToggle();
      }

    }

  }

  async function makeNumberMain() {

    setLoadingMain(l => !l);

    let response = await RemoteCall(
      true,
      "users/main-mobile",
      "patch",
      false,
      null,
      {main_mobile:mobileManager.item.mobile},
      source.token,
      history
    );

    setLoadingMain(l => !l);

    if(response != null){
      setUser(response.user);
      await setUserCredentials(userCredentials, response.user);
      notification("success",t(_number_set_as_main));
      onToggle();
    }

  }

  async function onEditPhoneNumber(e) {

    e.preventDefault();
    let newMobile = '+'+getCountryCallingCode(newCountry.code)+newPhoneNumber;

    if(newPhoneNumber === "" || !isValidPhoneNumber(newMobile)){
      setIsNewPhoneNumberValid(newPhoneNumber !== "" && isValidPhoneNumber(newMobile));
    }else{

      setLoadingEdition(l => !l);

      let response = await RemoteCall(
        true,
        "users/update-mobile",
        "patch",
        false,
        null,
        {
          old_mobile:mobileManager.item.mobile,
          new_mobile:newMobile
        },
        source.token,
        history
      );

      setLoadingEdition(l => !l);

      if(response != null){
        setUser(response.user);
        await setUserCredentials(userCredentials, response.user);
        notification("success",t(_number_modified));
        onToggle();
      }

    }

  }

  async function deleteMobileNumber() {

    setLoadingDeletion(l => !l);

    let response = await RemoteCall(
      true,
      "users/update-mobile",
      "patch",
      false,
      null,
      {old_mobile:mobileManager.item.mobile},
      source.token,
      history
    );

    setLoadingDeletion(l => !l);

    if(response != null){
      setUser(response.user);
      await setUserCredentials(userCredentials, response.user);
      notification("success",t(_number_deleted));
      onToggle();
    }

  }

  async function getUserBlockhainAdresses(){

    setLoadingUserBlockchainAdresses(l => !l);

    let response = await RemoteCall(
      true,
      "users/blockchain-addresses",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserBlockchainAdresses(l => !l);

    if(response != null){
      setUserBlockchainAddresses(response.addresses);
    }

  }

  async function getUserInfos(){

    setLoadingUserInfos(l => !l);

    let response = await RemoteCall(
      true,
      "users",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserInfos(l => !l);

    if(response != null){
      setUser(response.user);
      await setUserCredentials(userCredentials,response.user)
    }

  }

  async function getUserProfile(){

    setLoadingUserAccountStatus(l => !l);

    let response = await RemoteCall(
      true,
      "kyc/profile/v3",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserAccountStatus(l => !l);

    if(response != null){
      setUserAccountStatus(response.user_account_status);
    }

  }

  async function getUserIdentity(){

    let response = await RemoteCall(
      true,
      "kyc/mandatory/v3/identity",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    if(response != null){
      setUserIdentity(response.user_identity);
    }

  }

  async function getUserQuota(){

    let response = await RemoteCall(
      true,
      "kyc/mandatory/v3/quota",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    if(response != null){
      setUserQuota(response.user_quota);
    }

  }

  async function getUserIncomesInfo(){

    let response = await RemoteCall(
      true,
      "kyc/mandatory/v3/incomes-info",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    if(response != null){
      setUserIncomesInfo(response.user_incomes_info);
    }

  }

  useEffect(
    () => {
      getUserInfos();
      getUserBlockhainAdresses();
      getUserProfile();

      getUserIdentity();
      getUserQuota();
      getUserIncomesInfo();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={from != null ? "/home" : "/profile"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-4 ml-5">
              <h5 className="fw-bold">{t(_kamix_profile)}</h5>
            </div>

            <div className="small mb-1 ml-5">{t(_profile_information)}</div>
            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loadingUserInfos ? (
                    <>
                      <Skeleton active avatar paragraph={{rows: 1}} />
                      <Skeleton active avatar paragraph={{rows: 1}} />
                      <Skeleton active avatar paragraph={{rows: 1}} />
                    </>
                  ) : (
                    <>

                      {
                        user != null &&
                        <>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={3} className="d-flex justify-content-center">
                              <img alt={t(_icon)} src={KamixIcon} style={{ width: "25px", height:"100%" }} />
                            </Col>
                            <Col xs={18}>
                              <div className="text-normal small mb-2">{t(_my_kamix_id_2)}</div>
                              <div className="fw-bold text-black">{user.kid}</div>
                            </Col>
                            <Col xs={3} className="d-flex justify-content-end">
                              <MyCopyButton text={user.kid}>
                                <Button className="my-button-2 border-radius-kamix-2 bg-dark text-white fw-normal" style={{width:"25px", height:"25px"}}>
                                  <CopyOutlined style={{fontSize:"14px"}} />
                                </Button>
                              </MyCopyButton>
                            </Col>
                          </Row>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={3} className="d-flex justify-content-center">
                              <WalletOutlined style={{fontSize:"25px"}} />
                            </Col>
                            <Col xs={18}>
                              {
                                loadingUserBlockchainAdresses ? (
                                  <>
                                    <Skeleton active paragraph={{rows: 1}}/>
                                  </>
                                ) : (
                                  <>
                                    <div className="text-normal small mb-2">{t(_my_blockchain_addresses)}</div>
                                    {
                                      userBlockchainAddresses != null && userBlockchainAddresses.map((uba,index)=>(
                                        <React.Fragment key={index}>
                                          <Row className="justify-content-between align-items-start mb-1">
                                            <Col xs={20} className="d-flex flex-column small">
                                              <span className="text-ellipsis fw-bold">{uba.address}</span>
                                              <small>{uba.blockchain}</small>
                                            </Col>
                                            <MyCopyButton text={uba.address} />
                                          </Row>
                                        </React.Fragment>
                                      ))
                                    }
                                  </>
                                )
                              }
                            </Col>
                            <Col xs={3} />
                          </Row>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={3} className="d-flex justify-content-center">
                              <MailOutlined style={{fontSize:"25px"}} />
                            </Col>
                            <Col xs={18}>
                              <div className="text-normal small mb-2">{t(_email)}</div>
                              <div className="fw-bold text-black">
                                {
                                  user.email != null ? (
                                    <div>
                                      <div className="text-ellipsis">{user.email.email}</div>
                                      {
                                        !user.email.verified &&
                                        <Link to={`/profile/account/email/manage`}>
                                          <div className="text-danger small">{t(_click_to_verify)}</div>
                                        </Link>
                                      }
                                    </div>
                                  ) : (
                                    <Link to={`/profile/account/email/manage`}>
                                      <div>{t(_define_email)}</div>
                                    </Link>
                                  )
                                }
                              </div>
                            </Col>
                            <Col xs={3} className="d-flex justify-content-end">
                              <Link to={`/profile/account/email/manage`}>
                                <Button className="my-button-2 border-radius-kamix-2 bg-dark text-white fw-normal" style={{width:"25px", height:"25px"}}>
                                  <EditOutlined style={{fontSize:"14px"}} />
                                </Button>
                              </Link>
                            </Col>
                          </Row>

                          <Row gutter={[16]} className="justify-content-center align-items-start">
                            <Col xs={3} className="d-flex align-items-center justify-content-center">
                              <MobileOutlined style={{fontSize:"25px"}} />
                            </Col>
                            <Col xs={18}>
                              <div className="text-normal small mb-2">{t(_my_phone_numbers)}</div>
                              <div>
                                {
                                  user.mobiles != null && user.mobiles.map((item,index)=>(
                                    <div className="d-flex" key={item._id}>
                                      <Button
                                        size="small"
                                        className={(item.verified ? "bg-green-kamix" : "bg-red-kamix") + " my-button-2 text-white mb-2"}
                                        onClick={(e)=>makeAChoice(item,index,e)}
                                        style={{fontSize:"0.8em"}}
                                      >
                                        {formatPhoneNumberIntl(""+item.mobile)}
                                      </Button>
                                      {index === 0 && <StarFilled style={{fontSize:"18px", marginLeft:"10px", marginTop:"2px", color:"#ffc91e"}} />}
                                    </div>
                                  ))
                                }
                              </div>
                              <Link to={`/profile/account/mobile/create`}>
                                <Button className="border-radius-kamix text-darker fw-bold mt-3">
                                  {t(_add_mobile_number)}
                                </Button>
                              </Link>
                            </Col>
                            <Col xs={3}/>
                          </Row>

                        </>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

            <div className="small mb-1 ml-5">{t(_my_identity)}</div>
            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loadingUserAccountStatus ? (
                    <>
                      <Skeleton active avatar paragraph={{rows: 1}} />
                      <Skeleton active avatar paragraph={{rows: 1}} />
                      <Skeleton active avatar paragraph={{rows: 1}} />
                    </>
                  ) : (
                    <>

                      {
                        userAccountStatus != null &&
                        <>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={3} />
                            <Col xs={18}>
                              <div className="text-normal small mb-2">{t(_account_blocked)}</div>
                              <div className={"fw-bold status-"+(userAccountStatus.account_blocked ? "blocked" : "active")}>
                                {userAccountStatus.account_blocked ? t(_yes) : t(_no)}
                              </div>
                            </Col>
                            <Col xs={3} />
                          </Row>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={3} />
                            <Col xs={18}>
                              <div className="text-normal small mb-2">{t(_profile_status)}</div>
                              <div className={"fw-bold status-"+userAccountStatus.account_status.toLowerCase()}>
                                {textWithFirstCapitalLetter(t(userAccountStatus.account_status))}
                              </div>
                            </Col>
                            <Col xs={3} />
                          </Row>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={3} />
                            <Col xs={18}>
                              <div className="text-normal small mb-2">{t(_verification_status)}</div>
                              <div className={"fw-bold status-"+userAccountStatus.profile_status.toLowerCase()}>
                                {textWithFirstCapitalLetter(t(userAccountStatus.profile_status))}
                              </div>
                            </Col>
                            <Col xs={3} />
                          </Row>

                          {
                            userAccountStatus.profile_status_alerts != null && userAccountStatus.profile_status_alerts.length > 0 &&
                            <>

                              <Row gutter={[16]}>
                                <Col xs={3} />
                                <Col xs={18}><hr /></Col>
                                <Col xs={3} />
                              </Row>

                              <Row gutter={[16]} className="justify-content-center align-items-start">
                                <Col xs={3} />
                                <Col xs={18}>
                                  <div className="text-normal fw-bold small mb-2">{t(_indications)}</div>
                                  <div>
                                    <ul>
                                      {
                                        userAccountStatus.profile_status_alerts.map((element,index)=>(
                                          <li key={"indication-"+index} className="small">
                                            {getTextToDisplay2(element.message,"value")}
                                          </li>
                                        ))
                                      }
                                    </ul>
                                  </div>
                                </Col>
                                <Col xs={3} />
                              </Row>

                            </>
                          }

                          <Row gutter={[16]}>
                            <Col xs={3} />
                            <Col xs={18}><hr /></Col>
                            <Col xs={3} />
                          </Row>

                          <Link to={`/profile/account/kyc/identity`}>
                            <Row gutter={[16]} className="identity-element mt-2" title={t(_click_to_manage)}>
                              <Col xs={3} className="d-flex justify-content-center">
                                <AppstoreOutlined style={{fontSize:"25px"}} />
                              </Col>
                              <Col xs={17}>
                                <div className="text-normal fw-bold mb-2">{t(_my_identity)}</div>
                                <div className="fw-400 small">
                                  {t(_general_information)}
                                </div>
                              </Col>
                              <Col xs={4} className="d-flex justify-content-end">
                                {
                                  userIdentity != null &&
                                  <div className={"status-small-text status-"+userIdentity.status.toLowerCase()}>
                                    {textWithFirstCapitalLetter(t(userIdentity.status))}
                                  </div>
                                }
                              </Col>
                            </Row>
                          </Link>

                          <Link to={`/profile/account/kyc/incomes`}>
                            <Row gutter={[16]} className="identity-element" title={t(_click_to_manage)}>
                              <Col xs={3} className="d-flex justify-content-center">
                                <AppstoreOutlined style={{fontSize:"25px"}} />
                              </Col>
                              <Col xs={17}>
                                <div className="text-normal fw-bold mb-2">{t(_salary_and_incomes)}</div>
                                <div className="fw-400 small">
                                  {t(_salary_and_incomes_text)}
                                </div>
                              </Col>
                              <Col xs={4} className="d-flex justify-content-end">
                                {
                                  userIncomesInfo != null &&
                                  <div className={"status-small-text status-"+userIncomesInfo.status.toLowerCase()}>
                                    {textWithFirstCapitalLetter(t(userIncomesInfo.status))}
                                  </div>
                                }
                              </Col>
                            </Row>
                          </Link>

                          <Link to={`/profile/account/kyc/quota`}>
                            <Row gutter={[16]} className="identity-element" title={t(_click_to_manage)}>
                              <Col xs={3} className="d-flex justify-content-center">
                                <AppstoreOutlined style={{fontSize:"25px"}} />
                              </Col>
                              <Col xs={17}>
                                <div className="text-normal fw-bold mb-2">{t(_quota)}</div>
                                <div className="fw-400 small">
                                  {t(_average_monthly_transfer_volume)}
                                </div>
                              </Col>
                              <Col xs={4} className="d-flex justify-content-end align-items-start">
                                {
                                  userQuota != null &&
                                  <div className={"status-small-text status-"+userQuota.status.toLowerCase()}>
                                    {textWithFirstCapitalLetter(t(userQuota.status))}
                                  </div>
                                }
                              </Col>
                            </Row>
                          </Link>

                          <Row gutter={[16]}>
                            <Col xs={3} />
                            <Col xs={18}><hr /></Col>
                            <Col xs={3} />
                          </Row>

                          <Row gutter={[16]}>
                            <Col xs={3} />
                            <Col xs={17}>
                              <div className="text-normal fw-bold mb-2">{t(_additional_documents)}</div>
                              <a href={userAccountStatus.onboarding_url} target="_blank" rel="noopener noreferrer">
                                <Button className="special-button bg-green-kamix text-white" size="middle">
                                  {t(_add_dotfile_kyc)}
                                </Button>
                              </a>
                            </Col>
                            <Col xs={4} />
                          </Row>

                        </>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

            <div className="small mb-1 ml-5">{t(_danger_zone)}</div>
            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loadingUserInfos ? (
                    <Skeleton active avatar paragraph={{rows: 1}} />
                  ) : (
                    <>
                      <Row gutter={[16]} className="justify-content-center align-items-center">
                        <Col xs={3} className="d-flex align-items-center justify-content-center">
                          <DeleteOutlined style={{fontSize:"25px"}} />
                        </Col>
                        <Col xs={18}>
                          <div className="text-normal small mb-2">{t(_delete_my_kamix_profile)}</div>
                          <div className="">
                            <Button className="my-button-2 bg-red-kamix text-white" onClick={()=>setToggleDelete(t=>!t)}>
                              {t(_delete)}
                            </Button>
                          </div>
                        </Col>
                        <Col xs={3}/>
                      </Row>
                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>


        <Modal
          visible={toggle}
          onCancel={onToggle}
          title={<div className="fw-bold mb-0">{formatPhoneNumberIntl(""+mobileManager.item.mobile)}</div>}
          footer={null}
          closeIcon={<div className="d-flex justify-content-center mt-3"><CloseSquareOutlined style={{fontSize:"20px", color:"black"}} /></div>}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <div className="d-flex flex-column">

                {
                  mobileManager.switch === "main" &&
                  <div>
                    {
                      ! mobileManager.item.verified &&
                      <Row className="align-items-center mb-3">
                        <Col xs={3}>
                          <CheckCircleOutlined style={{fontSize:"20px"}} />
                        </Col>
                        <Col xs={21}>
                          <Button className="border-radius-kamix text-dark" onClick={(e)=>onSwitch('verify',e)}>
                            {t(_verify_mobile_number)}
                          </Button>
                        </Col>
                      </Row>
                    }
                    {
                      mobileManager.index !== 0 &&
                      <Row className="align-items-center mb-3">
                        <Col xs={3}>
                          <StarOutlined style={{fontSize:"20px"}}  />
                        </Col>
                        <Col xs={21}>
                          <Button className="border-radius-kamix text-dark" onClick={(e)=>onSwitch('mainPhoneNumber',e)}>
                            {t(_set_as_main_mobile)}
                          </Button>
                        </Col>
                      </Row>
                    }
                    <Row className="align-items-center mb-3">
                      <Col xs={3}>
                        <EditOutlined style={{fontSize:"20px"}}  />
                      </Col>
                      <Col xs={21}>
                        <Button className="border-radius-kamix text-dark" onClick={(e)=>onSwitch('edit',e)}>
                          {t(_edit_mobile_number)}
                        </Button>
                      </Col>
                    </Row>
                    {
                      mobileManager.index !==0 &&
                      <Row className="align-items-center">
                        <Col xs={3}>
                          <DeleteOutlined style={{fontSize:"20px"}} />
                        </Col>
                        <Col xs={21} style={{fontSize:"18px"}} >
                          <Button className="border-radius-kamix text-dark" onClick={(e)=>onSwitch('delete',e)}>
                            {t(_delete_mobile_number)}
                          </Button>
                        </Col>
                      </Row>
                    }
                  </div>
                }

                {
                  mobileManager.switch === "verify" &&
                  <div className="d-flex flex-column justify-content-center">

                    <h6 className="fw-bold mb-3">{t(_mobile_verification)}</h6>

                    {
                      loadingVerification ? (
                        <>
                          <Skeleton active paragraph={{rows: 4}} />
                        </>
                      ) : (
                        <div className="d-flex flex-column">

                          <div className="text-normal mb-3">
                            <span className="text-justify">{t(_send_code_by_sms_to)}</span> {" "}
                            <span className="fw-bold one-line">{mobileManager.item.mobile}</span>
                          </div>

                          <form onSubmit={(e)=>onsubmitCode(e)}>
                            <div>
                              <ReactCodeInput
                                ref={codeRef}
                                isValid={isCodeValid}
                                type='number'
                                fields={6}
                                value={code}
                                onChange={onChangeCode}
                                autoFocus={false}
                                {...codeInputProps}
                              />
                              {
                                !isCodeValid &&
                                <div className="position-absolute ant-form-item-explain-error mx-3">
                                  {code === "" && t(_required)}
                                  {code !== "" && code.length !== 6 && t(_code_invalid)}
                                </div>
                              }
                            </div>
                            <div className="mt-4 mb-3">
                              <Button type="text" loading={loadingResendCode} onClick={resendCode}>
                                <span className="text-underline">{t(_resend_code_text)}</span>
                              </Button>
                            </div>
                            <div className="d-flex align-items-start">
                              <Button className="border-radius-kamix text-dark mr-2" disabled={loadingCode} onClick={(e)=>onSwitch('main',e)}>
                                {t(_cancel)}
                              </Button>
                              <Button className="my-button-2 bg-yellow-kamix text-dark" loading={loadingCode} htmlType="submit">
                                {t(_continue)}
                              </Button>
                            </div>
                          </form>

                        </div>
                      )
                    }
                  </div>
                }

                {
                  mobileManager.switch === "mainPhoneNumber" &&
                  <Row className="justify-content-center">
                    <Col xs={3}>
                      <InfoCircleFilled style={{fontSize:"30px", color:"#ffc91e"}} />
                    </Col>
                    <Col xs={21}>
                      <h6 className="fw-bold">{t(_set_as_main_mobile)}</h6>
                      <div className="text-normal text-justify my-3">{t(_set_as_main_mobile_question)}</div>
                      <div className="d-flex">
                        <Button className="border-radius-kamix text-dark mr-2" disabled={loadingMain} onClick={()=>onSwitch('main')}>
                          {t(_cancel)}
                        </Button>
                        <Button className="my-button-2 bg-green-kamix text-white" loading={loadingMain} onClick={makeNumberMain}>
                          {t(_continue)}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                }

                {
                  mobileManager.switch === "edit" &&
                  <div>

                    <h6 className="fw-bold mb-3">{t(_set_as_main_mobile)}</h6>

                    <form onSubmit={(e)=>onEditPhoneNumber(e)}>
                      <div className="mb-3">

                        <div className="d-flex mb-2">
                          <div>
                            <small className="invisible">#</small>
                            <Select
                              className="my-select mt-1"
                              classNamePrefix="my-select"
                              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                              value={oldCountry}
                              autosize={false}
                              formatOptionLabel={(option, { context }) => {
                                return context === 'menu' ? option.label : option.label2;
                              }}
                              isDisabled
                            />
                          </div>
                          <div className="d-flex flex-column ml-2">
                            <small className="mb-1">{t(_old_mobile_number)}</small>
                            <Input
                              className="my-input text-dark"
                              type="number"
                              value={oldPhoneNumber}
                              size="large"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="d-flex">
                          <div>
                            <small className="invisible">#</small>
                            <Select
                              className="my-select mt-1 mr-2"
                              classNamePrefix="my-select"
                              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                              value={newCountry}
                              onChange={onChangeNewCountry}
                              options={getCountries()}
                              isSearchable
                              autosize={false}
                              getOptionLabel ={(option)=>option.name}
                              formatOptionLabel={(option, { context }) => {
                                return context === 'menu' ? option.label : option.label2;
                              }}
                            />
                          </div>
                          <div>
                            <small className="mb-1">{t(_new_mobile_number)}</small>
                            <Input
                              className="my-input"
                              type="number"
                              value={newPhoneNumber}
                              onChange={onChangeNewPhoneNumber}
                              status={isNewPhoneNumberValid ? "success" : "error"}
                              size="large"
                              placeholder={t(_mobile_number)}
                            />
                            {
                              !isNewPhoneNumberValid &&
                              <div className="position-absolute ant-form-item-explain-error">
                                {newPhoneNumber === "" && t(_required)}
                                {newPhoneNumber !== "" && !isValidPhoneNumber(newPhoneNumber) && t(_invalid_phone_number)}
                              </div>
                            }
                          </div>
                        </div>

                      </div>
                      <div className="d-flex mt-4">
                        <Button className="border-radius-kamix text-dark mr-2" disabled={loadingMain} onClick={()=>onSwitch('main')}>
                          {t(_cancel)}
                        </Button>
                        <Button className="my-button-2 bg-yellow-kamix text-dark" loading={loadingEdition} htmlType="submit">
                          {t(_edit)}
                        </Button>
                      </div>
                    </form>
                  </div>
                }

                {
                  mobileManager.switch === "delete" &&
                  <Row className="justify-content-center">
                    <Col xs={3}>
                      <InfoCircleFilled style={{fontSize:"30px", color:"#BF2626"}} />
                    </Col>
                    <Col xs={21}>
                      <h6 className="fw-bold">{t(_delete_mobile_number)}</h6>
                      <div className="text-normal text-justify my-3">{t(_delete_mobile_number_question)}</div>
                      <div className="d-flex">
                        <Button className="border-radius-kamix text-dark mr-2" disabled={loadingDeletion} onClick={()=>onSwitch('main')}>
                          {t(_cancel)}
                        </Button>
                        <Button className="my-button-2 bg-red-kamix text-white" loading={loadingDeletion} onClick={deleteMobileNumber}>
                          {t(_delete)}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                }

              </div>

            </Col>
          </Row>

        </Modal>

        <Modal
          visible={toggleDelete}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <Row className="justify-content-center">
                <Col xs={3}>
                  <WarningOutlined style={{fontSize:"30px", color:"#BF2626"}} />
                </Col>
                <Col xs={21}>
                  <h6 className="fw-bold">{t(_delete_my_kamix_profile)}</h6>
                  <div className="text-normal text-justify my-3">{t(_delete_my_kamix_profile_question)}</div>
                  <form onSubmit={(e)=>deleteKamixAccount(e)}>
                    <div className="mb-3">
                      <Input.Password
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="my-input"
                        size="large"
                        placeholder={t(_password)}
                        required
                      />
                    </div>
                    <div className="d-flex">
                      <Button className="border-radius-kamix text-dark mr-2" disabled={loadingDelete} onClick={()=>setToggleDelete(t=>!t)}>
                        {t(_cancel)}
                      </Button>
                      <Button className="my-button-2 bg-red-kamix text-white" loading={loadingDelete} htmlType="submit">
                        {t(_delete)}
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>

            </Col>
          </Row>

        </Modal>

      </Col>
    </Row>
  )

}

export default withRouter(MyAccount);