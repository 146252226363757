
import React from "react";
import axios from 'axios';
import {toast} from "react-toastify";
import {sessionStorage, localStorage} from './Security';
import i18n from "./i18n";
import detect from "detect.js";
import countryList from "country-list";
import {formatPhoneNumberIntl, getCountryCallingCode, isSupportedCountry} from "react-phone-number-input";
import {Modal} from "antd";
import {CheckOutlined, EllipsisOutlined, CloseOutlined, RollbackOutlined} from "@ant-design/icons";
//import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import getBrowserFingerprint from 'get-browser-fingerprint';
import creditCardType from "credit-card-type";
//------------------------------------------------------------------------------------------
import cc_ico_visa from "./assets/credit_card_logos/cc_ico_visa.png";
import cc_ico_mastercard from "./assets/credit_card_logos/cc_ico_mastercard.png";
import cc_ico_maestro from "./assets/credit_card_logos/cc_ico_maestro.png";
import american_express from "./assets/credit_card_logos/cc_ico_american_express.png";
import cc_ico_diners_club from "./assets/credit_card_logos/cc_ico_diners_club.png";
import cc_ico_discover from "./assets/credit_card_logos/cc_ico_discover.png";
import cc_ico_elo from "./assets/credit_card_logos/cc_ico_elo.png";
import cc_ico_hipercard from "./assets/credit_card_logos/cc_ico_hipercard.png";
import cc_ico_mir from "./assets/credit_card_logos/cc_ico_mir.png";
import cc_ico_unionpay from "./assets/credit_card_logos/cc_ico_unionpay.png";
//------------------------------------------------------------------------------------------
import {
  _error_message_user_not_exists, _something_went_wrong, _error_message_default, _error_message_not_online,
  _failed_to_connect_to_server, _log_back, _log_in_again, formatter, _error_message_91, _error_message_92,
  _error_message_93, _error_message_95, _error_message_99, _error_message_100, _error_message_101, _error_message_102,
  _error_message_103, _error_message_104, _error_message_105, _error_message_106, _error_message_107, _error_message_108,
  _error_message_109, _error_message_110, _error_message_111, _error_message_112, _error_message_113, _error_message_114,
  _error_message_115, _error_message_116, _error_message_117, _error_message_118, _error_message_119, _error_message_120,
  _error_message_121, _error_message_122, _error_message_123, _error_message_124, _error_message_125, _error_message_126,
  _error_message_127, _error_message_200, _error_message_201, _error_message_202, _error_message_203, _error_message_204,
  _error_message_205, _error_message_206, _error_message_207, _error_message_302, _error_message_303, _error_message_304,
  _error_message_305, _error_message_306, _error_message_307, _error_message_308, _error_message_309, _error_message_310,
  _error_message_311, _error_message_312, _error_message_313, _error_message_400, _error_message_401, _error_message_402,
  _error_message_403, _error_message_404, _error_message_405, _error_message_406, _error_message_407, _error_message_408,
  _error_message_409, _error_message_410, _error_message_411, _error_message_412, _error_message_413, _error_message_414,
  _error_message_415, _error_message_416, _error_message_417, _error_message_418, _error_message_419, _error_message_420,
  _error_message_421, _error_message_422, _error_message_128, _error_message_129, _error_message_130, _error_message_131,
  _error_message_132, _error_message_423, _error_message_424, _error_message_425, _error_message_426, _error_message_427,
  _error_message_429, _error_message_503, _error_message_504, countryNameInLang
} from "./UsefulConstants";


export function WarningModal(title,message){
  Modal.warning({
    title:title,
    content: message,
    maskClosable:true,
    okButtonProps:{className:"ok-button"}
  });
}

export function setUserCredentials(oldUserCredentials,user){
  let data = {};
  data.user = user != null ? user : oldUserCredentials.user;
  data.token = oldUserCredentials.token;
  data.device = oldUserCredentials.device;
  data.refreshLimit = oldUserCredentials.counter;
  sessionStorage.delete('userCredentials');
  sessionStorage.set('userCredentials', data);
}

export function setRegisterInformation(oldRegisterInformation,user,step){
  let data = {};
  data.user = user != null ? user : oldRegisterInformation.user;
  data.token = oldRegisterInformation.token;
  data.device = oldRegisterInformation.device;
  if(step === 4) data.step4 = "ok";
  if(step === 5) data.step5 = "ok";
  sessionStorage.delete('registerInformation');
  sessionStorage.set('registerInformation', data);
}

export function callableCountries() {
  const allCountries = Array.from(countryList.getData()).map(x => x.code);
  let callableCountries = [];
  for (const country of allCountries) {
    try {
      getCountryCallingCode(country);
      callableCountries.push(country);
    } catch (e) {}
  }
  return callableCountries;
}

export function getCountries(){

  // Authorized Countries list
  //--------------------------------------------
  /*const authorizedCountriesCodes = [
    'CM','BE','BG','CZ','DK','DE','EE','IE','GR','ES',
    'FR','HR','IT','CY','LV','LT','LU','HU','MT','NL',
    'AT','PL','PT','RO','SI','SK','FI','SE','IS','LI',
    'NO','CH','GB','US','CA','JP'];*/
  const authorizedCountriesCodes = callableCountries();
  let authorizedCountries = new Array(authorizedCountriesCodes.length);
  for (let i = 0; i < authorizedCountriesCodes.length; i++) {
    authorizedCountries[i] = {
      name: countryNameInLang.getName(authorizedCountriesCodes[i],getLanguage()),
      code: authorizedCountriesCodes[i],
      isOk: true,
      cc: getCountryCallingCode(authorizedCountriesCodes[i]),
    }
  }
  //--------------------------------------------
  for (let c of authorizedCountries){
    c.value = c.code;
    c.label =
      <div className="flag">
        <img alt={"flag"} className="icon" src={require(`./assets/flags/${c.code.toLowerCase()}.svg`).default} />
        <span className="text">{c.name}</span>
      </div>
    ;
    c.label2 =
      <div className="flag">
        <img alt={"flag"} className="icon" src={require(`./assets/flags/${c.code.toLowerCase()}.svg`).default} />
        <span className="text">+ {getCountryCallingCode(c.code)}</span>
      </div>
    ;
  }
  //--------------------------------------------
  return authorizedCountries;

}

export function getAllCountries() {

  // All Countries list
  //--------------------------------------------
  let data = Array.from(countryList.getData());
  let goodData = [];
  for(let c of data){
    if(isSupportedCountry(c.code)){
      c.value = c.code;
      c.label =
        <div className="flag">
          <img alt={"flag"} className="icon" src={require(`./assets/flags/${c.code.toLowerCase()}.svg`).default} />
          <span className="text">{countryNameInLang.getName(c.code,i18n.language)}</span>
        </div>
      ;
      c.label2 =
        <div className="flag">
          <img alt={"flag"} className="icon" src={require(`./assets/flags/${c.code.toLowerCase()}.svg`).default} />
          <span className="text">+ {getCountryCallingCode(c.code)}</span>
        </div>
      ;
      goodData.push(c);
    }
  }
  //--------------------------------------------
  return goodData;

}

export function getCountryFromCountryCode(countryCode){
  let name = countryNameInLang.getName(countryCode,getLanguage());
  return {
    name: name,
    code: countryCode,
    value: countryCode,
    label:
      <div className="flag">
        <img alt={"flag"} className="icon" src={require(`./assets/flags/${countryCode.toLowerCase()}.svg`).default} />
        <span className="text">{name}</span>
      </div>,
    label2:
      <div className="flag">
        <img alt={"flag"} className="icon" src={require(`./assets/flags/${countryCode.toLowerCase()}.svg`).default} />
        <span className="text">+ {getCountryCallingCode(countryCode)}</span>
      </div>,
    label3:
      <div className="flag">
        <img alt={"flag"} className="icon" src={require(`./assets/flags/${countryCode.toLowerCase()}.svg`).default} />
        <span>{name}</span>
      </div>,
  }
}

export function notification(type,message,autoClose){
  return (
    toast(
      <div>
        <span className="text-center">{message}</span> <br/>
      </div>,
      {
        position: "top-right",
        autoClose: autoClose != null ? autoClose : 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type:type
      })
  )
}

export function getName(user){
  let name = "";
  if(user != null){
    name = user.lastname != null ? user.lastname.toUpperCase() : "";
    name = user.firstname != null ? textWithFirstCapitalLetter(user.firstname) +" "+ name : "";
  }
  return name;
}

export function getReceiverV3Name (receiver) {
  return receiver?.surname || receiver?.receiver?.full_name || getReceiverV3Mobile(receiver);
}

export function getReceiverV3Mobile (receiver) {
  return formatPhoneNumberIntl('' + receiver?.receiver?.receiver_id)
}

export function hashCode(element){
  let hash = 0, i, chr;
  if (element.length === 0) return hash;
  for (i = 0; i < element.length; i++) {
    chr   = element.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function getBadgeInfos(user){

  let _colors = [
    {color:"red", hex: "#F44336"},
    {color:"pink", hex: "#E91E63"},
    {color:"deepPurple", hex: "#673AB7"},
    {color:"indigo", hex: "#3F51B5"},
    {color:"blue", hex: "#2196F3"},
    {color:"teal", hex: "#009688"},
    {color:"green", hex: "#4CAF50"},
    {color:"amber", hex: "#FFC107"},
    {color:"orange", hex: "#FF9800"},
    {color:"brown", hex: "#795548"},
  ];

  let source = typeof(user) === "object" ?  getName(user) : user;
  let parts = source.split(" ");
  let initiales = "";

  if (parts.length === 1) {
    initiales = parts[0].substring(0, 2);
  } else {
    initiales = parts[0].substring(0, 1) + parts[parts.length-1].substring(0, 1);
  }

  let index = hashCode(initiales) % _colors.length;

  return [initiales,_colors[index]];
}

export function textWithFirstCapitalLetter(name){
  return name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
}

export function isSender(transaction){
  let userCredentials = sessionStorage.get('userCredentials');
  let userId = userCredentials.user.kid;
  let result = false;
  if(transaction != null && transaction.sender != null && transaction.sender.kid === userId) result = true;
  return result;
}

export function isReceiver(transaction){
  let userCredentials = sessionStorage.get('userCredentials');
  let userId = userCredentials.user.kid;
  let result = false;
  if(transaction != null && transaction.receiver != null && transaction.receiver.kid === userId) result = true;
  return result;
}

export function getTextColorByStatus(status){
  let textColor = "text-";
  switch (status) {
    case "done":
      textColor = textColor + "green-kamix";
      break;
    case "failed":
      textColor = textColor + "danger";
      break;
    case "pending":
      textColor = textColor + "dark";
      break;
    case "rollback":
      textColor = textColor + "dark";
      break;
    case "sent":
      textColor = textColor + "warning-kamix";
      break;
    case "paid":
      textColor = textColor + "warning-kamix";
      break;
    default:
      textColor = textColor + "dark";
      break;
  }
  return textColor;
}

export function getAmountColorByTypeAndStatus(type,status,transaction){
  let amountColor = "text-";
  switch (type) {
    case "subscription":
      amountColor = amountColor + "danger";
      break;
    case "fund":
      if(status === "done") amountColor = amountColor + "green-kamix";
      else amountColor = amountColor + "darker";
      break;
    case "buy":
      if(status === "done") amountColor = amountColor + "green-kamix";
      else amountColor = amountColor + "darker";
      break;
    case "transfer":
      if(status === "done"){
        if(isSender(transaction)) amountColor = amountColor + "danger";
        else amountColor = amountColor + "green-kamix";
      }else{
        amountColor = amountColor + "darker";
      }
      break;
    case "refund":
      if(status === "done") amountColor = amountColor + "danger";
      else amountColor = amountColor + "darker";
      break;
    default:
      amountColor = amountColor + "darker";
      break;
  }
  return amountColor;
}

export function getTextToDisplayByTypeAndStatus(type,status,transaction,cryptoSymbols){
  let text = "";
  switch (type) {
    case "subscription":
      text = i18n.t('dt11');
      break;
    case "fund":
      if(status === "pending") text = i18n.t("dt1");
      if(status === "failed") text = i18n.t("dt14");
      if(status === "done") text = i18n.t("dt6");
      if(status === "paid") text = i18n.t("dt16");
      if(status === "cancelled") text = i18n.t("dt17");
      break;
    case "buy":
      if(status === "pending") text = i18n.t("dt2");
      if(status === "done") text = i18n.t("dt8");
      if(status === "failed") text = i18n.t("dt14");
      if(status === "cancelled") text = i18n.t("dt17");
      break;
    case "transfer":
      if(status === "pending") text = i18n.t("dt3");
      if(status === "sent") text = i18n.t("dt5");
      if(status === "done" && isReceiver(transaction)) text = i18n.t("dt13");
      if(status === "done" && isSender(transaction)){
        if(transaction.direct_receive) text = i18n.t("dt9");
        else text = i18n.t("dt10");
      }
      if(status === "failed") text = i18n.t("dt14");
      if(status === "rollback") text = i18n.t("dt15");
      if(status === "cancelled") text = i18n.t("dt17");
      break;
    case "refund":
      if(status === "pending") text = i18n.t("dt4");
      if(status === "paid") text = i18n.t("dt7");
      if(status === "done") text = i18n.t("dt12");
      if(status === "failed") text = i18n.t("dt14");
      if(status === "cancelled") text = i18n.t("dt17");
      break;
    default:
      break;
  }
  return text.replace("XXX",cryptoSymbols);
}

export function getStepper(type,status,transaction,cryptoSymbols){
  let stepper = {};
  switch (type) {
    case "fund":
      //-----------------------------------------------------------------------
      if(status === "pending"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt2").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"><EllipsisOutlined style={{fontSize:"12px", transform:"rotate(90deg)"}} /></span>
        });
        table.push({
          label:i18n.t("txt4").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 1;
      }
      //-----------------------------------------------------------------------
      if(status === "failed"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt12").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-danger"><CloseOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt4").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 1;
      }
      //-----------------------------------------------------------------------
      if(status === "done"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt9").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt4").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        stepper.table = table;
        stepper.activeStep = 3;
      }
      //-----------------------------------------------------------------------
      if(status === "paid"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt9").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt14").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 2;
      }
      //-----------------------------------------------------------------------
      break;
    case "buy":
      //-----------------------------------------------------------------------
      if(status === "pending"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt2").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"><EllipsisOutlined style={{fontSize:"12px", transform:"rotate(90deg)"}} /></span>
        });
        table.push({
          label:i18n.t("txt5").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 1;
      }
      //-----------------------------------------------------------------------
      if(status === "done"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt8").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt5").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        stepper.table = table;
        stepper.activeStep = 3;
      }
      //-----------------------------------------------------------------------
      if(status === "failed"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt12").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-danger"><CloseOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt5").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 1;
      }
      //-----------------------------------------------------------------------
      break;
    case "transfer":
      if(status === "pending"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt2").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"><EllipsisOutlined style={{fontSize:"12px", transform:"rotate(90deg)"}} /></span>
        });
        table.push({
          label:i18n.t(transaction.direct_receive ? "txt7": "txt6").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 1;
      }
      //-----------------------------------------------------------------------
      if(status === "sent"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt8").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt11").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 2;
      }
      //-----------------------------------------------------------------------
      if(status === "done" && isSender(transaction)){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt8").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t(transaction.direct_receive ? "txt7": "txt6").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        stepper.table = table;
        stepper.activeStep = 3;
      }
      //-----------------------------------------------------------------------
      if(status === "failed"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt12").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-danger"><CloseOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t(transaction.direct_receive ? "txt7" : "txt6").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 1;
      }
      //-----------------------------------------------------------------------
      if(status === "rollback"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt8").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt13").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-danger"><RollbackOutlined style={{fontSize:"12px"}} /></span>
        });
        stepper.table = table;
        stepper.activeStep = 2;
      }
      //-----------------------------------------------------------------------
      break;
    case "refund":
      //-----------------------------------------------------------------------
      if(status === "pending"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt2").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"><EllipsisOutlined style={{fontSize:"12px", transform:"rotate(90deg)"}} /></span>
        });
        table.push({
          label:i18n.t("txt7").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 1;
      }
      //-----------------------------------------------------------------------
      if(status === "paid"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt8").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt11").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 2;
      }
      //-----------------------------------------------------------------------
      if(status === "done"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt8").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt7").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        stepper.table = table;
        stepper.activeStep = 3;
      }
      //-----------------------------------------------------------------------
      if(status === "failed"){
        let table = [];
        table.push({
          label:i18n.t("txt1").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-green-kamix"><CheckOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt12").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-danger"><CloseOutlined style={{fontSize:"12px"}} /></span>
        });
        table.push({
          label:i18n.t("txt7").replace("XXX",cryptoSymbols),
          icon:<span className="circle-stepper bg-gray-kamix"/>
        });
        stepper.table = table;
        stepper.activeStep = 1;
      }
      //-----------------------------------------------------------------------
      break;
    default:
      stepper = null;
      break;
  }
  return stepper;
}

export function getLanguage(){
  return i18n.language;
}

export function getDate(time){

  let date = new Date(time);
  let result = {};

  let day = date.getDate();
  let min = date.getMinutes();
  let hour = date.getHours();
  let month = date.getMonth();
  let year = date.getFullYear();

  if(min.toString().length === 1) min = "0" + min;
  if(hour.toString().length === 1) hour = "0" + hour;
  if(day.toString().length === 1) day = "0" + day;
  if(month.toString().length === 1) month = "0" + month;

  result.day = day;
  result.month = month;
  result.year = year;
  result.yearPlusOne = year +1;
  result.hour = hour + ":" + min;
  result.minDate = day+"/"+month+"/"+year;
  result.fullDate = day+"/"+month+"/"+year+", "+ hour + ":" + min;

  return result;
}

export function getAmountToDisplay(amount,currency,format){
  let display = "";
  if(currency === "EUR"){
    display = Math.ceil(amount*100)/100;
  }else{
    display = Math.floor(amount);
  }
  if(format != null && format) display = formatter.format(display);
  return display.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function getAmountToDisplay2(amount){
  //----------------------------------------------
  let display = {};
  let eurToXaf = process.env.REACT_APP_EUR_TO_XAF;
  let amountInXaf = amount * eurToXaf;
  //----------------------------------------------
  let a = amountInXaf / 50;
  let dc = a - Math.floor(a);
  if (dc > 0.97) a = Math.ceil(a);
  else a = Math.floor(a);
  //----------------------------------------------
  display.decision = ((amountInXaf / 50) % 1) !== 0;
  display.eur = getAmountToDisplay3((a * 50)/eurToXaf,"EUR");
  display.xaf = a * 50;
  //----------------------------------------------
  return display;
}

export function getAmountToDisplay3(amount,currency){
  if(currency === "EUR"){
    return Math.ceil(amount*100)/100;
  }else{
    return Math.floor(amount);
  }
}

export function isToday(date){
  let today = new Date();
  let someDate = new Date(date);
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
}

export function isYesterday(date){
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  let someDate = new Date(date);
  return (
    someDate.getDate() === yesterday.getDate() &&
    someDate.getMonth() === yesterday.getMonth() &&
    someDate.getFullYear() === yesterday.getFullYear()
  );
}

export function getSplitMonth(month){
  let result = month;
  if(month.toString().length > 4) result = month.substring(0,3);
  if(month.toString().toLowerCase().includes("juillet")) result = month.substring(0,4);
  return result;
}

export function getDevicePlatform(name){
  let result = "";
  let deviceName = name.toString().toLowerCase();
  if(deviceName.includes('windows')){
    result = "windows";
  }else if(deviceName.includes("linux")){
    result = "linux";
  }else if(deviceName.includes("ios") || deviceName.includes("macos")){
    result = "macos"
  }else{
    result = "other"
  }
  return result;
}

export function groupBy(objectArray, property){
  return objectArray.reduce(function (accumulator, currentObject) {
    let key = currentObject[property];
    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    accumulator[key].push(currentObject);
    return accumulator;
  }, {});
}

export function getTextToDisplay2(display_names,item){
  let result  = "";
  if(display_names != null){
    for (let i = 0; i < display_names.length; i++) {
      if(display_names[i].lang === getLanguage()){
        return display_names[i][item != null ? item : "display_name"];
      }
    }
    if(display_names.length !== 0 && display_names[0] != null && display_names[0].display_name !== ""){
      result = display_names[0][item != null ? item : "display_name"];
    }
  }
  return result;
}

/*export async function getBrowserFingerPrint() {
  const fpPromise = FingerprintJS.load({apiKey: 'hU9omNcGzC6uSyhePmG8', region: 'eu'});
  let fingerprint = localStorage.get('fingerprint');
  if (fingerprint == null || fingerprint === "") {
    const fp = await fpPromise;
    const result = await fp.get();
    localStorage.set('fingerprint', result.visitorId);
    return result;
  } else {
    return fingerprint;
  }
}*/

export function getBrowserFingerPrint(){
  let fingerprint = localStorage.get('fingerprint');
  if (fingerprint == null || fingerprint === "") {
    const result = getBrowserFingerprint();
    localStorage.set('fingerprint', result);
    return result;
  } else {
    return fingerprint;
  }
}

export function getCreditCardLogo(card_scheme){
  let card_image= null;
  switch (card_scheme) {
    case "VISA":
      card_image = cc_ico_visa;
      break;
    case "MASTERCARD":
      card_image = cc_ico_mastercard;
      break;
    case "MAESTRO":
      card_image = cc_ico_maestro;
      break;
    case "AMERICAN EXPRESS":
      card_image = american_express;
      break;
    case "DINERS CLUB":
      card_image = cc_ico_diners_club;
      break;
    case "DISCOVER":
      card_image = cc_ico_discover;
      break;
    case "ELO":
      card_image = cc_ico_elo;
      break;
    case "HIPERCARD":
      card_image = cc_ico_hipercard;
      break;
    case "HIPER":
      card_image = null;
      break;
    case "JCB":
      card_image = null;
      break;
    case "MIR":
      card_image = cc_ico_mir;
      break;
    case "UNIONPAY":
      card_image = cc_ico_unionpay;
      break;
    default:
      break;
  }
  return card_image;
}

export function displayCreditCardNumber (firstSix, lastFour, cardType){
  if (!cardType) {
    return "--"
  }

  let cardInfo = creditCardType.getTypeInfo(cardType.toLowerCase());
  let cardNumberLength = cardInfo?.lengths != null ? Math.min(...cardInfo.lengths) : 16;

  let middleCN = "";
  for (let i=0; i<cardNumberLength-10; i++) middleCN = middleCN + "*";
  let cardNumber = firstSix + middleCN + lastFour;

  if(cardInfo){
    let offsets = [0].concat(cardInfo.gaps).concat([cardNumberLength]);
    let components = [];
    for (let i = 0; offsets[i] < cardNumber.length; i++) {
      let start = offsets[i];
      let end = Math.min(offsets[i + 1], cardNumber.length);
      components.push(cardNumber.substring(start, end));
    }

    return components.join(" ");
  }

  return cardNumber;
}

export function creditCardStatus (creditCard) {
  if (creditCard.deleted === true) return "DELETED";
  else if (creditCard.card_type != null) return "VALIDATED";
  else return "PENDING";
}

export function cardExpiryDate (expiryMonth, expiryYear) {
  if (expiryMonth && expiryYear) {
    return expiryMonth.toString().padStart(2, "0") + "/" + expiryYear.toString().substring(expiryYear.toString().length - 2)
  }
  else if (expiryYear) {
    return expiryYear.toString();
  }
  return "--";
}

function getError(data,history){
  let lang = getLanguage();
  if(data.error != null){
    //--------------------------------------------------------------------------
    let code = data.error.code;
    let display_messages = data.error.display_messages;
    //--------------------------------------------------------------------------
    if(display_messages != null){
      notification(
        "warning",
        (display_messages[lang] != null && display_messages[lang] !== "") ?
          display_messages[lang] : i18n.t(_error_message_default)
      );
    }else{
      if(code){
        switch (code){
          case 10:
            notification("warning",i18n.t(_error_message_default));
            break;
          case 11:
            notification("warning",i18n.t(_error_message_default));
            break;
          case 90:
            notification("warning",i18n.t(_error_message_user_not_exists));
            break;
          case 91:
            notification("warning",i18n.t(_error_message_91));
            break;
          case 92:
            notification("warning",i18n.t(_error_message_92));
            break;
          case 93:
            notification("warning",i18n.t(_error_message_93));
            break;
          case 94:
            notification("warning",i18n.t(_error_message_default));
            sessionStorage.delete('userCredentials');
            history.push('/sign-in');
            break;
          case 95:
            notification("warning",i18n.t(_error_message_95));
            sessionStorage.delete('userCredentials');
            history.push('/sign-in');
            break;
          case 97:
            notification("warning",i18n.t(_error_message_default));
            sessionStorage.delete('userCredentials');
            history.push('/sign-in');
            break;
          case 98:
            notification("warning",i18n.t(_log_back));
            sessionStorage.delete('userCredentials');
            history.push('/sign-in');
            break;
          case 99:
            notification("warning",i18n.t(_error_message_99));
            sessionStorage.delete('userCredentials');
            history.push('/sign-in');
            break;
          case 100:
            notification("warning",i18n.t(_error_message_100));
            break;
          case 101:
            notification("warning",i18n.t(_error_message_101));
            break;
          case 102:
            notification("warning",i18n.t(_error_message_102));
            break;
          case 103:
            notification("warning",i18n.t(_error_message_103));
            break;
          case 104:
            notification("warning",i18n.t(_error_message_104));
            break;
          case 105:
            notification("warning",i18n.t(_error_message_105));
            break;
          case 106:
            notification("warning",i18n.t(_error_message_106));
            break;
          case 107:
            notification("warning",i18n.t(_error_message_107));
            break;
          case 108:
            notification("warning",i18n.t(_error_message_108));
            break;
          case 109:
            notification("warning",i18n.t(_error_message_109));
            break;
          case 110:
            notification("warning",i18n.t(_error_message_110));
            break;
          case 111:
            notification("warning",i18n.t(_error_message_111));
            break;
          case 112:
            notification("warning",i18n.t(_error_message_112));
            break;
          case 113:
            notification("warning",i18n.t(_error_message_113));
            break;
          case 114:
            notification("warning",i18n.t(_error_message_114));
            break;
          case 115:
            notification("warning",i18n.t(_error_message_115));
            break;
          case 116:
            notification("warning",i18n.t(_error_message_116));
            break;
          case 117:
            notification("warning",i18n.t(_error_message_117));
            break;
          case 118:
            notification("warning",i18n.t(_error_message_118));
            break;
          case 119:
            notification("warning",i18n.t(_error_message_119));
            break;
          case 120:
            notification("warning",i18n.t(_error_message_120));
            break;
          case 121:
            notification("warning",i18n.t(_error_message_121));
            break;
          case 122:
            notification("warning",i18n.t(_error_message_122));
            break;
          case 123:
            notification("warning",i18n.t(_error_message_123));
            break;
          case 124:
            notification("warning",i18n.t(_error_message_124));
            break;
          case 125:
            notification("warning",i18n.t(_error_message_125));
            break;
          case 126:
            notification("warning",i18n.t(_error_message_126));
            break;
          case 127:
            notification("warning",i18n.t(_error_message_127));
            break;
          case 128:
            notification("warning",i18n.t(_error_message_128));
            break;
          case 129:
            notification("warning",i18n.t(_error_message_129));
            break;
          case 130:
            notification("warning",i18n.t(_error_message_130));
            break;
          case 131:
            notification("warning",i18n.t(_error_message_131));
            break;
          case 132:
            notification("warning",i18n.t(_error_message_132));
            break;
          case 200:
            notification("warning",i18n.t(_error_message_200));
            break;
          case 201:
            notification("warning",i18n.t(_error_message_201));
            break;
          case 202:
            notification("warning",i18n.t(_error_message_202));
            break;
          case 203:
            notification("warning",i18n.t(_error_message_203));
            break;
          case 204:
            notification("warning",i18n.t(_error_message_204));
            break;
          case 205:
            notification("warning",i18n.t(_error_message_205));
            break;
          case 206:
            notification("warning",i18n.t(_error_message_206));
            break;
          case 207:
            notification("warning",i18n.t(_error_message_207));
            break;
          case 300:
            notification("warning",i18n.t(_something_went_wrong));
            break;
          case 301:
            notification("warning",i18n.t(_something_went_wrong));
            break;
          case 302:
            notification("warning",i18n.t(_error_message_302));
            break;
          case 303:
            notification("warning",i18n.t(_error_message_303));
            break;
          case 304:
            notification("warning",i18n.t(_error_message_304));
            break;
          case 305:
            notification("warning",i18n.t(_error_message_305));
            break;
          case 306:
            notification("warning",i18n.t(_error_message_306));
            break;
          case 307:
            notification("warning",i18n.t(_error_message_307));
            break;
          case 308:
            notification("warning",i18n.t(_error_message_308));
            break;
          case 309:
            notification("warning",i18n.t(_error_message_309));
            break;
          case 310:
            notification("warning",i18n.t(_error_message_310));
            break;
          case 311:
            notification("warning",i18n.t(_error_message_311));
            break;
          case 312:
            notification("warning",i18n.t(_error_message_312));
            break;
          case 313:
            notification("warning",i18n.t(_error_message_313));
            break;
          case 400:
            notification("warning",i18n.t(_error_message_400));
            break;
          case 401:
            notification("warning",i18n.t(_error_message_401));
            sessionStorage.delete('userCredentials');
            history.push('/sign-in');
            break;
          case 402:
            notification("warning",i18n.t(_error_message_402));
            break;
          case 403:
            notification("warning",i18n.t(_error_message_403));
            break;
          case 404:
            notification("warning",i18n.t(_error_message_404));
            sessionStorage.delete('userCredentials');
            history.push('/sign-in');
            break;
          case 405:
            notification("warning",i18n.t(_error_message_405));
            break;
          case 406:
            notification("warning",i18n.t(_error_message_406));
            break;
          case 407:
            notification("warning",i18n.t(_error_message_407));
            break;
          case 408:
            notification("warning",i18n.t(_error_message_408));
            break;
          case 409:
            notification("warning",i18n.t(_error_message_409));
            break;
          case 410:
            notification("warning",i18n.t(_error_message_410));
            break;
          case 411:
            notification("warning",i18n.t(_error_message_411));
            break;
          case 412:
            notification("warning",i18n.t(_error_message_412));
            break;
          case 413:
            notification("warning",i18n.t(_error_message_413));
            break;
          case 414:
            notification("warning",i18n.t(_error_message_414));
            break;
          case 415:
            notification("warning",i18n.t(_error_message_415));
            break;
          case 416:
            notification("warning",i18n.t(_error_message_416));
            break;
          case 417:
            notification("warning",i18n.t(_error_message_417));
            break;
          case 418:
            notification("warning",i18n.t(_error_message_418));
            break;
          case 419:
            notification("warning",i18n.t(_error_message_419));
            break;
          case 420:
            notification("warning",i18n.t(_error_message_420));
            break;
          case 421:
            notification("warning",i18n.t(_error_message_421));
            break;
          case 422:
            notification("warning",i18n.t(_error_message_422));
            break;
          case 423:
            notification("warning",i18n.t(_error_message_423));
            break;
          case 424:
            notification("warning",i18n.t(_error_message_424));
            break;
          case 425:
            notification("warning",i18n.t(_error_message_425));
            break;
          case 426:
            notification("warning",i18n.t(_error_message_426));
            break;
          case 427:
            notification("warning",i18n.t(_error_message_427));
            break;
          case 428:
            notification("warning",i18n.t(_something_went_wrong));
            break;
          case 429:
            notification("warning",i18n.t(_error_message_429));
            break;
          case 500:
            notification("warning",i18n.t(_something_went_wrong));
            break;
          case 502:
            notification("warning",i18n.t(_something_went_wrong));
            break;
          case 503:
            notification("warning",i18n.t(_error_message_503));
            break;
          case 504:
            notification("warning",i18n.t(_error_message_504));
            break;
          case 505:
            notification("warning",i18n.t(_something_went_wrong));
            break;
          case 506:
            notification("warning",i18n.t(_something_went_wrong));
            break;
          default:
            break;
        }
      }
    }
  }
    //--------------------------------------------------------------------------
}

function getErrorAdmin(data,history){
  let error_code = data.error_code;
  if (error_code === 5000){
    notification("warning",i18n.t(_failed_to_connect_to_server));
  }else{
    notification("warning",i18n.t(_something_went_wrong));
  }
}

export function remoteCallFunction2(route,meth,queryParam,bodyParam,cancel,history){

  let config = {
    url: process.env.REACT_APP_URL_ADMIN + route,
    method:meth,
    headers:{},
    cancelToken:cancel
  };

  if(queryParam != null) config.params = queryParam;
  if(bodyParam != null) config.data = bodyParam;

  return axios.request(config)
    .then(response =>{
      getErrorAdmin(response.data,history);
      return response.data;
    }).catch(err =>{
      if (axios.isCancel(err)) {
      } else {
        if (err.response && err.response.status === 404){
          history.push('/404');
        }else if (err.response && err.response.data != null){
          getErrorAdmin(err.response.data,history);
        }else{

        }
      }
    });

}

export default function remoteCallFunction(auth,route,met,contentType,queryParam,bodyParam,cancelTok,history,blob){

  let deviceLang = getLanguage();
  let ua = detect.parse(window.navigator.userAgent);
  let deviceName = ua.browser.name + " / " + ua.os.name;

  let headers = {};
  headers['conn-type'] = 'web';
  headers['device_type'] = 'web';
  headers['timestamp'] = new Date().getTime();
  headers['device_lang'] = deviceLang;
  headers['device_name'] = deviceName;
  headers['device_platform'] = getDevicePlatform(ua.os.name);
  headers['device_os'] = ua.os.name;
  headers['device_unique_name'] = getBrowserFingerPrint();
  headers['app_version'] = process.env.REACT_APP_VERSION;

  if(auth){
    //----------------------------------------------------
    let token = "";
    let device = "";
    let kid = "";
    //----------------------------------------------------
    let userCredentials = sessionStorage.get('userCredentials');
    let registerInformation = sessionStorage.get('registerInformation');
    //----------------------------------------------------
    if(registerInformation != null){
      token = registerInformation.token;
      device = registerInformation.device;
      kid = registerInformation.user.kid;
    }else{
      token = userCredentials != null && userCredentials.token;
      device = userCredentials != null && userCredentials.device;
      kid = userCredentials != null && userCredentials.user.kid;
    }
    //----------------------------------------------------
    headers['token'] = token;
    headers['device'] = device;
    headers['kid'] = kid;
    headers['uid'] = kid;
  }

  if(contentType && typeof contentType === "boolean") headers['content-type'] = `multipart/form-data ; boundary =${bodyParam._boundary}`;
  if(contentType && typeof contentType !== "boolean") headers['content-type'] = `application/octet-stream`;

  let config = {
    url: process.env.REACT_APP_URL + route,
    method:met,
    headers:headers,
    cancelToken:cancelTok
  };
  if(queryParam != null) config.params = queryParam;
  if(bodyParam != null) config.data = bodyParam;
  if(blob) config.responseType = "blob";

  return axios.request(config)
    .then(response =>{
      getError(response.data,history);
      return response.data;
  }).catch(err =>{
    if (axios.isCancel(err)) {
    } else {
      if(err.message && err.message.includes("Network Error")){
        notification('warning',i18n.t(_error_message_not_online));
        sessionStorage.delete('userCredentials');
        history.push('/sign-in');
      }else if(err.message && err.message.includes("timeout")){
        notification('warning',i18n.t(_error_message_default));
        sessionStorage.delete('userCredentials');
        history.push('/sign-in');
      }else if (err.response && err.response.data && err.response.status === 400){
        getError(err.response.data,history);
      }else if (err.response && (err.response.status === 401 || err.response.status === 403)){
        notification('warning',i18n.t(_log_in_again));
        sessionStorage.delete('userCredentials');
        history.push('/sign-in');
      }else if (err.response && err.response.status === 404){
        history.push('/404');
      }else if (err.response && err.response.status === 500){
        history.push('/500');
      }else if(err.response && err.response.data != null){
        getError(err.response.data,history);
      }else{

      }
    }
  });

}
