import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {sessionStorage} from "./Security";
import 'react-toastify/dist/ReactToastify.min.css';

import DefaultLayout from "./containers/DefaultLayout";
import Start from "./pages/Start";
import Login from "./pages/Login";
import Page404 from "./pages/Page404";
import Page500 from "./pages/Page500";
import Verification from "./pages/Verification";
import ResetPage from "./pages/ResetPage";
import ResetPassword from "./pages/ResetPassword";
import PageSuccess from "./pages/PageSuccess";
import UnsubscribedMailList from "./pages/UnsubscribedMailList";
import SupplyResponse from "./pages/SupplyResponse";

function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>

          <Route exact path="/start" name="Login" render={props => <Start {...props}/>} />
          <Route path="/sign-in" name="Login" render={props => <Login {...props}/>} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
          <Route exact path="/success" name="Success" render={props => <PageSuccess {...props}/>} />
          <Route exact path="/error" name="Error" render={props => <Page500 {...props}/>} />
          <Route exact path="/verification/:kid/:link" name="Verification" render={props => <Verification {...props}/>} />
          <Route exact path="/forget-password" name="Reset Page" render={props => <ResetPage {...props}/>} />
          <Route exact path="/reset/password/:link" name="Reset Password" render={props => <ResetPassword {...props}/>} />
          <Route exact path="/reset/password/:link/:code" name="Reset Password" render={props => <ResetPassword {...props}/>} />
          <Route exact path="/unsubscribed/:kid" name="Unsubscribed mail list" render={props => <UnsubscribedMailList {...props}/>} />
          <Route exact path="/supply_response" name="Supply response" render={props => <SupplyResponse {...props}/>} />
          <Route exact path="/supply_response/:code" name="Supply response" render={props => <SupplyResponse {...props}/>} />

          <Route path="/" name="Home" render={props => (
            sessionStorage.get('userCredentials') ? <DefaultLayout {...props}/> : <Redirect to="/start" />)}
          />

        </Switch>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </>
  );
}

export default App;
