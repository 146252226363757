
import countryList from "country-list";
import {callableCountries, getCountries} from "./UsefulFunctions";
import {localStorage} from "./Security";
import "../src/assets/styles/constants.css";

// Countries names internationalizer
export const countryNameInLang = require("i18n-iso-countries");
countryNameInLang.registerLocale(require("i18n-iso-countries/langs/en.json"));
countryNameInLang.registerLocale(require("i18n-iso-countries/langs/fr.json"));

// Colors
export const green_kamix = '#5E923D';
export const yellow_kamix = '#FFC820';
export const gray_background = '#F5F7FA';

// Format number to 1K, 1M, ...
export const formatter = Intl.NumberFormat('en', { notation: 'compact' });

// Strong password regex
export const strongPassword = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@*?#_\-=+]).{8,}$/);

// React Code input
export const codeInputProps = {
  className: "reactCodeInput",
  inputStyle: {
    fontFamily: 'Inter',
    MozAppearance: 'textfield',
    backgroundColor: gray_background,
    margin: '4px',
    width: '40px',
    height: '48px',
    fontSize: '25px',
    color: 'black',
    border: '2px solid black',
    borderRadius: '8px',
    textAlign: 'center'
  },
  inputStyleInvalid: {
    fontFamily: 'Inter',
    MozAppearance: 'textfield',
    backgroundColor: '#f7dddd',
    margin: '4px',
    width: '40px',
    height: '48px',
    fontSize: '25px',
    color: 'black',
    border: '2px solid black',
    borderRadius: '8px',
    textAlign: 'center'
  }
};

// Authorized Countries list
//--------------------------------------------
/*const authorizedCountriesCodes = [
  'CM','BE','BG','CZ','DK','DE','EE','IE','GR','ES',
  'FR','HR','IT','CY','LV','LT','LU','HU','MT','NL',
  'AT','PL','PT','RO','SI','SK','FI','SE','IS','LI',
  'NO','CH','GB','US','CA','JP'];*/
let authorizedCountriesCodes = callableCountries();
let authorizedCountries = new Array(authorizedCountriesCodes.length);
for (let i = 0; i < authorizedCountriesCodes.length; i++) {
  authorizedCountries[i] = {
    name: countryList.getName(authorizedCountriesCodes[i]),
    code: authorizedCountriesCodes[i],
    isOk: true
  }
}

// Default country of app
//--------------------------------------------
let defaultCountryCode = localStorage.get("defaultCountryCode");
defaultCountryCode = defaultCountryCode?.toString()?.toUpperCase();
defaultCountryCode = (defaultCountryCode != null && defaultCountryCode !== "" && authorizedCountriesCodes.includes(defaultCountryCode)) ? defaultCountryCode : "FR";
export const defaultCountry = getCountries().filter(c=> c.code === defaultCountryCode)[0];

// Languages constants
export const _error_message_default = 'error_message_default';
export const _error_message_not_online = 'error_message_not_online';
export const _error_message_device_already_exists = 'error_message_device_already_exists';
export const _error_message_user_not_exists = 'error_message_user_not_exists';
export const _error_message_91 = 'error_message_91';
export const _error_message_92 = 'error_message_92';
export const _error_message_93 = 'error_message_93';
export const _error_message_95 = 'error_message_95';
export const _error_message_99 = 'error_message_99';
export const _error_message_100 = 'error_message_100';
export const _error_message_101 = 'error_message_101';
export const _error_message_102 = 'error_message_102';
export const _error_message_103 = 'error_message_103';
export const _error_message_104 = 'error_message_104';
export const _error_message_105 = 'error_message_105';
export const _error_message_106 = 'error_message_106';
export const _error_message_107 = 'error_message_107';
export const _error_message_108 = 'error_message_108';
export const _error_message_109 = 'error_message_109';
export const _error_message_110 = 'error_message_110';
export const _error_message_111 = 'error_message_111';
export const _error_message_112 = 'error_message_112';
export const _error_message_113 = 'error_message_113';
export const _error_message_114 = 'error_message_114';
export const _error_message_115 = 'error_message_115';
export const _error_message_116 = 'error_message_116';
export const _error_message_117 = 'error_message_117';
export const _error_message_118 = 'error_message_118';
export const _error_message_119 = 'error_message_119';
export const _error_message_120 = 'error_message_120';
export const _error_message_121 = 'error_message_121';
export const _error_message_122 = 'error_message_122';
export const _error_message_123 = 'error_message_123';
export const _error_message_124 = 'error_message_124';
export const _error_message_125 = 'error_message_125';
export const _error_message_126 = 'error_message_126';
export const _error_message_127 = 'error_message_127';
export const _error_message_128 = 'error_message_128';
export const _error_message_129 = 'error_message_129';
export const _error_message_130 = 'error_message_130';
export const _error_message_131 = 'error_message_131';
export const _error_message_132 = 'error_message_132';
export const _error_message_200 = 'error_message_200';
export const _error_message_201 = 'error_message_201';
export const _error_message_202 = 'error_message_202';
export const _error_message_203 = 'error_message_203';
export const _error_message_204 = 'error_message_204';
export const _error_message_205 = 'error_message_205';
export const _error_message_206 = 'error_message_206';
export const _error_message_207 = 'error_message_207';
export const _error_message_302 = 'error_message_302';
export const _error_message_303 = 'error_message_303';
export const _error_message_304 = 'error_message_304';
export const _error_message_305 = 'error_message_305';
export const _error_message_306 = 'error_message_306';
export const _error_message_307 = 'error_message_307';
export const _error_message_308 = 'error_message_308';
export const _error_message_309 = 'error_message_309';
export const _error_message_310 = 'error_message_310';
export const _error_message_311 = 'error_message_311';
export const _error_message_312 = 'error_message_312';
export const _error_message_313 = 'error_message_313';
export const _error_message_400 = 'error_message_400';
export const _error_message_401 = 'error_message_401';
export const _error_message_402 = 'error_message_402';
export const _error_message_403 = 'error_message_403';
export const _error_message_404 = 'error_message_404';
export const _error_message_405 = 'error_message_405';
export const _error_message_406 = 'error_message_406';
export const _error_message_407 = 'error_message_407';
export const _error_message_408 = 'error_message_408';
export const _error_message_409 = 'error_message_409';
export const _error_message_410 = 'error_message_410';
export const _error_message_411 = 'error_message_411';
export const _error_message_412 = 'error_message_412';
export const _error_message_413 = 'error_message_413';
export const _error_message_414 = 'error_message_414';
export const _error_message_415 = 'error_message_415';
export const _error_message_416 = 'error_message_416';
export const _error_message_417 = 'error_message_417';
export const _error_message_418 = 'error_message_418';
export const _error_message_419 = 'error_message_419';
export const _error_message_420 = 'error_message_420';
export const _error_message_421 = 'error_message_421';
export const _error_message_422 = 'error_message_422';
export const _error_message_423 = 'error_message_423';
export const _error_message_424 = 'error_message_424';
export const _error_message_425 = 'error_message_425';
export const _error_message_426 = 'error_message_426';
export const _error_message_427 = 'error_message_427';
export const _error_message_429 = 'error_message_429';
export const _error_message_503 = 'error_message_503';
export const _error_message_504 = 'error_message_504';

export const _app_name = 'app_name';
export const _welcome_to_kamix = 'welcome_to_kamix';
export const _sending_code_verification = 'sending_code_verification';
export const _first_and_last_name_must_match_id_document = 'first_and_last_name_must_match_id_document';
export const _mobile_number = 'mobile_number';
export const _first_name = 'first_name';
export const _last_name = 'last_name';
export const _continue = 'continue';
export const _code_confirmation_text = 'code_confirmation_text';
export const _send_code_by_sms_to = 'send_code_by_sms_to';
export const _code_invalid = 'code_invalid';
export const _resend_code_text = 'resend_code_text';
export const _required = 'required';
export const _let_set_password = 'let_set_password';
export const _password = 'password';
export const _old_password = 'old_password';
export const _new_password = 'new_password';
export const _confirm_password = 'confirm_password';
export const _confirm_new_password = 'confirm_new_password';
export const _repeat_thing = 'repeat_thing';
export const _invalid_phone_number = 'invalid_phone_number';
export const _invalid_email = 'invalid_email';
export const _invalid_password = 'invalid_password';
export const _invalid_password_2 = 'invalid_password_2';
export const _introduction = 'introduction';
export const _introduction_text_1 = 'introduction_text_1';
export const _introduction_text_2 = 'introduction_text_2';
export const _step = 'step';
export const _on = 'on';
export const _verify_profile = 'verify_profile';
export const _verify_profile_text_1 = 'verify_profile_text_1';
export const _verify_profile_text_2 = 'verify_profile_text_2';
export const _hello = 'hello';
export const _email = 'email';
export const _address = 'address';
export const _zip_code = 'zip_code';
export const _living_country = 'living_country';
export const _living_city = 'living_city';
export const _living_address = 'living_address';
export const _birth_date = 'birth_date';
export const _birth_place = 'birth_place';
export const _do_it_later = 'do_it_later';
export const _how_to_make_transfer = 'how_to_make_transfer';
export const _how_to_make_transfer_text_1 = 'how_to_make_transfer_text_1';
export const _dont_miss_anything = 'dont_miss_anything';
export const _dont_miss_anything_text_1 = 'dont_miss_anything_text_1';
export const _oops_you_are_lost = 'oops_you_are_lost';
export const _page_not_found_info = 'page_not_found_info';
export const _go_to_home_page = 'go_to_home_page';
export const _oops = 'oops';
export const _something_went_wrong = 'something_went_wrong';
export const _home = 'home';
export const _history = 'history';
export const _support = 'support';
export const _profile = 'profile';
export const _language = 'language';
export const _english = 'english';
export const _french = 'french';
export const _logout = 'logout';
export const _next = 'next';
export const _password_constraint_text = 'password_constraint_text';
export const _password_constraint_text_2 = 'password_constraint_text_2';
export const _need_to_collect_data_explanation = 'need_to_collect_data_explanation';
export const _email_verification_indication = 'email_verification_indication';
export const _monthly_funds_transfer = 'monthly_funds_transfer';
export const _monthly_funds_transfer_explanation = 'monthly_funds_transfer_explanation';
export const _id_verification = 'id_verification';
export const _id_verification_text_1 = 'id_verification_text_1';
export const _id_verification_text_2 = 'id_verification_text_2';
export const _use_smart_features = 'use_smart_features';
export const _use_smart_features_text = 'use_smart_features_text';
export const _log_in = 'log_in';
export const _log_in_with_email = 'log_in_with_email';
export const _log_in_with_mobile_number = 'log_in_with_mobile_number';
export const _log_in_again = 'log_in_again';
export const _enter_your_mobile = 'enter_your_mobile';
export const _enter_your_email = 'enter_your_email';
export const _balance_from_transactions_history = 'balance_from_transactions_history';
export const _kamix_tutorial = 'kamix_tutorial';
export const _invite_someone = 'invite_someone';
export const _missing_information_text = 'missing_information_text';
export const _start_a_transfer = 'start_a_transfer';
export const _accept = 'accept';
export const _verify = 'verify';
export const _forget_password_question = 'forget_password_question';
export const _forget_password_explanation = 'forget_password_explanation';
export const _welcome_message_with_user_name = 'welcome_message_with_user_name';
export const _welcome_message_without_user_name = 'welcome_message_without_user_name';
export const _enter_verification_code_text = 'enter_verification_code_text';
export const _verification_code_sent_message= 'verification_code_sent_message';
export const _cancel = 'cancel';
export const _goodbye = 'goodbye';
export const _failed_to_connect_to_server = 'failed_to_connect_to_server';
export const _log_back = 'log_back';
export const _kamix_profile = 'kamix_profile';
export const _icon = 'icon';
export const _my_devices = 'my_devices';
export const _invite_friends = 'invite_friends';
export const _my_receivers = 'my_receivers';
export const _my_receivers_text = 'my_receivers_text';
export const _my_credit_cards = 'my_credit_cards';
export const _my_credit_cards_text = 'my_credit_cards_text';
export const _security_settings = 'security_settings';
export const _payment_settings = 'payment_settings';
export const _privacy_policy = 'privacy_policy';
export const _terms_and_conditions = 'terms_and_conditions';
export const _terms_and_privacy_policy = 'terms_and_privacy_policy';
export const _my_kamix_id_2 = 'my_kamix_id_2';
export const _change_profile_password = 'change_profile_password';
export const _change_profile_password_explanation = 'change_profile_password_explanation';
export const _password_forgotten = 'password_forgotten';
export const _password_forgotten_explanation = 'password_forgotten_explanation';
export const _update_available_text = 'update_available_text';
export const _select = 'select';
export const _copied = 'copied';
export const _my_blockchain_addresses = 'my_blockchain_addresses';
export const _my_email = 'my_email';
export const _click_to_verify = 'click_to_verify';
export const _click_to_modify = 'click_to_modify';
export const _resend_mail = 'resend_mail';
export const _verified = 'verified';
export const _email_not_verified_yet_text = 'email_not_verified_yet_text';
export const _email_sent = 'email_sent';
export const _email_verification = 'email_verification';
export const _email_verification_success_text_1 = 'email_verification_success_text_1';
export const _email_verification_success_text_2 = 'email_verification_success_text_2';
export const _close_page_and_good_continuation = 'close_page_and_good_continuation';
export const _add_mobile_number = 'add_mobile_number';
export const _mobile_number_added = 'mobile_number_added';
export const _add = 'add';
export const _my_phone_numbers = 'my_phone_numbers';
export const _verify_mobile_number = 'verify_mobile_number';
export const _set_as_main_mobile = 'set_as_main_mobile';
export const _set_as_main_mobile_question = 'set_as_main_mobile_question';
export const _edit_mobile_number = 'edit_mobile_number';
export const _delete_mobile_number = 'delete_mobile_number';
export const _delete_mobile_number_question = 'delete_mobile_number_question';
export const _delete_my_kamix_profile = 'delete_my_kamix_profile';
export const _delete_my_kamix_profile_question = 'delete_my_kamix_profile_question';
export const _delete = 'delete';
export const _profile_deleted = 'profile_deleted';
export const _register = 'register';
export const _reset_by_email = 'reset_by_email';
export const _reset_by_mobile_number = 'reset_by_mobile_number';
export const _enter_email_associated_to_your_profile = 'enter_email_associated_to_your_profile';
export const _enter_mobile_number_associated_to_your_profile = 'enter_mobile_number_associated_to_your_profile';
export const _close = 'close';
export const _mail_sent_to = 'mail_sent_to';
export const _click_on_link_received_and_set_new_password = 'click_on_link_received_and_set_new_password';
export const _mail_not_receive_resend = 'mail_not_receive_resend';
export const _resend = 'resend';
export const _set_new_password = 'set_new_password';
export const _confirm = 'confirm';
export const _password_reset_success = 'password_reset_success';
export const _resend_new_link = 'resend_new_link';
export const _modify = 'modify';
export const _modify_email_address = 'modify_email_address';
export const _number_set_as_main = 'number_set_as_main';
export const _number_verified = 'number_verified';
export const _old_mobile_number = 'old_mobile_number';
export const _new_mobile_number = 'new_mobile_number';
export const _edit = 'edit';
export const _mobile_verification = 'mobile_verification';
export const _number_modified = 'number_modified';
export const _number_deleted = 'number_deleted';
export const _email_missing = 'email_missing';
export const _warning_email_not_provided_yet = 'warning_email_not_provided_yet';
export const _warning_email_provided_but_not_verified = 'warning_email_provided_but_not_verified';
export const _select_phone_number_to_use = 'select_phone_number_to_use';
export const _warning_phone_provided_but_not_verified = 'warning_phone_provided_but_not_verified';
export const _my_sponsor_link = 'my_sponsor_link';
export const _nationality = 'nationality';
export const _warning_email_verification_not_done = 'warning_email_verification_not_done';
export const _not_redirected_to_ubble_question = 'not_redirected_to_ubble_question';
export const _click_on_link_below = 'click_on_link_below';
export const _id_verification_complete_question = 'id_verification_complete_question';
export const _define_email = 'define_email';
export const _dont_have_profile_yet_question = 'dont_have_profile_yet_question';
export const _already_have_an_profile_question = 'already_have_an_profile_question';
export const _success = 'success';
export const _operation_completed = 'operation_completed';
export const _newsletter = 'newsletter';
export const _unsubscribe_successful = 'unsubscribe_successful';
export const _unsubscribed_question = 'unsubscribed_question';
export const _unsubscribe = 'unsubscribe';
export const _can_perform_transaction_text = 'can_perform_transaction_text';
export const _last_seen_on = 'last_seen_on';
export const _last_seen_today = 'last_seen_today';
export const _on_authorization = 'on_authorization';
export const _main_device = 'main_device';
export const _connected_device = 'connected_device';
export const _terms_of_use = 'terms_of_use';
export const _device_id = 'device_id';
export const _device_type = 'device_type';
export const _is_main_device = 'is_main_device';
export const _yes = 'yes';
export const _no = 'no';
export const _save = 'save';
export const _modification_applied = 'modification_applied';
export const _delete_device = 'delete_device';
export const _set_as_main_device = 'set_as_main_device';
export const _actions = 'actions';
export const _set_device_as_main_question = 'set_device_as_main_question';
export const _delete_device_question = 'delete_device_question';
export const _no_receivers_registered = 'no_receivers_registered';
export const _no_receivers_registered_yet = 'no_receivers_registered_yet';
export const _register_receiver = 'register_receiver';
export const _new_receiver = 'new_receiver';
export const _relationship_with_receiver = 'relationship_with_receiver';
export const _receiver_created = 'receiver_created';
export const _receiver_modified = 'receiver_modified';
export const _receiver_deleted = 'receiver_deleted';
export const _receiver_device_question = 'receiver_device_question';
export const _delete_receiver = 'delete_receiver';
export const _follow_us_on_social_media = 'follow_us_on_social_media';
export const _our_different_pages = 'our_different_pages';
export const _define_email_address = 'define_email_address';
export const _my_email_address = 'my_email_address';
export const _get_started = 'get_started';
export const _transfer_funds_with_no_fees = 'transfer_funds_with_no_fees';
export const _kamix_for_web_plus_version = 'kamix_for_web_plus_version';
export const _profile_information = 'profile_information';
export const _my_identity = 'my_identity';
export const _danger_zone = 'danger_zone';
export const _profile_status = 'profile_status';
export const _verification_status = 'verification_status';
export const _quota = 'quota';
export const _average_monthly_transfer_volume = 'average_monthly_transfer_volume';
export const _general_information = 'general_information';
export const _status = 'status';
export const _of = 'of';
export const _updated_at = 'updated_at';
export const _quota_selected = 'quota_selected';
export const _wallet_missing_information_privacy_title = 'wallet_missing_information_privacy_title';
export const _wallet_missing_information_mobile_title = 'wallet_missing_information_mobile_title';
export const _wallet_missing_information_email_title = 'wallet_missing_information_email_title';
export const _wallet_missing_information_infos_title = 'wallet_missing_information_infos_title';
export const _wallet_missing_information_quota_title = 'wallet_missing_information_quota_title';
export const _wallet_missing_information_incomes_title = 'wallet_missing_information_incomes_title';
export const _click_to_manage = 'click_to_manage';
export const _understood = 'understood';
export const _usage_text = 'usage_text';
export const _points_earned_text = 'points_earned_text';
export const _no_invited_user_yet = 'no_invited_user_yet';
export const _view_more = 'view_more';
export const _documentation = 'documentation';
export const _documentation_review_text = 'documentation_review_text';
export const _quick_actions = 'quick_actions';
export const _send_money_to_new_beneficiary = 'send_money_to_new_beneficiary';
export const _search = 'search';
export const _saved_receivers = 'saved_receivers';
export const _to = 'to';
export const _balance = 'balance';
export const _in = 'in';
export const _days = 'days';
export const _reason = 'reason';
export const _message_optional = 'message_optional';
export const _transfer_details = 'transfer_details';
export const _you_send = 'you_send';
export const _fees = 'fees';
export const _recipient_receive = 'recipient_receive';
export const _receiver = 'receiver';
export const _no_fees = 'no_fees';
export const _enter_password = 'enter_password';
export const _choose_method = 'choose_method';
export const _transfer_with = 'transfer_with';
export const _crypto_balance = 'crypto_balance';
export const _send_money_from_your_balance = 'send_money_from_your_balance';
export const _bank_transfer = 'bank_transfer';
export const _send_money_through_bank_transfer = 'send_money_through_bank_transfer';
export const _kamix_banks = 'kamix_banks';
export const _bank = 'bank';
export const _holder = 'holder';
export const _kamix_iban = 'kamix_iban';
export const _kamix_bic = 'kamix_bic';
export const _method_blocked = 'method_blocked';
export const _making_transfer = 'making_transfer';
export const _making_purchase = 'making_purchase';
export const _transfer_in_progress = 'transfer_in_progress';
export const _purchase_in_progress = 'purchase_in_progress';
export const _try_again = 'try_again';
export const _transaction_text_pending = 'transaction_text_pending';
export const _transaction_text_accepted = 'transaction_text_accepted';
export const _transaction_text_failed = 'transaction_text_failed';
export const _purchase_text_pending = 'purchase_text_pending';
export const _purchase_text_accepted = 'purchase_text_accepted';
export const _purchase_text_failed = 'purchase_text_failed';
export const _purchase = 'purchase';
export const _purchase_nkap = 'purchase_nkap';
export const _send = 'send';
export const _maximum_amount_per_transfer = 'maximum_amount_per_transfer';
export const _maximum_amount_per_transfer_warning = 'maximum_amount_per_transfer_warning';
export const _remaining_transfer_limit = 'remaining_transfer_limit';
export const _remaining_transfer_limit_warning = 'remaining_transfer_limit_warning';
export const _invalid_amount = 'invalid_amount';
export const _insufficient_balance = 'insufficient_balance';
export const _info = 'info';
export const _subscription_title = 'subscription_title';
export const _payment_to_kamix = 'payment_to_kamix';
export const _purchase_of_crypto = 'purchase_of_crypto';
export const _from = 'from';
export const _crypto_withdrawal = 'crypto_withdrawal';
export const _yesterday = 'yesterday';
export const _code = 'code';
export const _payment_collection_pending_details_1 = 'payment_collection_pending_details_1';
export const _payment_collected_by_kamix = 'payment_collected_by_kamix';
export const _finalize_tx = 'finalize_tx';
export const _amount = 'amount';
export const _payment_mode = 'payment_mode';
export const _reference = 'reference';
export const _date = 'date';
export const _message = 'message';
export const _see_tx_on_blockchain = 'see_tx_on_blockchain';
export const _check_status = 'check_status';
export const _transfer_cryptos = 'transfer_cryptos';
export const _perform_same_transaction = 'perform_same_transaction';
export const _no_transaction_made_yet = 'no_transaction_made_yet';
export const _details = 'details';
export const _transaction_invoice = 'transaction_invoice';
export const _download = 'download';
export const _instructions_to_follow = 'instructions_to_follow';
export const _instructions_to_follow_1 = 'instructions_to_follow_1';
export const _instructions_to_follow_2 = 'instructions_to_follow_2';
export const _instructions_to_follow_3 = 'instructions_to_follow_3';
export const _instructions_to_follow_4 = 'instructions_to_follow_4';
export const _instructions_to_follow_5 = 'instructions_to_follow_5';
export const _instructions_to_follow_6 = 'instructions_to_follow_6';
export const _instructions_to_follow_7 = 'instructions_to_follow_7';
export const _transaction_info = 'transaction_info';
export const _transaction_code = 'transaction_code';
export const _my_transactions = 'my_transactions';
export const _search_by = 'search_by';
export const _search_by_types = 'search_by_types';
export const _search_by_statuses = 'search_by_statuses';
export const _search_by_crypto_currency = 'search_by_crypto_currency';
export const _search_by_warning_text = 'search_by_warning_text';
export const _search_by_warning_text_date = 'search_by_warning_text_date';
export const _transfers = 'transfers';
export const _transaction_statement = 'transaction_statement';
export const _transaction_statement_text = 'transaction_statement_text';
export const _transaction_statement_success_message = 'transaction_statement_success_message';
export const _full_statement = 'full_statement';
export const _no_ticket_message = 'no_ticket_message';
export const _new_ticket = 'new_ticket';
export const _contact_kamix = 'contact_kamix';
export const _ticket_title = 'ticket_title';
export const _enter_ticket_title = 'enter_ticket_title';
export const _ticket_created = 'ticket_created';
export const _select_category = 'select_category';
export const _photo = 'photo';
export const _video = 'video';
export const _you = 'you';
export const _admin = 'admin';
export const _solved = 'solved';
export const _file_size_warning_max_10_mo = 'file_size_warning_max_10_mo';
export const _ticket_solved_message = 'ticket_solved_message';
export const _ticket_deleted_message = 'ticket_deleted_message';
export const _tap_a_text_message = 'tap_a_text_message';
export const _warning_delete_ticket_message = 'warning_delete_ticket_message';
export const _warning_mark_ticket_solved_message = 'warning_mark_ticket_solved_message';
export const _mark_as_solved = 'mark_as_solved';
export const _browser_video_warning = 'browser_video_warning';
export const _information_note = 'information_note';
export const _ok = 'ok';
export const _back = 'back';
export const _amount_nearest_50_xaf_warning = 'amount_nearest_50_xaf_warning';
export const _today = 'today';
export const _salary_and_incomes = 'salary_and_incomes';
export const _salary_and_incomes_text = 'salary_and_incomes_text';
export const _annual_incomes_range = 'annual_incomes_range';
export const _socio_professional_category = 'socio_professional_category';
export const _activity_area = 'activity_area';
export const _purchase_nkap_infos_1 = 'purchase_nkap_infos_1';
export const _purchase_nkap_infos_2 = 'purchase_nkap_infos_2';
export const _verified_at = 'verified_at';
export const _file_size_warning = 'file_size_warning';
export const _click_to_download = 'click_to_download';
export const _not_uploaded_yet = 'not_uploaded_yet';
export const _warning = 'warning';
export const _warning_file_upload_1 = 'warning_file_upload_1';
export const _warning_file_upload_2 = 'warning_file_upload_2';
export const _warning_file_upload_3 = 'warning_file_upload_3';
export const _warning_file_upload_4 = 'warning_file_upload_4';
export const _choose_file = 'choose_file';
export const _file_selected = 'file_selected';
export const _zoom_in_or_out_text = 'zoom_in_or_out_text';
export const _indications = 'indications';
export const _add_additional_kyc = 'add_additional_kyc';
export const _select_document_type = 'select_document_type';
export const _document_name = 'document_name';
export const _description = 'description';
export const _add_a_document = 'add_a_document';
export const _documents = 'documents';
export const _additional_document = 'additional_document';
export const _additional_documents = 'additional_documents';
export const _automatic_bank_transfer = 'automatic_bank_transfer';
export const _select_country_where_bank_account_is_held = 'select_country_where_bank_account_is_held';
export const _not_redirected_to_transfer_completion_page = 'not_redirected_to_transfer_completion_page';
export const _payment_link = 'payment_link';
export const _country_selected = 'country_selected';
export const _purchase_details = 'purchase_details';
export const _you_recharge = 'you_recharge';
export const _purchase_with = 'purchase_with';
export const _enter_password_to_confirm_identity = 'enter_password_to_confirm_identity';
export const _manage = 'manage';
export const _link_to_usa = 'link_to_usa';
export const _accommodation_type = 'accommodation_type';
export const _add_credit_card_text = 'add_credit_card_text';
export const _add_credit_card = 'add_credit_card';
export const _new_credit_card = 'new_credit_card';
export const _credit_card_deleted = 'credit_card_deleted';
export const _credit_card_deletion_warning = 'credit_card_deletion_warning';
export const _type = 'type';
export const _card_expiry_date = "card_expiry_date";
export const _card_holder_name = "card_holder_name";
export const _card_issuer = "card_issuer";
export const _card_issuer_country = "card_issuer_country";
export const _created_at = "created_at";
export const _card_number = "card_number";
export const _name_on_card = "name_on_card";
export const _phone_number = 'phone_number';
export const _select_a_country = 'select_a_country';
export const _state = 'state';
export const _optional = 'optional';
export const _billing_address_line_1 = "billing_address_line_1";
export const _billing_address_line_2 = "billing_address_line_2";
export const _email_address = "email_address";
export const _verify_card = "verify_card";
export const _i_have_finished = "i_have_finished";
export const _card_form_not_filled = "card_form_not_filled";
export const _card_validation_failed = "card_validation_failed";
export const _send_fund_by_credit_card = 'send_fund_by_credit_card';
export const _credit_card = 'credit_card';
export const _no_credit_card_registered = 'no_credit_card_registered';
export const _select_credit_card_to_use = 'select_credit_card_to_use';
export const _you_will_be_charged = "you_will_be_charged";
export const _recommended_automatic_bank_transfer = "recommended_automatic_bank_transfer";
export const _abandon_transaction_confirm_question = "abandon_transaction_confirm_question";
export const _surname = "surname";
export const _as_in_id_document = "as_in_id_document";
export const _add_dotfile_kyc = "add_dotfile_kyc";
export const _account_blocked = "account_blocked";
export const _ = '';