import React, {useState} from 'react';
import {Row, Col, Input, Button} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import RemoteCall, {notification, getCountries} from "../../../UsefulFunctions";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {
  _add, _add_mobile_number, _invalid_phone_number, _mobile_number, _mobile_number_added,
  _kamix_profile, _required, defaultCountry
} from "../../../UsefulConstants";
import Select from "react-select";
import {getCountryCallingCode, isValidPhoneNumber} from "react-phone-number-input";
import "../../../assets/styles/myAccount.css";

function MobileCreation() {

  const { t } = useTranslation();
  const history = useHistory();

  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [country, setCountry] = useState(defaultCountry);
  const [phoneNumber,setPhoneNumber] = useState('');
  const [isPhoneInputValid,setIsPhoneInputValid] = useState(true);

  function onChangePhoneNumber(e){
    setPhoneNumber(e.target.value);
    setIsPhoneInputValid(true);
  }

  function onChangeCountry(e) {
    setCountry(e)
  }

  async function onSubmit(e) {

    e.preventDefault();
    let mobile = '+'+getCountryCallingCode(country.code)+phoneNumber;

    if(phoneNumber === "" || !isValidPhoneNumber(mobile)){
      setIsPhoneInputValid(phoneNumber !== "" && isValidPhoneNumber(mobile));
    }else{

      setLoading(l => !l);

      let response = await RemoteCall(
        true,
        "users/update-mobile",
        "patch",
        false,
        null,
        {new_mobile: mobile},
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        notification("success",t(_mobile_number_added));
        history.push('/profile/account');
      }

    }

  }

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile/account"}>
              <ArrowLeftOutlined className="icon-sm mb-3" />
            </Link>


            <div className="mb-3 ml-5">
              <small>{t(_kamix_profile)}</small>
              <h5 className="fw-bold">{t(_add_mobile_number)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                <form onSubmit={(e)=>onSubmit(e)}>
                  <div className="mb-3">

                    <div className="d-flex">
                      <Select
                        className="my-select"
                        classNamePrefix="my-select"
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        value={country}
                        onChange={onChangeCountry}
                        options={getCountries()}
                        isSearchable
                        autosize={false}
                        getOptionLabel ={(option)=>option.name}
                        formatOptionLabel={(option, { context }) => {
                          return context === 'menu' ? option.label : option.label2;
                        }}
                      />
                      <div className="ml-2">
                        <Input
                          className="my-input"
                          type="text"
                          value={phoneNumber}
                          onChange={onChangePhoneNumber}
                          status={isPhoneInputValid ? "success" : "error"}

                          size="large"
                          placeholder={t(_mobile_number)}
                        />
                        {
                          !isPhoneInputValid &&
                          <div className="position-absolute ant-form-item-explain-error">
                            {phoneNumber === "" && t(_required)}
                            {phoneNumber !== "" && !isValidPhoneNumber(phoneNumber) && t(_invalid_phone_number)}
                          </div>
                        }
                      </div>
                    </div>

                  </div>
                  <Button className="my-button bg-yellow-kamix text-dark mt-2" loading={loading} htmlType="submit">
                    {t(_add)}
                  </Button>
                </form>

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(MobileCreation);