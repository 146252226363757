import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Skeleton} from "antd";
import {Link, withRouter, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ArrowRightOutlined} from "@ant-design/icons";
import {sessionStorage} from "../Security";
import axios from "axios";
import RemoteCall, {getAmountToDisplay, getLanguage, getTextToDisplay2} from "../UsefulFunctions";
import ProfileElement from "./others/ProfileElement";
import CreditCard from "../assets/icons/profile/credit_card.svg";
import FollowUsOnRs from "../assets/icons/profile/feedback.svg";
import {
  _accept, _balance_from_transactions_history, _hello, _invite_someone, _kamix_tutorial, _missing_information_text,
  _follow_us_on_social_media, _my_receivers, _purchase_nkap, _add, _start_a_transfer, _verify, _wallet_missing_information_email_title,
  _wallet_missing_information_infos_title, _wallet_missing_information_mobile_title, _wallet_missing_information_privacy_title,
  _wallet_missing_information_quota_title, _wallet_missing_information_incomes_title, _additional_document
} from "../UsefulConstants";
import "../assets/styles/home.css";

function Home() {

  const { t } = useTranslation();
  const history = useHistory();
  const lang = getLanguage();

  let userCredentials = sessionStorage.get('userCredentials');
  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [loadingUserAccountStatus,setLoadingUserAccountStatus] = useState(false);
  const [userAccountStatus,setUserAccountStatus] = useState(null);
  const [kycNames,setKycNames] = useState([]);
  const [userBalance,setUserBalance] = useState(null);

  async function getUserAccountStatus(){

    setLoadingUserAccountStatus(l => !l);

    let response = await RemoteCall(
      true,
      "kyc/profile/v3",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserAccountStatus(l => !l);

    if(response != null){
      let uas = response.user_account_status;
      setUserAccountStatus(uas);
      if(uas != null && uas.profile_status_alerts != null){
        setKycNames(uas.profile_status_alerts.map(el => el.kyc_name));
      }
    }

  }

  async function getUserBalance(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/wallet/balances",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      let balances = response.balances;
      for(let element of balances){
        if(element.currency != null && element.currency.unique_name === "stable"){
          setUserBalance(element);
          break;
        }
      }
    }

  }

  function makeTransfer(){
    if(userAccountStatus != null && kycNames.includes('cguv_accept')){
      history.push('/home/accept-cguv');
    }else if(userAccountStatus != null && userAccountStatus.account_status === "ACTIVE" && !userAccountStatus.account_blocked){
      history.push('/transfer/select-receiver');
    }else{
      history.push('/home/control-docs');
    }
  }

  function purchaseNkap(){
    if(userAccountStatus != null && kycNames.includes('cguv_accept')){
      history.push('/home/accept-cguv');
    }else if(userAccountStatus != null && userAccountStatus.account_status === "ACTIVE" && !userAccountStatus.account_blocked){
      let data={};
      data.tx_type = "purchase";
      sessionStorage.set('transactionInformation',data);
      history.push('/transfer/data-entries');
    }else{
      history.push('/home/control-docs');
    }
  }


  useEffect(
    () => {
      getUserAccountStatus();
      getUserBalance();
      sessionStorage.delete('transactionInformation');
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <div className="hello-box">
              {t(_hello)}
              {
                userCredentials != null && userCredentials.user != null &&
                userCredentials.user.firstname != null  && userCredentials.user.firstname !== "" &&
                (", " + userCredentials.user.firstname)
              }
            </div>

            <Row className="my-box balance-box">
              <Col flex="auto">
                <div>
                  {
                    loading ? (
                      <>
                        <Skeleton.Button active shape block style={{borderRadius:"2px", width:"30%", height:"35px", marginBottom: "7px"}} />
                        <Skeleton.Button active shape block style={{borderRadius:"2px", width:"50%", height:"20px"}} />
                      </>
                    ) : (
                      <>
                        {
                          userBalance != null &&
                          <div className="amount">
                            <span className="mr-2">{getAmountToDisplay(userBalance.balance, "EUR")}</span>
                            <span className="small">{userBalance.currency != null && userBalance.currency.short_symbols}</span>
                          </div>
                        }
                        <div className="amount-title">{t(_balance_from_transactions_history)}</div>
                      </>
                    )
                  }
                </div>
              </Col>
              <div className="d-none d-md-block">
                <div className="d-flex flex-column justify-content-end align-items-end">
                  <Button
                    className="my-button-2 bg-yellow-kamix text-dark"
                    disabled={loadingUserAccountStatus} onClick={makeTransfer}
                  >
                    {t(_start_a_transfer)}
                  </Button>
                  <Button className="my-button-2 bg-green-kamix text-white mt-1 fw-normal" onClick={purchaseNkap}>
                    {t(_purchase_nkap)}
                  </Button>
                </div>
              </div>
              <Col xs={24} className="d-flex d-md-none mt-2">
                <Link to={`/home/purchase`}>
                  <Button className="my-button-2 bg-green-kamix text-white mt-1 fw-normal">
                    {t(_purchase_nkap)}
                  </Button>
                </Link>
              </Col>
            </Row>

            <Row gutter={[16,8]}>
              <Col xs={12}>
                <a href={`${process.env.REACT_APP_URL}faq`} target="_blank" rel="noopener noreferrer">
                  <div className="my-box action-box-1">
                    <div className="title">{t(_kamix_tutorial)}</div>
                    <div className="line-decoration"/>
                    <ArrowRightOutlined className="icon"/>
                  </div>
                </a>
              </Col>
              <Col xs={12}>
                <Link to={'/home/invitation'}>
                  <div className="my-box action-box-2">
                    <div className="title">{t(_invite_someone)}</div>
                    <div className="line-decoration"/>
                    <ArrowRightOutlined className="icon" />
                  </div>
                </Link>
              </Col>
            </Row>

            {
              loadingUserAccountStatus ? (
                <div>
                  <div className="missing-information">
                    <Skeleton.Button active shape block style={{borderRadius:"8px", width:"50%"}} />
                  </div>
                  <div className="documents-missing-box-layout">
                    <Skeleton.Button
                      active shape block
                      style={{height:"150px", padding:"16px", marginBottom:"15px", borderRadius: "8px"}}
                    />
                  </div>
                </div>
              ) : (
                <>

                  {
                    (userAccountStatus != null && userAccountStatus.profile_status_alerts !== null && userAccountStatus.profile_status_alerts.length > 0) ? (
                      <>

                        <h3 className="missing-information">{t(_missing_information_text)}</h3>
                        <div className="documents-missing-box-layout">

                          {
                            userAccountStatus.profile_status_alerts.map((alert,index)=>(
                              <React.Fragment key={alert._id != null ? alert._id : "alert-"+index}>

                                {
                                  alert.kyc_name === "cguv_accept" &&
                                  <div className={"my-box documents-missing-box "+lang}>
                                    <div className={"sub-box-"+lang}>
                                      <div className="title">
                                        {
                                          alert.kyc_display_names != null ?
                                            getTextToDisplay2(alert.kyc_display_names,"value") :
                                            t(_wallet_missing_information_privacy_title)
                                        }
                                      </div>
                                      <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                    </div>
                                    <Link to={"/home/accept-cguv"}>
                                      <Button block className="my-button-2 fw-normal">
                                        {t(_accept)}
                                      </Button>
                                    </Link>
                                  </div>
                                }

                                {
                                  alert.kyc_name === "email" &&
                                  <div className={"my-box documents-missing-box "+lang}>
                                    <div className={"sub-box-"+lang}>
                                      <div className="title">
                                        {
                                          alert.kyc_display_names != null ?
                                            getTextToDisplay2(alert.kyc_display_names,"value") :
                                            t(_wallet_missing_information_email_title)
                                        }
                                      </div>
                                      <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                    </div>
                                    <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                      <Link to={'/profile/account/email/manage/h'}>
                                        <Button block className="my-button-2 fw-normal">
                                          {t(_verify)}
                                        </Button>
                                      </Link>
                                    </div>
                                  </div>
                                }

                                {
                                  alert.kyc_name === "mobiles" &&
                                  <div className={"my-box documents-missing-box "+lang}>
                                    <div className={"sub-box-"+lang}>
                                      <div className="title">
                                        {
                                          alert.kyc_display_names != null ?
                                            getTextToDisplay2(alert.kyc_display_names,"value") :
                                            t(_wallet_missing_information_mobile_title)
                                        }
                                      </div>
                                      <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                    </div>
                                    <Link to={'/profile/account/h'}>
                                      <Button block className="my-button-2 fw-normal">
                                        {t(_verify)}
                                      </Button>
                                    </Link>
                                  </div>
                                }

                                {
                                  alert.kyc_name === "quota" &&
                                  <div className={"my-box documents-missing-box "+lang}>
                                    <div className={"sub-box-"+lang}>
                                      <div className="title">
                                        {
                                          alert.kyc_display_names != null ?
                                            getTextToDisplay2(alert.kyc_display_names,"value") :
                                            t(_wallet_missing_information_quota_title)
                                        }
                                      </div>
                                      <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                    </div>
                                    <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                      <Link to={'/profile/account/kyc/quota/h'}>
                                        <Button block className="my-button-2 fw-normal">
                                          {t(_verify)}
                                        </Button>
                                      </Link>
                                    </div>
                                  </div>
                                }

                                {
                                  alert.kyc_name === "identity" &&
                                  <div className={"my-box documents-missing-box "+lang}>
                                    <div className={"sub-box-"+lang}>
                                      <div className="title">
                                        {
                                          alert.kyc_display_names != null ?
                                            getTextToDisplay2(alert.kyc_display_names,"value") :
                                            t(_wallet_missing_information_infos_title)
                                        }
                                      </div>
                                      <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                    </div>
                                    <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                      <Link to={'/profile/account/kyc/identity/h'}>
                                        <Button block className="my-button-2 fw-normal">
                                          {t(_verify)}
                                        </Button>
                                      </Link>
                                    </div>
                                  </div>
                                }

                                {
                                  alert.kyc_name === "incomes_info" &&
                                  <div className={"my-box documents-missing-box "+lang}>
                                    <div className={"sub-box-"+lang}>
                                      <div className="title">
                                        {
                                          alert.kyc_display_names != null ?
                                            getTextToDisplay2(alert.kyc_display_names,"value") :
                                            t(_wallet_missing_information_incomes_title)
                                        }
                                      </div>
                                      <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                    </div>
                                    <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                      <Link to={'/profile/account/kyc/incomes/h'}>
                                        <Button block className="my-button-2 fw-normal">
                                          {t(_verify)}
                                        </Button>
                                      </Link>
                                    </div>
                                  </div>
                                }

                                {
                                  (
                                    alert.kyc_name !== "cguv_accept" && alert.kyc_name !== "email" && alert.kyc_name !== "mobiles" &&
                                    alert.kyc_name !== "quota" && alert.kyc_name !== "identity" && alert.kyc_name !== "proof_of_identity" &&
                                    alert.kyc_name !== "incomes_info"
                                  ) &&
                                  <div className={"my-box documents-missing-box "+lang}>
                                    <div className={"sub-box-"+lang}>
                                      <div className="title">
                                        {
                                          alert.kyc_display_names != null ?
                                            getTextToDisplay2(alert.kyc_display_names,"value") :
                                            t(_additional_document)
                                        }
                                      </div>
                                      <div className="description">{getTextToDisplay2(alert.message,"value")}</div>
                                    </div>
                                    <div className={alert.kyc_status === "FILLED" ? "invisible" : ""}>
                                      {
                                        alert.kyc_name != null ? (
                                          <Link to={`/profile/account/add-kyc/${alert.kyc_name}/h`}>
                                            <Button block className="my-button-2 fw-normal">
                                              {t(_add)}
                                            </Button>
                                          </Link>
                                        ) : (
                                          <a href={userAccountStatus.onboarding_url} target="_blank" rel="noopener noreferrer">
                                            <Button block className="my-button-2 fw-normal">
                                              {t(_add)}
                                            </Button>
                                          </a>
                                        )
                                      }
                                    </div>
                                  </div>
                                }

                              </React.Fragment>
                            ))
                          }

                        </div>

                      </>
                    ) : (
                      <div className="missing-information">

                        <Row gutter={[16,16]} className="justify-content-center">
                          <Col xs={24} lg={12}>
                            <ProfileElement
                              where={"in"}
                              to={"/profile/payment/receivers/h"}
                              title={t(_my_receivers)}
                              image={CreditCard}
                            />
                          </Col>
                          <Col xs={24} lg={12}>
                            <ProfileElement
                              where={"in"}
                              to={'/profile/follow-us/h'}
                              title={t(_follow_us_on_social_media)}
                              image={FollowUsOnRs}
                            />
                          </Col>
                        </Row>

                      </div>
                    )
                  }

                </>
              )
            }

            <Row>
              <Col xs={24} className="transfer-box-parent">
                <div className="transfer-box">
                  <Button
                    className="start-button bg-yellow-kamix text-dark"
                    disabled={loadingUserAccountStatus} onClick={makeTransfer}
                  >
                    {t(_start_a_transfer)}
                  </Button>
                </div>
              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(Home);