import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {Row, Col, Button} from "antd";
import {useTranslation} from "react-i18next";
import KamixLogo from "../assets/images/logo_kamix.png";
import LanguageSelector from "../components/others/LanguageSelector";
import {
  _app_name,
  _transfer_funds_with_no_fees,
  _kamix_for_web_plus_version,
  _log_in, _register
} from "../UsefulConstants";
import KamixLegalText from "../components/others/KamixLegalText";
import "../assets/styles/pages.css";
import RemoteCall, {getBrowserFingerPrint} from "../UsefulFunctions";
import axios from "axios";

function Start() {

  const { t } = useTranslation();
  const history = useHistory();

  const source = axios.CancelToken.source();

  const [loadingRegistrationLink,setLoadingRegistrationLink] = useState(false);
  const [registrationLink,setRegistrationLink] = useState("");

  async function getRegistrationLink() {

    if(registrationLink === ""){

      setLoadingRegistrationLink(l=>!l);

      let response = await RemoteCall(
          true,
          "users/onboarding",
          "post",
          false,
          {device_unique_name:getBrowserFingerPrint()},
          null,
          source.token,
          history
      );

      setLoadingRegistrationLink(l=>!l);

      if(response != null){
        setRegistrationLink(response.onboarding_url);
        const win = window.open(response.onboarding_url, "_blank");
        win.focus();
      }

    }else{
      const win = window.open(registrationLink, "_blank");
      win.focus();
    }

  }

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="d-flex justify-content-between align-items-center margin-bottom-25">
              <Link to={"#"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
              <LanguageSelector location={"out"} />
            </div>

            <div className="text-center" style={{marginTop: "80px", marginBottom: "50px"}}>
              <h5 className="mb-4">{t(_transfer_funds_with_no_fees)}</h5>
              <div className="mb-4">
                <Link to={'/sign-in'}>
                  <Button className="my-button bg-yellow-kamix text-dark half-width">
                    {t(_log_in)}
                  </Button>
                </Link>
              </div>
              <div>
                <Button loading={loadingRegistrationLink} className="my-button bg-green-kamix text-white half-width" onClick={getRegistrationLink}>
                  {t(_register)}
                </Button>
              </div>

            </div>

            <div className="mb-2">
              <KamixLegalText/>
            </div>

            <small className="d-flex justify-content-center">
              {t(_kamix_for_web_plus_version).replace("XXX", "2.0.0")}
            </small>

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default Start;
