import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton, Button} from "antd";
import {withRouter, useHistory, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RemoteCall from "../UsefulFunctions";
import axios from "axios";
import Pagination from "./others/Pagination";
import TicketPresentation from "./support/TicketPresentation";
import {PlusCircleFilled} from "@ant-design/icons";
import {_new_ticket, _no_ticket_message} from "../UsefulConstants";

function Support() {

  const { t } = useTranslation();
  const history = useHistory();
  const source = axios.CancelToken.source();

  const size = 3;
  const [loading,setLoading] = useState(false);
  const [tickets,setTickets] = useState([]);
  const [ticketsShowed,setTicketsShowed] = useState([]);

  async function getTickets(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "support/tickets",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      let tickets = response.tickets;
      setTickets(tickets);
      setTicketsShowed(tickets.slice(0,size));
    }

  }

  useEffect(
    () => {
      getTickets();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center my-3">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={23} md={20} className="my-3 p-2">

                {
                  loading ? (
                    <Row className="justify-content-center">
                      <Col xs={24} md={22}>
                        <div className="d-flex justify-content-end mb-4">
                          <Skeleton.Button active shape style={{width:"50px"}} />
                        </div>
                        <Skeleton.Button active shape block style={{width:"100%", height:"85px", borderRadius:"8px", marginBottom:"10px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"85px", borderRadius:"8px", marginBottom:"10px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"85px", borderRadius:"8px", marginBottom:"10px"}} />
                        <div className="d-flex justify-content-end mt-4">
                          <Skeleton.Button active shape style={{width:"50px"}} />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <>

                      {
                        tickets.length === 0 ? (
                          <Row className="justify-content-center my-4">
                            <Col xs={24} md={20}>
                              <h6 className="text-normal text-center mb-3">
                                {t(_no_ticket_message)}
                              </h6>
                              <div className="text-center">
                                <Link to={'/support/create'}>
                                  <Button className="my-button bg-yellow-kamix text-dark fw-normal">{t(_new_ticket)}</Button>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <div>

                            <div className="mb-4">
                              <Pagination table={tickets} size={size} toShow={setTicketsShowed} />
                            </div>

                            <Row className="justify-content-center">
                              <Col md={24} lg={22}>
                                {
                                  ticketsShowed.map(item => (
                                    <div key={item.ticket_id} className="mb-2 child">
                                      <Link to={`/support/ticket/${item.ticket_id}`}>
                                        <TicketPresentation ticket={item} />
                                      </Link>
                                    </div>
                                  ))
                                }
                              </Col>
                            </Row>

                            <div className="d-flex justify-content-end mt-4">
                              <Link to={'/support/create'}>
                                <div className="createReceiverButton">
                                  <PlusCircleFilled style={{fontSize:"20px"}} />
                                </div>
                              </Link>
                            </div>

                          </div>
                        )
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(Support);