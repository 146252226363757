
// Import components
import Home from "./components/Home";
import History from "./components/History";
import Support from "./components/Support";
import Profile from "./components/Profile";
import SecuritySettings from "./components/profile/security/SecuritySettings";
import PasswordUpdate from "./components/profile/security/PasswordUpdate";
import PasswordReset from "./components/profile/security/PasswordReset";
import MyAccount from "./components/profile/myAccount/MyAccount";
import EmailManagement from "./components/profile/myAccount/EmailManagement";
import MobileCreation from "./components/profile/myAccount/MobileCreation";
import Devices from "./components/profile/devices/Devices";
import InviteFriends from "./components/profile/inviteFriends/InviteFriends";
import DeviceManager from "./components/profile/devices/DeviceManager";
import FollowUs from "./components/profile/followUs/FollowUs";
import UserIdentity from "./components/profile/myAccount/kyc/UserIdentity";
import UserIncomesInfo from "./components/profile/myAccount/kyc/UserIncomesInfo";
import Quota from "./components/profile/myAccount/kyc/Quota";
import PaymentSettings from "./components/profile/payment/PaymentSettings";
import ReceiversV3List from "./components/profile/payment/receiversv3/ReceiversV3List";
import ReceiverV3Manager from "./components/profile/payment/receiversv3/ReceiverV3Manager";
import CreditCards from "./components/profile/payment/creditCards/CreditCards";
import SelectReceiver from "./components/transfer/SelectReceiver";
import AcceptCGUV from "./components/home/AcceptCGUV";
import ControlDocs from "./components/home/ControlDocs";
import DataEntries from "./components/transfer/DataEntries";
import DataReview from "./components/transfer/DataReview";
import MethodSelection from "./components/transfer/MethodSelection";
import TransferExecution from "./components/transfer/TransferExecution";
import TxHistory from "./components/history/TxHistory";
import TxInfos from "./components/history/TxInfos";
import TxFinalStep from "./components/history/TxFinalStep";
import TxStatement from "./components/profile/payment/txStatement/TxStatement";
import TicketCreation from "./components/support/TicketCreation";
import TicketManager from "./components/support/TicketManager";
import Purchase from "./components/home/Purchase";
import AdditionalKyc from "./components/profile/myAccount/kyc/AdditionalKyc";
import CreditCardManager from "./components/profile/payment/creditCards/CreditCardManager";
import CreditCardDetails from "./components/profile/payment/creditCards/CreditCardDetails";

const routes = [

  { path: '/', exact: true, name: 'Home' },

  { path: '/home', exact: true, name: 'Home', component: Home },
  { path: '/history', exact: true, name: 'History', component: History },
  { path: '/support', exact: true, name: 'Support', component: Support },
  { path: '/profile', exact: true, name: 'Profile', component: Profile },

  { path: '/home/purchase', exact: true, name: 'Home', component: Purchase },
  { path: '/home/accept-cguv', exact: true, name: 'Home', component: AcceptCGUV },
  { path: '/home/control-docs', exact: true, name: 'Home', component: ControlDocs },

  { path: '/transfer/select-receiver', exact: true, name: 'Select receiver', component: SelectReceiver },
  { path: '/transfer/data-entries', exact: true, name: 'Data entries', component: DataEntries },
  { path: '/transfer/data-review', exact: true, name: 'Data entries', component: DataReview },
  { path: '/transfer/method-selection', exact: true, name: 'Method selection', component: MethodSelection },
  { path: '/transfer/execution', exact: true, name: 'Method selection', component: TransferExecution },

  { path: '/history/all', exact: true, name: 'Transaction history', component: TxHistory },
  { path: '/history/tx/:from/:code', exact: true, name: 'Transaction infos', component: TxInfos },
  { path: '/history/tx/:from/:code/finalize-tx', exact: true, name: 'Finalize transaction', component: TxFinalStep },

  { path: '/support/create', exact: true, name: 'Create Ticket', component: TicketCreation },
  { path: '/support/ticket/:id', exact: true, name: 'Manage Ticket', component: TicketManager },

  { path: '/profile/account', exact: true, name: 'My account', component: MyAccount },
  { path: '/profile/account/:from', exact: true, name: 'My account', component: MyAccount },
  { path: '/profile/account/email/manage', exact: true, name: 'Manage email address', component: EmailManagement },
  { path: '/profile/account/email/manage/:from', exact: true, name: 'Manage email address', component: EmailManagement },
  { path: '/profile/account/mobile/create', exact: true, name: 'Add mobile number', component: MobileCreation },
  { path: '/profile/account/kyc/identity', exact: true, name: 'User identity', component: UserIdentity },
  { path: '/profile/account/kyc/identity/:from', exact: true, name: 'User identity', component: UserIdentity },
  { path: '/profile/account/kyc/incomes', exact: true, name: 'User identity', component: UserIncomesInfo },
  { path: '/profile/account/kyc/incomes/:from', exact: true, name: 'User identity', component: UserIncomesInfo },
  { path: '/profile/account/kyc/quota', exact: true, name: 'ID Verification', component: Quota },
  { path: '/profile/account/kyc/quota/:from', exact: true, name: 'ID Verification', component: Quota },
  { path: '/profile/account/add-kyc/:kycName', exact: true, name: 'Additional KYC', component: AdditionalKyc },
  { path: '/profile/account/add-kyc/:kycName/:from', exact: true, name: 'Additional KYC', component: AdditionalKyc },

  { path: '/profile/devices', exact: true, name: 'Devices', component: Devices },
  { path: '/profile/devices/:id', exact: true, name: 'Device Manager', component: DeviceManager },

  { path: '/profile/payment', exact: true, name: 'Payment settings', component: PaymentSettings },
  { path: '/profile/payment/receivers', exact: true, name: 'My receivers', component: ReceiversV3List },
  { path: '/profile/payment/receivers/:from', exact: true, name: 'My receivers', component: ReceiversV3List },
  { path: '/profile/payment/receivers/create/:from', exact: true, name: 'Manage receiver', component: ReceiverV3Manager},
  { path: '/profile/payment/receivers/:id/manage', exact: true, name: 'Manage receiver', component: ReceiverV3Manager },
  { path: '/profile/payment/credit-cards', exact: true, name: 'My credit cards', component: CreditCards },
  { path: '/profile/payment/credit-cards/create', exact: true, name: 'Manage credit card', component: CreditCardManager },
  { path: '/profile/payment/credit-cards/create/:from', exact: true, name: 'Manage credit card', component: CreditCardManager },
  { path: '/profile/payment/credit-cards/:id/manage', exact: true, name: 'Manage credit card', component: CreditCardDetails },
  { path: '/profile/payment/tx-statement', exact: true, name: 'Get Tx statement', component: TxStatement },

  { path: '/:location/invitation', name: 'Invitation', component: InviteFriends },

  { path: '/profile/security', exact: true, name: 'Security Settings', component: SecuritySettings },
  { path: '/profile/security/password-update', exact: true, name: 'Password update', component: PasswordUpdate },
  { path: '/profile/security/password-reset', exact: true, name: 'Password forgot', component: PasswordReset },

  { path: '/profile/follow-us', exact: true, name: 'Follow us on social media', component: FollowUs },
  { path: '/profile/follow-us/:from', exact: true, name: 'Follow us on social media', component: FollowUs },

];

export default routes;
