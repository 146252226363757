import React from 'react';
import {Row, Col} from "antd";
import {Link, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import SubMenuElement from "../../others/SubMenuElement";
import {
  _security_settings, _change_profile_password, _change_profile_password_explanation,
  _password_forgotten, _password_forgotten_explanation, _transfers
} from "../../../UsefulConstants";

function SecuritySettings() {

  const { t } = useTranslation();

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <h5 className="fw-bold">{t(_security_settings)}</h5>
            </div>

            <div className="small mb-1 ml-5">{t(_transfers)}</div>
            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={20} className="my-3">

                <SubMenuElement
                  to={"/profile/security/password-update"}
                  title={t(_change_profile_password)}
                  description={t(_change_profile_password_explanation)}
                />

                <SubMenuElement
                  to={"/profile/security/password-reset"}
                  title={t(_password_forgotten)}
                  description={t(_password_forgotten_explanation)}
                />

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(SecuritySettings);