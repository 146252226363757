import React from 'react';
import {Row, Col} from "antd";
import {CreditCardFilled} from "@ant-design/icons";
import {displayCreditCardNumber, getCreditCardLogo} from "../../../../UsefulFunctions";

function CreditCardPresentation({creditCard}) {
  let cardImage= creditCard != null ? getCreditCardLogo(creditCard.card_scheme?.toUpperCase()) : null;
  return(
    <Row>
      <div className="d-flex align-items-center mr-3">
        {
          cardImage != null ? (
            <img
              alt={"credit card icon"}
              className="img-fluid"
              src={cardImage}
              style={{ width: "15px", height:"15px", objectFit:"cover" }}
            />
          ) : (
            <CreditCardFilled style={{fontSize:"15px"}} />
          )
        }
      </div>
      <Col flex={"auto"}>
        <div className="fw-bold">
          {creditCard.credit_card_id ? displayCreditCardNumber(creditCard.bin, creditCard.last_four, creditCard.card_scheme) : creditCard.temporary_id}
        </div>
      </Col>
    </Row>
  )
}

export default CreditCardPresentation;