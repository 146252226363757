import React from 'react';
import {Row, Col} from "antd";
import UserBadge from "../../../others/UserBadge";
import {getName} from "../../../../UsefulFunctions";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import "../../../../assets/styles/receiverPresentation.css";

function ReceiverPresentation({receiver}) {

  return(
    <Row className="receiverPresentation">
      <UserBadge user={receiver} />
      <Col flex="auto" className="infos">
        <div className="d-flex flex-column">
          <div className="fw-bolder text-ellipsis">{getName(receiver)}</div>
          <div className="text-mobile">{formatPhoneNumberIntl(''+receiver.mobile)}</div>
        </div>
      </Col>
    </Row>
  )

}

export default ReceiverPresentation;