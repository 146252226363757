import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Skeleton, Input} from "antd";
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import axios from "axios";
import RemoteCall, {getDate, notification, textWithFirstCapitalLetter} from "../../../../UsefulFunctions";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {
  _kamix_profile, _message, _modification_applied, _monthly_funds_transfer, _monthly_funds_transfer_explanation,
  _next, _quota, _quota_selected, _required, _status, _updated_at, _verified_at
} from "../../../../UsefulConstants";
import "../../../../assets/styles/myAccount.css";

function Quota() {

  const { t } = useTranslation();
  const { from } = useParams();
  const history = useHistory();

  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [loadingSelection,setLoadingSelection] = useState(false);
  const [quotaSelected,setQuotaSelected] = useState('');
  const [isQuotaSelectedValid,setIsQuotaSelectedValid] = useState(true);
  const [userQuota,setUserQuota] = useState(null);


  function onChangeQuotaSelected(e){
    setQuotaSelected(e.target.value);
    setIsQuotaSelectedValid(true);
  }

  async function getUserQuota(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "kyc/mandatory/v3/quota",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      setUserQuota(response.user_quota);
      if(response.user_quota != null) setQuotaSelected(response.user_quota.quota)
    }

  }

  async function selectQuota(){

    if(quotaSelected === ''){
      setIsQuotaSelectedValid(false)
    }else{

      setLoadingSelection(l => !l);

      let response = await RemoteCall(
        true,
        "kyc/mandatory/v3/quota",
        "patch",
        false,
        null,
        {
          updates: [
            {label: "quota", value: quotaSelected}
          ]
        },
        source.token,
        history
      );

      setLoadingSelection(l => !l);

      if(response != null){
        notification("success",t(_modification_applied));
        setUserQuota(response.user_quota);
      }

    }

  }

  useEffect(
    () => {
      getUserQuota();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={from != null ? "/home" : "/profile/account"}>
              <ArrowLeftOutlined className="icon-sm mb-3" />
            </Link>


            <div className="mb-3 ml-5">
              <small>{t(_kamix_profile)}</small>
              <h5 className="fw-bold">{t(_quota)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loading ? (
                    <div className="mb-2">
                      <Skeleton active paragraph={{ rows: 5 }} />
                    </div>
                  ) : (
                    <>

                      {
                        userQuota != null && userQuota.status !== "INCOMPLETE" &&
                        <>

                          <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                            <Col xs={21}>
                              <div className="text-normal small mb-2">{t(_status)}</div>
                              <div className={"status-"+userQuota.status.toLowerCase()}>
                                {textWithFirstCapitalLetter(t(userQuota.status))}
                              </div>
                            </Col>
                            <Col xs={3} />
                          </Row>

                          {
                            (userQuota.status_message != null && userQuota.status_message !== "") &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_message)}</div>
                                <div className="text-dark">
                                  {userQuota.message}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            userQuota.updated_at != null &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_updated_at)}</div>
                                <div className="text-dark">
                                  {getDate(userQuota.updated_at).fullDate}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            (userQuota.verified_at != null && (userQuota.status === "ACCEPTED" || userQuota.status === "VALIDATED")) &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mb-3">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_verified_at)}</div>
                                <div className="text-dark">
                                  {getDate(userQuota.verified_at).fullDate}
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                          {
                            userQuota.quota != null &&
                            <Row gutter={[16]} className="justify-content-center align-items-start mt-4">
                              <Col xs={21}>
                                <div className="text-normal small mb-2">{t(_quota_selected)}</div>
                                <div className="fw-bold text-dark">
                                  {userQuota.quota} <small>{userQuota.currency}</small>
                                </div>
                              </Col>
                              <Col xs={3} />
                            </Row>
                          }

                        </>
                      }

                      {
                        (userQuota == null || userQuota.status === "INCOMPLETE" || userQuota.status === "REJECTED") &&
                        <div className="mt-4">

                          <h6 className="">{t(_monthly_funds_transfer)}</h6>
                          <div className="text-normal mb-4">{t(_monthly_funds_transfer_explanation)}</div>

                          <Row gutter={[8,8]} className="mb-4">
                            <Col xs={12} lg={8}>
                              <Input
                                type="number"
                                value={quotaSelected}
                                size="large"
                                onChange={onChangeQuotaSelected}
                                status={isQuotaSelectedValid ? "success" : "error"}
                              />
                              {
                                !isQuotaSelectedValid &&
                                <div className="position-absolute ant-form-item-explain-error">
                                  {t(_required)}
                                </div>
                              }
                            </Col>
                          </Row>

                          <Button className="my-button bg-yellow-kamix text-dark mt-2" loading={loadingSelection} onClick={selectQuota}>
                            {t(_next)}
                          </Button>

                        </div>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(Quota);