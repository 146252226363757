import React, {useEffect, useState} from 'react';
import {Row, Col, Collapse, Button, Modal} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {getAmountToDisplay, getTextToDisplay2, notification, WarningModal} from "../../UsefulFunctions";
import {sessionStorage} from "../../Security";
import {
  WalletOutlined,
  BankFilled,
  WarningOutlined,
  CreditCardOutlined,
  ArrowLeftOutlined,
  ExclamationCircleFilled
} from "@ant-design/icons";
import Select from "react-select";
import MyCopyButton from "../others/MyCopyButton";
import CreditCardMiniPresentation from "../profile/payment/creditCards/CreditCardMiniPresentation";
import {
  _balance,
  _bank,
  _bank_transfer,
  _choose_method,
  _continue,
  _crypto_balance,
  _holder,
  _info,
  _insufficient_balance,
  _kamix_banks,
  _kamix_bic,
  _kamix_iban,
  _method_blocked,
  _send_money_from_your_balance,
  _send_money_through_bank_transfer,
  _transfer_with,
  _understood,
  _automatic_bank_transfer,
  _select_country_where_bank_account_is_held,
  _purchase_with,
  _send_fund_by_credit_card,
  _credit_card,
  _select_credit_card_to_use,
  _no_credit_card_registered,
  _add_credit_card,
  _recommended_automatic_bank_transfer,
  yellow_kamix,
  _amount_nearest_50_xaf_warning,
  _no,
  _yes,
  _abandon_transaction_confirm_question
} from "../../UsefulConstants";

const { Panel } = Collapse;

function MethodSelection() {

  const { t } = useTranslation();
  const source = axios.CancelToken.source();
  const history = useHistory();

  const [toggle,setToggle] = useState(false);
  const [countrySelected,setCountrySelected] = useState(null);
  const [cardSelected,setCardSelected] = useState(null);

  const [deviceSessionId, setDeviceSessionId] = useState('');
  const [deviceSessionIdExpiryDate, setDeviceSessionIdExpiryDate] = useState(0);

  const [toggleAbandonTx, setToggleAbandonTx] = useState(false);

  let transactionInformation = sessionStorage.get('transactionInformation');
  let userBalance =  transactionInformation != null && transactionInformation.userBalance;
  let tx_type = transactionInformation != null && transactionInformation.tx_type != null ? transactionInformation.tx_type : "transfer";

  //-----------------------------------------------------------
  let cryptoBalance =
    transactionInformation != null && transactionInformation.allowed_modes != null &&
    transactionInformation.allowed_modes.filter(el => el.name === "CRYPTO_BALANCE")[0]
  ;
  //-----------------------------------------------------------
  let creditCardMode =
    transactionInformation != null && transactionInformation.allowed_modes != null &&
    transactionInformation.allowed_modes.filter(el => el.name === "CREDIT_CARD")[0]
  ;
  if(creditCardMode != null && creditCardMode.data != null && creditCardMode.data.length !== 0){
    for(let el of creditCardMode.data){
      el.value = el.credit_card_id;
      el.label = <CreditCardMiniPresentation creditCard={el} />
    }
  }
  //-----------------------------------------------------------
  let bankTransfer =
    transactionInformation != null && transactionInformation.allowed_modes != null &&
    transactionInformation.allowed_modes.filter(el => el.name === "BANK_TRANSFER")[0]
  ;
  //-----------------------------------------------------------
  let automaticBankTransfer =
    transactionInformation != null && transactionInformation.allowed_modes != null &&
    transactionInformation.allowed_modes.filter(el => el.name === "AUTOMATIC_BANK_TRANSFER")[0]
  ;
  //----------------------------
  if(automaticBankTransfer != null && automaticBankTransfer.data != null && automaticBankTransfer.data.countries != null){
    for(let el of automaticBankTransfer.data.countries){
      el.value = el.country;
      el.label = getTextToDisplay2(el.display_names,"value");
    }
  }
  //-----------------------------------------------------------

  let isOkToUseCryptoBalance =
    (
      cryptoBalance != null && cryptoBalance.data != null && cryptoBalance.data.balance != null &&
      transactionInformation != null && transactionInformation.transaction != null &&
      (
        !cryptoBalance.data.balance.frozen ||
        cryptoBalance.data.balance.balance >= transactionInformation.transaction.amount
      )
    )
  ;

  let isCryptoBalanceFrozen =
    (
      cryptoBalance != null && cryptoBalance.data != null && cryptoBalance.data.balance != null &&
      cryptoBalance.data.balance.frozen
    )
  ;

  //-----------------------------------------------------------

  async function selectMethod(method){

    if(method === "CRYPTO_BALANCE" && !isOkToUseCryptoBalance){
      WarningModal(t(_info),t(_insufficient_balance))
    }else if(method === "CRYPTO_BALANCE" && isCryptoBalanceFrozen){
      setToggle(t=> !t);
    }else if(method === "CREDIT_CARD" && cardSelected == null){
      notification("warning",t(_select_credit_card_to_use));
    }else if(method === "AUTOMATIC_BANK_TRANSFER" && countrySelected == null){
      notification("warning",t(_select_country_where_bank_account_is_held));
    }else{
      let data={};
      data.tx_type = transactionInformation.tx_type;
      data.receiver = transactionInformation.receiver;
      data.userBalance = transactionInformation.userBalance;
      data.transaction = transactionInformation.transaction;
      data.allowed_modes = transactionInformation.allowed_modes;
      //--------------------------------------------
      let devSessionId = null;
      if (deviceSessionId != null && Date.now() < deviceSessionIdExpiryDate) {
        devSessionId = deviceSessionId;
      }
      else {
        try {
          const risk = window.Risk.init(process.env.REACT_APP_RISK_PUBLIC_KEY);
          devSessionId = await risk.publishRiskData(); //expires in 20 minutes
          setDeviceSessionId(devSessionId);
          setDeviceSessionIdExpiryDate(Date.now() + 17 * 60 * 1000);
        } catch (e) {}
      }
      //--------------------------------------------

      if(method === "CREDIT_CARD" && cardSelected != null){
        cardSelected.device_session_id = devSessionId;
        data.creditCard = cardSelected;
        data.isNewCard = false;
      }
      if(method === "AUTOMATIC_BANK_TRANSFER" && countrySelected != null) data.country = countrySelected.value;
      data.method = method;
      await sessionStorage.delete('transactionInformation');
      sessionStorage.set('transactionInformation',data);
      history.push('/transfer/execution');
    }

  }

  function onToggleAbandonTx (toggle) {
    setToggleAbandonTx(toggle);
  }

  function abandonTx () {
    history.push('/home');
  }

  useEffect(
    () => {
      if(transactionInformation == null || transactionInformation === "") history.push('/home');
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <div onClick={() => setToggleAbandonTx(true)}>
              <ArrowLeftOutlined className="icon-sm" />
            </div>

            <div className="mb-3 ml-5">
              <h5 className="fw-bold mb-0">{t(_choose_method)}</h5>
              <small className="fw-500">
                {tx_type === "transfer" ? t(_transfer_with) : t(_purchase_with)}
              </small>
            </div>

            <Row className="small">
              <Col xs={24}>

                <Collapse accordion bordered={false} >

                  {
                    tx_type === "transfer" &&
                    <Panel
                      key="crypto_balance"
                      header={
                        <Row>
                          <div className="createReceiverButton">
                            <WalletOutlined style={{fontSize:"18px"}} />
                          </div>
                          <Col flex="auto" className="infos">
                            <div className="d-flex flex-column">
                              <div className="fw-bolder">{t(_crypto_balance)}</div>
                              <div className="text-mobile">{t(_send_money_from_your_balance)}</div>
                            </div>
                          </Col>
                        </Row>
                      }
                      className={
                        "border-kamix border-radius-kamix p-3 px-md-5 bg-white mb-2" +
                        (isOkToUseCryptoBalance ? "" : " border-danger")
                      }
                      showArrow={false}
                    >
                      <hr className="mt-0" />
                      <div className="small mb-1">{t(_balance)}</div>
                      <div>
                        {
                          cryptoBalance != null ? (
                            <h5 className="mb-0">
                              {
                                cryptoBalance.data != null && cryptoBalance.data.balance != null ?
                                  getAmountToDisplay(cryptoBalance.data.balance.balance, "EUR") : 0
                              }
                              {" "}
                              {
                                cryptoBalance.data != null && cryptoBalance.data.balance != null &&
                                cryptoBalance.data.balance.currency != null && cryptoBalance.data.balance.currency.short_symbols
                              }
                            </h5>
                          ) : (
                            <h5 className="mb-0">
                              {userBalance != null && userBalance.balance != null ? getAmountToDisplay(userBalance.balance, "EUR") : 0}
                              {" "}
                              {userBalance != null && userBalance.currency != null && userBalance.currency.short_symbols}
                            </h5>
                          )
                        }
                      </div>
                      <div className="d-flex justify-content-end cursorView mt-2" onClick={(e)=>selectMethod("CRYPTO_BALANCE",e)}>
                        <span className="fw-bold text-green-kamix">{t(_continue)}</span>
                      </div>
                    </Panel>
                  }

                  {
                    creditCardMode != null &&
                    <Panel
                      key="creditCard"
                      header={
                        <Row>
                          <div className="createReceiverButton">
                            <CreditCardOutlined style={{fontSize:"18px"}} />
                          </div>
                          <Col flex="auto" className="infos">
                            <div className="d-flex flex-column">
                              <div className="fw-bolder">{t(_credit_card)}</div>
                              <div className="text-mobile">{t(_send_fund_by_credit_card)}</div>
                            </div>
                          </Col>
                        </Row>
                      }
                      className="border-kamix border-radius-kamix p-3 px-md-5 py-3 bg-white mb-2"
                      showArrow={false}
                    >
                      <hr className="mt-0" />
                      {
                        (creditCardMode.data != null && creditCardMode.data.length !== 0) ? (
                          <>
                            <div className="small mb-1">{t(_select_credit_card_to_use)}</div>
                            <Row className="mb-2">
                              <Col xs={24} md={16}>
                                <Select
                                  value={cardSelected}
                                  onChange={(e)=>setCardSelected(e)}
                                  options={creditCardMode.data}
                                  isSearchable
                                  required
                                />
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <div className="mb-1">{t(_no_credit_card_registered)}</div>
                        )
                      }
                      <Link to={'/profile/payment/credit-cards/create/t'}>
                        <Button className="my-button-with-inherit-width bg-green-kamix text-white fw-normal small" size="small">
                          {t(_add_credit_card)}
                        </Button>
                      </Link>
                      {
                        (creditCardMode.data != null && creditCardMode.data.length !== 0) &&
                        <div className="d-flex justify-content-end cursorView mt-2" onClick={(e)=>selectMethod("CREDIT_CARD",e)}>
                          <span className="fw-bold text-green-kamix">{t(_continue)}</span>
                        </div>
                      }
                    </Panel>
                  }

                  {
                    automaticBankTransfer != null && automaticBankTransfer.data != null && automaticBankTransfer.data.countries != null &&
                    <Panel
                      key="automaticBankTransfer"
                      header={
                        <Row>
                          <div className="createReceiverButton">
                            <BankFilled style={{fontSize:"18px"}} />
                          </div>
                          <Col flex="auto" className="infos">
                            <div className="d-flex flex-column">
                              <div className="fw-bolder">{t(_automatic_bank_transfer)} </div>
                              <div className="text-mobile">{t(_send_money_through_bank_transfer)} <span className="text-green-kamix text-italic">({t(_recommended_automatic_bank_transfer)})</span> </div>
                            </div>
                          </Col>
                        </Row>
                      }
                      className="border-kamix border-radius-kamix p-3 px-md-5 py-3 bg-white mb-2"
                      showArrow={false}
                    >
                      <hr className="mt-0" />
                      <div className="small mb-1">{t(_select_country_where_bank_account_is_held)}</div>
                      <Row>
                        <Col xs={24} md={16}>
                          <Select
                            value={countrySelected}
                            onChange={(e)=>setCountrySelected(e)}
                            options={automaticBankTransfer.data.countries}
                            isSearchable
                            required
                          />
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end cursorView mt-3" onClick={(e)=>selectMethod("AUTOMATIC_BANK_TRANSFER",e)}>
                        <span className="fw-bold text-green-kamix">{t(_continue)}</span>
                      </div>
                    </Panel>
                  }

                  <Panel
                    key="bankTransfer"
                    header={
                      <Row>
                        <div className="createReceiverButton">
                          <BankFilled style={{fontSize:"18px"}} />
                        </div>
                        <Col flex="auto" className="infos">
                          <div className="d-flex flex-column">
                            <div className="fw-bolder">{t(_bank_transfer)}</div>
                            <div className="text-mobile">{t(_send_money_through_bank_transfer)}</div>
                          </div>
                        </Col>
                      </Row>
                    }
                    className="border-kamix border-radius-kamix p-3 px-md-5 py-3 bg-white border-bottom-kamix"
                    showArrow={false}
                  >
                    <hr className="mt-0" />
                    <div className="small mb-1">{t(_kamix_banks)}</div>
                    {
                      bankTransfer != null && bankTransfer.data != null && bankTransfer.data.kamix_ribs.map((element,index)=>(
                        <React.Fragment key={index}>
                          <Collapse>
                            <Panel
                              key="1"
                              header={
                                <div>
                                  <div className="small">{element.bank_name}</div>
                                  <div className="small fw-bold">{element.iban}</div>
                                </div>
                              }
                            >

                              <Row className="align-items-end mb-3">
                                <div className="d-flex justify-content-center align-items-end mr-2"  style={{marginBottom:"3px"}}>
                                  <MyCopyButton text={element.bank_name} fontSize={13} />
                                </div>
                                <Col flex="auto">
                                  <div className="text-normal small mb-2">{t(_bank)}</div>
                                  <div className="fw-bold small text-black text-ellipsis">{element.bank_name}</div>
                                </Col>
                              </Row>

                              <Row className="align-items-end mb-3">
                                <div className="d-flex justify-content-center align-items-end mr-2"  style={{marginBottom:"3px"}}>
                                  <MyCopyButton text={element.account_holder} fontSize={13} />
                                </div>
                                <Col flex="auto">
                                  <div className="text-normal small mb-2">{t(_holder)}</div>
                                  <div className="fw-bold small text-black text-ellipsis">{element.account_holder}</div>
                                </Col>
                              </Row>

                              <Row className="align-items-end mb-3">
                                <div className="d-flex justify-content-center align-items-end mr-2"  style={{marginBottom:"3px"}}>
                                  <MyCopyButton text={element.iban} fontSize={13} />
                                </div>
                                <Col flex="auto">
                                  <div className="text-normal small mb-2">{t(_kamix_iban)}</div>
                                  <div className="fw-bold small text-black text-ellipsis">{element.iban}</div>
                                </Col>
                              </Row>

                              <Row className="align-items-end mb-3">
                                <div className="d-flex justify-content-center align-items-end mr-2"  style={{marginBottom:"3px"}}>
                                  <MyCopyButton text={element.bic} fontSize={13} />
                                </div>
                                <Col flex="auto">
                                  <div className="text-normal small mb-2">{t(_kamix_bic)}</div>
                                  <div className="fw-bold small text-black text-ellipsis">{element.bic}</div>
                                </Col>
                              </Row>

                            </Panel>
                          </Collapse>
                        </React.Fragment>
                      ))
                    }
                    <div className="d-flex justify-content-end cursorView mt-3" onClick={(e)=>selectMethod("BANK_TRANSFER",e)}>
                      <span className="fw-bold text-green-kamix">{t(_continue)}</span>
                    </div>
                  </Panel>

                </Collapse>

              </Col>
            </Row>

          </Col>
        </Row>

        <Modal
          visible={toggle}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <Row className="justify-content-center">
                <Col xs={3}>
                  <WarningOutlined style={{fontSize:"30px", color:"#BF2626"}} />
                </Col>
                <Col xs={21}>
                  <h6 className="fw-normal mb-3">{t(_method_blocked)}</h6>
                  <div className="d-flex">
                    <Button className="border-radius-kamix bg-yellow-kamix text-dark" onClick={()=>setToggle(t=>!t)}>
                      {t(_understood)}
                    </Button>
                  </div>
                </Col>
              </Row>

            </Col>
          </Row>

        </Modal>

        <Modal
          visible={toggleAbandonTx}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <>
                <div className="d-flex justify-content-center">
                  <ExclamationCircleFilled style={{fontSize:"40px", color:yellow_kamix}} />
                </div>
                <div className="text-normal text-center mt-3 mb-4">{t(_abandon_transaction_confirm_question)}</div>
                <div className="d-flex justify-content-center">
                  <Button className="my-button-2 text-dark fw-normal m-1" onClick={() => onToggleAbandonTx(false)}>{t(_no)}</Button>
                  <Button className="my-button-2 bg-yellow-kamix text-dark m-1" onClick={abandonTx}>{t(_yes)}</Button>
                </div>
              </>

            </Col>
          </Row>

        </Modal>

      </Col>
    </Row>
  )

}

export default withRouter(MethodSelection);