import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Row, Col, Button} from "antd";
import ResetElement from "../components/others/ResetElement";
import KamixLogo from "../assets/images/logo_kamix.png";
import {_app_name, _forget_password_explanation, _forget_password_question, _log_in} from "../UsefulConstants";
import "../assets/styles/pages.css";
import "../assets/styles/constants.css";

function ResetPage() {

  const { t } = useTranslation();

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="margin-bottom-25">
              <Link to={"/sign-in"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
            </div>

            <h1 className="text-header">{t(_forget_password_question)}</h1>
            <div className="text-normal text-justify mb-4">
              {t(_forget_password_explanation)}
            </div>

            <ResetElement where={"out"} />

            <Link to={'/sign-in'}>
              <Button type="dashed" className="border-radius-kamix text-dark mt-5">
                {t(_log_in)}
              </Button>
            </Link>

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default ResetPage;
