import React, {useState, useEffect} from 'react';
import {Row, Col, Skeleton, Input, Button, Modal} from "antd";
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import axios from "axios";
import RemoteCall, {notification, setUserCredentials} from "../../../UsefulFunctions";
import {sessionStorage} from "../../../Security";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {
  _cancel, _continue, _define_email_address, _email_not_verified_yet_text, _email_sent, _modify,
  _modify_email_address, _kamix_profile, _my_email, _my_email_address, _next, _resend_mail, _verified
} from "../../../UsefulConstants";
import "../../../assets/styles/myAccount.css";

function EmailManagement() {

  const { t } = useTranslation();
  const { from } = useParams();
  const history = useHistory();

  let userCredentials = sessionStorage.get('userCredentials');
  const source = axios.CancelToken.source();

  const [toggle,setToggle] = useState(false);
  const [loadingUserInfos,setLoadingUserInfos] = useState(false);
  const [loadingNewEmail,setLoadingNewEmail] = useState(false);
  const [loadingResendCode,setLoadingResetCode] = useState(false);
  const [user,setUser] = useState(null);
  const [newEmail,setNewEmail] = useState("");

  function onToggle(){
    setToggle(t => !t);
    setNewEmail( (user != null && user.email != null) ? user.email.email : "" )
  }

  async function getUserInfos(){

    setLoadingUserInfos(l => !l);

    let response = await RemoteCall(
      true,
      "users",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingUserInfos(l => !l);

    if(response != null){
      await setUserCredentials(userCredentials,response.user);
      setUser(response.user);
    }

  }

  async function modifyEmailAddress(e){
    e.preventDefault();

    setLoadingNewEmail(l => !l);

    let response = await RemoteCall(
      true,
      "users/update-email",
      "patch",
      false,
      null,
      {email:newEmail},
      source.token,
      history
    );

    setLoadingNewEmail(l => !l);

    if(response != null){
      notification("success",t(_email_sent));
      await setUserCredentials(userCredentials,response.user);
      setUser(response.user);
      setToggle(false);
    }

  }

  async function resendCode() {

    setLoadingResetCode(l=>!l);

    let response = await RemoteCall(
      true,
      "users/confirm-email",
      "get",
      false,
      {force:false},
      null,
      source.token,
      history
    );

    setLoadingResetCode(l=>!l);

    if(response != null){
      notification("success",t(_email_sent));
    }

  }

  useEffect(
    () => {
      getUserInfos();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={from != null ? "/home" : "/profile/account"}>
              <ArrowLeftOutlined className="icon-sm mb-3" />
            </Link>


            <div className="mb-3 ml-5">
              <small>{t(_kamix_profile)}</small>
              <h5 className="fw-bold">{t(_my_email)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loadingUserInfos ? (
                    <>
                      <Skeleton active paragraph={{rows: 3}}/>
                    </>
                  ) : (
                    <>

                      {
                        user != null &&
                        <>

                          {
                            user.email == null ? (
                              <>
                                <div className="text-normal small">{t(_define_email_address)}</div>
                                <form onSubmit={modifyEmailAddress}>
                                  <Row className="mt-2 mb-3">
                                    <Col xs={24} md={20}>
                                      <Input
                                        name="address"
                                        type="text"
                                        value={newEmail}
                                        onChange={(e) => setNewEmail(e.target.value)}
                                        className="my-input"
                                        size="large"
                                        required
                                      />
                                    </Col>
                                  </Row>
                                  <div className="d-flex">
                                    <Link to={`/profile/account`}>
                                      <Button className="border-radius-kamix text-dark fw-normal mr-2" disabled={loadingNewEmail}>
                                        {t(_cancel)}
                                      </Button>
                                    </Link>
                                    <Button className="my-button-2 border-radius-kamix bg-yellow-kamix text-dark" loading={loadingNewEmail} htmlType="submit">
                                      {t(_next)}
                                    </Button>
                                  </div>
                                </form>
                              </>
                            ) : (
                              <>
                                <div className="text-normal small mb-2">{t(_my_email_address)}</div>
                                <div className="d-flex">
                                  <Button type="dashed" className="border-radius-kamix text-dark" disabled>
                                    {user.email.email}
                                  </Button>
                                  <Button className="my-button-2 bg-yellow-kamix text-dark fw-500 ml-2" onClick={onToggle}>
                                    {t(_modify)}
                                  </Button>
                                </div>
                                {
                                  user.email.verified ? (
                                    <div className="text-success small mt-1">{t(_verified)} !</div>
                                  ) : (
                                    <Col xs={24} className="mt-3">
                                      <div className="text-normal text-justify mb-2">{t(_email_not_verified_yet_text)}</div>
                                      <Button type="text" className="d-block mb-3"  loading={loadingResendCode} onClick={resendCode}>
                                        <span className="text-underline">{t(_resend_mail)}</span>
                                      </Button>
                                      <Button className="my-button-2 bg-yellow-kamix text-dark" onClick={getUserInfos}>{t(_continue)}</Button>
                                    </Col>
                                  )
                                }
                              </>
                            )
                          }

                        </>
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>

        <Modal
          visible={toggle}
          onCancel={onToggle}
          title={null}
          footer={null}
          closable={false}
        >

          <Row className="justify-content-center">
            <Col xs={22} md={20}>

              <h6 className="fw-bold">{t(_modify_email_address)}</h6>
              <form onSubmit={modifyEmailAddress}>
                <Row className="mt-2 mb-3">
                  <Col xs={24} md={20}>
                    <Input
                      name="address"
                      type="text"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      className="my-input"
                      size="large"
                      required
                    />
                  </Col>
                </Row>
                <div className="d-flex">
                  <Button className="border-radius-kamix text-dark mr-2" disabled={loadingNewEmail} onClick={onToggle}>
                    {t(_cancel)}
                  </Button>
                  <Button className="my-button-2 bg-yellow-kamix text-dark" loading={loadingNewEmail} htmlType="submit">
                    {t(_next)}
                  </Button>
                </div>
              </form>

            </Col>
          </Row>

        </Modal>

      </Col>
    </Row>
  )

}

export default withRouter(EmailManagement);