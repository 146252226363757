import React from 'react';
import {Popover } from "antd";

function MyTooltip({id,color,icon,title,text}) {
  return(
    <>
      <Popover title={title} content={<div className="text-dark text-justify">{text}</div>}>
        <span id={id+"_tooltip"} className={color != null ? "tooltip-icon bg-"+color : "tooltip-icon"}>{icon}</span>
      </Popover>
    </>
  )
}

export default MyTooltip;