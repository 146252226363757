import React, {useEffect} from 'react';
import {Redirect, Route, Switch, withRouter, useHistory} from 'react-router-dom';
import {Layout, BackTop} from "antd";
import routes from '../routes';
import ErrorBoundary from "../components/others/ErrorBoundary";
import DefaultHeader from "./DefaultHeader"
import axios from "axios";
import {sessionStorage} from "../Security";
import RemoteCall, {getName, notification} from "../UsefulFunctions";
import {useTranslation} from "react-i18next";
import {_goodbye} from "../UsefulConstants";
import {UpCircleFilled} from "@ant-design/icons";

const { Content } = Layout;

function DefaultLayout() {

  const { t } = useTranslation();
  const history = useHistory();

  const userCredentials = sessionStorage.get('userCredentials');
  const currencies = sessionStorage.get('currencies');
  const source = axios.CancelToken.source();

  async function tick() {

    if(userCredentials != null){

      let refreshLimit = userCredentials.refreshLimit;
      let user = userCredentials.user;

      if(new Date().getTime() >= refreshLimit ){

        let response = await RemoteCall(
          true,
          "users/devices/token/refresh",
          "get",
          false,
          null,
          null,
          null,
          history
        );

        if(response != null){
          let counter = 60 * 2 * 60000;
          let data = {};
          data.user = user;
          data.token = response.tkn;
          data.device = userCredentials.device;
          data.refreshLimit = new Date().getTime() + counter;
          sessionStorage.delete('userCredentials');
          sessionStorage.set('userCredentials', data);
        }

      }

    }

  }

  async function signOut(e) {

    e.preventDefault();

    let response = await RemoteCall(
      true,
      "users/logout",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    if(response != null){
      sessionStorage.delete('userCredentials');
      history.push('/sign-in');
      notification("success",t(_goodbye) +" " + getName(userCredentials.user));
    }

  }

  async function getCurrencies(){

    let response = await RemoteCall(
      true,
      "transactions/wallet/currencies",
      "get",
      false,
      {limit:true},
      null,
      source.token,
      history
    );

    if(response != null){
      sessionStorage.set('currencies', response.currencies);
    }

  }

  useEffect(
    ()=>{
      if(currencies == null || currencies === "") getCurrencies();
      tick();
      const timerID = setInterval(
        () => tick(),
        60000
      );
      return () => {clearInterval(timerID);}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const style = {
    height: 40, width: 40, fontSize: 20,
    display: 'flex', justifyContent:"center", alignItems:"center",
    backgroundColor: '#172b4d', color: '#fff', borderRadius: 4,
  };

  return(
    <Layout>

      <BackTop duration={100}>
        <UpCircleFilled style={style} />
      </BackTop>

      <DefaultHeader onLogout={e => signOut(e)} />
      <Content className="main">
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                  <ErrorBoundary>
                    <route.component {...props} />
                  </ErrorBoundary>
                )} />
            ) : (null);
          })}
          <Redirect from="/" to="/home" />
        </Switch>
      </Content>

    </Layout>
  )

}

export default withRouter(DefaultLayout);