import React, {useState, useRef, useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {Row, Col, Button, Input} from "antd";
import {useTranslation} from "react-i18next";
import KamixLogo from "../../assets/images/logo_kamix.png";
import ReactCodeInput from "react-code-input";
import axios from "axios";
import RemoteCall, {getName, notification} from "../../UsefulFunctions";
import LanguageSelector from "../../components/others/LanguageSelector";
import {sessionStorage} from "../../Security";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import {
  codeInputProps, strongPassword, _next, _send_code_by_sms_to, _required, _resend_code_text, _code_invalid, _app_name,
  _invalid_password_2, _verification_code_sent_message, _welcome_message_with_user_name, _welcome_message_without_user_name,
  _let_set_password, _log_in, _password_constraint_text, _password, _repeat_thing, _invalid_password, _cancel
} from "../../UsefulConstants";
import "../../assets/styles/pages.css";

function LoginStep3() {

  let loginInformation = sessionStorage.get('loginInformation');
  let user = loginInformation != null && loginInformation.user;
  let mobile = user != null && user.mobiles != null && user.mobiles[0] != null && formatPhoneNumberIntl(user.mobiles[0].mobile);

  const { t } = useTranslation();
  const codeRef = useRef(undefined);
  const history = useHistory();
  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [loadingResendCode,setLoadingResetCode] = useState(false);
  const [code,setCode] = useState('');
  const [isCodeValid,setIsCodeValid] = useState(true);
  const [confirmConnectionId,setConfirmConnectionId] = useState(loginInformation != null ? loginInformation.confirm_connection_id : "");
  const [password,setPassword] = useState('');
  const [password2,setPassword2] = useState('');
  const [isPassword1Valid,setIsPassword1Valid] = useState(true);
  const [isPassword2Valid,setIsPassword2Valid] = useState(true);

  function onChangePassword(e) {
    setPassword(e.target.value);
    setIsPassword1Valid(true);
  }

  function onChangePassword2(e) {
    setPassword2(e.target.value);
    setIsPassword2Valid(true);
  }


  function onChangeCode(code){
    setCode(code);
    setIsCodeValid(true);
  }

  function clearCodeInput() {
    if(codeRef != null && codeRef.current != null){
      codeRef.current.state.input[0] = "";
      codeRef.current.state.input[1] = "";
      codeRef.current.state.input[2] = "";
      codeRef.current.state.input[3] = "";
      codeRef.current.state.input[4] = "";
      codeRef.current.state.input[5] = "";
    }
  }

  async function resendCode() {

    setLoadingResetCode(l=>!l);

    let response = await RemoteCall(
      false,
      "users/confirm-device-connection/"+user.kid,
      "get",
      false,
      {confirm_connection_id:confirmConnectionId},
      null,
      source.token,
      history
    );

    setLoadingResetCode(l=>!l);
    clearCodeInput();

    if(response != null){
      setCode('');
      setConfirmConnectionId(response.confirm_connection_id);
      notification("success",t(_verification_code_sent_message));
    }

  }

  async function onSubmit(e) {

    e.preventDefault();

    if(code === "" || code.length !== 6){
      setIsCodeValid(code !== "" && code.length === 6);
    }else{

      setLoading(l => !l);

      let response = await RemoteCall(
        false,
        "users/confirm-device-connection/"+user.kid,
        "post",
        false,
        {confirm_connection_id:confirmConnectionId},
        {code:code},
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        if(response.redirect_url != null && response.redirect_url !== ""){
          //redirection to  + response.redirect_url
        }else{
          let data = {};
          let welcome = getName(response.user) !== "" ?
            t(_welcome_message_with_user_name).replace("XXXX",getName(response.user)) :
            t(_welcome_message_without_user_name);
          let counter = 60 * 2 * 60000;
          data.user = response.user;
          data.token = response.tkn;
          data.device = response.device;
          data.refreshLimit = new Date().getTime() + counter;
          sessionStorage.set('userCredentials', data);
          history.push('/');
          notification('success',welcome);
        }
      }

    }

  }

  async function onSubmit2(e){

    e.preventDefault();

    if(password === "" || password2 === "" || password !== password2){
      setIsPassword1Valid(password !== "" && strongPassword.test(password));
      setIsPassword2Valid(password2 !== "" && password === password2);
    }else if(!strongPassword.test(password)){
      setIsPassword1Valid(password !== "" && strongPassword.test(password) );
      setIsPassword2Valid(password2 !== "" && password === password2);
    }else{

      let formData = {};
      formData.password = password;
      formData.confirm_password = password2;

      setLoading(l => !l);

      let response = await RemoteCall(
        false,
        "users/login/password/new/"+user.kid,
        "post",
        false,
        null,
        formData,
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        if(response.redirect_url != null && response.redirect_url !== ""){
          //redirection to  + response.redirect_url
        }else{
          let data = {};
          let welcome = getName(response.user) !== "" ?
            t(_welcome_message_with_user_name).replace("XXXX",getName(response.user)) :
            t(_welcome_message_without_user_name);
          let counter = 60 * 2 * 60000;
          data.user = response.user;
          data.token = response.tkn;
          data.device = response.device;
          data.refreshLimit = new Date().getTime() + counter;
          sessionStorage.set('userCredentials', data);
          history.push('/');
          notification('success',welcome);
        }
      }

    }

  }

  useEffect(
    () => {
      if(loginInformation == null || loginInformation === "") history.push('/sign-in');
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="d-flex justify-content-between align-items-center margin-bottom-25">
              <Link to={"#"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
              <LanguageSelector location={"out"} />
            </div>

            {
              confirmConnectionId !=  null ? (
                <>

                  <h1 className="text-header">{t(_log_in)}</h1>
                  <div className="text-normal mb-4">
                    <span className="text-justify">{t(_send_code_by_sms_to)}</span> {" "}
                    <span className="fw-bold one-line">{mobile}</span>
                  </div>

                  <form onSubmit={(e)=>onSubmit(e)}>

                    <div>
                      <ReactCodeInput
                        ref={codeRef}
                        isValid={isCodeValid}
                        type='number'
                        fields={6}
                        value={code}
                        onChange={onChangeCode}
                        autoFocus={false}
                        {...codeInputProps}
                      />
                      {
                        !isCodeValid &&
                        <div className="position-absolute ant-form-item-explain-error mx-3">
                          {code === "" && t(_required)}
                          {code !== "" && code.length !== 6 && t(_code_invalid)}
                        </div>
                      }
                    </div>

                    <div className="mb-4">
                      <Button type="text" className="mt-3" loading={loadingResendCode} onClick={resendCode}>
                        <span className="text-underline">{t(_resend_code_text)}</span>
                      </Button>
                    </div>

                    <div className="d-flex">
                      <Link to={"/sign-in"}>
                        <Button className="special-button bg-light text-dark me-2" disabled={loading}>
                          {t(_cancel)}
                        </Button>
                      </Link>
                      <Button className="special-button bg-yellow-kamix text-dark" loading={loading} htmlType="submit">
                        {t(_next)}
                      </Button>
                    </div>

                  </form>

                </>
              ) : (
                <>

                  {
                    loginInformation != null && getName(loginInformation.user) ? (
                      <>
                        <h1 className="text-header mb-0">{getName(loginInformation.user) + ","}</h1>
                        <h5 className="mb-4">{t(_let_set_password)}</h5>
                      </>
                    ) : (
                      <h1 className="text-header">{t(_let_set_password)}</h1>
                    )
                  }
                  <div className="text-normal text-justify mb-4">{t(_password_constraint_text)}</div>

                  <form onSubmit={(e)=>onSubmit2(e)}>

                    <div className="mb-4">
                      <Input.Password
                        type="password"
                        value={password}
                        onChange={onChangePassword}
                        status={isPassword1Valid ? "success" : "error"}
                        className="my-input"
                        size="large"
                        placeholder={t(_password)}
                      />
                      {
                        !isPassword1Valid &&
                        <div className="position-absolute ant-form-item-explain-error">
                          {password === "" && t(_required)}
                          {password !== "" && !strongPassword.test(password) && t(_invalid_password_2)}
                        </div>
                      }
                    </div>

                    <div className="mb-4">
                      <Input.Password
                        type="password"
                        value={password2}
                        onChange={onChangePassword2}
                        status={isPassword2Valid ? "success" : "error"}
                        className="my-input"
                        size="large"
                        placeholder={t(_repeat_thing)}
                      />
                      {
                        !isPassword2Valid &&
                        <div className="position-absolute ant-form-item-explain-error">
                          {password2 === "" && t(_required)}
                          {password !== password2 && password2 !== "" && t(_invalid_password)}
                        </div>
                      }
                    </div>

                    <div className="d-flex">
                      <Link to={"/sign-in"}>
                        <Button className="special-button bg-light text-dark me-2" disabled={loading}>
                          {t(_cancel)}
                        </Button>
                      </Link>
                      <Button className="special-button bg-yellow-kamix text-dark" loading={loading} htmlType="submit">
                        {t(_next)}
                      </Button>
                    </div>

                  </form>

                </>
              )
            }

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default LoginStep3;
