
const ___escapeRegExp = function (string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll (str, match, replacement) {
    return str.replace(new RegExp(___escapeRegExp(match), 'g'), ()=>replacement);
}

export function isStringEmpty (str) {
    return str==null || (str.replace(" ", "").replace("\n", "").replace("\r", "").replace("\t", "")==="");
}

export function trimToEmpty (_str) {
    let str = _str;
    if (str==null) str = "";
    while (str.endsWith(" ") || str.endsWith("\n") || str.endsWith("\r") || str.endsWith("\t")){
        str = str.substring(0, str.length-1);
    }
    while (str.startsWith(" ") || str.startsWith("\n") || str.startsWith("\r") || str.startsWith("\t")){
        str = str.substring(1);
    }
    return str;
}

export function trimToEmptyForDisplay (_str, defaultValue = "--") {
    let tmp = trimToEmpty(_str)
    return isStringEmpty(tmp) ? defaultValue : tmp;
}

export function trimToNull (_str) {
    let tmp = trimToEmpty(_str)
    return isStringEmpty(tmp) ? null : tmp;
}