import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {PropagateLoader} from "react-spinners";
import {green_kamix} from "./UsefulConstants";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//import * as Sentry from '@sentry/browser';
//Sentry.init({dsn: "https://da72b01d59474163bfd6cabf487f1669@o401144.ingest.sentry.io/5260347"});

import './i18n';
import 'antd/dist/antd.css';
import '../src/assets/styles/main.css';
import 'bootstrap/dist/css/bootstrap.css';
import "antd/dist/antd.css";

import App from './App';

const loading = () =>
  <>
    <div className="d-flex justify-content-center mt-5">
      <PropagateLoader
        sizeUnit={"px"}
        size={10}
        color={green_kamix}
      />
    </div>
  </>;

ReactDOM.render(
  <Suspense fallback={loading()}>
    <App />
  </Suspense>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();