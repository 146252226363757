import {useTranslation} from "react-i18next";
import {Link, useHistory, useParams, withRouter} from "react-router-dom";
import axios from "axios";
import React, {useEffect, useState} from "react";
import RemoteCall, {
    getCountryFromCountryCode,
    getReceiverV3Name,
    getTextToDisplay2,
    notification
} from "../../../../UsefulFunctions";
import {Button, Col, DatePicker, Input, Modal, Row, Skeleton} from "antd";
import {ArrowLeftOutlined, EditOutlined, WarningOutlined} from "@ant-design/icons";
import {
    _as_in_id_document,
    _birth_date,
    _birth_place,
    _cancel,
    _continue,
    _delete,
    _delete_receiver,
    _first_name,
    _invalid_phone_number,
    _last_name,
    _mobile_number,
    _modify,
    _my_receivers,
    _new_receiver,
    _optional,
    _payment_settings,
    _receiver_created,
    _receiver_deleted,
    _receiver_device_question,
    _receiver_modified,
    _relationship_with_receiver,
    _required,
    _save,
    _surname,
    _zip_code
} from "../../../../UsefulConstants";
import Select from "react-select";
import {getCountryCallingCode, isValidPhoneNumber} from "react-phone-number-input";
import "../../../../assets/styles/myAccount.css";
import {isStringEmpty} from "../../../../stringutils";
import moment from "moment";
import {sessionStorage} from "../../../../Security";

const MGT_MODES = {
    creation: "creation",
    edition: "edition"
}
const MGT_CREATION_STEPS = {
    phone_number: "phone_number",
    receiver_info: "receiver_info",
}

function ReceiverV3Manager() {
    const { t } = useTranslation();
    const history = useHistory();
    const { from, id } = useParams();

    const source = axios.CancelToken.source();

    const [mgtMode, setMgtMode] = useState(MGT_MODES.creation);
    const [mgtCreationStep, setMgtCreationStep] = useState(MGT_CREATION_STEPS.phone_number);
    const [toggleDeleteR, setToggleDeleteR] = useState(false);
    const [mgtLoading, setMgtLoading] = useState(false);
    const [mgtDeleteLoading, setMgtDeleteLoading] = useState(false);
    const [gtrLoading, setGtrLoading] = useState(false);

    const [receiverKid, setReceiverKid] = useState('-');
    const [receiver, setReceiver] = useState(null);
    const [relationships, setRelationships] = useState([]);

    const [country, setCountry] = useState(getCountryFromCountryCode('CM'));
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [birthplace, setBirthplace] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [surname, setSurname] = useState("");
    const [relationship, setRelationship] = useState(null);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isBirthdateValid, setIsBirthdateValid] = useState(true);
    const [isBirthplaceValid, setIsBirthplaceValid] = useState(true);
    const [isZipCodeValid, setIsZipCodeValid] = useState(true);
    const [isSurnameValid, setIsSurnameValid] = useState(true);
    const [isRelationshipValid, setIsRelationshipValid] = useState(true);

    function onChangePhoneNumber(e){
        setPhoneNumber(e.target.value);
        setIsPhoneNumberValid(true);
    }

    function onChangeCountry(e) {
        setCountry(e)
    }

    function onChangeFirstName(e) {
        setFirstname(e.target.value);
        setIsNameValid(true);
    }

    function onChangeLastName(e) {
        setLastname(e.target.value);
        setIsNameValid(true);
    }

    function onChangeBirthdate(date) {
        setBirthdate(date);
        setIsBirthdateValid(true)
    }

    function onChangeBirthplace(e) {
        setBirthplace(e.target.value);
        setIsBirthplaceValid(true);
    }

    function onChangeZipcode(e) {
        setZipcode(e.target.value);
        setIsZipCodeValid(true);
    }

    function onChangeSurname(e) {
        setSurname(e.target.value);
        setIsSurnameValid(true);
    }

    function onChangeRelationship(rls) {
        setRelationship(rls);
        setIsRelationshipValid(rls != null);
    }

    function toggleEditPhoneNumber(e) {
        if (mgtMode === MGT_MODES.creation) {
            setMgtCreationStep(MGT_CREATION_STEPS.phone_number)
        }
    }

    async function onSubmitForm (e) {
        e.preventDefault();
        if (mgtMode === MGT_MODES.creation) {
            if (mgtCreationStep === MGT_CREATION_STEPS.phone_number) {
                await handleGetReceiverInfo();
            }
            else if (mgtCreationStep === MGT_CREATION_STEPS.receiver_info) {
                await handleUpdateReceiver();
            }
        }
        else if (mgtMode === MGT_MODES.edition) {
            await handleUpdateReceiver();
        }
    }

    async function handleDeleteReceiver () {
        setMgtDeleteLoading(l => !l);

        let response = await RemoteCall(
            true,
            "transactions/receiversv3/transfer/"+id,
            "delete",
            false,
            null,
            null,
            source.token,
            history
        );

        setMgtDeleteLoading(l => !l);

        if(response != null){
            notification("success",t(_receiver_deleted));
            history.push('/profile/payment/receivers');
        }
    }

    async function fetchRelationships () {
        let response = await RemoteCall(
            true,
            "transactions/receiversv3/transfer/relationships",
            "get",
            false,
            null,
            null,
            source.token,
            history
        )
        if (response != null && response.relationships) {
            return response.relationships;
        }
        return [];

    }

    async function initializeReceiverEdition () {
        setGtrLoading(l => !l);

        let response = await RemoteCall(
            true,
            "transactions/receiversv3/transfer/"+id,
            "get",
            false,
            null,
            null,
            source.token,
            history
        );
        let _relationships = await fetchRelationships();

        setGtrLoading(l => !l);

        if(response != null){
            let receiver = response.transfer_receiver;
            setReceiver(receiver);

            if(receiver != null && receiver.receiver != null){
                let mobile = receiver.receiver.receiver_id;
                let countryCallingCode = getCountryCallingCode(receiver.receiver.country);
                mobile = mobile.toString().replace("+"+countryCallingCode,"");
                setPhoneNumber(mobile);
                setCountry(getCountryFromCountryCode(receiver.receiver.country));
                setFirstname(receiver.receiver.firstname);
                setLastname(receiver.receiver.lastname);
                setBirthdate(receiver.receiver.birthdate ? moment(receiver.receiver.birthdate, "DD/MM/YYYY") : null);
                setBirthplace(receiver.receiver.birthplace);
                setZipcode(receiver.receiver.zip_code);
                setSurname(receiver.surname);

                let currentRelationship = receiver.relationship;
                let table = [];
                let selectedOption = null;
                for (let element of Array.from(_relationships)) {
                    element.value = element.name;
                    element.label = getTextToDisplay2(element.display_names, "value");
                    table.push(element);
                    if(currentRelationship != null){ if(element.name === currentRelationship) selectedOption = element; }
                }
                setRelationships(table);
                setRelationship(selectedOption);

                setIsPhoneNumberValid(true);
                setIsNameValid(true);
                setIsBirthdateValid(true);
                setIsBirthplaceValid(true);
                setIsZipCodeValid(true);
                setIsSurnameValid(true);
                setIsRelationshipValid(true);

                setMgtMode(MGT_MODES.edition);
            }
        }
    }

    async function initializeReceiverCreation () {
        let _relationships = await fetchRelationships();

        let table = [];
        for (let element of Array.from(_relationships)) {
            element.value = element.name;
            element.label = getTextToDisplay2(element.display_names, "value");
            table.push(element);
        }
        setRelationships(table);
        setRelationship(null);

        setMgtMode(MGT_MODES.creation);
        setMgtCreationStep(MGT_CREATION_STEPS.phone_number);
    }

    function validatePhoneNumber () {
        let mobile = '+'+getCountryCallingCode(country.code)+phoneNumber;
        let isValid = isValidPhoneNumber(mobile);
        return {mobile, isValid}
    }

    function validateReceiverInfoForm () {
        let isValidName = !isStringEmpty(firstname) || !isStringEmpty(lastname);
        let isValidBirthdate = birthdate != null;
        let isValidBirthplace = !isStringEmpty(birthplace);
        let isValidZipCode = true;
        let isValidRelationship = relationship != null && !isStringEmpty(relationship.value) && (relationships || []).map(x => x.name).includes(relationship.value);
        let isValidSurname = true;
        return {
            form: isValidName && isValidBirthdate && isValidBirthplace && isValidZipCode && isValidRelationship && isValidSurname,
            details: {
                isValidName, isValidBirthdate, isValidBirthplace, isValidZipCode, isValidRelationship, isValidSurname
            }
        }
    }

    async function handleGetReceiverInfo () {
        let {isValid, mobile} = validatePhoneNumber();
        if (!isValid) {
            setIsPhoneNumberValid(isValid);
            return;
        }

        setMgtLoading(l => !l);

        let response = await RemoteCall(
            true,
            "transactions/receiversv3/transfer/info",
            "get",
            false,
            {phone_number: encodeURIComponent(mobile)},
            null,
            source.token,
            history
        )

        setMgtLoading(l => !l);

        if (response && response['info']) {
            setFirstname(response['info']['firstname']);
            setLastname(response['info']['lastname']);
            setBirthdate(response['info']['birthdate'] ? moment(response['info']['birthdate'], "DD/MM/YYYY") : null);
            setBirthplace(response['info']['birthplace']);
            setZipcode(response['info']['zip_code']);
            setSurname(response['info']['surname']);
            setRelationship(null);

            setReceiverKid(response['info']['kid'])

            setIsNameValid(true);
            setIsBirthdateValid(true);
            setIsBirthplaceValid(true);
            setIsZipCodeValid(true);
            setIsSurnameValid(true);
            setIsRelationshipValid(true);

            setMgtCreationStep(MGT_CREATION_STEPS.receiver_info)
        }

    }

    async function handleUpdateReceiver () {
        let validity = validateReceiverInfoForm();
        if (!validity.form) {
            setIsNameValid(validity.details.isValidName);
            setIsBirthdateValid(validity.details.isValidBirthdate);
            setIsBirthplaceValid(validity.details.isValidBirthplace);
            setIsZipCodeValid(validity.details.isValidZipCode);
            setIsSurnameValid(validity.details.isValidSurname);
            setIsRelationshipValid(validity.details.isValidRelationship);
            return;
        }

        setMgtLoading(l => !l);

        let payload = {
            firstname,
            lastname,
            birthdate: birthdate ? birthdate.format("DD/MM/YYYY") : null,
            birthplace,
            zip_code: zipcode,
            surname,
            relationship: relationship.value
        }

        let response = await RemoteCall(
            true,
            "transactions/receiversv3/transfer/" + receiverKid,
            "post",
            false,
            null,
            payload,
            source.token,
            history
        )

        setMgtLoading(l => !l);

        if(response != null){

            if(from != null && from === "t"){
                let data={};
                data.receiver = response.transfer_receiver;
                sessionStorage.set('transactionInformation',data);
                history.push('/transfer/data-entries');
            }else{
                history.push("/profile/payment/receivers");
            }

            notification('success',t(id != null ? _receiver_modified : _receiver_created));

        }
    }

    useEffect(
        () => {
            if(id != null) initializeReceiverEdition();
            else initializeReceiverCreation();
            setReceiverKid(id);
            return () =>  source.cancel();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );


    return (
        <Row className="justify-content-center mb-2">
            <Col xs={24} md={20} xl={16}>

                <Row className="justify-content-center">
                    <Col xs={24} md={20} xl={16}>

                        <Link
                            to={(from != null && from === "t") ? '/transfer/select-receiver' : '/profile/payment/receivers'}>
                            <ArrowLeftOutlined className="icon-sm"/>
                        </Link>

                        <div className="d-flex flex-column mb-3 ml-5">
                            <small>{t(_payment_settings)} / {t(_my_receivers)}</small>
                            {
                                gtrLoading ? (
                                    <Skeleton.Button active shape style={{width: "300px", height: "30px"}}/>
                                ) : (
                                    <>
                                        {
                                            id != null ? (
                                                <h5 className="fw-bold">{getReceiverV3Name(receiver)}</h5>
                                            ) : (
                                                <h5 className="fw-bold">{t(_new_receiver)}</h5>
                                            )
                                        }
                                    </>
                                )
                            }
                        </div>

                        <Row className="justify-content-center bg-white my-box mb-3">
                            <Col xs={22} md={18} className="my-3 p-2">

                                {
                                    gtrLoading ? (
                                        <>
                                            <Skeleton active paragraph={{rows: 5}}/>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                mgtMode === "edition" &&
                                                <div className="d-flex justify-content-end mb-3">
                                                    <Button className="border-radius-kamix bg-red-kamix text-white"
                                                            onClick={() => setToggleDeleteR(t => !t)}>
                                                        {t(_delete_receiver)}
                                                    </Button>
                                                </div>
                                            }

                                            <div className="mb-4">
                                                <small className="mb-1 text-normal">{t(_mobile_number)} <span className="text-red-kamix">*</span></small>
                                                <div className="d-flex">
                                                    <Select
                                                        className="my-select mr-2"
                                                        classNamePrefix="my-select"
                                                        components={{
                                                            DropdownIndicator: () => null,
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        value={country}
                                                        onChange={onChangeCountry}
                                                        isSearchable
                                                        autosize={false}
                                                        getOptionLabel={(option) => option.name}
                                                        formatOptionLabel={(option, {context}) => {
                                                            return context === 'menu' ? option.label : option.label2;
                                                        }}
                                                        isDisabled={mgtMode === MGT_MODES.edition || (mgtMode === MGT_MODES.creation && mgtCreationStep === MGT_CREATION_STEPS.receiver_info)}
                                                    />
                                                    <div className="full-width">
                                                        <Input
                                                            className="my-input"
                                                            type="number"
                                                            value={phoneNumber}
                                                            onChange={onChangePhoneNumber}
                                                            status={isPhoneNumberValid ? "success" : "error"}
                                                            disabled={mgtMode === MGT_MODES.edition || (mgtMode === MGT_MODES.creation && mgtCreationStep === MGT_CREATION_STEPS.receiver_info)}
                                                            size="large"
                                                            placeholder={t(_mobile_number)}
                                                        />
                                                        {
                                                            !isPhoneNumberValid &&
                                                            <div
                                                                className="position-absolute ant-form-item-explain-error">
                                                                {phoneNumber === "" && t(_required)}
                                                                {phoneNumber !== "" && !isValidPhoneNumber(phoneNumber) && t(_invalid_phone_number)}
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        mgtMode === MGT_MODES.creation && mgtCreationStep === MGT_CREATION_STEPS.receiver_info &&
                                                        <div className="ms-2">
                                                            <Button
                                                                className="my-button-2 border-radius-kamix-2 text-dark fw-normal"
                                                                style={{width: "36px", height: "36px"}}
                                                                onClick={toggleEditPhoneNumber}
                                                            >
                                                                <EditOutlined style={{fontSize: "14px"}}/>
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            {
                                                (mgtMode === MGT_MODES.edition || (mgtMode === MGT_MODES.creation && mgtCreationStep === MGT_CREATION_STEPS.receiver_info)) &&
                                                <>

                                                    <div className="mb-4">
                                                        <div className="mb-1">
                                                            <small className="mb-1 text-normal">{t(_first_name)} <span className="text-red-kamix">*</span></small> <br/>
                                                            <small className="mb-1 text-italic text-green-kamix">{t(_as_in_id_document)}</small>
                                                        </div>
                                                        <Input
                                                            type="text"
                                                            value={firstname}
                                                            onChange={onChangeFirstName}
                                                            status={isNameValid ? "success" : "error"}
                                                            className="my-input"
                                                            size="large"
                                                            placeholder={t(_first_name)}
                                                        />
                                                        {
                                                            !isNameValid &&
                                                            <div
                                                                className="position-absolute ant-form-item-explain-error">
                                                                {t(_required)}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="mb-4">
                                                        <div className={"mb-1"}>
                                                            <small className="mb-1 text-normal">{t(_last_name)} <span className="text-red-kamix">*</span></small> <br/>
                                                            <small className="mb-1 text-italic text-green-kamix">{t(_as_in_id_document)}</small>
                                                        </div>
                                                        <Input
                                                            type="text"
                                                            value={lastname}
                                                            onChange={onChangeLastName}
                                                            status={isNameValid ? "success" : "error"}
                                                            className="my-input"
                                                            size="large"
                                                            placeholder={t(_last_name)}
                                                        />
                                                        {
                                                            !isNameValid &&
                                                            <div
                                                                className="position-absolute ant-form-item-explain-error">
                                                                {t(_required)}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="mb-4">
                                                        <div className={"mb-1"}>
                                                            <small className="mb-1 text-normal">{t(_birth_date)} <span className="text-red-kamix">*</span></small> <br/>
                                                            <small className="mb-1 text-italic text-green-kamix">{t(_as_in_id_document)}</small>
                                                        </div>
                                                        <DatePicker
                                                            type="text"
                                                            value={birthdate}
                                                            onChange={(e) => onChangeBirthdate(e)}
                                                            className="my-input"
                                                            status={birthdate != null ? "success" : "error"}
                                                            size="large"
                                                            placeholder={t(_birth_date)}
                                                        />
                                                        {
                                                            !isBirthdateValid &&
                                                            <div
                                                                className="position-absolute ant-form-item-explain-error">
                                                                {t(_required)}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="mb-4">
                                                        <div className={"mb-1"}>
                                                            <small className="mb-1 text-normal">{t(_birth_place)} <span className="text-red-kamix">*</span></small> <br/>
                                                            <small className="mb-1 text-italic text-green-kamix">{t(_as_in_id_document)}</small>
                                                        </div>
                                                        <Input
                                                            type="text"
                                                            value={birthplace}
                                                            onChange={onChangeBirthplace}
                                                            status={isBirthplaceValid ? "success" : "error"}
                                                            className="my-input"
                                                            size="large"
                                                            placeholder={t(_birth_place)}
                                                        />
                                                        {
                                                            !isBirthplaceValid &&
                                                            <div
                                                                className="position-absolute ant-form-item-explain-error">
                                                                {t(_required)}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="mb-4">
                                                        <small className="mb-1 text-normal">{t(_zip_code)}</small>
                                                        <Input
                                                            type="text"
                                                            value={zipcode}
                                                            onChange={onChangeZipcode}
                                                            status={isZipCodeValid ? "success" : "error"}
                                                            className="my-input"
                                                            size="large"
                                                            placeholder={t(_zip_code) + " (" + t(_optional) + ")"}
                                                        />
                                                        {
                                                            !isZipCodeValid &&
                                                            <div
                                                                className="position-absolute ant-form-item-explain-error">
                                                                {t(_required)}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="mb-4">
                                                        <small className="mb-1 text-normal">{t(_surname)}</small>
                                                        <Input
                                                            type="text"
                                                            value={surname}
                                                            onChange={onChangeSurname}
                                                            status={isSurnameValid ? "success" : "error"}
                                                            className="my-input"
                                                            size="large"
                                                            placeholder={t(_surname) + " (" + t(_optional) + ")"}
                                                        />
                                                        {
                                                            !isSurnameValid &&
                                                            <div
                                                                className="position-absolute ant-form-item-explain-error">
                                                                {t(_required)}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="mb-5">
                                                        <small
                                                            className="mb-1 text-normal">{t(_relationship_with_receiver)} <span className="text-red-kamix">*</span>
                                                        </small>
                                                        <Select
                                                            className={relationship != null ? "my-select-2" : "my-select-2-warning"}
                                                            classNamePrefix={relationship != null ? "my-select-2" : "my-select-2-warning"}
                                                            components={{
                                                                DropdownIndicator: () => null,
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            value={relationship}
                                                            onChange={(e) => onChangeRelationship(e)}
                                                            options={relationships}
                                                            isSearchable
                                                            autosize={false}
                                                            required
                                                        />
                                                        {
                                                            !isRelationshipValid &&
                                                            <div
                                                                className="position-absolute ant-form-item-explain-error">
                                                                {t(_required)}
                                                            </div>
                                                        }
                                                    </div>

                                                </>
                                            }

                                            <div className="d-flex justify-content-end">
                                                <Link to={'/profile/payment/receivers'}>
                                                    <Button className="border-radius-kamix text-dark mr-2"
                                                            disabled={mgtLoading}>{t(_cancel)}</Button>
                                                </Link>
                                                <Button
                                                    className="border-radius-kamix bg-yellow-kamix text-dark fw-normal"
                                                    loading={mgtLoading} onClick={onSubmitForm}>
                                                    {
                                                        mgtMode === MGT_MODES.edition ? t(_modify) :
                                                            ( mgtCreationStep === MGT_CREATION_STEPS.phone_number ? t(_continue) : t(_save) )
                                                    }
                                                </Button>
                                            </div>


                                        </>
                                    )
                                }

                            </Col>
                        </Row>

                    </Col>
                </Row>

                <Modal
                    visible={toggleDeleteR}
                    title={null}
                    footer={null}
                    closable={false}
                >

                    <Row className="justify-content-center">
                        <Col xs={22} md={20}>

                            <Row className="justify-content-center">
                                <Col xs={3}>
                                    <WarningOutlined style={{fontSize:"30px", color:"#BF2626"}} />
                                </Col>
                                <Col xs={21}>
                                    <div className="text-normal mb-3">{t(_receiver_device_question)}</div>
                                    <div className="d-flex">
                                        <Button
                                            className="border-radius-kamix text-dark mr-2"
                                            disabled={mgtDeleteLoading}
                                            onClick={()=>setToggleDeleteR(t=>!t)}
                                        >
                                            {t(_cancel)}
                                        </Button>
                                        <Button
                                            className="border-radius-kamix bg-red-kamix text-white"
                                            loading={mgtDeleteLoading}
                                            onClick={handleDeleteReceiver}
                                        >
                                            {t(_delete)}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </Modal>

            </Col>
        </Row>
    )

}

export default withRouter(ReceiverV3Manager);