import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Skeleton} from "antd";
import {Link, withRouter, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {sessionStorage} from "../Security";
import axios from "axios";
import TxPresentation from "./history/TxPresentation";
import RemoteCall, {getAmountToDisplay, getDate, groupBy, isToday, isYesterday} from "../UsefulFunctions";
import {_no_transaction_made_yet, _purchase, _send, _today, _view_more, _yesterday} from "../UsefulConstants";
import "../assets/styles/history.css";

function History() {

  const { t } = useTranslation();
  const history = useHistory();

  let currencies = sessionStorage.get('currencies');
  let nkap_currency = currencies != null && currencies.filter(c => c.unique_name === "stable")[0];
  const source = axios.CancelToken.source();

  const [loadingSummary,setLoadingSummary] = useState(false);
  const [loadingTransactions,setLoadingTransactions] = useState(false);
  const [summary,setSummary] = useState(null);
  const [transactions,setTransactions] = useState(null);
  const [tx,setTx] = useState(null);
  const [keys,setKeys] = useState(null);
  const [count,setCount] = useState(0);

  async function getTransactions(){

    setLoadingTransactions(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/details/history/list",
      "get",
      false,
      {limit:true},
      null,
      source.token,
      history
    );

    setLoadingTransactions(l => !l);

    if(response != null){
      let transactions = response.transactions;
      let tx = response.transactions;

      for(let tx of transactions) tx.minDate = getDate(tx.max_date).minDate;
      if(transactions.length > 0) transactions = groupBy(transactions,"minDate");

      setCount(response.count);
      setTx(tx);
      setTransactions(transactions);
      setKeys(Object.keys(transactions));
    }

  }

  async function getSummary(){

    setLoadingSummary(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/details/history/summary",
      "get",
      false,
      {labels:"purchases-transfers_out"},
      null,
      source.token,
      history
    );

    setLoadingSummary(l => !l);

    if(response != null){
      setSummary(response.summary);
    }

  }

  useEffect(
    () => {
      getTransactions();
      getSummary();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center my-3">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Row gutter={[16,8]} className="mb-5">
              {
                loadingSummary ? (
                  <>
                    <Col xs={12}>
                      <div className="my-box summary-box">
                        <Skeleton.Button active shape block style={{width:"100%", height:"20px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"30px", margin: "5px 0"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"20px"}} />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="my-box summary-box">
                        <Skeleton.Button active shape block style={{width:"100%", height:"20px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"30px", margin: "5px 0"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"20px"}} />
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    {
                      summary != null && summary.map((element,index)=>(
                        <Col xs={12} key={index}>
                          <div className="my-box summary-box">
                            <div className="text">{t(element.label === "purchases" ? _purchase : _send)}</div>
                            <div className="amount d-none d-sm-block">{getAmountToDisplay(element.summary_amount,"EUR")}</div>
                            <div className="amount d-sm-none">{getAmountToDisplay(element.summary_amount,"EUR",true)}</div>
                            <div className="text">{nkap_currency.short_symbols}</div>
                          </div>
                        </Col>
                      ))
                    }
                  </>
                )
              }
            </Row>

            <Row className="mb-3">
              {
                loadingTransactions ? (
                  <>
                    <div className="full-width mb-3">
                      <Skeleton.Button
                        active shape block
                        style={{height:"25px", borderRadius:"8px", width:"25%", marginBottom:"10px"}}
                      />
                      <div className="my-box bg-white p-4">
                        <Skeleton.Button
                          active shape block
                          style={{height:"90px", padding:"16px", borderRadius: "8px"}}
                        />
                      </div>
                    </div>
                    <div className="full-width">
                      <Skeleton.Button
                        active shape block
                        style={{height:"25px", borderRadius:"8px", width:"25%", marginBottom:"10px"}}
                      />
                      <div className="my-box bg-white p-4">
                        <Skeleton.Button
                          active shape block
                          style={{height:"90px", padding:"16px", borderRadius: "8px"}}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="full-width">

                    {
                      transactions != null && transactions.length !== 0 ? (
                        <>

                          {
                            keys != null && keys.map((element,index)=>(
                              <div key={index} className="child mb-3">
                                <>
                                  {
                                    (isToday(transactions[element][0].max_date) || isYesterday(transactions[element][0].max_date)) ? (
                                      <small>
                                        {isToday(transactions[element][0].max_date) ? t(_today) : t(_yesterday)}
                                      </small>
                                    ) : (
                                      <small>
                                        {
                                          getDate(transactions[element][0].max_date).day +" "+
                                          t("months_"+getDate(transactions[element][0].max_date).month) +" "+
                                          getDate(transactions[element][0].max_date).year
                                        }
                                      </small>
                                    )
                                  }
                                </>
                                <div className="my-box bg-white mt-2 p-2">
                                  {
                                    transactions[element].map(item => (
                                      <div key={item.code} className="child">
                                        <Link to={`/history/tx/h/${item.code}`}>
                                          <TxPresentation transaction={item} />
                                        </Link>
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>
                            ))
                          }

                          {
                            tx != null && count > tx.length &&
                            <div className="d-flex justify-content-center my-3">
                              <Link to={'/history/all'}>
                                <Button className="my-button-2 bg-yellow-kamix text-dark">
                                  {t(_view_more)}
                                </Button>
                              </Link>
                            </div>
                          }

                        </>
                      ) : (
                        <div className="my-box bg-white p-2 text-center">
                          <h6 className="my-5">{t(_no_transaction_made_yet)}.</h6>
                        </div>
                      )
                    }

                  </div>
                )
              }
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(History);