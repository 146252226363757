import React  from 'react';
import {Row, Col} from "antd";
import {useTranslation} from "react-i18next";
import {_privacy_policy, _terms_of_use} from "../../UsefulConstants";
import {getLanguage} from "../../UsefulFunctions";

function KamixLegalText() {

  const { t } = useTranslation();
  let lang = getLanguage();
  const CGU = process.env.REACT_APP_CGUV;
  const RGPD = process.env.REACT_APP_RGPD;

  return(
    <>

      {
        lang === "en" ? (
          <Row className="justify-content-center mb-2 d-none d-md-flex">
            <Col flex="auto" className="text-right">
              <small className="text-underline text-right cursorView">
                <a href={CGU} target="_blank" rel="noopener noreferrer">
                  {t(_terms_of_use)}
                </a>
              </small>
            </Col>
            <Col xs={1} className="d-flex justify-content-center align-items-center">|</Col>
            <Col flex="auto" className="text-left">
              <small className="text-underline cursorView">
                <a href={RGPD} target="_blank" rel="noopener noreferrer">
                  {t(_privacy_policy)}
                </a>
              </small>
            </Col>
          </Row>
        ) : (
          <div className="d-flex flex-column align-items-center">
            <small className="text-underline cursorView mb-1">
              <a href={CGU} target="_blank" rel="noopener noreferrer">
                {t(_terms_of_use)}
              </a>
            </small>
            <small className="text-underline cursorView">
              <a href={RGPD} target="_blank" rel="noopener noreferrer">
                {t(_privacy_policy)}
              </a>
            </small>
          </div>
        )
      }

    </>
  )

}

export default KamixLegalText;