import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton, Button, Input, Radio, Space} from "antd";
import {Link, useHistory, withRouter} from "react-router-dom";
import axios from "axios";
import RemoteCall, {notification} from "../../UsefulFunctions";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {
  _required, _support, _contact_kamix, _enter_ticket_title, _ticket_title,
  _select_category, _new_ticket, _ticket_created
} from "../../UsefulConstants";

function TicketCreation() {

  const { t } = useTranslation();
  const history = useHistory();

  const source = axios.CancelToken.source();

  const [loading,setLoading] = useState(false);
  const [loadingCategories,setLoadingCategories] = useState(false);

  const [title,setTitle] = useState('');
  const [isTitleValid,setIsTitleValid] = useState(true);
  const [category,setCategory] = useState('');
  const [isCategoryValid,setIsCategoryValid] = useState(true);
  const [categories,setCategories] = useState(null);

  function onChangeTitle(e){
    setTitle(e.target.value);
    setIsTitleValid(true);
  }

  function onChange(e) {
    setCategory(e.target.value);
    setIsCategoryValid(true);
  }

  async function getCategories(){

    setLoadingCategories(l => !l);

    let response = await RemoteCall(
      true,
      "support/tickets/categories/list",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingCategories(l => !l);

    if(response != null){
      setCategories(response.categories);
    }

  }

  async function createTicket() {

    if(title === "" || category === ""){
      setIsTitleValid(title !== "");
      setIsCategoryValid(category !== "");
    }else{

      setLoading(l => !l);

      let response = await RemoteCall(
        true,
        "support/tickets",
        "post",
        false,
        null,
        {
          title:title,
          category:category
        },
        source.token,
        history
      );

      setLoading(l => !l);

      if(response != null){
        notification('success',t(_ticket_created));
        if(response.ticket != null) history.push('/support/ticket/'+response.ticket.ticket_id);
      }

    }

  }


  useEffect(
    () => {
      getCategories();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/support"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="d-flex flex-column mb-3 ml-5">
              <small>{t(_support)}</small>
              <h5 className="fw-bold">{t(_contact_kamix)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box mb-3">
              <Col xs={22} md={18} className="my-3 p-2">

                <div className="small mb-1">{t(_ticket_title)}</div>
                <div className="mb-4">
                  <Input
                    type="text"
                    value={title}
                    onChange={onChangeTitle}
                    status={isTitleValid ? "success" : "error"}
                    className="my-input"
                    size="large"
                    placeholder={t(_enter_ticket_title)}
                  />
                  {
                    !isTitleValid &&
                    <div className="position-absolute ant-form-item-explain-error">
                      {t(_required)}
                    </div>
                  }
                </div>

                <div className="d-flex align-items-center mb-3">
                  <div className="small">
                    {t(_select_category)}
                  </div>
                  {
                    !isCategoryValid &&
                    <div className="ant-form-item-explain-error ml-3">
                      {t(_required)}
                    </div>
                  }
                </div>
                <div className="mb-4">
                  {
                    loadingCategories ? (
                      <>
                        <Skeleton.Button active shape block style={{width:"100%", height:"20px", borderRadius:"8px", marginBottom:"8px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"20px", borderRadius:"8px", marginBottom:"8px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"20px", borderRadius:"8px", marginBottom:"8px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"20px", borderRadius:"8px", marginBottom:"8px"}} />
                      </>
                    ) : (
                      <>

                        <Radio.Group onChange={onChange} value={category}>
                          <Space direction="vertical">
                            {
                              categories != null && categories.map((category,index)=>(
                                <React.Fragment key={index}>
                                  <Radio value={category}>
                                    {category}
                                  </Radio>
                                </React.Fragment>
                              ))
                            }
                          </Space>
                        </Radio.Group>

                      </>
                    )
                  }
                </div>

                <div className="d-flex">
                  <Button className="border-radius-kamix bg-yellow-kamix text-dark" loading={loading} onClick={createTicket}>
                    {t(_new_ticket)}
                  </Button>
                </div>

              </Col>
            </Row>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(TicketCreation);