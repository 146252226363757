import React, {useEffect, useState} from 'react';
import {Row, Col, Skeleton, Button} from "antd";
import {Link, withRouter, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RemoteCall from "../../../../UsefulFunctions";
import Pagination from "../../../others/Pagination";
import CreditCardPresentation from "./CreditCardPresentation";
import {ArrowLeftOutlined, PlusCircleFilled} from "@ant-design/icons";
import axios from "axios";
import {
  _add_credit_card, _add_credit_card_text, _my_credit_cards, _payment_settings
} from "../../../../UsefulConstants";

function CreditCards() {

  const { t } = useTranslation();
  const source = axios.CancelToken.source();
  const history = useHistory();

  const size = 4;
  const [loading,setLoading] = useState(false);
  const [creditCards,setCreditCards] = useState([]);
  const [creditCardsShowed,setCreditCardsShowed] = useState([]);


  async function getCreditCards(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "transactions/credit-cards",
      "get",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      let ccs = response.credit_cards;
      setCreditCards(ccs);
      setCreditCardsShowed(ccs.slice(0,size));
    }

  }

  useEffect(
    () => {
      getCreditCards();
      return () =>  source.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return(
    <Row className="justify-content-center mb-2">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/profile/payment"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mb-3 ml-5">
              <small>{t(_payment_settings)}</small>
              <h5 className="fw-bold">{t(_my_credit_cards)}</h5>
            </div>

            <Row className="justify-content-center bg-white my-box">
              <Col xs={22} md={20} className="my-3 p-2">

                {
                  loading ? (
                    <Row className="justify-content-center">
                      <Col xs={24} md={22}>
                        <div className="d-flex justify-content-end mb-4">
                          <Skeleton.Button active shape style={{width:"50px"}} />
                        </div>
                        <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"55px", borderRadius:"8px", marginBottom:"10px"}} />
                        <div className="d-flex justify-content-end mt-4">
                          <Skeleton.Button active shape style={{width:"50px"}} />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <>

                      {
                        creditCards.length === 0 ? (
                          <Row className="justify-content-center my-4">
                            <Col xs={24} md={20}>
                              <h6 className="text-normal text-center mb-3">
                                {t(_add_credit_card_text)}
                              </h6>
                              <div className="text-center">
                                <Link to={'/profile/payment/credit-cards/create'}>
                                  <Button className="my-button bg-yellow-kamix text-dark fw-normal">{t(_add_credit_card)}</Button>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <div>

                            <div className="mb-4">
                              <Pagination table={creditCards} size={size} toShow={setCreditCardsShowed} />
                            </div>

                            <Row className="justify-content-center">
                              <Col xs={24} md={22}>
                                {
                                  creditCardsShowed.map(item => (
                                    <div key={item._id}>
                                      <Link to={item.credit_card_id != null ? `/profile/payment/credit-cards/${item.credit_card_id}/manage` : '#'}>
                                        <CreditCardPresentation creditCard={item} />
                                      </Link>
                                    </div>
                                  ))
                                }
                              </Col>
                            </Row>

                            <div className="d-flex justify-content-end mt-4">
                              <Link to={'/profile/payment/credit-cards/create'}>
                                <div className="createReceiverButton">
                                  <PlusCircleFilled style={{fontSize:"20px"}} />
                                </div>
                              </Link>
                            </div>

                          </div>
                        )
                      }

                    </>
                  )
                }

              </Col>
            </Row>

          </Col>
        </Row>


      </Col>
    </Row>
  )

}

export default withRouter(CreditCards);