import React, {useEffect, useState} from 'react';
import {LeftSquareFilled, RightSquareFilled} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {_of} from "../../UsefulConstants";

function Pagination(props) {

  const { t } = useTranslation();

  const [index,setIndex] = useState(1);
  const [start,setStart] = useState(1);
  const [end,setEnd] = useState(props.size);

  function getStartAndEnd(size,page){
    setStart(size*(page-1)+1);
    setEnd(size*page);
  }

  function go(){
    let nextIndex = index;
    let size = props.size;
    let table = props.table;
    let max = Math.ceil(table.length/size);

    if(nextIndex === max){
      nextIndex = 1
    }else{
      nextIndex = nextIndex + 1
    }

    let i = nextIndex * size;
    getStartAndEnd(props.size,nextIndex);
    setIndex(nextIndex);
    props.toShow(props.table.slice(i-size,i));
  }

  function back(){
    let nextIndex = index;
    let size = props.size;
    let table = props.table;
    let max = Math.ceil(table.length/size);

    if(nextIndex === 1){
      nextIndex = max
    }else{
      nextIndex = nextIndex - 1
    }

    let i = nextIndex * size;
    setIndex(nextIndex);
    getStartAndEnd(props.size,nextIndex);
    props.toShow(props.table.slice(i-size,i));
  }

  async function init() {
    await getStartAndEnd(props.size,1);
  }

  useEffect(
    () => {
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  let isActive = props.table.length > props.size;
  let total = props.table.length;

  return(
    <div className="d-flex justify-content-end align-items-center">
      <span className="mr-2">
          {start} - {end > total ? total : end} {t(_of)} {total}
        </span>
      <div className="cursorView mr-2" onClick={isActive ? back : null}>
        <LeftSquareFilled style={{fontSize:"25px", color:`${isActive ? 'black' : 'gray'}`}} />
      </div>
      <div className="cursorView" onClick={isActive ? go : null}>
        <RightSquareFilled style={{fontSize:"25px", color:`${isActive ? 'black' : 'gray'}`}} />
      </div>
    </div>
  )

}

export default Pagination;