import React, {useEffect, useRef, useState} from 'react';
import {Row, Col, Modal, Button, Image, Skeleton} from "antd";
import {useHistory, withRouter, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RemoteCall, {notification} from "../../UsefulFunctions";
import axios from "axios";
import {Link} from "react-router-dom";
import ContentEditable from "react-contenteditable";
import MessagePresentation from "./MessagePresentation";
import FormData from 'form-data';
import {
  ArrowLeftOutlined, EllipsisOutlined, PaperClipOutlined, DownOutlined, SendOutlined, LoadingOutlined
} from "@ant-design/icons";
import {
  _browser_video_warning, _cancel, _delete, _file_size_warning_max_10_mo, _mark_as_solved, _photo, _send, _solved,
  _tap_a_text_message, _ticket_deleted_message, _ticket_solved_message, _warning_delete_ticket_message,
  _warning_mark_ticket_solved_message
} from "../../UsefulConstants";
import "../../assets/styles/ticketManager.css";

function TicketManager() {

  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const source = axios.CancelToken.source();

  const messagesEndRef = useRef(undefined);
  const wrapperRef = useRef(undefined);

  const [loading,setLoading] = useState(false);
  const [loadingSolved,setLoadingSolved] = useState(false);
  const [loadingDelete,setLoadingDelete] = useState(false);
  const [loadingSendMessageText,setLoadingSendMessageText] = useState(false);
  const [loadingSendMessageMultimedia,setLoadingSendMessageMultimedia] = useState(false);

  const [toggle,setToggle] = useState(false);
  const [toggleSolved,setToggleSolved] = useState(false);
  const [toggleDelete,setToggleDelete] = useState(false);
  const [togglePreview,setTogglePreview] = useState(false);

  const [ticket,setTicket] = useState(null);
  const [messages,setMessages] = useState([]);
  //const [page,setPage] = useState(1);
  const page = 1;

  const [message,setMessage] = useState("");
  const [file,setFile] = useState(null);
  const [fileType,setFileType] = useState(null);

  const [displayScrollToBottomButton,setDisplayScrollToBottomButton] = useState(false);

  function onToggle() {
    setToggle(t => !t);
  }

  function onToggleSolved() {
    setToggleSolved(t => !t);
    setToggle(false);
  }

  function onToggleDelete() {
    setToggleDelete(t => !t);
    setToggle(false);
  }

  function onTogglePreview() {
    setTogglePreview(t => !t);
  }

  function onChangeMessage(e){
    setMessage(e.target.value);
  }


  function scrollToBottom(){
    messagesEndRef != null && messagesEndRef.current != null && messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    setDisplayScrollToBottomButton(false);
  }

  function handleScroll(e){
    let element = e.target;
    if((element.scrollHeight - element.scrollTop - element.clientHeight >=10)) {
      setDisplayScrollToBottomButton(true);
    }else{
      setDisplayScrollToBottomButton(false);
    }
  }

  function handleClickOutside(event) {
    if (wrapperRef != null && !(wrapperRef.current != null && wrapperRef.current.contains(event.target))) {
      setToggle(false);
    }
  }

  function uploadFile(e){
    let file = e.target.files[0];
    if(file != null){
      if((file.size/1048576)>10){
        notification("warning",t(_file_size_warning_max_10_mo));
      }else{
        setFile(file);
        setFileType(file.type);
        onTogglePreview();
      }
    }
  }

  async function getTicketInformation(){

    setLoading(l => !l);

    let response = await RemoteCall(
      true,
      "support/tickets/messages/"+id,
      "get",
      false,
      {page:page},
      null,
      source.token,
      history
    );

    setLoading(l => !l);

    if(response != null){
      setTicket(response.ticket);
      setMessages(response.messages.reverse());

      setTimeout(()=>{scrollToBottom()},1500);
    }

  }

  async function markTicketMessagesAsRead(){

    let response = await RemoteCall(
      true,
      "support/tickets/messages/mark-as-read/"+id,
      "patch",
      false,
      null,
      null,
      source.token,
      history
    );

    if(response != null){
      setTicket(response.ticket);
    }

  }

  async function markTicketAsSolved(){

    setLoadingSolved(l => !l);

    let response = await RemoteCall(
      true,
      "support/tickets/solved/"+id,
      "patch",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingSolved(l => !l);

    if(response != null){
      notification("success",t(_ticket_solved_message));
      history.push('/support');
    }

  }

  async function deleteTicket(){

    setLoadingDelete(l => !l);

    let response = await RemoteCall(
      true,
      "support/tickets/"+id,
      "delete",
      false,
      null,
      null,
      source.token,
      history
    );

    setLoadingDelete(l => !l);

    if(response != null){
      notification("success",t(_ticket_deleted_message));
      history.push('/support');
    }

  }

  async function sendMessageText(){

    if(message === ""){
      notification("warning", t(_tap_a_text_message))
    }else{

      setLoadingSendMessageText(l => !l);

      let response = await RemoteCall(
        true,
        "support/tickets/messages/text/"+id,
        "post",
        false,
        null,
        {text:message},
        source.token,
        history
      );

      setLoadingSendMessageText(l => !l);

      if(response != null){
        setMessage("");
        setMessages([...messages, response.ticket_message]);
        scrollToBottom();
      }

    }

  }

  async function sendMessageMultimedia(){

    let formData = new FormData();
    formData.append('file',file,'image');
    formData.append('message_type',fileType.includes("image") ? "PHOTO" : "VIDEO");

    setLoadingSendMessageMultimedia(l => !l);

    let response = await RemoteCall(
      true,
      "support/tickets/messages/media/"+id,
      "post",
      false,
      null,
      formData,
      source.token,
      history
    );

    setLoadingSendMessageMultimedia(l => !l);

    if(response != null){
      setMessage("");
      setMessages([...messages, response.ticket_message]);
      setTimeout(()=>{scrollToBottom()},1000);
      onTogglePreview();
    }

  }


  useEffect(
    () => {
      getTicketInformation();
      markTicketMessagesAsRead();
      setTimeout(()=>{scrollToBottom()},1500);
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        source.cancel();
        document.removeEventListener('mousedown', handleClickOutside);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return(
    <Row className="justify-content-center">
      <Col xs={24} md={20} xl={16}>

        <Row className="justify-content-center">
          <Col xs={24} lg={22} xl={20}>

            <Link to={"/support"}>
              <ArrowLeftOutlined className="icon-sm" />
            </Link>

            <div className="mt-2 mt-md-0 mb-3 mx-2 mx-md-5">
              {
                loading ? (
                  <Row>
                    <Col xs={12} md={20}>
                      <Skeleton.Button active shape block style={{width:"100%", height:"18px", borderRadius:"8px", marginBottom:"5px"}} />
                      <Skeleton.Button active shape block style={{width:"50%", height:"13px", borderRadius:"8px"}} />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col xs={14} md={18}>
                      {
                        ticket != null &&
                        <div>
                          <div className="text-darker text-ellipsis fw-bold">{ticket.title}</div>
                          <small className="text-ellipsis">{ticket.category}</small>
                        </div>
                      }
                    </Col>
                    <Col flex="auto" className="d-flex justify-content-end align-items-center">
                      {
                        (ticket != null && ticket.solved) &&
                        <span className="solvedMessage mr-2">{t(_solved)}</span>
                      }
                      <div className={toggle ? "dropdownButtonClicked" : "dropdownButton"} onClick={onToggle}>
                        <EllipsisOutlined style={{fontSize:"15px", transform:"rotate(90deg)"}} />
                      </div>
                      {
                        toggle &&
                        <div ref={wrapperRef} className="myDropdown">
                          {
                            (ticket != null && !ticket.solved) &&
                            <div className="dropdownOption" onClick={onToggleSolved}>{t(_mark_as_solved)}</div>
                          }
                          <div className="dropdownOption" onClick={onToggleDelete}>{t(_delete)}</div>
                        </div>
                      }
                    </Col>
                  </Row>
                )
              }
            </div>

            {/* Messagerie */}

            <div id="chat-box">

              {
                loading ? (
                  <div>

                    <div id="chat-body" onScroll={handleScroll}>
                      <div className="d-flex">
                        <Skeleton.Button block style={{width:"100%", height:"80px", backgroundColor:"#e7ddd5"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"80px", borderRadius:"4px", marginBottom:"10px"}} />
                      </div>
                      <div className="d-flex">
                        <Skeleton.Button block style={{width:"100%", height:"80px", backgroundColor:"#e7ddd5"}} />
                        <Skeleton.Button active shape block style={{width:"100%", height:"80px", borderRadius:"4px", marginBottom:"10px"}} />
                      </div>
                      <Skeleton.Button active shape block style={{width:"50%", height:"80px", borderRadius:"4px", marginBottom:"10px"}} />
                    </div>

                    <div id="chat-bottom">
                      <div id="chat-input-container">
                        <ContentEditable
                          html={""} // innerHTML of the editable div
                          disabled={true} // use true to disable edition
                          id="chat-input"
                        />
                      </div>
                      <div className="ml-3">
                        <SendOutlined style={{fontSize:"20px"}} />
                      </div>
                      <div className="ml-3">
                        <PaperClipOutlined style={{fontSize:"20px"}} />
                      </div>
                    </div>

                  </div>
                ) : (
                  <div>

                    <div id="chat-body" onScroll={handleScroll}>

                      {
                        messages.map(item => (
                          <div key={item._id} className="mb-2 child">
                            <MessagePresentation message={item}/>
                          </div>
                        ))
                      }
                      <div ref={messagesEndRef} />

                      {
                        displayScrollToBottomButton &&
                        <span className="scrollButton circle" onClick={scrollToBottom}>
                          <DownOutlined style={{fontSize:"13px"}} />
                        </span>
                      }

                    </div>

                    <div id="chat-bottom">
                      <div id="chat-input-container">
                        <ContentEditable
                          html={message} // innerHTML of the editable div
                          disabled={(ticket != null && (ticket.solved || ticket.deleted))} // use true to disable edition
                          onChange={onChangeMessage} // handle innerHTML change
                          id="chat-input"
                        />
                      </div>
                      <div className="ml-3">
                        {
                          loadingSendMessageText ? (
                            <LoadingOutlined style={{fontSize:"20px"}} />
                          ) : (
                            <span
                              className="cursorView"
                              onClick={!(ticket != null && (ticket.solved || ticket.deleted)) ? sendMessageText : null}
                            >
                              <SendOutlined style={{fontSize:"20px"}} />
                            </span>
                          )
                        }
                      </div>
                      <div className="ml-3">
                        <input
                          type="file" name="file" id="file" className="inputfile"
                          onChange={uploadFile} accept="image/png, image/jpeg, video/mp4,video/x-m4v,video/*"
                          disabled={(ticket != null && (ticket.solved || ticket.deleted))}
                        />
                        <label htmlFor="file" className="cursorView">
                          <PaperClipOutlined style={{fontSize:"20px"}} />
                        </label>
                      </div>
                    </div>

                  </div>
                )
              }

            </div>

            {/* Prévisualisation */}

            <Modal
              visible={togglePreview}
              onCancel={onTogglePreview}
              title={null}
              footer={null}
              closable={false}
            >

              <Row className="justify-content-center">
                <Col xs={22} md={20}>

                  {
                    file != null &&
                    <div className="text-center mb-3">
                      {
                        fileType.includes("image") &&
                        <>
                          <Image
                            alt={t(_photo)}
                            className="multimediaPreview"
                            src={URL.createObjectURL(file)}
                          />
                        </>
                      }
                      {
                        fileType.includes("video") &&
                        <div className="text-center">
                          <video className="multimediaPreview" controls="controls">
                            <source src={URL.createObjectURL(file)} type={fileType}/>
                            {t(_browser_video_warning)}
                          </video>
                        </div>
                      }
                    </div>
                  }

                  <div className="d-flex justify-content-center">
                    <Button
                      className="border-radius-kamix text-dark mr-2"
                      disabled={loadingSendMessageMultimedia}
                      onClick={onTogglePreview}
                    >
                      {t(_cancel)}
                    </Button>
                    <Button
                      className="my-button-2 bg-yellow-kamix text-dark"
                      loading={loadingSendMessageMultimedia}
                      onClick={sendMessageMultimedia}
                    >
                      {t(_send)}
                    </Button>
                  </div>

                </Col>
              </Row>

            </Modal>


            {/* Suppression ticket */}

            <Modal
              visible={toggleDelete}
              onCancel={onToggleDelete}
              title={null}
              footer={null}
              closable={false}
            >

              <Row className="justify-content-center">
                <Col xs={22} md={20}>

                  <div className="text-normal text-center mb-3">{t(_warning_delete_ticket_message)}</div>
                  <div className="d-flex justify-content-center">
                    <Button className="border-radius-kamix text-dark mr-2" disabled={loadingDelete} onClick={onToggleDelete}>
                      {t(_cancel)}
                    </Button>
                    <Button className="my-button-2 bg-red-kamix text-white" loading={loadingDelete} onClick={deleteTicket}>
                      {t(_delete)}
                    </Button>
                  </div>

                </Col>
              </Row>

            </Modal>

            {/* Marquer le ticket comme résolu ? */}

            <Modal
              visible={toggleSolved}
              onCancel={onToggleSolved}
              title={null}
              footer={null}
              closable={false}
            >

              <Row className="justify-content-center">
                <Col xs={22} md={20}>

                  <div className="text-normal text-center mb-3">{t(_warning_mark_ticket_solved_message)}</div>
                  <div className="d-flex justify-content-center">
                    <Button className="border-radius-kamix text-dark mr-2" disabled={loadingSolved} onClick={onToggleSolved}>
                      {t(_cancel)}
                    </Button>
                    <Button className="my-button-2 bg-yellow-kamix text-dark" loading={loadingSolved} onClick={markTicketAsSolved}>
                      {t(_mark_as_solved)}
                    </Button>
                  </div>

                </Col>
              </Row>

            </Modal>

          </Col>
        </Row>

      </Col>
    </Row>
  )

}

export default withRouter(TicketManager);