import React from "react";
import {Link, useParams} from "react-router-dom";
import {Row, Col, Button} from "antd";
import {useTranslation} from "react-i18next";
import {_app_name, _go_to_home_page, _information_note} from "../UsefulConstants";
import KamixLogo from "../assets/images/logo_kamix.png";
import "../assets/styles/pages.css";

function SupplyResponse() {

  const { t } = useTranslation();
  const { code } = useParams();
  let codes = ['00','01','02','03','05','12','17','30','34','75','90'];
  let textToDisplay = 'supply_response';
  if(codes.includes(code)) textToDisplay = textToDisplay + "_" + code;

  return(
    <Row className="justify-content-center">

      <Col xs={24} lg={12}>

        <Row className="full-half-page padding-25">
          <Col xs={22} md={18} lg={22} xl={16}>

            <div className="margin-bottom-25">
              <Link to={"#"}>
                <img
                  src={KamixLogo}
                  alt={t(_app_name)}
                  className="kamix-logo"
                />
              </Link>
            </div>

            <h4 className="fw-bold mb-2">{t(_information_note)}</h4>
            <div className="text-dark mb-4">{t(textToDisplay)}</div>

            <Link to={'/'}>
              <Button type="text" className="my-button bg-dark text-white fw-normal mt-2">
                {t(_go_to_home_page)}
              </Button>
            </Link>

          </Col>
        </Row>

      </Col>

      <Col xs={12} className="d-none d-lg-block">
        <div className="full-half-page padding-25 background-illustration-transfer-funds-to-africa"/>
      </Col>

    </Row>
  )

}

export default SupplyResponse;
